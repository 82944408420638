import {
  call, put, all, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  registrationSuccess,
  registrationFailure,
  USER_REGISTRATION_SUBMIT,
  USER_REGISTRATION_ALPHA_SUBMIT,
  registrationAlphaSuccess,
  registrationAlphaFailure,
} from './registrationActions';
import authService from '../../services/authService';
import history from '../../config/history';

function* registration(action) {
  try {
    const { payload } = action;

    yield call(authService.registration, payload);

    yield put(registrationSuccess());
    toast.info('We have sent you an email with your password.');
  } catch (error) {
    const errorMessage = error.response || error;
    // toast.warn(errorMessage);
    yield put(registrationFailure(errorMessage));
  }
}
function* registrationAlpha(action) {
  try {
    const { email, password, token } = action.payload;
    yield call(authService.registrationAlpha, { email, password, token });

    yield put(registrationAlphaSuccess());
    history.push('/dashboard/downloads');
  } catch (error) {
    const errorMessage = error.response || error;
    // toast.warn(errorMessage);
    yield put(registrationAlphaFailure(errorMessage));
  }
}

export default function* registrationSaga() {
  yield all([takeLatest(USER_REGISTRATION_SUBMIT, registration)]);
  yield all([takeLatest(USER_REGISTRATION_ALPHA_SUBMIT, registrationAlpha)]);
}
