import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import React from 'react';
import TelegramIcon from '@material-ui/icons/Telegram';
import IconButton from '@material-ui/core/IconButton';
import { version } from '../../../package.json';

export default function Copyright() {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
      <Typography variant="body2" color="textSecondary" align="center">
        {' © '}
        <Link color="inherit" href="https://www.nzbcloud.com/">
          NZBCloud v.
          {' '}
          {version}
        </Link>
        {' '}
      </Typography>
      {/* <Link color="inherit" href="https://t.me/nzbcloud"> */}
      {/*  <IconButton color="primary" aria-label="Telegram" component="div"> */}
      {/*    <TelegramIcon /> */}
      {/*  </IconButton> */}
      {/* </Link> */}
      {/* {' '} */}
      {/* {new Date().getFullYear()} */}
      {/* {'.'} */}

    </div>
  );
}
