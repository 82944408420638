import React from 'react';

const FileIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g
      id="icon/action/file"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="Rectangle" x="0" y="0" width="24" height="24" />
      <g id="iso" transform="translate(3.000000, 0.000000)" fillRule="nonzero">
        <path
          d="M1.5,0 C0.675,0 0,0.675 0,1.5 L0,22.5 C0,23.325 0.675,24 1.5,24 L16.5,24 C17.325,24 18,23.325 18,22.5 L18,6 L12,0 L1.5,0 Z"
          id="Path"
          fill="#E2E5E7"
        />
        <path
          d="M13.5,6 L18,6 L12,0 L12,4.5 C12,5.325 12.675,6 13.5,6 Z"
          id="Path"
          fill="#B0B7BD"
        />
        <polygon id="Path" fill="#CAD1D8" points="18 11 13 6 18 6" />
      </g>
    </g>
  </svg>
);

export default FileIcon;
