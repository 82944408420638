import React from 'react';
import LottieAnimationPlayer from '../../../atoms/LottieAnimationPlayer';
import useStyles from './styles';

export default function EncodeStatusReadyBadge() {
  const classes = useStyles();

  return (
    <div className={classes.fileTranscodeStatus}>
      • Ready
      <div className={classes.transcodeIconStatusWrapper}><LottieAnimationPlayer animation="EncDone" loop={false} /></div>
    </div>

  );
}
