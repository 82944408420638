import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import PaymentIcon from '@material-ui/icons/Payment';
import moment from 'moment';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { getUserPaymentsSubmit } from '../../redux/payments/paymentsActions';
import fPrice from '../../helpers/formaters';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: 32,
    borderRadius: 2,
  },
  settingsTab: {
    textTransform: 'none',
  },
  container: {
    backgroundColor: '#fff',
    border: '1px solid rgb(237, 237, 237)',
  },
  accountInput: {
    width: '30%',
  },
  textField: {
    width: '30%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class PaymentsTab extends React.Component {
  componentDidMount() {
    const { getUserPayments } = this.props;
    getUserPayments();
  }

  render() {
    const { classes, userPayments } = this.props;
    return (
      <div className={classes.container}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid rgb(237, 237, 237)',
          padding: 20,
        }}
        >
          <PaymentIcon style={{
            marginRight: 8,
            color: '#2c5ce2',
          }}
          />
          <span style={{
            fontWeight: 500,
            fontSize: 16,
          }}
          >
            {' '}
            Payments
          </span>
        </div>
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow style={{ borderBottom: '1px solid #F7F7F7' }}>
                  <TableCell style={{ borderBottom: 'none' }}>Date</TableCell>
                  <TableCell align="right" style={{ borderBottom: 'none' }}>
                    Item
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: 'none' }}>
                    Description
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: 'none' }}>
                    Amount
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: 'none' }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userPayments.map(payment => (
                  <TableRow
                    key={payment.id}
                    style={{ borderBottom: '1px solid #F7F7F7' }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ borderBottom: 'none' }}
                    >
                      {moment(payment.createdAt).format('MM.DD.YYYY')}
                    </TableCell>
                    <TableCell align="right" style={{ borderBottom: 'none' }}>
                      {' '}
                      {payment.plan.name}
                    </TableCell>
                    <TableCell align="right" style={{ borderBottom: 'none' }}>
                      {' '}
                      {payment.plan.description}
                    </TableCell>
                    <TableCell align="right" style={{ borderBottom: 'none' }}>
                      {fPrice(payment.plan.price, payment.plan.currency)}
                    </TableCell>
                    <TableCell align="right" style={{ borderBottom: 'none' }}>
                      {payment.paymentStatus}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => state.payments;
const mapDispatchToProps = dispatch => ({
  getUserPayments: () => {
    dispatch(getUserPaymentsSubmit());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(PaymentsTab));
