import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SmileIcon from '../../atoms/DoneReportSmileIcon';
import history from '../../../config/history';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 2,
  },
  formInput: {
    padding: '0px 24px',
    width: 380,
    '& fieldset': {
      borderRadius: 2,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formInputPicker: {
    padding: '5px 24px',
    '& fieldset': {
      borderRadius: 2,
    },
  },
  errorModalSubTitle: {
    width: 380,
    padding: '0px 10px 10px 24px',
    fontSize: 15,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  reportFilesLabel: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
  },
  browseButton: {
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
      color: '#fff',
    },
  },
  fileNameList: {
    fontSize: 16,
    marginLeft: 8,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 230,
  },
  submit: {
    marginLeft: 20,
    backgroundColor: '#2158eb',
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
    },
  },
  doneModalWrapper: {
    width: 380,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  doneContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '80px 50px',
    flexDirection: 'column',
  },
  doneIconWrapper: {
    width: 120,
    height: 120,
  },
  doneContentText: {
    fontSize: 24,
    color: 'rgba(0,0,0,0.87)',
    textAlign: 'center',
    marginTop: 30,
    fontWeight: 500,
  },
  doneModalFooterText: {
    fontSize: 20,
    color: 'rgba(0,0,0,0.87)',
    marginBottom: 6,
    fontWeight: 500,
  },
  doneModalFooterSubText: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 500,
  },
}));
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Use only a valid email address. “Valid” means “correct”.')
    .required('Come on, man. Enter your email here. '),
  text: Yup.string().required(
    'Some details needed. We can’t read your thoughts so far. ',
  ),
  date: Yup.string().required('Please input date').nullable(),
  time: Yup.string().required('Please input time').nullable(),
});

export default function ReportErrorModal(props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    email,
    handleSendReport,
    reportLoading,
    loadingComplete,
  } = props;

  const handleFormSubmit = (valuesFromForm) => {
    handleSendReport(valuesFromForm);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={!reportLoading ? handleClose : () => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            Report bug
            {' '}
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        {loadingComplete ? (
          <div className={classes.doneModalWrapper}>
            <div className={classes.doneContentWrapper}>
              <div className={classes.doneIconWrapper}>
                <SmileIcon />
              </div>
              <div className={classes.doneContentText}>
                Thanks for helping us make NZB Cloud better!
              </div>
            </div>
            <div
              style={{
                padding: '26px 20px 26px 32px',
                backgroundColor: '#F7F7F7',
                borderTop: '1px solid #E0E0E0',
                justifyContent: 'flex-end',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div className={classes.doneModalFooterText}>
                Still have questions?
              </div>
              <div className={classes.doneModalFooterSubText}>
                Drop us a letter to
                {' '}
                <Link
                  component="button"
                  variant="body2"
                  style={{ fontWeight: 500, fontSize: 16 }}
                  onClick={() => {
                    handleClose();
                    history.push('/contacts');
                  }}
                >
                  support@nzbcloud.com
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Formik
              initialValues={{
                email,
                text: '',
                date: new Date(),
                time: new Date(),
                files: [],
              }}
              validationSchema={validationSchema}
              onSubmit={values => handleFormSubmit(values)}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                touched,
                errors,
                setFieldValue,
              }) => (
                <>
                  <form>
                    <div className={classes.errorModalSubTitle}>
                      If you have any issues with working NZBCloud, please
                      contact us through the form below.
                    </div>
                    <div className={classes.formInput}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        error={!!(touched.email && errors.email)}
                        fullWidth
                        id="new-email"
                        label="Your E-mail"
                        name="email"
                        style={{ marginBottom: 0 }}
                        value={values.email}
                        onChange={handleChange('email')}
                      />
                      <div style={{ color: 'red', height: 20 }}>
                        {touched.email && errors.email}
                      </div>
                    </div>
                    <div className={classes.formInputPicker}>
                      <KeyboardDatePicker
                        // placeholder="2018/10/10"
                        error={!!(touched.date && errors.date)}
                        value={values.date}
                        fullWidth
                        invalidDateMessage=""
                        inputVariant="outlined"
                        label="Date"
                        onChange={(value) => {
                          setFieldValue('date', value);
                        }}
                        format="MM/dd/yyyy"
                      />
                      <div style={{ color: 'red', height: 20 }}>
                        {touched.date && errors.date}
                      </div>
                    </div>
                    <div className={classes.formInputPicker}>
                      <KeyboardTimePicker
                        ampm={false}
                        fullWidth
                        invalidDateMessage=""
                        inputVariant="outlined"
                        error={!!(touched.time && errors.time)}
                        label="Time"
                        value={values.time}
                        onChange={(value) => {
                          setFieldValue('time', value);
                        }}
                      />
                      <div style={{ color: 'red', height: 20 }}>
                        {touched.time && errors.time}
                      </div>
                    </div>
                    <div className={classes.formInputPicker}>
                      <TextField
                        id="outlined-multiline-static"
                        label="Describe your problem"
                        multiline
                        rows="4"
                        error={!!(touched.text && errors.text)}
                        fullWidth
                        name="text"
                        value={values.text}
                        variant="outlined"
                        autoFocus
                        onChange={handleChange('text')}
                      />
                      <div style={{ color: 'red', height: 20 }}>
                        {touched.text && errors.text}
                      </div>
                    </div>
                    <div className={classes.formInputPicker}>
                      <input
                        accept="*"
                        style={{ display: 'none' }}
                        id="reportFiles"
                        type="file"
                        onChange={(event) => {
                          setFieldValue('files', event.target.files);
                        }}
                      />
                      <div className={classes.reportFilesLabel}>
                        <label htmlFor="reportFiles">
                          <Button
                            variant="outlined"
                            color="primary"
                            component="span"
                            className={classes.browseButton}
                          >
                            BROWSE
                          </Button>
                        </label>
                        <div className={classes.fileNameList}>
                          {values.files[0] ? (
                            values.files[0].name
                          ) : (
                            <div style={{ fontWeight: 400 }}>
                              Choose file to upload
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div style={{ height: 4 }}>
                      {reportLoading && <LinearProgress />}
                    </div>
                    <div
                      style={{
                        padding: '12px 32px',
                        backgroundColor: '#F7F7F7',
                        borderTop: '1px solid #E0E0E0',
                        justifyContent: 'flex-end',
                        display: 'flex',
                      }}
                    >
                      <Button
                        onClick={reportLoading ? () => {} : handleClose}
                        variant="outlined"
                        color="primary"
                        component="span"
                        className={classes.browseButton}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={reportLoading}
                        className={classes.submit}
                        disableElevation
                        onClick={handleSubmit}
                      >
                        SEND
                      </Button>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        )}
      </Dialog>
    </div>
  );
}
