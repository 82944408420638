import api from '../helpers/api';

class UsersService {
  setGoogleCredentials(payload) {
    const { token } = payload;
    console.log('UsersService.setGoogleCredentials: ', payload);
    return api.sendRequest('user/save-google-credentials', 'post', {
      token,
    }).then(response => response.data);
  }

  async requestLink({ email }) {
    return api.sendRequest('user/send-login-link', 'post', {
      email,
    }).then(response => response);

    // const { accessToken, expiresIn } = response.data;
    //
    // await localStorage.setItem('@accessToken', accessToken);
    // await localStorage.setItem('@expiresIn', expiresIn);
  }

  unsetGoogleCredentials(payload) {
    const { token } = payload;
    console.log('UsersService.unsetGoogleCredentials: ', payload);
    return api.sendRequest('user/delete-google-credentials', 'post', {
      token,
    }).then(response => response.data);
  }

  setDropboxCredentials(payload) {
    const { token } = payload;
    return api.sendRequest('user/save-dropbox-credentials', 'post', {
      token,
    }).then(response => response.data);
  }

  unsetDropboxCredentials(payload) {
    const { token } = payload;
    return api.sendRequest('user/delete-dropbox-credentials', 'post', {
      token,
    }).then(response => response.data);
  }

  setOnedriveCredentials(payload) {
    return api.sendRequest('user/save-onedrive-credentials', 'post', payload)
      .then(response => response.data);
  }

  unsetOnedriveCredentials(payload) {
    return api.sendRequest('user/delete-onedrive-credentials', 'post', payload)
      .then(response => response.data);
  }

  forgotPassword(payload) {
    return api
      .sendRequest('user/reset-password', 'post', payload)
      .then(response => response.data);
  }

  setNewPassword(payload) {
    return api
      .sendRequest('user/set-new-password', 'post', payload)
      .then(response => response.data);
  }

  verifyUserEmail(payload) {
    return api
      .sendRequest('/user/verify-email', 'post', payload)
      .then(response => response.data);
  }

  getUserPayments() {
    return api
      .sendRequest('/payment/me', 'get')
      .then(response => response.data);
  }

  setNewEmail({ email }) {
    return api
      .sendRequest('/user/change-email', 'post', { newEmail: email })
      .then(response => response.data);
  }

  changePassword({ oldPassword, newPassword }) {
    return api
      .sendRequest('/user/change-password', 'post', { oldPassword, newPassword })
      .then(response => response.data);
  }

  sentEmailVerLinkEmail() {
    return api
      .sendRequest('/user/send-email-verification-link', 'post')
      .then(response => response.data);
  }

  getAvatars() {
    return api
      .sendRequest('/avatars', 'get')
      .then(response => response.data);
  }

  setAvatar(id) {
    return api
      .sendRequest(`/user/change-avatar/${id}`, 'post')
      .then(response => response.data);
  }

  updateAntivirusSettings(payload) {
    return api
      .sendRequest('/settings/antivirus', 'post', { antivirusEnabled: payload })
      .then(response => response.data);
  }

  updateEncryptionSettings(payload) {
    return api
      .sendRequest('/settings/external-encrypt', 'post', { externalStorageEncryptionEnabled: payload })
      .then(response => response.data);
  }

  setWizardFinished(payload) {
    return api
      .sendRequest('/user/wizard-finished', 'post', { status: payload })
      .then(response => response.data);
  }
}

export default new UsersService();
