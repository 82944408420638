import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import Dialog from '@material-ui/core/Dialog';
import CryptoJS from 'crypto-js';
import {
  setWelcomeModalOpen,
  setWelcomeModalStep,
} from '../../../../redux/viewsEffects/viewsEffectsActions';
import ExternalStoragesTab from '../../../SettingsTab/ExternalStorages';
import { setWizardFinishedSubmit } from '../../../../redux/user/userActions';

const usenetProvidersPresets = [
  {
    name: 'Custom',
    serverHostSSL: '',
    serverHostPlain: '',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Astraweb',
    serverHostSSL: 'ssl-eu.astraweb.com',
    serverHostPlain: 'eu.astraweb.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Blocknews EU',
    serverHostSSL: 'eunews.blocknews.net',
    serverHostPlain: 'eunews.blocknews.net',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: '4UX',
    serverHostSSL: 'news.4ux.nl',
    serverHostPlain: 'news.4ux.nl',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: '@Home v1',
    serverHostSSL: 'news.home.nl',
    serverHostPlain: 'newnews.home.nl',
    portSSl: '',
    portPlain: 119,
  },
  {
    name: '@Home v2',
    serverHostSSL: 'newnews.home.nl',
    serverHostPlain: 'newnews.home.nl',
    portSSl: '',
    portPlain: 119,
  },
  {
    name: 'Blocknews US',
    serverHostSSL: 'usnews.blocknews.net',
    serverHostPlain: 'usnews.blocknews.net',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Casema',
    serverHostSSL: 'news.casema.nl',
    serverHostPlain: 'news.casema.nl',
    portSSl: '',
    portPlain: 119,
  },
  {
    name: 'Cheapnews (block)',
    serverHostSSL: 'block.cheapnews.eu',
    serverHostPlain: 'block.cheapnews.eu',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Cheapnews',
    serverHostSSL: 'news.cheapnews.eu',
    serverHostPlain: 'news.cheapnews.eu',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Dommel',
    serverHostSSL: 'textnews.dommel.be',
    serverHostPlain: 'textnews.dommel.be',
    portSSl: '',
    portPlain: 119,
  },
  {
    name: 'Download2day',
    serverHostSSL: 'reader.download2day.com',
    serverHostPlain: 'reader.download2day.com',
    portSSl: '',
    portPlain: 119,
  },
  {
    name: 'EasyNews',
    serverHostSSL: 'secure.news.easynews.com',
    serverHostPlain: 'secure.news.easynews.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'EasyUsenet',
    serverHostSSL: 'reader.easyusenet.nl',
    serverHostPlain: 'reader.easyusenet.nl',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Hitnews EU',
    serverHostSSL: 'ssl.hitnews.eu',
    serverHostPlain: 'news.hitnews.eu',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'I-Telligent',
    serverHostSSL: 'reader.i-telligent.com',
    serverHostPlain: 'news.i-telligent.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'KPN',
    serverHostSSL: 'textnews.kpn.nl',
    serverHostPlain: 'textnews.kpn.nl',
    portSSl: '',
    portPlain: 119,
  },
];
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 2,
  },
  formInput: {
    padding: '0px 24px',
    // width: 380,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formInputHalf: {
    padding: '0px 24px',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  halfWidthBlock: {
    display: 'flex',
  },
  formInputPicker: {
    padding: '5px 24px',
  },
  errorModalSubTitle: {
    // width: 380,
    padding: '0px 10px 10px 24px',
    fontSize: 15,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  reportFilesLabel: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
  },
  fileNameList: {
    fontSize: 16,
    marginLeft: 8,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 230,
  },
  submit: {
    marginLeft: 20,
    backgroundColor: '#2158eb',
  },
  doneModalWrapper: {
    // width: 380,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  doneContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '80px 50px',
    flexDirection: 'column',
  },
  doneIconWrapper: {
    width: 120,
    height: 120,
  },
  doneContentText: {
    fontSize: 24,
    color: 'rgba(0,0,0,0.87)',
    textAlign: 'center',
    marginTop: 30,
    fontWeight: 500,
  },
  doneModalFooterText: {
    fontSize: 20,
    color: 'rgba(0,0,0,0.87)',
    marginBottom: 6,
    fontWeight: 500,
  },
  doneModalFooterSubText: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 500,
  },
  bottom: {
    padding: '12px 32px',
    backgroundColor: 'rgb(247, 247, 247)',
    borderTop: '1px solid rgb(224, 224, 224)',
    justifyContent: 'flex-end',
    display: 'flex',
  },
}));

export default function Step2(props) {
  const classes = useStyles();
  const dispatch = useDispatch();


  const handleFinish = () => {
    dispatch(setWizardFinishedSubmit(true));
  };
  const handleSetStep = () => {
    dispatch(setWelcomeModalStep(1));
  };
  return (
    <div className={classes.stepWrapper}>
      <ExternalStoragesTab />
      <div className={classes.bottom}>


        <Button
          // type="submit"
          variant="contained"
          color="primary"
          // disabled={isLoading}
          className={classes.submit}
          disableElevation
          onClick={handleFinish}
        >
          FINISH
        </Button>
      </div>
    </div>
  );
}
