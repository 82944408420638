import React from 'react';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import Button from '@material-ui/core/Button';

import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import JobItem from './JobItem';
import { clearFinishedJobsSubmit } from '../../../../redux/jobs/jobsActions';


// const test = [{
//   _id: '617e5979a7d94269ac540c27', createdAt: '2021-10-31T08:53:13.804Z', updatedAt: '2021-10-31T08:53:13.804Z', parentID: '617e5979562099b8ccdd1a1c', storageID: 9, name: 'nnnggjhjhjjthjhjfjhjjfjjhjfjhjf.mp4', type: 'VIDEO', mimeType: 'video/mp4', path: '/var/www/media/9/completed/NZBCLOUD/Air War Over Finland 1939 to 1945 (Documentary with rare footage).nzb - nzbcloud/nnnggjhjhjjthjhjfjhjjfjjhjfjhjf.mp4', NZBID: 11, size: '344941940', previewUrl: 'https://s3.nl-ams.scw.cloud/images-394851/617e59756f9e6b003070bf31.png', transcodeStatus: 'executing', customName: 'nnnggjhjhjjthjhjfjhjjfjjhjfjhjf.mp4', transcodeProgress: 66, transcodeTaskHidden: false, transcodeQueuePosition: 2, viruses: [], targetStorage: 'NZBCLOUD', externalStorageId: '9/Air War Over Finland 1939 to 1945 (Documentary with rare footage).nzb - nzbcloud/nnnggjhjhjjthjhjfjhjjfjjhjfjhjf.mp4', externalStorageEncryption: 'NONE', downloadId: '617e596f02fb63f9e55db1d7',
// }, {
//   _id: '617fc29bbcbe7c31852c2338', createdAt: '2021-11-01T10:34:03.542Z', updatedAt: '2021-11-01T10:34:03.542Z', parentID: '617fc29b633c199739ff230e', storageID: 9, name: 'a21d3c1e71d94610a9878fe8b181425a.mp4', type: 'VIDEO', mimeType: 'video/mp4', path: '/var/www/media/9/completed/NZBCLOUD/Showtrial_S01E03_Lady_Tease_1080p_iP_WEBRip_AAC2_0_H264-NTb.nzb - nzbcloud/a21d3c1e71d94610a9878fe8b181425a.mkv', NZBID: 3, size: '970081450', previewUrl: 'https://s3.nl-ams.scw.cloud/images-394851/617fc29052fef9002f234c7a.png', transcodeStatus: 'finished', customName: 'a21d3c1e71d94610a9878fe8b181425a.mp4', transcodeProgress: 100, transcodeTaskHidden: false, transcodeQueuePosition: 3, viruses: [], targetStorage: 'NZBCLOUD', externalStorageId: '9/Showtrial_S01E03_Lady_Tease_1080p_iP_WEBRip_AAC2_0_H264-NTb.nzb - nzbcloud/a21d3c1e71d94610a9878fe8b181425a.mp4', externalStorageEncryption: 'NONE', downloadId: '617fc28766cf6d1029b6e11e',
// }, {
//   _id: '617fc508dc25ecf38c69c7e4', createdAt: '2021-11-01T10:44:24.788Z', updatedAt: '2021-11-01T10:44:24.788Z', parentID: '617fc5089534b59df7b120d5', storageID: 9, name: '0bd7ff610693486ca4de9c3448f9fad3.mkv', type: 'VIDEO', mimeType: 'video/x-matroska', path: '/var/www/media/9/completed/NZBCLOUD/SEAL_Team_S05E05_1080p_WEB_H264-GGWP.nzb - nzbcloud/0bd7ff610693486ca4de9c3448f9fad3.mkv', NZBID: 4, size: '3079014613', previewUrl: 'https://s3.nl-ams.scw.cloud/images-394851/617fc50252fef9002f234c7c.png', transcodeStatus: 'executing', customName: '0bd7ff610693486ca4de9c3448f9fad3.mkv', transcodeProgress: 62, transcodeTaskHidden: false, transcodeQueuePosition: 1, viruses: [], targetStorage: 'NZBCLOUD', externalStorageId: '9/SEAL_Team_S05E05_1080p_WEB_H264-GGWP.nzb - nzbcloud/0bd7ff610693486ca4de9c3448f9fad3.mkv', externalStorageEncryption: 'NONE', downloadId: '617fc4f3efea87fe8ea8684f',
// }, {
//   _id: '617fcc126e9af280e6c648ec', createdAt: '2021-11-01T11:14:26.232Z', updatedAt: '2021-11-01T11:14:26.232Z', parentID: '617fcc125bc192587bba49d3', storageID: 9, name: 'The Abyss (1989) 1080p BluRay Uncut.mkv', type: 'VIDEO', mimeType: 'video/x-matroska', path: '/var/www/media/9/completed/NZBCLOUD/The Abyss (1989) 1080p BluRay DD5_1 Uncut Version x264 NL Sub.nzb - nzbcloud/The Abyss (1989) 1080p BluRay Uncut.mkv', NZBID: 5, size: '6279029224', previewUrl: 'https://s3.nl-ams.scw.cloud/images-394851/617fcc0d52fef9002f234c7d.png', transcodeStatus: 'executing', customName: 'The Abyss (1989) 1080p BluRay Uncut.mkv', transcodeProgress: 1, transcodeTaskHidden: false, transcodeQueuePosition: 3, viruses: [], targetStorage: 'NZBCLOUD', externalStorageId: '9/The Abyss (1989) 1080p BluRay DD5_1 Uncut Version x264 NL Sub.nzb - nzbcloud/The Abyss (1989) 1080p BluRay Uncut.mkv', externalStorageEncryption: 'NONE', downloadId: '617fcabb55d24a13b621783c',
// }, {
//   _id: '6182829a37c0e8d33a07f2cb', createdAt: '2021-11-03T12:37:46.235Z', updatedAt: '2021-11-03T12:37:46.235Z', parentID: '6182829aad5aa83d0a46bbb0', storageID: 9, name: 'fdeb990edc6a4f57ad2a2cd8a667993d.mkv', type: 'VIDEO', mimeType: 'video/x-matroska', path: '/var/www/media/9/completed/NZBCLOUD/Stargirl_S02E13_1080p_WEB_h264-GOSSIP.nzb - nzbcloud/fdeb990edc6a4f57ad2a2cd8a667993d.mkv', NZBID: 1, size: '2047075277', previewUrl: 'https://s3.nl-ams.scw.cloud/images-394851/618282968ba518002f7b872b.png', transcodeStatus: 'queued', customName: 'fdeb990edc6a4f57ad2a2cd8a667993d.mkv', transcodeProgress: 0, transcodeTaskHidden: false, transcodeQueuePosition: 4, viruses: [], targetStorage: 'NZBCLOUD', externalStorageId: '9/Stargirl_S02E13_1080p_WEB_h264-GOSSIP.nzb - nzbcloud/fdeb990edc6a4f57ad2a2cd8a667993d.mkv', externalStorageEncryption: 'NONE', downloadId: '6182828d641f2c1a75b077de',
// }, {
//   _id: '618282f17859dd42715d21e6', createdAt: '2021-11-03T12:39:13.690Z', updatedAt: '2021-11-03T12:39:13.690Z', parentID: '618282f1c4350d3a8ce53653', storageID: 9, name: '3db6bbcd807b41c9a3174aabb9292995.mkv', type: 'VIDEO', mimeType: 'video/x-matroska', path: '/var/www/media/9/completed/NZBCLOUD/Bering_Sea_Gold_S14E02_1080p_WEB_h264-BAE.nzb - nzbcloud/3db6bbcd807b41c9a3174aabb9292995.mkv', NZBID: 3, size: '1595242148', previewUrl: 'https://s3.nl-ams.scw.cloud/images-394851/618282eb8ba518002f7b872c.png', transcodeStatus: 'queued', customName: '3db6bbcd807b41c9a3174aabb9292995.mkv', transcodeProgress: 0, transcodeTaskHidden: false, transcodeQueuePosition: 6, viruses: [], targetStorage: 'NZBCLOUD', externalStorageId: '9/Bering_Sea_Gold_S14E02_1080p_WEB_h264-BAE.nzb - nzbcloud/3db6bbcd807b41c9a3174aabb9292995.mkv', externalStorageEncryption: 'NONE', downloadId: '618282e470b581994a0581f6',
// }, {
//   _id: '618282f6848afa7c1b6bc929', createdAt: '2021-11-03T12:39:18.705Z', updatedAt: '2021-11-03T12:39:18.705Z', parentID: '618282f6ce4db59f211293b6', storageID: 9, name: 'American Pie (1999) Unrated.mkv', type: 'VIDEO', mimeType: 'video/x-matroska', path: '/var/www/media/9/completed/NZBCLOUD/American Pie (1999) UNRATED - 1080p Retail NL Subs.nzb - nzbcloud/American Pie (1999) Unrated.mkv', NZBID: 2, size: '3019531159', previewUrl: 'https://s3.nl-ams.scw.cloud/images-394851/618282f28ba518002f7b872d.png', transcodeStatus: 'queued', customName: 'American Pie (1999) Unrated.mkv', transcodeProgress: 0, transcodeTaskHidden: false, transcodeQueuePosition: 5, viruses: [], targetStorage: 'NZBCLOUD', externalStorageId: '9/American Pie (1999) UNRATED - 1080p Retail NL Subs.nzb - nzbcloud/American Pie (1999) Unrated.mkv', externalStorageEncryption: 'NONE', downloadId: '6182829bb9678968a9ca6959',
// }];


const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  subHeaderWrapper: {
    display: 'flex',
    padding: 10,
    // borderBottom: '1px solid #F7F7F7',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  emptyWrapperTab: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#f3f3f3',
    width: 420,
    height: 420,
    justifyContent: 'center',
    borderRadius: '50%',
    marginTop: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      backgroundColor: 'inherit',
      borderRadius: 0,
    },
  },
  showJobsButton: {
    backgroundColor: '#F0F0F0',
    padding: '4px 10px',
    marginRight: 20,
    textTransform: 'none',
    border: '1px solid #EDEDED',
    '& :hover': {
      backgroundColor: 'red',
    },
  },
  emptyWrapperPopper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  downloadsList: {
    width: 480,
    paddingBottom: 0,
    borderRadius: 2,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    'downloadsList div li:last-child': {
      marginBottom: 80,
    },
  },
  extendedIcon: {
    marginRight: 5,
    fontSize: 20,
  },
}));
const JobsButton = withStyles(theme => ({
  root: {
    backgroundColor: '#F0F0F0',
    padding: '4px 10px',
    // marginRight: 20,
    textTransform: 'none',
    border: '1px solid #EDEDED',
    color: 'rgba(0, 0, 0, 0.6)',
    '&:hover': {
      backgroundColor: '#E5E5E5',
    },
  },
}))(Button);
export default function ActiveJobs(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const { transcodingJobs, compressingJobs } = useSelector(
    state => state.jobs,
  );
  const { onMediaFileClick } = props;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClearFinishedClick = () => {
    dispatch(clearFinishedJobsSubmit());
  };

  const arrayWithoutFinished = transcodingJobs.filter(download => download.transcodeStatus !== 'finished');
  const sortedTranscodeJobs = [..._.chain(arrayWithoutFinished).sortBy('transcodeQueuePosition').reverse().sortBy('transcodeProgress')
    .value()
    .reverse(), ..._.differenceWith(transcodingJobs, arrayWithoutFinished, _.isEqual).reverse()];

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <JobsButton
        variant="contained"
        disableElevation
        // className={classes.showJobsButton}
        // color="default"
        onClick={handleClick}
        endIcon={(
          <div style={{ width: 25, height: 25 }}>
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </div>
        )}
      >
        <TimelapseIcon className={classes.extendedIcon} />
        {/* <div style={{ width: 25, height: 25 }}> */}
        {/*  <LottieAnimationPlayer animation="clockIcon" loop /> */}
        {/* </div> */}
        Running jobs
      </JobsButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{
          borderRadius: 2,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <>
          <List
            className={classes.downloadsList}
            style={
              {
                // width: containerWidth,
                // marginBottom: 70,
              }
            }
            subheader={(
              <div
                className={classes.subHeaderWrapper}
                style={{ paddingLeft: 16 }}
              >
                <div style={{ paddingRight: 50 }}>
                  <Typography
                    variant="h6"
                    style={{ fontSize: 16 }}
                    display="block"
                  >
                    Running jobs
                  </Typography>
                </div>
                <div>
                  <Button onClick={handleClearFinishedClick} color="secondary">
                    Clear finished
                  </Button>
                </div>
              </div>
            )}
          >
            <div style={{ padding: 8, backgroundColor: 'rgb(247, 247, 247)' }}>
              <div
                style={{
                  fontSize: 14,
                  color: 'rgba(0, 0, 0, 0.38)',
                  fontWeight: 500,
                  marginBottom: 8,
                }}
              >
                Encoding
              </div>
              <div>
                {sortedTranscodeJobs.length ? (
                  sortedTranscodeJobs.map(file => (
                    <JobItem
                      file={file}
                      key={file._id}
                      onMediaFileClick={onMediaFileClick}
                    />
                  ))
                ) : (
                  <div
                    style={{
                      textAlign: 'center',
                      marginBottom: 20,
                      fontSize: 16,
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontWeight: 400,
                    }}
                  >
                    You have no encoding files
                  </div>
                )}
              </div>
              {/* <div */}
              {/*  style={{ */}
              {/*    fontSize: 14, */}
              {/*    color: 'rgba(0, 0, 0, 0.38)', */}
              {/*    fontWeight: 500, */}
              {/*    marginBottom: 8, */}
              {/*  }} */}
              {/* > */}
              {/*  Archiving */}
              {/* </div> */}
              {/* {compressingJobs?.length ? ( */}
              {/*  compressingJobs.map(file => ( */}
              {/*    <JobItem file={file} key={file._id} /> */}
              {/*  )) */}
              {/* ) : ( */}
              {/*  <div */}
              {/*    style={{ */}
              {/*      textAlign: 'center', */}
              {/*      marginBottom: 20, */}
              {/*      fontSize: 16, */}
              {/*      color: 'rgba(0, 0, 0, 0.6)', */}
              {/*      fontWeight: 400, */}
              {/*    }} */}
              {/*  > */}
              {/*    You have no archiving files */}
              {/*  </div> */}
              {/* )} */}
            </div>
          </List>
        </>
      </Popover>
    </>
  );
}
