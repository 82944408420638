import React from 'react';
import { useDispatch } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';

import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';

import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';

import { filesLinkUploadSubmit } from '../../../redux/files/filesActions';

import TorrentFileGreenIcon from '../../atoms/TorrentFileGreenIcon';
import NZBFileGreenIcon from '../../atoms/NZBFileGreenIcon';

import SizeIcon from '../../atoms/SizeIcon';
import IndexerIcon from '../../atoms/IndexerIcon';
import formatBytes from '../../../helpers/formatBytes';

const useStyles = makeStyles(theme => ({
  listItemWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-between',
    minWidth: 310,
    zIndex: 2,
  },
  statusIconWrapper: {
    display: 'flex',
  },
  listItem: {
    height: 88,
    // marginBottom: 6,
    padding: 0,
    backgroundColor: '#ffffff',
    borderRadius: 2,
    overflow: 'hidden',
    // cursor: 'pointer',
    // '&:hover': {
    //   opacity: 0.7,
    //   backgroundColor: 'rgba(0, 0, 0, 0.04)'
    // boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.05)',

    // },
  },
}));
export default function ResultItem(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDownloadClick = (id, title) => {
    dispatch(filesLinkUploadSubmit({ id, title }));
  };

  const { data } = props;

  const {
    title, age, indexer, link, size, downloadType,
  } = data;
  // let itemAgeColor;

  const getFileIcon = () => {
    if (downloadType !== 'NZB') {
      return <TorrentFileGreenIcon />;
    }

    return <NZBFileGreenIcon />;
  };
  return (
    <ListItem className={classes.listItem}>
      <div
        style={{
          color: '#E2E5E7',
          marginRight: 12,
          zIndex: 2,
          marginLeft: 10,
          // height: 24,
          // width: 24,
        }}
      >
        {getFileIcon()}
      </div>
      <div className={classes.listItemWrapper}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '90%',
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontSize: 14,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'rgba(0, 0, 0, 0.87)',
              marginBottom: 8,
              maxWidth: '88%',
            }}
          >
            {title}
          </Typography>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                fontSize: 12,
                // color: '#50BCB6',
                display: 'flex',
                alignItems: 'center',
                // marginLeft: 16,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'rgba(0, 0, 0, 0.6)',
                }}
              >
                <div className={classes.statusIconWrapper}>
                  <SizeIcon />
                  <span style={{ marginLeft: 8 }}>{formatBytes(size)}</span>
                  {/*  style={{ color: '#50BCB6', fontSize: 15 }} */}
                  {/* /> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 15,
                  }}
                >
                  <IndexerIcon />
                  {' '}
                  <span style={{ marginLeft: 8 }}>{indexer}</span>
                  {' '}
                </div>
                <div style={{ fontWeight: 500, fontSize: 12, marginLeft: 15 }}>
                  Age:
                  {' '}
                  <span>{age}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            right: 12,
          }}
        >
          <div>
            <Tooltip title="Download via NZBCloud">
              <IconButton
                size="small"
                onClick={() => handleDownloadClick(link, title)}
              >
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </ListItem>
  );
}
