import {
  call, put, all, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  BUG_REPORT_SEND_MESSAGE_SUBMIT,
  bugReportSendMessageFailure,
  bugReportSendMessageSuccess,
  CONTACTS_SEND_MESSAGE_SUBMIT,
  contactsSendMessageFailure,
  contactsSendMessageSuccess,
} from './contactsActions';


import contactsService from '../../services/contactsService';

function* sendMessage({ payload }) {
  try {
    const { email, message } = payload;
    yield call(contactsService.sendMessage, { email, message });

    yield put(contactsSendMessageSuccess());
    // history.push('/dashboard/files');
    toast.info('Message sent. Thank you for making our cloud puffy.');
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(contactsSendMessageFailure(errorMessage));
  }
}
function* sendReport({ payload }) {
  try {
    const {
      email, text, date, files, id,
    } = payload;

    const data = new FormData();
    for (let i = 0; i < files.length; ++i) {
      data.append('files', files[i]);
    }
    data.append('email', email);
    data.append('userId', id);
    data.append('message', text);
    data.append('dateTime', date.toISOString());
    yield call(contactsService.sendReport, data);

    yield put(bugReportSendMessageSuccess());
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(bugReportSendMessageFailure(errorMessage));
  }
}
export default function* contactsSaga() {
  yield all([takeLatest(CONTACTS_SEND_MESSAGE_SUBMIT, sendMessage)]);
  yield all([takeLatest(BUG_REPORT_SEND_MESSAGE_SUBMIT, sendReport)]);
}
