import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Image from 'material-ui-image';
import { Replay } from 'vimond-replay';
import CompoundVideoStreamer from 'vimond-replay/video-streamer/compound';
import PlayPauseButton from 'vimond-replay/components/controls/PlayPauseButton/PlayPauseButton';
import Modal from '@material-ui/core/Modal';
import 'vimond-replay/index.css';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import appConsts from '../../../config/appConsts';
import './custom-styles.css';
import SneilIcon from '../../atoms/SneilIcon';
import CustomPlayer from './Player/player';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './Player/styles';
import EncodeBadge from './Player/encodeBadge';


export default function ReplayModal(props) {
  const classes = useStyles();
  const {
    data, modalHandler, handleClose, onEncodingClick,
  } = props;
  let subtitlesArray = [];
  const {
    transcodeStatus, previewUrl, subtitles, targetStorage, _id,
  } = data;
  const token = localStorage.getItem('@accessToken');
  const [encodeBadgeVisible, setEncodeBadgeVisible] = React.useState(false);

  if (subtitles && subtitles.length) {
    const validSubtitles = subtitles.filter(s => s.isSubtitleValid);
    subtitlesArray = validSubtitles.map(subtitle => ({
      src: `${appConsts.FILES_URL}/files/${subtitle._id}/getSubtitle?token=${token}`,
      label: subtitle.name,
      // language: ' ',
      kind: 'subtitles',
    }));
  }


  const handleEncode = () => {
    onEncodingClick(data);
  };

  const handleVideoError = (err) => {
    console.log(err);
    setEncodeBadgeVisible(true);
  };
  const handleModalClose = () => {
    setEncodeBadgeVisible(false);
    handleClose();
  };

  return (
    <Modal open={modalHandler.open} onClose={handleModalClose} keepMounted={false}>
      <div className={classes.modal}>
        {modalHandler.type === 'IMAGE' && previewUrl && (
          <div className={classes.image}>
            <Image
              imageStyle={{ position: 'inherit', objectFit: 'contain' }}
              style={{
                padding: 0,
                width: '100%',
                height: '100%',
                textAlign: 'center',
              }}
              src={previewUrl}
            />
          </div>
        )}
        {modalHandler.type === 'MEDIA' && (
          <div>
            <div>
              <CustomPlayer
                source={{
                  streamUrl: `${appConsts.FILES_URL}/files/${_id}/play?token=${token}`,
                  startPosition: 0,
                  crossOrigin: '',
                }}
                textTracks={subtitlesArray.length ? subtitlesArray : false}
                onError={err => handleVideoError(err)}
                options={{
                  keyboardShortcuts: {
                    keyMap: {
                      skipBack: 'ArrowLeft',
                      skipForward: 'ArrowRight',
                    },
                  },

                }}
                encodeBadgeVisible={encodeBadgeVisible}
                transcodeStatus={transcodeStatus}
                handleEncode={handleEncode}
              >

                {/*<CompoundVideoStreamer />*/}
              </CustomPlayer>
            </div>
            {transcodeStatus !== 'finished' && targetStorage === 'NZBCLOUD' && !encodeBadgeVisible && (
            <EncodeBadge transcodeStatus={transcodeStatus} handleEncode={handleEncode} />

            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
