import api from '../helpers/api';

class FilesService {
  getDownloads() {
    return api.sendRequest('/downloads', 'get').then(response => response.data);
  }

  pauseFileDownload(id) {
    return api
      .sendRequest(`/downloads/${id}/pause`, 'post')
      .then(response => response.data);
  }

  resumeFileDownload(id) {
    return api
      .sendRequest(`/downloads/${id}/resume`, 'post')
      .then(response => response.data);
  }

  restartFileDownload(id) {
    return api
      .sendRequest(`/downloads/${id}/restart`, 'post')
      .then(response => response.data);
  }

  deleteFileDownload(id) {
    return api
      .sendRequest(`/downloads/${id}/delete`, 'post')
      .then(response => response.data);
  }

  uploadNzbFiles(files) {
    return api
      .sendRequest('/downloads', 'post', files)
      .then(response => response.data);
  }

  getUserStorage() {
    return api.sendRequest('/files', 'get').then(response => response.data);
  }

  getUserStorageFolderData(id) {
    return api
      .sendRequest(`files/${id}`, 'get')
      .then(response => response.data);
  }

  renameRootFolder({ id, newCustomName }) {
    return api
      .sendRequest(`files/${id}/changeCustomName`, 'post', { newCustomName }).then(response => response.data);
  }

  prepareZipFolder(id) {
    return api
      .sendRequest(`files/${id}/zip`, 'get')
      .then(response => response.data);
  }

  getExternalDrivesList() {
    return api
      .sendRequest('/user/external-storages', 'get')
      .then(response => response.data);
  }

  deleteRootFolder(id) {
    return api
      .sendRequest(`files/${id}/delete`, 'post')
      .then(response => response.data);
  }

  clearCompleted() {
    return api
      .sendRequest('/downloads/clearCompleted', 'post')
      .then(response => response.data);
  }

  applyPassword(data) {
    const { id, password } = data;
    return api
      .sendRequest(`downloads/${id}/password`, 'post', { password })
      .then(response => response.data);
  }

  getActiveJobs() {
    return api
      .sendRequest('files/jobs', 'get')
      .then(response => response.data);
  }

  clearFinishedJobs() {
    return api
      .sendRequest('/files/clearCompletedJobs', 'post')
      .then(response => response.data);
  }

  mediaTranscode(data) {
    return api
      .sendRequest(`/files/${data}/startTranscode`, 'post')
      .then(response => response.data);
  }

  uploadByLink(data) {
    return api
      .sendRequest('/downloads/searcher', 'post', data)
      .then(response => response.data);
  }

  downloadByLink(data) {
    return api
      .sendRequest('/downloads/addUrl', 'post', data)
      .then(response => response.data);
  }

  setDownloadDestination(data) {
    return api
      .sendRequest('/user/download-storage', 'post', { storageCredentialType: data })
      .then(response => response.data);
  }

  setStorageType(data) {
    return api
      .sendRequest('/user/storage', 'post', { storageCredentialType: data })
      .then(response => response.data);
  }

  purgeExternalStorage(drive) {
    return api
      .sendRequest('/user/storage-clear', 'post', { storageType: drive })
      .then(response => response.data);
  }
}

export default new FilesService();
