import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';
import WarningIcon from '@material-ui/icons/Warning';
import AddIcon from '@material-ui/icons/Add';
import PublicIcon from '@material-ui/icons/Public';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SettingsInputCompositeIcon from '@material-ui/icons/SettingsInputComposite';
import HttpsIcon from '@material-ui/icons/Https';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import CryptoJS from 'crypto-js';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Grid from '@material-ui/core/Grid';
import ErrorIcon from '@material-ui/icons/Error';

import {
  Card,

  CardHeader,
  IconButton,

} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

import {
  usenetAddConfigSubmit,
  usenetChangeCredentialsSubmit,
  usenetDeleteConfigSubmit,
  usenetEditConfigSubmit,
  usenetGetProviderCredentialsSubmit,
} from '../../../redux/usenet/usenetProvidersActions';
import UsenetModal from './usenetModal';

import {
  setUsenetProviderModalClose,
  setUsenetProviderModalOpen,
} from '../../../redux/viewsEffects/viewsEffectsActions';

const useStyles = makeStyles(theme => ({
  accountTabContainer: {
    display: 'flex',
    border: '1px solid rgb(237, 237, 237)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  emailWarningWrapper: {
    backgroundColor: '#FCEED6',
    borderRadius: 2,
    padding: 24,
    marginLeft: 32,
    marginRight: 32,
    // width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '16px 24px 16px 24px',
    },
  },
  emailWarningButtonWrapper: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      width: '100%',
    },
  },
  sectionWrapper: {
    padding: '24px 32px 24px 32px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 150,
    [theme.breakpoints.down('sm')]: {
      padding: '24px 0px 24px 0px',
    },
  },
  sectionIconWrapper: {
    height: 48,
    width: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
    borderRadius: 2,
  },
  sectionContentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionDataWrapper: {
    marginLeft: 24,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      width: 150,
    },
  },
  sectionTitle: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 500,
  },
  sectionSubTitle: {
    fontSize: 14,
    color: 'rgba(0,0,0,0.38)',
    fontWeight: 400,
    // [theme.breakpoints.down('xs')]: {
    //   whiteSpace: 'nowrap',
    //   overflow: 'hidden',
    //   textOverflow: 'ellipsis',
    // },
  },
  emailWarningTitle: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 2,
    },
  },
  emailWarningText: {
    color: 'rgba(0,0,0,0.6)',
    fontWeight: 400,
  },

  divider: {
    position: 'relative',
    '&:before': {
      content: "''",
      height: 1,
      background: '#F7F7F7',
      width: '100%',
      position: 'absolute',
      top: 0,
    },
  },
  generalInfoContainer: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 16,
    flex: 1,
    borderRadius: 2,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 16,
      marginBottom: 100,
    },
  },
  secondaryContainer: {
    backgroundColor: theme.palette.background.paper,
    width: '49.5%',
    borderRadius: 2,
    height: 320,
    // overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 16,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  credsContainer: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    borderRadius: 2,
    // height: 470,
    // overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: 180,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 180,
    },
  },
  mobileProfileContainer: {
    display: 'none',
    marginBottom: 16,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  profileEmail: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      maxWidth: 156,
    },
  },
  profileAvatarWrapper: {
    width: 140,
    height: 140,
    borderRadius: '50%',
    backgroundColor: 'black',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 24,
    marginTop: 24,
  },
  profileActivePlan: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  expContent: {
    flexDirection: 'column',
  },
  expRoot: {
    padding: '0 32px 0 32px',
  },
  details: {
    borderTop: '1px solid rgb(247, 247, 247)',
    padding: 0,
  },
  avatarList: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  sectionSubTitlePriority: {
    fontSize: 14,
    color: 'rgba(0,0,0,0.38)',
    fontWeight: 400,
    marginLeft: 15,
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: 0,
    },
  },
  sectionSubTitleWrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  iconGrid: {
    paddingLeft: 20,
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 20,
    },
  },
  credName: {
    fontWeight: 500,
    fontSize: 16,
    color: '#1F374D',
    paddingLeft: 5,
  },
  credValue: {
    color: '#1F374D',
    // paddingLeft: 20,
    paddingRight: 32,
  },
  credChangeButton: {
    // backgroundColor: '#2158eb',
    // color: '#fff',
    width: 86,
    [theme.breakpoints.down('xs')]: {
      // display: 'none',
    },
  },

  credAddButton: {
    backgroundColor: '#2158eb',
    borderRadius: 2,
    // height: 52,
    color: '#fff',
    transition: 'all .3s ease-in-out',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
      textDecoration: 'none',
    },
  },
  credChangeButtonMobile: {
    backgroundColor: '#2158eb',
    color: '#fff',
    height: 36,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  credChangeButtonWrapperMobile: {
    display: 'none',
    marginTop: 20,
    paddingLeft: 32,
    paddingRight: 32,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
}));

function ConfigCard(originalCredentials) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={<WarningIcon style={{ color: '#FF4D63' }} />}
        action={(
          <IconButton aria-label="settings">
            <EditIcon />
          </IconButton>
        )}
        title="Config is not valid"
        subheader="Check credentials"
      />
    </Card>
  );
}

export default function UsenetProvidersTab() {
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    dispatch(usenetGetProviderCredentialsSubmit());
  }, []);
  const handleEditCredentials = (data) => {
    dispatch(
      setUsenetProviderModalOpen({
        usenetModalOpen: true,
        usenetModalType: 'edit',
        usenetModalData: data,
      }),
    );
  };
  const handleDeleteConfig = (data) => {
    dispatch(usenetDeleteConfigSubmit(data));
  };
  const handleUsenetModalOpen = () => {
    dispatch(
      setUsenetProviderModalOpen({
        usenetModalOpen: true,
        usenetModalType: 'add',
        usenetModalData: undefined,
      }),
    );
  };
  const handleUsenetModalClose = () => {
    dispatch(
      setUsenetProviderModalClose({
        usenetModalOpen: false,
        usenetModalType: 'add',
        usenetModalData: undefined,
      }),
    );
  };
  const handleChangeCredentials = (data) => {
    dispatch(usenetAddConfigSubmit(data));
  };
  const handleEditCredentialsSubmit = (data) => {
    dispatch(usenetEditConfigSubmit(data));
  };
  const {
    isLoading,
    usenetModalIsLoading,
    credentials,
    usenetError,
  } = useSelector(state => state.usenetReducer);

  const { email } = useSelector(state => state.user);

  const {
    usenetModalOpen = false,
    usenetModalData,
    usenetModalType,
  } = useSelector(state => state.viewsEffects);

  return (
    <>
      <div
        className={classes.accountTabContainer}
      >

        {/* <div className={classes.secondaryContainer}> */}
        {/*  <div */}
        {/*    style={{ */}
        {/*      paddingTop: 32, */}
        {/*      paddingBottom: 20, */}
        {/*      paddingRight: 32, */}
        {/*      paddingLeft: 32, */}
        {/*      borderBottom: '1px solid rgb(247, 247, 247)', */}
        {/*    }} */}
        {/*  > */}
        {/*    <Typography variant="h6">Recommended Providers</Typography> */}
        {/*    <Typography className={classes.sectionSubTitle}> */}
        {/*      Select the Usenet provider to configure the settings */}
        {/*      {' '} */}
        {/*    </Typography> */}
        {/*  </div> */}

        {/*  <RecommendedProvidersList /> */}
        {/* </div> */}
        {/* <div className={classes.mobileProfileContainer}> */}
        {/*  <Accordion defaultExpanded={false} elevation={0}> */}
        {/*    <AccordionSummary */}
        {/*      expandIcon={<ExpandMoreIcon />} */}
        {/*      aria-controls="panel1c-content" */}
        {/*      id="panel1c-header" */}
        {/*      classes={{ content: classes.expContent, root: classes.expRoot }} */}
        {/*    > */}
        {/*      <div style={{ paddingTop: 18 }}> */}
        {/*        <Typography variant="h6">Recommended Providers</Typography> */}
        {/*        <Typography className={classes.sectionSubTitle}> */}
        {/*          Select the Usenet provider to configure the settings */}
        {/*          {' '} */}
        {/*        </Typography> */}
        {/*      </div> */}
        {/*    </AccordionSummary> */}
        {/*    <AccordionDetails className={classes.details}> */}
        {/*      <RecommendedProvidersList /> */}
        {/*    </AccordionDetails> */}
        {/*  </Accordion> */}
        {/* </div> */}
        <div className={classes.credsContainer}>
          <div
            style={{
              // paddingTop: 32,
              // paddingBottom: 15,
              // paddingRight: 32,
              // paddingLeft: 32,
              borderBottom: '1px solid rgb(247, 247, 247)',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                // borderBottom: '1px solid rgb(237, 237, 237)',
                padding: 20,
              }}
              >
                <AccountTreeIcon style={{
                  marginRight: 8,
                  color: '#2c5ce2',
                }}
                />
                <span style={{
                  fontWeight: 500,
                  fontSize: 16,
                }}
                >
                  {' '}
                  Usenet provider details
                </span>
              </div>
              <div style={{
                display: 'flex',
                alignItems: 'center',

                padding: 20,
              }}
              >
                <Button
                  startIcon={(
                    <div style={{ width: 25, height: 25 }}>
                      <AddIcon />
                    </div>
                )}

                  onClick={handleUsenetModalOpen}
                  className={classes.credAddButton}
                  disabled={credentials.length === 3}
                >
                  ADD
                </Button>
              </div>
            </div>
            <div>
              {/* <Typography className={classes.sectionSubTitle}> */}
              {/* */}
              {/*  {' '} */}
              {/* </Typography> */}
            </div>
          </div>

          {isLoading ? (
            <div
              style={{
                // position: 'absolute',
                display: 'flex',
                width: '100%',
                paddingTop: 20,
                paddingBottom: 20,
                fontWeight: 500,
                color: '#1F374D',
                // height: '100%',
                justifyContent: 'center',
                top: 0,
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              Receiving Credentials...
            </div>
          ) : (
            <>
              {credentials && credentials.length
                ? credentials.map((creds) => {
                  const {
                    serverUsername = '',
                    serverPassword = '',
                    serverHost,
                    serverPort,
                    serverConnections,
                    validationStatus,
                    encryption,
                    _id,
                    priority,
                  } = creds;

                  const { Utf8 } = CryptoJS.enc;
                  const originalServerUsername = CryptoJS.AES.decrypt(
                    serverUsername,
                    email,
                  ).toString(Utf8);
                  const originalServerPassword = CryptoJS.AES.decrypt(
                    serverPassword,
                    email,
                  ).toString(Utf8);
                  const originalCredentials = {
                    serverUsername: originalServerUsername,
                    serverPassword: originalServerPassword,
                    serverHost,
                    serverPort,
                    serverConnections,
                    validationStatus,
                    encryption,
                    priority,
                    _id,
                  };
                  return (
                    <Card
                      key={_id}
                      style={{
                        backgroundColor: '#FFF',
                        borderRadius: 2,
                      // padding: 32,
                      }}
                    >
                      <CardHeader
                        style={{ borderBottom: '1px solid rgb(247, 247, 247)', padding: 20 }}
                        avatar={(
                          <>
                            {validationStatus === 'valid'
                            && <CheckCircleOutlineIcon style={{ color: '#2158eb' }} />}
                            {validationStatus === 'invalid'
                            && <ErrorIcon style={{ color: '#FF4D63' }} />}
                            {validationStatus === 'validation'
                            && <WarningIcon style={{ color: '#ffb84d' }} />}
                          </>
                        )}


                        action={(
                          <>
                            <IconButton
                              onClick={() => handleEditCredentials(originalCredentials)
                            }
                            >
                              <EditIcon style={{ color: '#2158eb' }} />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteConfig(originalCredentials)
                            }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                      )}
                        title={(
                          <>
                            {validationStatus === 'valid'
                            && 'Config is valid'}
                            {validationStatus === 'invalid'
                            && 'Config is not valid. Default config is used instead'}
                            {validationStatus === 'validation'
                            && 'Validating config'}
                          </>
                        )}
                        subheader={(
                          <>
                            {validationStatus === 'valid'
                            && 'You can start Downloads'}
                            {validationStatus === 'invalid'
                            && 'Please input valid credentials for your Usenet provider.'}
                            {validationStatus === 'validation'
                            && 'Can take a few moments'}
                          </>
                        )}
                      />

                      <div>
                        {/* <List className={classes.root}> */}
                        {/*  <ListItem> */}
                        {/*    <ListItemAvatar> */}
                        {/*      <PublicIcon */}
                        {/*        style={{ */}
                        {/*          color: '#989EB3', */}
                        {/*          width: 28, */}
                        {/*          height: 28, */}
                        {/*        }} */}
                        {/*      /> */}
                        {/*    </ListItemAvatar> */}
                        {/*    <ListItemText */}
                        {/*      primary="Host" */}
                        {/*      secondary={originalCredentials.serverHost} */}
                        {/*    /> */}
                        {/*  </ListItem> */}
                        {/*  <ListItem> */}
                        {/*    <ListItemAvatar> */}
                        {/*      <SettingsEthernetIcon */}
                        {/*        style={{ */}
                        {/*          color: '#989EB3', */}
                        {/*          width: 28, */}
                        {/*          height: 28, */}
                        {/*        }} */}
                        {/*      /> */}
                        {/*    </ListItemAvatar> */}
                        {/*    <ListItemText */}
                        {/*      primary="Port" */}
                        {/*      secondary={originalCredentials.serverPort} */}
                        {/*    /> */}
                        {/*  </ListItem> */}
                        {/* </List> */}
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          style={{
                            height: 55,
                            borderBottom: '1px solid rgb(247, 247, 247)',
                          }}
                        >
                          <Grid item xs={2} className={classes.iconGrid}>
                            <PublicIcon
                              style={{
                                color: '#989EB3',
                                width: 28,
                                height: 28,
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={3}
                            zeroMinWidth
                            className={classes.credName}
                          >
                            Host
                          </Grid>

                          <Grid item xs zeroMinWidth>
                            <Typography className={classes.credValue} noWrap>
                              {originalCredentials.serverHost}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          style={{
                            height: 55,
                            borderBottom: '1px solid rgb(247, 247, 247)',
                          }}
                        >
                          <Grid item xs={2} className={classes.iconGrid}>
                            <HttpsIcon
                              style={{
                                color: '#989EB3',
                                width: 28,
                                height: 28,
                              }}
                            />
                          </Grid>
                          <Grid item xs={4} sm={3} className={classes.credName}>
                            SSL
                          </Grid>

                          <Grid item xs zeroMinWidth>
                            <Typography noWrap className={classes.credValue}>
                              {originalCredentials.encryption ? 'YES' : 'NO'}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          style={{
                            height: 55,
                            borderBottom: '1px solid rgb(247, 247, 247)',
                          }}
                        >
                          <Grid item xs={2} className={classes.iconGrid}>
                            <SettingsEthernetIcon
                              style={{
                                color: '#989EB3',
                                width: 28,
                                height: 28,
                              }}
                            />
                          </Grid>
                          <Grid item xs={4} sm={3} className={classes.credName}>
                            Port
                          </Grid>

                          <Grid item xs zeroMinWidth>
                            <Typography noWrap className={classes.credValue}>
                              {originalCredentials.serverPort}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          style={{
                            height: 55,
                            borderBottom: '1px solid rgb(247, 247, 247)',
                          }}
                        >
                          <Grid item xs={2} className={classes.iconGrid}>
                            <SettingsInputCompositeIcon
                              style={{
                                color: '#989EB3',
                                width: 28,
                                height: 28,
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={3}
                            zeroMinWidth
                            className={classes.credName}
                          >
                            Connections
                          </Grid>

                          <Grid item xs zeroMinWidth>
                            <Typography className={classes.credValue} noWrap>
                              {originalCredentials.serverConnections}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          style={{
                            height: 55,
                            borderBottom: '1px solid rgb(247, 247, 247)',
                          }}
                        >
                          <Grid item xs={2} className={classes.iconGrid}>
                            <PersonIcon
                              style={{
                                color: '#989EB3',
                                width: 28,
                                height: 28,
                              }}
                            />
                          </Grid>
                          <Grid item xs={4} sm={3} className={classes.credName}>
                            Username
                          </Grid>

                          <Grid zeroMinWidth item xs>
                            <Typography noWrap className={classes.credValue}>
                              {originalCredentials.serverUsername}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          style={{
                            height: 55,
                            borderBottom: '1px solid rgb(247, 247, 247)',
                          }}
                        >
                          <Grid item xs={2} className={classes.iconGrid}>
                            <LockIcon
                              style={{
                                color: '#989EB3',
                                width: 28,
                                height: 28,
                              }}
                            />
                          </Grid>
                          <Grid item xs={4} sm={3} className={classes.credName}>
                            Password
                          </Grid>

                          <Grid item xs zeroMinWidth>
                            <Typography noWrap className={classes.credValue}>
                              {originalCredentials.serverPassword
                                ? '***********'
                                : ''}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                      {/* <div className={classes.credChangeButtonWrapperMobile}> */}
                      {/*  <Button */}
                      {/*    startIcon={( */}
                      {/*      <div style={{ width: 25, height: 25 }}> */}
                      {/*        <EditIcon /> */}
                      {/*      </div> */}
                      {/*    )} */}
                      {/*    fullWidth */}
                      {/*    onClick={handleUsenetModalOpen} */}
                      {/*    className={classes.credChangeButtonMobile} */}
                      {/*    style={{ backgroundColor: '#2158eb', color: '#fff' }} */}
                      {/*  > */}
                      {/*    ADD */}
                      {/*  </Button> */}
                      {/* </div> */}
                    </Card>
                  // <ConfigCard />
                  );
                }) : (
                  <div
                    style={{
                      // position: 'absolute',
                      display: 'flex',
                      width: '100%',
                      fontWeight: 500,
                      color: '#1F374D',
                      textAlign: 'center',
                      paddingTop: 20,
                      paddingBottom: 20,
                      justifyContent: 'center',
                      top: 0,
                      alignItems: 'center',
                    }}
                  >
                    To start using NZBCLoud please add credentials,
                    <br />
                    {' '}
                    click &quot;+ ADD&quot; button to add.
                  </div>
                ) }
            </>
          )}
        </div>
      </div>
      <UsenetModal
        handleClose={handleUsenetModalClose}
        open={usenetModalOpen}
        handleSubmit={handleChangeCredentials}
        modalIsLoading={usenetModalIsLoading}
        error={usenetError}
        data={usenetModalData}
        type={usenetModalType}
        handleEdit={handleEditCredentialsSubmit}
      />
    </>
  );
}
