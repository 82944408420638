import React, { useEffect, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

import {
  getExternalDrivesListSubmit,
  setBrowseStorageTypeSubmit,
} from '../../../../redux/userStorage/userStorageActions';
import { setDashboardTab } from '../../../../redux/viewsEffects/viewsEffectsActions';
import storageOptions from '../../../../helpers/storageIconOptions';
import formatBytes from '../../../../helpers/formatBytes';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  controlRowContainer: {
    display: 'flex',
    // width: '100%',
    paddingTop: 7,
  },
  fileTypeTitle: {
    color: 'rgba(0,0,0,0.6)',
    // fontFamily: 'Roboto, san-serif',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
  },
  foldersContainer: {
    display: 'flex',
    marginTop: 12,
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      // marginBottom: 150,
    },
  },
  filesContainer: {
    display: 'flex',
    marginTop: 12,
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      justifyContent: 'space-between',
      marginBottom: 200,
    },
  },
  gridHeadersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileViewTypeContainer: {
    // borderLeft: '1px solid rgba(0,0,0,0.1)',
    paddingLeft: 10,
  },
  menuIcon: {
    width: 25,
    height: 25,
    minWidth: 25,
    marginRight: 5,
  },
  menuItem: {
    borderBottom: '1px solid #F7F7F7',
    borderRadius: 2,

  },
  menuItemCaption: {
    padding: 10,
    textAlign: 'center',
    maxWidth: 200,
    whiteSpace: 'normal',
    borderRadius: 2,
  },
}));

export default function StorageLeft() {
  const { externalStorages = [{ type: '' }], targetStorage = 'NZBCLOUD' } = useSelector(
    state => state.userStorage,
  );


  const { storageLeft } = useSelector(state => state.user);
  const getSelectedStorageObject = () => externalStorages.find(storage => storage.type === targetStorage);
  const selectedStorageObj = useMemo(() => getSelectedStorageObject(), [
    getSelectedStorageObject,
    targetStorage,
  ]);
  const calculateStorageLeft = () => {
    const value = selectedStorageObj
      ? selectedStorageObj?.totalSpace - selectedStorageObj?.usedSpace
      : storageLeft * 1024 * 1024;

    if (value > 0) {
      return formatBytes(value);
    } return 'exceeded';
  };

  return (


    <div style={{ whiteSpace: 'nowrap' }}>
      {calculateStorageLeft()}
    </div>

  );
}
