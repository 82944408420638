import {
  call, put, all, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  GET_USER_SUBMIT,
  getUserSuccess,
  getUserFailure,
  SET_PASSWORD_SUBMIT,
  setPasswordSuccess,
  setPasswordFailure,
  FORGOT_PASSWORD_SUBMIT,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  getUserDataSuccess,
  getUserDataFailure,
  userChangeEmailSuccess,
  userChangeEmailFailure,
  USER_CHANGE_EMAIL_SUBMIT,
  USER_CHANGE_EMAIL_SUCCESS,
  USER_SENT_VER_LINK_SUBMIT,
  userSentVerLinkSuccess,
  userSentVerLinkFailure,
  userChangePasswordSuccess,
  userChangePasswordFailure,
  USER_CHANGE_PASSWORD_SUBMIT,
  userSetAvatarSuccess,
  userSetAvatarFail,
  SET_NEW_AVATAR_SUBMIT,
  USER_SET_GOOGLE_CREDENTIALS,
  USER_SET_DROPBOX_CREDENTIALS,
  USER_SET_ONEDRIVE_CREDENTIALS,
  USER_UNSET_GOOGLE_CREDENTIALS,
  USER_UNSET_DROPBOX_CREDENTIALS,
  USER_UNSET_ONEDRIVE_CREDENTIALS,
  USER_UPDATE_ANTIVIRUS_SETTINGS_SUBMIT,
  userUpdateAntivirusSettingsSuccess,
  userUpdateAntivirusSettingsFail,
  userUpdateEncryptionSettingsSuccess,
  userUpdateEncryptionSettingsFail,
  USER_UPDATE_ENCRYPTION_SETTINGS_SUBMIT,
  setWizardFinishedFail,
  SET_WELCOME_MODAL_WIZARD_FINISHED_SUBMIT, setWizardFinishedSuccess,
} from './userActions';

import authService from '../../services/authService';
import userService from '../../services/usersService';
import { DELETE_STORAGE_ROOT_FOLDER_SUCCESS } from '../userStorage/userStorageActions';
import {
  NZB_FILES_UPLOAD_SUCCESS,
  NZB_FILES_DOWNLOADING_DELETE_SUCCESS,
  NZB_FILES_DOWNLOADING_COMPLETE,
} from '../files/filesActions';
import usenetProvidersService from '../../services/usenetProvidersService';
import {
  USENET_ADD_CONFIG_SUCCESS, USENET_DELETE_CONFIG_SUCCESS,
  usenetChangeCredentialsFail,
  usenetChangeCredentialsSuccess
} from '../usenet/usenetProvidersActions';
import { IO_FORCE_USER_UPDATE } from '../sockets/socketsActions';

function* userSetGoogleCredentials({ payload }) {
  try {
    const result = yield call(userService.setGoogleCredentials, payload);
    yield put(getUserDataSuccess(result));
  } catch (e) {
    const errorMessage = e.response || e;
    yield put(getUserDataFailure(errorMessage));
  }
}
function* userUnsetGoogleCredentials() {
  try {
    const result = yield call(userService.unsetGoogleCredentials, {});
    yield put(getUserDataSuccess(result));
  } catch (e) {
    const errorMessage = e.response || e;
    yield put(getUserDataFailure(errorMessage));
  }
}

function* userSetDropboxCredentials({ payload }) {
  try {
    const result = yield call(userService.setDropboxCredentials, payload);
    yield put(getUserDataSuccess(result));
  } catch (e) {
    const errorMessage = e.response || e;
    yield put(getUserDataFailure(errorMessage));
  }
}
function* userUnsetDropboxCredentials() {
  try {
    const result = yield call(userService.unsetDropboxCredentials, {});
    yield put(getUserDataSuccess(result));
  } catch (e) {
    const errorMessage = e.response || e;
    yield put(getUserDataFailure(errorMessage));
  }
}

function* userSetOnedriveCredentials({ payload }) {
  try {
    const result = yield call(userService.setOnedriveCredentials, payload);
    yield put(getUserDataSuccess(result));
  } catch (e) {
    const errorMessage = e.response || e;
    yield put(getUserDataFailure(errorMessage));
  }
}
function* userUnsetOnedriveCredentials() {
  try {
    const result = yield call(userService.unsetOnedriveCredentials, {});
    yield put(getUserDataSuccess(result));
  } catch (e) {
    const errorMessage = e.response || e;
    yield put(getUserDataFailure(errorMessage));
  }
}

function* getUserData() {
  try {
    const result = yield call(authService.getMe);

    yield put(getUserDataSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getUserDataFailure(errorMessage));
  }
}
function* userGetMe() {
  try {
    const result = yield call(authService.getMe);

    yield put(getUserSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getUserFailure(errorMessage));
  }
}

function* forgotPassword(action) {
  try {
    const result = yield call(userService.forgotPassword, action.payload);

    const { status, message } = result;
    if (status === 200) {
      toast.success(message);
    } else {
      toast.warn(message);
    }
    yield put(forgotPasswordSuccess(message));
  } catch (error) {
    yield put(forgotPasswordFailure());
  }
}

function* setPassword(action) {
  try {
    const result = yield call(userService.setNewPassword, action.payload);

    yield put(setPasswordSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(setPasswordFailure(errorMessage));
  }
}
function* changePassword(action) {
  try {
    const result = yield call(userService.changePassword, action.payload);

    yield put(userChangePasswordSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(userChangePasswordFailure(errorMessage));
  }
}
function* setNewEmail({ payload }) {
  try {
    const result = yield call(userService.setNewEmail, payload);
    localStorage.setItem('vl', null);
    yield put(userChangeEmailSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(userChangeEmailFailure(errorMessage));
  }
}
function* sentVerLinkEmail() {
  try {
    const result = yield call(userService.sentEmailVerLinkEmail);

    localStorage.setItem('vl', Date.now().toString());
    yield put(userSentVerLinkSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(userSentVerLinkFailure(errorMessage));
  }
}
function* userSetAvatar({ payload }) {
  try {
    const result = yield call(userService.setAvatar, payload);

    yield put(userSetAvatarSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(userSetAvatarFail(errorMessage));
  }
}

function* updateAntivirusSettings({ payload }) {
  try {
    const result = yield call(userService.updateAntivirusSettings, payload);

    yield put(userUpdateAntivirusSettingsSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(userUpdateAntivirusSettingsFail(errorMessage));
  }
}
function* updateEncryptionSettings({ payload }) {
  try {
    const result = yield call(userService.updateEncryptionSettings, payload);

    yield put(userUpdateEncryptionSettingsSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(userUpdateEncryptionSettingsFail(errorMessage));
  }
}


function* setWizardFinished(action) {
  const { payload } = action;
  try {
    const result = yield call(
      userService.setWizardFinished,
      payload,
    );
    yield put(setWizardFinishedSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(setWizardFinishedFail(errorMessage));
  }
}

export default function* usersSaga() {
  yield all([
    takeLatest(USER_SET_GOOGLE_CREDENTIALS, userSetGoogleCredentials),
  ]);
  yield all([
    takeLatest(SET_WELCOME_MODAL_WIZARD_FINISHED_SUBMIT, setWizardFinished),
  ]);
  yield all([
    takeLatest(USER_UNSET_GOOGLE_CREDENTIALS, userUnsetGoogleCredentials),
  ]);
  yield all([
    takeLatest(USER_SET_DROPBOX_CREDENTIALS, userSetDropboxCredentials),
  ]);
  yield all([
    takeLatest(USER_UNSET_DROPBOX_CREDENTIALS, userUnsetDropboxCredentials),
  ]);
  yield all([
    takeLatest(USER_SET_ONEDRIVE_CREDENTIALS, userSetOnedriveCredentials),
  ]);
  yield all([
    takeLatest(USER_UNSET_ONEDRIVE_CREDENTIALS, userUnsetOnedriveCredentials),
  ]);
  yield all([takeLatest(USER_SENT_VER_LINK_SUBMIT, sentVerLinkEmail)]);
  yield all([takeLatest(GET_USER_SUBMIT, userGetMe)]);
  yield all([
    takeLatest(
      [
        USER_CHANGE_EMAIL_SUCCESS,
        NZB_FILES_UPLOAD_SUCCESS,
        NZB_FILES_DOWNLOADING_COMPLETE,
        NZB_FILES_DOWNLOADING_DELETE_SUCCESS,
        DELETE_STORAGE_ROOT_FOLDER_SUCCESS,
        USENET_ADD_CONFIG_SUCCESS,
        USENET_DELETE_CONFIG_SUCCESS,
        IO_FORCE_USER_UPDATE,
      ],
      getUserData,
    ),
  ]);
  yield all([takeLatest([FORGOT_PASSWORD_SUBMIT], forgotPassword)]);
  yield all([takeLatest([USER_CHANGE_PASSWORD_SUBMIT], changePassword)]);
  yield all([takeLatest([USER_CHANGE_EMAIL_SUBMIT], setNewEmail)]);
  yield all([takeLatest([SET_PASSWORD_SUBMIT], setPassword)]);
  yield all([takeLatest([SET_NEW_AVATAR_SUBMIT], userSetAvatar)]);
  yield all([
    takeLatest(
      [USER_UPDATE_ANTIVIRUS_SETTINGS_SUBMIT],
      updateAntivirusSettings,
    ),
  ]);
  yield all([
    takeLatest(
      [USER_UPDATE_ENCRYPTION_SETTINGS_SUBMIT],
      updateEncryptionSettings,
    ),
  ]);
}
