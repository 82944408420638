import React from 'react';
import LottieAnimationPlayer from '../../../atoms/LottieAnimationPlayer';
import useStyles from './styles';

export default function EncodeStatusProgressBadge() {
  const classes = useStyles();

  return (
    <div className={classes.fileTranscodeStatus}>
      • Encoding
      <div className={classes.transcodeIconStatusWrapper}><LottieAnimationPlayer animation="EncProgress" loop /></div>
    </div>

  );
}
