import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';

import LinearProgress from '@material-ui/core/LinearProgress';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 2,
  },
  formInput: {
    padding: '0px 24px',
    width: 380,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formInputPicker: {
    padding: '5px 24px',
  },
  errorModalSubTitle: {
    width: 380,
    padding: '0px 10px 10px 24px',
    fontSize: 15,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  reportFilesLabel: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
  },
  fileNameList: {
    fontSize: 16,
    marginLeft: 8,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 230,
  },
  submit: {
    marginLeft: 20,
    backgroundColor: '#2158eb',
  },
  doneModalWrapper: {
    width: 380,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  doneContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '80px 50px',
    flexDirection: 'column',
  },
  doneIconWrapper: {
    width: 120,
    height: 120,
  },
  doneContentText: {
    fontSize: 24,
    color: 'rgba(0,0,0,0.87)',
    textAlign: 'center',
    marginTop: 30,
    fontWeight: 500,
  },
  doneModalFooterText: {
    fontSize: 20,
    color: 'rgba(0,0,0,0.87)',
    marginBottom: 6,
    fontWeight: 500,
  },
  doneModalFooterSubText: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 500,
  },
}));
const validationSchema = Yup.object().shape({
  apiKey: Yup.string().required('Required'),
  host: Yup.string().required(
    'Required ',
  ),
  name: Yup.string().matches(/^[a-zA-Z]+$/, 'Only letters allowed').required('Required'),
  username: Yup.string(),
  password: Yup.string(),
});

export default function AddIndexerModal(props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    handleAddIndexer,
    modalIsLoading,
    hdrError,
  } = props;

  const handleFormSubmit = (valuesFromForm) => {
    handleAddIndexer(valuesFromForm);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={!modalIsLoading ? handleClose : () => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            Add Indexer
            {' '}
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <div>
          <Formik
            initialValues={{
              apiKey: '',
              host: '',
              name: '',
              username: '',
              password: '',
              score: 100,
            }}
            validationSchema={validationSchema}
            onSubmit={values => handleFormSubmit(values)}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
              setFieldValue,
            }) => (
              <>
                <form>
                  <div className={classes.errorModalSubTitle}>
                    Add your own indexer to NZB Search Engine
                  </div>
                  <div className={classes.formInput}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      error={!!(touched.apiKey && errors.apiKey)}
                      fullWidth
                      id="apiKey"
                      label="API Key"
                      name="apiKey"
                      style={{ marginBottom: 0 }}
                      value={values.apiKey}
                      onChange={handleChange('apiKey')}
                    />
                    <div style={{ color: 'red', height: 20 }}>
                      {touched.apiKey && errors.apiKey}
                    </div>
                  </div>
                  <div className={classes.formInput}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      error={!!(touched.host && errors.host)}
                      fullWidth
                      placeholder="https://example.ex/"
                      id="host"
                      label="Host"
                      autoComplete="off"
                      name="Host"
                      style={{ marginBottom: 0 }}
                      value={values.host}
                      onChange={handleChange('host')}
                    />
                    <div style={{ color: 'red', height: 20 }}>
                      {touched.host && errors.host}
                    </div>
                  </div>
                  <div className={classes.formInput}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      error={!!(touched.name && errors.name)}
                      fullWidth
                      id="name"
                      autoComplete="off"
                      label="Indexer Name"
                      name="name"
                      style={{ marginBottom: 0 }}
                      value={values.name}
                      onChange={handleChange('name')}
                    />
                    <div style={{ color: 'red', height: 20 }}>
                      {touched.name && errors.name}
                    </div>
                  </div>
                  <div className={classes.formInput}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      error={!!(touched.username && errors.username)}
                      fullWidth
                      id="name"
                      label="username"
                      autoComplete="off"
                      name="name"
                      style={{ marginBottom: 0 }}
                      value={values.username}
                      onChange={handleChange('username')}
                    />
                    <div style={{ color: 'red', height: 20 }}>
                      {touched.username && errors.username}
                    </div>
                  </div>
                  <div className={classes.formInput}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      error={!!(touched.password && errors.password)}
                      autoComplete="off"
                      fullWidth
                      id="name"
                      label="password"
                      name="name"
                      style={{ marginBottom: 0 }}
                      value={values.password}
                      onChange={handleChange('password')}
                    />
                    <div style={{ color: 'red', height: 20 }}>
                      {touched.password && errors.password}
                    </div>
                    <div style={{ height: 20, color: 'red' }}>{hdrError}</div>
                  </div>

                  <div style={{ height: 4 }}>
                    {modalIsLoading && <LinearProgress />}
                  </div>

                  <div
                    style={{
                      padding: '12px 32px',
                      backgroundColor: '#F7F7F7',
                      borderTop: '1px solid #E0E0E0',
                      justifyContent: 'flex-end',
                      display: 'flex',
                    }}
                  >
                    <Button
                      onClick={modalIsLoading ? () => {} : handleClose}
                      color="default"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={modalIsLoading}
                      className={classes.submit}
                      disableElevation
                      onClick={handleSubmit}
                    >
                      ADD
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}
