import React from 'react';

const SneilIcon = () => (
  <svg width="48px" height="42px" viewBox="0 0 48 42" version="1.1">
    <g id="Transcoding" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-74.000000, -390.000000)" id="Verification-mob">
        <g transform="translate(50.000000, 371.000000)">
          <g id="Snail" transform="translate(24.000000, 16.000000)">
            <rect id="Rectangle" x="0" y="0" width="48" height="48" />
            <g id="snail" transform="translate(0.000000, 3.656250)" fillRule="nonzero">
              <path d="M43.0344828,13.2413793 C42.7903198,13.2413731 42.5586202,13.1335495 42.4013793,12.9467586 L35.7806897,5.08468966 L37.0468966,4.01875862 L42.3972414,10.3729655 L43.0386207,3.64137931 L44.6896552,3.80689655 L43.862069,12.4965517 C43.8302275,12.8289366 43.6017597,13.1096256 43.2827586,13.2082759 C43.2021169,13.231644 43.11843,13.2428023 43.0344828,13.2413793 L43.0344828,13.2413793 Z" id="Shape" fill="#71C285" />
              <path d="M34.7255172,24.5544828 C35.7054743,22.2146854 36.5455746,19.8187414 37.2413793,17.3793103 C38.0689655,14.0689655 39.7241379,12.4137931 43.0344828,12.4137931 C46.3448276,12.4137931 48,14.8965517 48,15.7241379 C48,16.5517241 46.3448276,19.0344828 45.5172414,23.1724138 C44.6896552,27.3103448 46.3448276,40.5517241 37.2413793,40.5517241 L13.2413793,40.5517241 C8.27586207,40.5517241 3.31034483,40.5517241 0,38.8965517 C3.67478508,37.682351 6.54169813,34.7754058 7.70482759,31.0841379 L34.7255172,24.5544828 Z" id="Shape" fill="#7ED09E" />
              <path d="M34.3282759,29.76 C34.2356461,30.1628645 33.9852023,30.5116969 33.6331034,30.7282759 C31.9696552,31.737931 26.8386207,34.3531034 21.4593103,31.7462069 C20.9373962,31.4923985 20.4340733,31.2020199 19.9531034,30.8772414 C19.9365517,30.8689655 19.92,30.8524138 19.9034483,30.8441379 C17.5867302,32.8269376 14.4514875,33.5613743 11.4951724,32.8137931 C5.31828958,31.1738962 1.47124232,25.0251348 2.69793103,18.7531034 C3.48829446,14.4458383 6.14511405,10.7095255 9.95406886,8.54872221 C13.7630237,6.38791894 18.333206,6.02438527 22.4358621,7.55586207 C31.5202759,10.8206897 36.2805517,20.6631724 34.3282759,29.76 L34.3282759,29.76 Z" id="Shape" fill="#FCB643" />
              <path d="M24.2482759,26.7806897 C23.7998546,28.6635538 22.8338204,30.3834959 21.4593103,31.7462069 C20.9373962,31.4923985 20.4340733,31.2020199 19.9531034,30.8772414 C21.2871757,29.6888369 22.2257306,28.1207282 22.6427586,26.3834483 C23.5647934,22.6977044 21.362908,18.9530263 17.6937931,17.9668966 C16.361345,17.6277544 14.9485733,17.8363915 13.7710345,18.5462069 C12.5383462,19.2823309 11.6550462,20.4849319 11.3213793,21.8813793 C10.7705758,24.0815095 12.0853398,26.3169764 14.2758621,26.9048276 C15.0517553,27.1060783 15.8761121,26.9836196 16.56,26.5655172 C17.2790869,26.1358553 17.795071,25.4349519 17.9917241,24.6206897 C18.3077591,23.3695373 17.5589095,22.0968617 16.3117241,21.7655172 C15.8683728,21.6512514 15.6015963,21.1992134 15.7158621,20.7558621 C15.8301279,20.3125107 16.2821659,20.0457342 16.7255172,20.16 C18.849339,20.722618 20.1281862,22.8859706 19.5972414,25.017931 C19.2946457,26.2625081 18.504339,27.33315 17.4041379,27.9889655 C16.3416619,28.6321775 15.0648217,28.8201236 13.862069,28.5103448 C10.7949022,27.6912233 8.95014041,24.5650701 9.71586207,21.4841379 C10.1526416,19.6605296 11.3076173,18.0907177 12.9186207,17.1310345 C14.4755079,16.1902086 16.3446401,15.9129689 18.1075862,16.3613793 C22.6556676,17.5757245 25.3890265,22.2135935 24.2482759,26.7806897 L24.2482759,26.7806897 Z" id="Shape" fill="#EF4056" />
              <g id="Group" transform="translate(2.482759, 6.714440)" fill="#FCEED6">
                <path d="M32.2510345,17.9724569 C31.1818753,18.894807 29.7273782,19.2321266 28.3613793,18.8745259 C25.4317241,18.2869397 24.8275862,19.7931466 24.8027586,19.8510776 C24.6763857,20.1762725 24.3654031,20.3923236 24.0165517,20.3972845 C23.9233087,20.3968873 23.8308636,20.380079 23.7434483,20.3476293 C23.3211601,20.2027478 23.0928772,19.7461819 23.2303448,19.3214224 C23.2717241,19.1972845 24.2648276,16.3669397 28.6841379,17.2524569 C30.0724019,17.6735571 31.5419076,16.903816 31.9862069,15.5228017 C32.1207305,16.3336891 32.2091484,17.1515546 32.2510345,17.9724569 Z" id="Shape" />
                <path d="M30.7531034,11.3021121 C30.5320236,11.6706373 30.2427228,11.9936431 29.9006897,12.2538362 C29.0226491,12.8915664 27.9045993,13.1012007 26.8551724,12.8248707 C26.0200784,12.5509263 25.1169703,12.980631 24.8027586,13.8014224 C24.6812279,14.1303983 24.3672568,14.3485256 24.0165517,14.3476321 C23.9233087,14.3472322 23.8308636,14.3304239 23.7434483,14.2979741 C23.3171174,14.1482806 23.0916103,13.6824791 23.2386207,13.2552155 C23.827218,11.6544759 25.5377998,10.7651169 27.1862069,11.2028017 C27.7681287,11.3748988 28.3965196,11.2777562 28.8993103,10.9379741 C29.3762115,10.5160015 29.6945047,9.94365786 29.8013793,9.31590517 C30.1582947,9.95822214 30.476114,10.6214972 30.7531034,11.3021121 L30.7531034,11.3021121 Z" id="Shape" />
                <path d="M27.6165517,6.16280172 C26.6574278,7.09857372 25.3554775,7.59840294 24.0165517,7.54487069 C21.9062069,7.54487069 21.8482759,8.83590517 21.8482759,8.98487069 C21.8482759,9.44193389 21.4777529,9.8124569 21.0206897,9.8124569 C20.5636265,9.8124569 20.1931034,9.44193389 20.1931034,8.98487069 C20.1931034,7.91728448 20.9875862,5.88969828 24.0165517,5.88969828 C24.9385135,5.95014211 25.8404105,5.60209461 26.4827586,4.93797414 C26.88,5.32693966 27.2606897,5.74073276 27.6165517,6.16280172 L27.6165517,6.16280172 Z" id="Shape" />
                <path d="M21.4924138,1.47038793 C21.2176674,2.8179617 20.2944511,3.9425508 19.0262069,4.47452586 C18.6192628,4.58542672 18.2793769,4.86544506 18.0926503,5.24364559 C17.9059236,5.62184619 17.8902701,6.06194578 18.0496552,6.4524569 C18.1924557,6.88886446 17.955726,7.35862481 17.52,7.50349138 C17.4368628,7.52931737 17.3504893,7.54324862 17.2634483,7.54487069 C16.9059599,7.54306357 16.5895308,7.3132361 16.4772414,6.97383621 C16.1840537,6.16575836 16.2349932,5.27281221 16.6181758,4.50331891 C17.0013585,3.7338257 17.6832718,3.1550839 18.5048276,2.90211207 C19.2919762,2.48933077 19.8313632,1.72186018 19.9531034,0.841422414 C20.4827586,1.03176724 20.9958621,1.23866379 21.4924138,1.47038793 L21.4924138,1.47038793 Z" id="Shape" />
                <path d="M13.497931,6.32831897 C13.3576964,6.60661941 13.0730137,6.78254686 12.7613793,6.78353122 C12.6325464,6.78474426 12.5051423,6.75643229 12.3889655,6.70073276 C11.6441379,6.32831897 10.4524138,5.00418103 11.222069,2.67038793 C11.5065679,1.91253074 11.5809142,1.09186174 11.4372414,0.295215517 C11.9743667,0.165948414 12.5188049,0.0692207917 13.0675862,0.00556034483 C13.2587577,1.07314622 13.1645456,2.17228721 12.7944828,3.19176724 C12.2731034,4.76418103 13.1255172,5.21935345 13.1337931,5.21935345 C13.5395694,5.42589877 13.7022822,5.92143323 13.497931,6.32831897 L13.497931,6.32831897 Z" id="Shape" />
                <path d="M9.83172414,7.47038793 C9.83172414,7.92745113 9.46120113,8.29797414 9.00413793,8.29797414 C8.17802764,8.32054126 7.37923283,8.00113643 6.79643553,7.41520572 C6.21363815,6.82927502 5.89852097,6.02877919 5.92551724,5.20280172 C5.90409401,4.54893662 5.68477808,3.91696059 5.29655172,3.39038793 C5.71690522,3.03581954 6.1561704,2.70429869 6.61241379,2.39728448 C7.22152601,3.20770639 7.56027228,4.18920203 7.58068966,5.20280172 C7.55278742,5.58966923 7.69317517,5.96976153 7.9658525,6.24560942 C8.23852974,6.52145739 8.61697374,6.66622978 9.00413793,6.64280172 C9.46120113,6.64280172 9.83172414,7.01332473 9.83172414,7.47038793 Z" id="Shape" />
                <path d="M6.82763014,10.5076293 C6.8231021,10.9582515 6.45889804,11.3224555 6.00827586,11.3269397 C4.72399753,11.2695123 3.57422773,10.5135863 3.01241379,9.35728448 C2.75064578,8.73938652 2.19825271,8.29250669 1.53931034,8.16556034 C1.79379139,7.65261629 2.07842762,7.1551937 2.39172414,6.67590517 C3.32112247,7.01773676 4.07705548,7.71414749 4.4937931,8.6124569 C4.77456459,9.21318823 5.35567418,9.61743836 6.01655172,9.67176724 C6.47037666,9.68082194 6.83221564,10.0537377 6.82763014,10.5076293 Z" id="Shape" />
                <path d="M5.23862069,13.9090086 C5.03207534,13.5032323 4.53654087,13.3405195 4.12965517,13.5448707 C3.38921677,13.8023871 2.56700675,13.5593858 2.08551724,12.9407328 C1.60337232,12.4078938 0.932321934,12.0842456 0.215172414,12.0386638 C0.115048792,12.579984 0.0514569269,13.127427 0.0248275862,13.6772845 C0.367811934,13.6880981 0.68991576,13.8446346 0.910344828,14.1076293 C1.5881131,14.8522611 2.54356927,15.2835642 3.55034483,15.2993534 C4.00716129,15.3052415 4.45953435,15.2091122 4.87448276,15.0179741 C5.28025903,14.8114288 5.44297183,14.3158944 5.23862069,13.9090086 L5.23862069,13.9090086 Z" id="Shape" />
                <path d="M5.84275862,18.6510776 C4.03034483,20.4717672 2.84689655,19.6524569 1.49793103,19.6524569 C1.40633532,19.6525066 1.3149307,19.6608162 1.22482759,19.6772845 C0.97914053,19.1768101 0.768966546,18.6596715 0.595862069,18.1296983 C0.889240419,18.0452485 1.19265919,18.0007099 1.49793103,17.9972845 C1.90327412,18.0010485 2.30753484,18.0398132 2.7062069,18.1131466 C3.427212,18.3521811 4.22005001,18.0979377 4.66758621,17.484181 C4.98981579,17.1596661 5.51410579,17.1578136 5.83862069,17.4800431 C6.16313561,17.8022726 6.16498817,18.3265627 5.84275862,18.6510776 Z" id="Shape" />
              </g>
              <circle id="Oval" fill="#547580" cx="36.4137931" cy="4.13793103" r="2.48275862" />
              <circle id="Oval" fill="#547580" cx="43.862069" cy="2.48275862" r="2.48275862" />
              <path d="M37.2413793,38.0689655 L33.9310345,38.0689655 C33.4739713,38.0689655 33.1034483,37.6984425 33.1034483,37.2413793 C33.1034483,36.7843161 33.4739713,36.4137931 33.9310345,36.4137931 L37.2413793,36.4137931 C37.6984425,36.4137931 38.0689655,36.7843161 38.0689655,37.2413793 C38.0689655,37.6984425 37.6984425,38.0689655 37.2413793,38.0689655 Z" id="Shape" fill="#71C285" />
              <path d="M29.7931034,38.0689655 L12.4137931,38.0689655 C11.9567299,38.0689655 11.5862069,37.6984425 11.5862069,37.2413793 C11.5862069,36.7843161 11.9567299,36.4137931 12.4137931,36.4137931 L29.7931034,36.4137931 C30.2501666,36.4137931 30.6206897,36.7843161 30.6206897,37.2413793 C30.6206897,37.6984425 30.2501666,38.0689655 29.7931034,38.0689655 Z" id="Shape" fill="#71C285" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SneilIcon;
