export const CONTACTS_SEND_MESSAGE_SUBMIT = 'CONTACTS_SEND_MESSAGE_SUBMIT';
export const CONTACTS_SEND_MESSAGE_SUCCESS = 'CONTACTS_SEND_MESSAGE_SUCCESS';
export const CONTACTS_SEND_MESSAGE_FAILURE = 'CONTACTS_SEND_MESSAGE_FAILURE';
export const BUG_REPORT_SEND_MESSAGE_SUBMIT = 'BUG_REPORT_SEND_MESSAGE_SUBMIT';
export const BUG_REPORT_SEND_MESSAGE_SUCCESS = 'BUG_REPORT_SEND_MESSAGE_SUCCESS';
export const BUG_REPORT_SEND_MESSAGE_FAILURE = 'BUG_REPORT_SEND_MESSAGE_FAILURE';
export const bugReportSendMessageSubmit = payload => ({
  type: BUG_REPORT_SEND_MESSAGE_SUBMIT,
  payload,
});

export const bugReportSendMessageSuccess = payload => ({
  type: BUG_REPORT_SEND_MESSAGE_SUCCESS,
  payload,
});

export const bugReportSendMessageFailure = payload => ({
  type: BUG_REPORT_SEND_MESSAGE_FAILURE,
  payload,
});
export const contactsSendMessageSubmit = payload => ({
  type: CONTACTS_SEND_MESSAGE_SUBMIT,
  payload,
});

export const contactsSendMessageSuccess = payload => ({
  type: CONTACTS_SEND_MESSAGE_SUCCESS,
  payload,
});

export const contactsSendMessageFailure = payload => ({
  type: CONTACTS_SEND_MESSAGE_FAILURE,
  payload,
});
