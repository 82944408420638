import React from 'react';

import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Copyright from '../../Copyright';
import { setPasswordSubmit } from '../../../redux/user/userActions';
import useStyles from '../styles';
import getQueryParam from '../../../helpers/getQueryParam';

export default function ResetPassword() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showPasswordValue, setFormShowPassword] = React.useState({
    showPassword: false,
  });
  const handleFormSubmit = (valuesFromForm) => {
    const { password } = valuesFromForm;
    const token = getQueryParam('confirmToken');

    dispatch(setPasswordSubmit({ password, token }));
  };

  const handleClickShowPassword = () => {
    setFormShowPassword({ showPassword: !showPasswordValue.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .label('New password')
      .min(6)
      .required('Enter your new password. Make it strong and beautiful. '),
  });

  return (
    <div className={classes.paper}>
      <Typography className={classes.loginTitle}>Set new password</Typography>

      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={values => handleFormSubmit(values)}
      >
        {({
          handleChange, handleSubmit, values, touched, errors,
        }) => (
          <>
            <div className={classes.formInput}>
              <FormControl fullWidth variant="outlined">
                <InputLabel
                  error={!!(touched.password && errors.password)}
                  classes={{
                    focused: classes.inputLabelFocus,
                    root: classes.inputLabel,
                  }}
                  htmlFor="outlined-adornment-password"
                >
                  New password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPasswordValue.showPassword ? 'text' : 'password'}
                  value={values.password}
                  name="password"
                  error={!!(touched.password && errors.password)}
                  fullWidth
                  onChange={handleChange('password')}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPasswordValue.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )}
                  labelWidth={70}
                />
                <div style={{ color: 'red', marginTop: 8 }}>
                  {touched.password && errors.password}
                </div>
              </FormControl>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
              style={{ boxShadow: 'none' }}
            >
              Change
            </Button>
          </>
        )}
      </Formik>
      <Box mt={5}>
        <Copyright />
      </Box>
    </div>
  );
}
