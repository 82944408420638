import * as redux from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../redux/rootReducer';
import rootSaga from '../redux/rootSaga';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['verifyEmail', 'notifications'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middleware = [];
const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || redux.compose;
const store = redux.createStore(
  persistedReducer,
  composeEnhancers(redux.applyMiddleware(...middleware)),
);
const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
export { store, persistor };
