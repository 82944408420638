import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import HelpIcon from '@material-ui/icons/Help';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Image from 'material-ui-image';

import { userLogOut } from '../../redux/login/loginActions';
import history from '../../config/history';
import StorageSpaceTracker from './StorageSpaceTracker';
import Copyright from '../Copyright';
import BugIcon from '../atoms/BugIcon';
import BettaBanner from './BettaBanner/BettaBanner';
import NotificationBar from './notificationBar';
import MailIcon from '@material-ui/icons/Mail';
import { Badge } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  subHeaderWrapper: {
    display: 'flex',
    padding: 10,
    width: 280,
    borderBottom: '1px solid #F7F7F7',
    alignItems: 'center',
    flexDirection: 'column',
  },
  bottomButton: {
    textTransform: 'none',
  },
  avatarWrapper: {
    width: 48,
    height: 48,
    '&:hover': {
      opacity: 0.7,
    },
  },
  paper: {
    borderRadius: 2,
  },
  customBadge: {
    backgroundColor: '#da5933',
    color: 'white',
  },
}));

export default function SimplePopover(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { user, handleSettingsTab } = props;
  const { activePlan, storageLeft } = user;
  const { unreadCount } = useSelector(
    state => state.notifications,
  );
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // dispatch(getUserSubmit());
  };
  const handleLogOutClick = () => {
    dispatch(userLogOut());
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSettingsNavigation = () => {
    handleClose();
    handleSettingsTab(2, 2);
  };
  const handleMenuNavigation = (route) => {
    handleClose();
    history.push(route);
  };

  return (
    <div>
      <Fab
        style={{ boxShadow: 'none', width: 48, height: 48 }}
        onClick={handleClick}
        // size="small"
      >
        <Badge
          classes={{ badge: classes.customBadge }}
          badgeContent={unreadCount}
        >
          <div className={classes.avatarWrapper}>
            <AccountCircleIcon style={{ width: '100%', height: '100%', color: 'rgb(44, 92, 226)' }} />
          </div>
        </Badge>

      </Fab>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List
          subheader={(
            <div className={classes.subHeaderWrapper}>
              <div
                style={{ display: 'flex', alignItems: 'center', width: '100%' }}
              >
                <div style={{ width: 40, height: 40 }}>
                  <AccountCircleIcon style={{ width: '100%', height: '100%', color: 'rgba(0, 0, 0, 0.54)' }} />
                </div>
                <div style={{ paddingLeft: 10, width: '80%' }}>
                  {user.userLoading ? (
                    <div style={{ width: '100%' }}>
                      <Skeleton variant="text" />
                      {' '}
                      <Skeleton variant="text" />
                    </div>
                  ) : (
                    <div>
                      <Typography
                        style={{
                          fontSize: 13,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        variant="h6"
                      >
                        {user.email}
                      </Typography>

                      <Typography style={{ color: '#0086FA', fontSize: 12 }}>
                        User id:
                        {' '}
                        {user.id}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  marginTop: 16,
                  marginBottom: 8,
                  height: 64,
                }}
              >
                <StorageSpaceTracker user={user} />
              </div>
            </div>
          )}
          className={classes.root}
        >
          {/*<ListItem button>*/}
          {/*/!*<ListItem button onClick={() => handleMenuNavigation('/pricing')}>*!/*/}
          {/*  <ListItemIcon>*/}
              {/*<FavoriteIcon />*/}
              <NotificationBar />
            {/*</ListItemIcon>*/}
            {/*<ListItemText primary="Notifications" />*/}
          {/*</ListItem>*/}
          <ListItem button onClick={() => handleSettingsNavigation()}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem button onClick={() => handleMenuNavigation('/contacts')}>
            <ListItemIcon>
              <ContactMailIcon />
            </ListItemIcon>
            <ListItemText primary="Contacts" />
          </ListItem>
          {/* <ListItem button onClick={() => handleMenuNavigation('/contacts')}> */}
          {/*  <ListItemIcon style={{height: 25, width: 25}}> */}
          {/*    <BugIcon /> */}
          {/*  </ListItemIcon> */}
          {/*  <ListItemText primary="Report a bug" /> */}
          {/* </ListItem> */}
          {/*<BettaBanner />*/}
          <ListItem button onClick={() => handleMenuNavigation('/faq')}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="Help" />
          </ListItem>
          <ListItem button onClick={handleLogOutClick}>
            <ListItemIcon>
              <PowerSettingsNewIcon />
            </ListItemIcon>
            <ListItemText id="switch-list-label-bluetooth" primary="Log Out" />
          </ListItem>
          <div
            style={{
              display: 'flex',
              borderTop: '1px solid #F7F7F7',
              borderBottom: '1px solid #F7F7F7',
              padding: '10px 10px 5px 10px',
              justifyContent: 'space-around',
            }}
          >
            <Button
              size="small"
              className={classes.bottomButton}
              onClick={() => handleMenuNavigation('/privacy')}
            >
              <Typography variant="caption" display="block">
                Privacy policy
              </Typography>
            </Button>
            <Button
              size="small"
              className={classes.bottomButton}
              onClick={() => handleMenuNavigation('/terms')}
            >
              <Typography variant="caption" display="block">
                Terms of Service
              </Typography>
            </Button>
          </div>
          <div
            style={{
              paddingTop: 10,
            }}
          >
            <Copyright />
          </div>
        </List>
      </Popover>
    </div>
  );
}
