import React, { useEffect } from 'react';


import { useDispatch, useStore } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import { LinearProgress } from '@material-ui/core';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import * as Yup from 'yup';
import Copyright from '../../Copyright';
import {
  linkLoginSubmit,
  loginSubmit,
  requestLinkLoginSubmit
} from '../../../redux/login/loginActions';
import getQueryParam from '../../../helpers/getQueryParam';
import useStyles from '../styles';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Use only a valid email address. “Valid” means “correct”.')
    .required('Please enter email'),

});

export default function LinkLogin(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useStore();
  const { auth } = store.getState();
  const { isAuthenticated } = auth;
  const { history, location } = props;
  const [showPasswordValue, setFormShowPassword] = React.useState({
    showPassword: false,
  });
  const loginToken = getQueryParam('loginToken', location);


  useEffect(() => {
    if (isAuthenticated) {
      const { state } = history.location;
      // console.log(state);
      if (state && state.goBack && !state.goBack.includes('/login')) {
        history.push(state.goBack);
      } else {
        history.push('dashboard/downloads');
      }
    } else if (loginToken) {
      dispatch(linkLoginSubmit({ loginToken }));
    }
  }, [isAuthenticated, dispatch, history]);
  const handleFormSubmit = (valuesFromForm) => {
    dispatch(requestLinkLoginSubmit(valuesFromForm));
  };

  return (
    <div className={classes.paper}>
      {' '}
      <Typography className={classes.loginTitle}>Passwordless Authentication</Typography>
      {loginToken ? <LinearProgress /> : (
        <>
          <Typography className={classes.loginCaption}>Request a magic link</Typography>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            onSubmit={values => handleFormSubmit(values)}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
            }) => (
              <>
                <form>
                  <div className={classes.formInput}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      error={!!(touched.email && errors.email)}
                      fullWidth
                      id="email"
                  // classes={{ input: classes.textField }}
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      value={values.email}
                      onChange={handleChange('email')}
                    />
                    <div style={{ color: 'red' }}>
                      {touched.email && errors.email}
                    </div>
                  </div>


                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleSubmit}
                  >
                    Send me a magic link
                  </Button>
                </form>
              </>
            )}
          </Formik>
          <Grid container>
            <Grid item xs>
              <Link
                component="button"
                onClick={() => history.push('/login')}
                variant="body2"
                className={classes.linksOpt}
              >
                Back to login?
              </Link>
            </Grid>
            <Grid item>
              <Link
            // component="button"
            // onClick={() => history.push('/pricing')}
                variant="body2"
                className={classes.linksOpt}
                href="https://checkout.nzbcloud.com/checkout"
                target="_blank"
              >
                Don't have an account?
              </Link>
            </Grid>
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </>
      )}
    </div>
  );
}
