import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Step0 from './step0';
import Step1 from './step1';
import Step2 from './step2';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  stepper: {
    padding: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Set up Usenet Credentials', 'Connect external Drives'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    // case 0:
    //   return (<Step0 />);
    case 1:
      return (<Step1 />);
    case 2:
      return (<Step2 />);
    default:
      return (<Step1 />);
  }
}

export default function WelcomeStepper(props) {
  const classes = useStyles();
  const { welcomeModalStep } = props;


  const steps = getSteps();


  return (
    <div className={classes.root}>
      <Stepper activeStep={welcomeModalStep} alternativeLabel className={classes.stepper}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>

          <div>
            <div className={classes.instructions}>
              {getStepContent(welcomeModalStep)}
            </div>
            <div>
              {/* <Button */}
              {/*  disabled={welcomeModalStep === 0} */}
              {/*  onClick={handleBack} */}
              {/*  className={classes.backButton} */}
              {/* > */}
              {/*  Back */}
              {/* </Button> */}
              {/* <Button */}
              {/*  variant="contained" */}
              {/*  color="primary" */}
              {/*  onClick={handleNext} */}
              {/* > */}
              {/*  {activeStep === steps.length - 1 */}
              {/*    ? 'Finish' */}
              {/*    : 'Next'} */}
              {/* </Button> */}
            </div>
          </div>

      </div>
    </div>
  );
}
