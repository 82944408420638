import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { onRenameRootFolderSubmit } from '../../../redux/userStorage/userStorageActions';


const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 2,
  },
  formInput: {
    padding: '2px 24px',
    width: 600,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  submit: {
    marginLeft: 20,
    backgroundColor: '#2158eb',
  },
}));
const validationSchema = Yup.object().shape({
  newCustomName: Yup.string()
    .min(1)
    .required('Come on, man. Enter valid name '),
});
export default function ChangeNodeNameModal(props) {
  const classes = useStyles();
  const {
    open, handleOpen, handleClose, folderName, id,
  } = props;
  const dispatch = useDispatch();

  const handleFormSubmit = (valuesFromForm) => {
    const { newCustomName } = valuesFromForm;

    dispatch(onRenameRootFolderSubmit({ newCustomName, id }));
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change Folder name</DialogTitle>
        <div>
          <Formik
            initialValues={{ newCustomName: folderName }}
            validationSchema={validationSchema}
            onSubmit={values => handleFormSubmit(values)}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
            }) => (
              <>
                <form>
                  <div className={classes.formInput}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      error={!!(touched.newCustomName && errors.newCustomName)}
                      fullWidth
                      id="new-name"
                      label="Folder name"
                      name="Folder name"
                      value={values.newCustomName}
                      onChange={handleChange('newCustomName')}
                      autoComplete="off"
                    />
                    <div style={{ color: 'red', height: 20 }}>
                      {touched.newCustomName && errors.newCustomName}
                    </div>
                  </div>
                  <div
                    style={{
                      padding: '18px 32px',
                      backgroundColor: '#F7F7F7',
                      borderTop: '1px solid #E0E0E0',
                      justifyContent: 'flex-end',
                      display: 'flex',
                    }}
                  >
                    <Button onClick={handleClose} color="default">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleSubmit}
                      style={{ boxShadow: 'none' }}
                    >
                      SAVE
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
      </Dialog>
    </>
  );
}
