import { createMuiTheme } from '@material-ui/core';
import { orange } from '@material-ui/core/colors';

const theme = createMuiTheme({
  text: {
    main: '#1F374D',
    secondary: 'rgba(31, 55, 77, 0.6)',

  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Poppins',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(', '),
  },

});

export default theme;
