const unreadCountFilter = (notifications) => {
  const unreadCount = notifications.filter(
    notification => notification.unreaded === true,
  ).length;

  return {
    notifications,
    unreadCount,
  };
};
export default unreadCountFilter;
