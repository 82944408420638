import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ViewListIcon from '@material-ui/icons/ViewList';
import { useDispatch } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Folder from './folder';
import File from './file';
import { storageSetDisplayType } from '../../../../redux/files/filesActions';
import ReplayModal from '../ReplayModal';
import { onMediaTranscodingSubmit } from '../../../../redux/userStorage/userStorageActions';
import ActiveJobs from '../ActiveJobs/ActiveJobs';
import AudioPlayer from '../AudioPlayer';
import appConsts from '../../../../config/appConsts';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  storageContainer: {
    // paddingTop: 20,
    width: '100%',
  },
  fileTypeTitle: {
    color: 'rgba(0,0,0,0.6)',
    // fontFamily: 'Roboto, san-serif',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
  },
  foldersContainer: {
    display: 'flex',
    marginTop: 12,
    width: '100%',
    flexWrap: 'wrap',
    // paddingLeft: 20,
    // paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      // marginBottom: 150,
    },
  },
  filesContainer: {
    display: 'flex',
    marginTop: 12,
    width: '100%',
    flexWrap: 'wrap',
    // paddingLeft: 20,
    // paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      justifyContent: 'space-between',
      marginBottom: 200,
    },
  },
  gridHeadersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingLeft: 20,
    // paddingRight: 20,
  },
  fileViewTypeContainer: {
    // borderLeft: '1px solid rgba(0,0,0,0.1)',
    paddingLeft: 10,
  },
}));

export default function FilesGrid(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { folders, files } = props;
  const [modalHandler, setModalHandler] = React.useState({
    open: false,
    type: 'IMAGE',
    data: {},
  });
  const [audioPlayerProps, setAudioPlayerProps] = React.useState({
    open: false,
    audioLists: [],
  });
  const handleClose = () => {
    setModalHandler({ open: false, type: 'IMAGE', data: {} });
  };

  const handleFileClick = (data) => {
    if (data.type === 'IMAGE') {
      setModalHandler({ open: true, type: 'IMAGE', data });
    } else if (data.type === 'VIDEO') {
      setModalHandler({ open: true, type: 'MEDIA', data });
    }
    if (data.type === 'AUDIO') {
      const audioFiles = files.filter(file => file.type === 'AUDIO');
      const token = localStorage.getItem('@accessToken');
      const playList = audioFiles.map(file => ({
        name: file.customName,
        musicSrc: () => Promise.resolve(
          `${appConsts.FILES_URL}/files/${file._id}/play?token=${token}`,
        ),
        cover: file.previewUrl || '',
      }));
      const index = audioFiles.findIndex(x => x._id === data._id);

      setAudioPlayerProps({
        open: true,
        audioLists: playList,
        playIndex: index,
      });
    }
  };
  const handleViewTypeSwitch = (type) => {
    dispatch(storageSetDisplayType(type));
  };
  const onEncodingClick = (data) => {
    dispatch(onMediaTranscodingSubmit(data));
    handleClose();
  };
  return (
    <div className={classes.storageContainer}>
      {folders.length || files.length ? (
        <>
          <div className={classes.gridHeadersContainer}>
            <div className={classes.fileTypeTitle}>
              {' '}
              {folders.length ? 'Folders' : ' '}
            </div>
            <div className={classes.fileViewTypeContainer}>
              {/*<ActiveJobs onMediaFileClick={handleFileClick} />*/}
              <Tooltip placement="left" title="List view" aria-label="add">
                <IconButton
                  style={{ color: 'rgba(0,0,0,0.6)', fontSize: 12 }}
                  component="div"
                  onClick={() => handleViewTypeSwitch('list')}
                >
                  <ViewListIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div
            className={classes.foldersContainer}
            style={{ marginBottom: files.length ? 0 : 200 }}
          >
            {folders.map(folder => (
              <Folder
                key={folder._id}
                data={folder}
                // onFolderSelect={onFolderSelect}
              />
            ))}
          </div>
        </>
      ) : null}

      {files.length ? (
        <>
          <div className={classes.gridHeadersContainer}>
            <div className={classes.fileTypeTitle}>Files</div>
            {/* {!folders.length && ( */}
            {/*  <div className={classes.fileViewTypeContainer}> */}
            {/*    <IconButton */}
            {/*      style={{ color: 'rgba(0,0,0,0.6)', fontSize: 12 }} */}
            {/*      component="div" */}
            {/*      onClick={() => handleViewTypeSwitch('list')} */}
            {/*    > */}
            {/*      <ViewListIcon /> */}
            {/*    </IconButton> */}
            {/*  </div> */}
            {/* )} */}
          </div>
          <div className={classes.filesContainer}>
            {files.map(file => (
              <File
                key={file._id}
                data={file}
                onMediaFileClick={handleFileClick}
              />
            ))}
          </div>
        </>
      ) : null}
      {audioPlayerProps.open ? <AudioPlayer {...audioPlayerProps} /> : null}
      <ReplayModal
        modalHandler={modalHandler}
        data={modalHandler.data}
        handleClose={() => handleClose()}
        onEncodingClick={onEncodingClick}
      />
    </div>
  );
}
