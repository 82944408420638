import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  fileTranscodeStatus: {
    marginLeft: 'auto',
    borderRadius: 2,
    padding: '7px 12px',
    backgroundColor: 'rgb(242, 242, 242)',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    color: 'rgba(0, 0, 0, 0.47)',
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: '2px 8px',
      borderRadius: 2,
    },
  },
  transcodeIconStatusWrapper: {
    width: 16,
    height: 16,
    marginLeft: 8,
    [theme.breakpoints.down('sm')]: {
      width: 12,
      height: 12,
    },
  },
}));

export default useStyles;
