import {
  GET_AVATARS_SUCCESS,
  SET_ADD_INDEXER_MODAL_CLOSE,
  SET_ADD_INDEXER_MODAL_OPEN,
  SET_BUG_REPORT_MODAL_CLOSE,
  SET_BUG_REPORT_MODAL_OPEN,
  SET_DASHBOARD_TAB,
  SET_DOWNLOADS_POPOVER_OPEN, SET_NZB_CREDENTIAL_MODAL_OPEN,
  SET_USENET_MODAL_CLOSE,
  SET_USENET_MODAL_OPEN,
  SET_WELCOME_MODAL_CLOSE,
  SET_WELCOME_MODAL_OPEN,
  SET_WELCOME_MODAL_STEP,
} from './viewsEffectsActions';
import {
  GET_STORAGE_FOLDER_DATA_SUCCESS,
  SET_BROWSE_STORAGE_TYPE_SUBMIT,
  SET_BROWSE_STORAGE_TYPE_SUCCESS,
} from '../userStorage/userStorageActions';
import {
  NZB_FILES_DOWNLOAD_BY_LINK_SUCCESS,
  NZB_FILES_LINK_UPLOAD_SUCCESS,
  NZB_FILES_UPLOAD_SUCCESS,
} from '../files/filesActions';
import { HDR_ADD_INDEXER_SUCCESS } from '../hydraSearch/hydraSearchActions';
import {
  USENET_ADD_CONFIG_SUCCESS,
  USENET_EDIT_CONFIG_SUCCESS,
} from '../usenet/usenetProvidersActions';
import { GET_USER_SUBMIT, GET_USER_SUCCESS } from '../user/userActions';

const initState = {
  userDataLoading: true,
  dashboardTab: 0,
  bugReportModalOpen: false,
  addIndexerModalOpen: false,
  usenetModalOpen: false,
  usenetModalType: 'add',
  usenetModalData: {},
  avatars: [
    {
      createdAt: '2020-05-04T10:12:12.708Z',
      description: 'How To Meet That Special Someone',
      id: 1,
      name: 'Medical sloth',
      updatedAt: '2020-05-04T10:12:12.708Z',
      url:
        'https://avatars-321.s3.nl-ams.scw.cloud/medicalmaskslothwithgloves.jpg',
    },
  ],
  storageIsLoading: false,
  downloadsPopoverOpen: false,
  welcomeModalOpen: false,
  welcomeModalStep: 1,
  nzbCredentialModalOpen: false,
};

function viewsEffectsReducer(state = initState, { type, payload }) {
  switch (type) {
    case GET_USER_SUBMIT: {
      return {
        ...state,
        userDataLoading: true,
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        userDataLoading: false,
      };
    }

    case SET_WELCOME_MODAL_OPEN: {
      return {
        ...state,
        welcomeModalOpen: payload,
      };
    }

    case USENET_ADD_CONFIG_SUCCESS: {
      return {
        ...state,
        usenetModalOpen: false,
        nzbCredentialModalOpen: false,
        welcomeModalStep: 2,
      };
    }
    case SET_WELCOME_MODAL_STEP: {
      return {
        ...state,
        welcomeModalStep: payload,
      };
    }
    case SET_DASHBOARD_TAB: {
      return {
        ...state,
        dashboardTab: payload,
      };
    }
    case SET_DOWNLOADS_POPOVER_OPEN: {
      return {
        ...state,
        downloadsPopoverOpen: payload,
      };
    }
    case NZB_FILES_DOWNLOAD_BY_LINK_SUCCESS:
    case NZB_FILES_LINK_UPLOAD_SUCCESS:
    case NZB_FILES_UPLOAD_SUCCESS: {
      return {
        ...state,
        downloadsPopoverOpen: true,
      };
    }
    case SET_BUG_REPORT_MODAL_OPEN: {
      return {
        ...state,
        bugReportModalOpen: payload,
      };
    }
    case SET_BUG_REPORT_MODAL_CLOSE: {
      return {
        ...state,
        bugReportModalOpen: payload,
      };
    }
    case GET_STORAGE_FOLDER_DATA_SUCCESS: {
      return {
        ...state,
        // dashboardTab: 0,
      };
    }
    case GET_AVATARS_SUCCESS: {
      return {
        ...state,
        avatars: payload,
      };
    }
    case SET_ADD_INDEXER_MODAL_OPEN: {
      return {
        ...state,
        addIndexerModalOpen: payload,
      };
    }
    case SET_ADD_INDEXER_MODAL_CLOSE:
    case HDR_ADD_INDEXER_SUCCESS: {
      return {
        ...state,
        addIndexerModalOpen: false,
      };
    }
    // case USENET_ADD_CONFIG_SUCCESS:
    case USENET_EDIT_CONFIG_SUCCESS:
    case SET_USENET_MODAL_CLOSE: {
      const { usenetModalOpen, usenetModalType, usenetModalData } = payload;
      return {
        ...state,
        usenetModalOpen,
        usenetModalType,
        usenetModalData,
        welcomeModalStep: 2,
      };
    }
    case SET_USENET_MODAL_OPEN: {
      const { usenetModalOpen, usenetModalType, usenetModalData } = payload;
      return {
        ...state,
        usenetModalOpen,
        usenetModalType,
        usenetModalData,
      };
    }
    case SET_BROWSE_STORAGE_TYPE_SUBMIT: {
      return {
        ...state,
        storageIsLoading: true,
      };
    }
    case SET_BROWSE_STORAGE_TYPE_SUCCESS: {
      return {
        ...state,
        storageIsLoading: false,
      };
    }
    case SET_NZB_CREDENTIAL_MODAL_OPEN: {
      const { nzbCredentialModalOpen } = payload;
      return {
        ...state,
        nzbCredentialModalOpen,
      };
    }
    default: {
      return state;
    }
  }
}

export default viewsEffectsReducer;
