import {
  GET_DOWNLOADS_SUCCESS,
  NZB_FILES_UPLOAD_SUCCESS,
  NZB_FILES_DOWNLOADING_RESUME_SUCCESS,
  NZB_FILES_DOWNLOADING_DELETE_SUCCESS,
  NZB_FILES_DOWNLOADING_PAUSE_SUCCESS,
  NZB_FILES_UPLOAD_SUBMIT,
  NZB_FILES_CLEAR_COMPLETED_SUCCESS,
  NZB_FILES_LINK_UPLOAD_SUCCESS,
  NZB_FILES_LINK_UPLOAD_SUBMIT,
  NZB_FILES_DOWNLOAD_BY_LINK_SUCCESS,
  NZB_FILES_DOWNLOADING_RESTART_SUBMIT,
  NZB_FILES_DOWNLOADING_RESTART_SUCCESS,
} from './filesActions';
import { USER_LOGOUT_SUBMIT } from '../login/loginActions';
import { IO_DOWNLOADS_UPDATE } from '../sockets/socketsActions';

const initState = {
  filesUploading: false,
  downloads: [],
  filesDownloadingCount: 0,
  filesQueuedCount: 0,
};

function filesReducer(state = initState, { type, payload }) {
  switch (type) {
    case NZB_FILES_DOWNLOAD_BY_LINK_SUCCESS:
    case NZB_FILES_LINK_UPLOAD_SUCCESS:
    case NZB_FILES_UPLOAD_SUCCESS:
    case NZB_FILES_DOWNLOADING_PAUSE_SUCCESS:
    case GET_DOWNLOADS_SUCCESS:
    case NZB_FILES_DOWNLOADING_DELETE_SUCCESS:
    case NZB_FILES_DOWNLOADING_RESUME_SUCCESS:
    case NZB_FILES_DOWNLOADING_RESTART_SUCCESS:
    case NZB_FILES_CLEAR_COMPLETED_SUCCESS:
    case IO_DOWNLOADS_UPDATE: {
      const { downloads, filesDownloadingCount, filesQueuedCount } = payload;
      return {
        ...state,
        downloads,
        filesDownloadingCount,
        filesQueuedCount,
      };
    }
    case NZB_FILES_LINK_UPLOAD_SUBMIT:
    case NZB_FILES_UPLOAD_SUBMIT: {
      return {
        ...state,
        filesQueuedCount: 1,
      };
    }
    case USER_LOGOUT_SUBMIT: {
      return {
        ...initState,
        // userLoading: true,
      };
    }
    default: {
      return state;
    }
  }
}

export default filesReducer;
