import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';
import StarIcon from '@material-ui/icons/Star';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { toast } from 'react-toastify';
import Tooltip from '@material-ui/core/Tooltip';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Chip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  getStorageFolderArchiveSubmit,
  onStorageSelectFolder,
} from '../../../../redux/userStorage/userStorageActions';
import DoneZipIcon from '../../../atoms/DoneZipFolderIcon';

import DeleteFolderModal from '../deleteModal';
import LottieAnimationPlayer from '../../../atoms/LottieAnimationPlayer';
import ChangeNodeNameModal from '../changeNodeNameModal';
import appConsts from '../../../../config/appConsts';
import InfectedFolder from '../../../atoms/InfectedFolderIcon';
import OnlyPaidModal from '../../onlyPaidModal';

const useStyles = makeStyles(theme => ({
  // FOLDER
  folderWrapper: {
    // position: 'relative',
    height: 70,
    width: '24%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    // backgroundColor: '#FFFFFF',
    border: '1px solid #EDEDED',
    borderRadius: 2,
    cursor: 'pointer',
    marginRight: '1%',
    marginBottom: 20,
    // borderLeft: '0rem',
    transition: 'border-left 300ms ease-in-out, padding-left 300ms ease-in-out',
    justifyContent: 'space-between',
    '&:hover': {
      // opacity: 0.7,
      // backgroundColor: 'rgba(0, 0, 0, 0.04)'
      boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.05)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 66,
      marginRight: 0,
      marginBottom: 8,
      // '&:last-child': {
      //   marginBottom: 150,
      // },
    },
  },

  folderClickableWrapper: {
    padding: '16px 5px 16px 16px',
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '75%',
      // height: 66,
      // marginRight: 0,
      // marginBottom: 8,
    },
  },

  folderIconWrapper: {
    marginRight: 10,
    width: 40,
    height: 40,
  },
  folderContentWrapper: {
    // width: 168,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgba(0,0,0,0.87)',
    // fontFamily: 'Roboto, san-serif',
    fontSize: 16,
    letterSpacing: 0.15,
    width: '85%',
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  folderIcon: {
    fontSize: 40,
    color: '#FCB643',
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
  },
  folderMenuWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    // borderLeft: '1px solid #00000026',
    color: '#00000099',
    justifyContent: 'center',
    // width: '15%',
    minWidth: 64,
  },
  buttonRoot: {
    width: '100%',
    height: '100%',
  },
  folderMenu: {
    // width: 280,
    // borderRadius: 2,
    // backgroundColor: '#FFFFFF',
  },
  folderIconEncryptedWrapper: {
    position: 'relative',
  },
  folderIconEncrypted: {
    position: 'absolute',
    color: '#2158EB',
    top: '30%',
    left: '30%',
    height: 16,
    width: 16,
  },
}));

export default function Folder(props) {
  const classes = useStyles();
  const { data } = props;
  const {
    customName,
    _id,
    name,
    viruses,
    type,
    targetStorage,
    isEncrypted,
    compressStatus,
  } = data;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = localStorage.getItem('@accessToken');
  const [modalOpen, setOpen] = React.useState(false);
  const [renameModalOpen, setRenameOpen] = React.useState(false);
  const { zipDownloadAvailable } = useSelector(
    state => state.user,
  );
  const [paidModalOpen, setPaidModalOpen] = React.useState(false);
  const handlePaidModalOpen = () => {
    setPaidModalOpen(true);
  };
  const handlePaidModalClose = () => {
    setPaidModalOpen(false);
  };
  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleWrapperClick = () => {
    dispatch(onStorageSelectFolder(data));
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePrepareZipFolder = () => {
    dispatch(getStorageFolderArchiveSubmit(_id));
    handleClose();
  };
  const handleRenameModalOpen = () => {
    setRenameOpen(true);
    setAnchorEl(null);
  };
  const handleRenameModalClose = () => {
    setRenameOpen(false);
  };

  const handleDownloadFolder = () => {
    const link = document.createElement('a');

    link.href = `${appConsts.FILES_URL}/files/${_id}/downloadzip?token=${token}`;
    // link.target = '_blank';
    link.download = 'proposed_file_name';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDeleteFolder = () => {
    handleModalOpen();
  };
  const toastErrorText = 'Something went wrong with external storage. Please Delete folder and try again.';
  const toastSyncText = 'Folder is Syncing with storage. Please wait...';
  const handleClickWhileSync = () => {
    toast.warn(type === 'FOLDER_SYNC_ERROR' ? toastErrorText : toastSyncText);
  };
  const open = Boolean(anchorEl);
  const folderName = customName || name;
  const isSyncing = type === 'FOLDER_SYNC_PROCESS' || type === 'FOLDER_SYNC_ERROR';
  return (
    <>
      <ChangeNodeNameModal
        open={renameModalOpen}
        handleClose={handleRenameModalClose}
        folderName={folderName}
        id={_id}
      />

      <DeleteFolderModal
        open={modalOpen}
        handleOpen={handleModalOpen}
        handleClose={handleModalClose}
        folderName={folderName}
        id={_id}
      />
      <div
        className={classes.folderWrapper}
        style={{
          backgroundColor:
            viruses && viruses.length ? 'rgba(255, 77, 99, 0.07)' : '#FFFFFF',
        }}
      >
        <Tooltip
          title={folderName}
          placement="top"
        >
          <div
            role="button"
            className={classes.folderClickableWrapper}
          // onClick={isSyncing ? handleClickWhileSync : handleWrapperClick}
            onClick={handleWrapperClick}
          >

            {isSyncing ? (
              <div
                className={classes.folderIconWrapper}
                style={{ position: 'relative' }}
              >
                {type === 'FOLDER_SYNC_PROCESS' && (
                  <>
                    <FolderIcon className={classes.folderIcon} />
                    <div
                      style={{
                        width: 25,
                        height: 25,
                        position: 'absolute',
                        top: 8,
                        right: 8,
                      }}
                    >
                      <LottieAnimationPlayer animation="inProcessingCloud" />
                    </div>
                  </>
                )}
                {type === 'FOLDER_SYNC_ERROR' && (
                  <>
                    <FolderIcon className={classes.folderIcon} />
                    <div
                      style={{
                        width: 25,
                        height: 25,
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <ReportProblemIcon
                        style={{
                          color: '#EF4056',
                          fontSize: 20,
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className={classes.folderIconWrapper}>
                {viruses && viruses.length ? (
                  <InfectedFolder className={classes.folderIcon} />
                ) : (
                  compressStatus && (
                    <>
                      {compressStatus === 'COMPRESSING' && (
                      <LottieAnimationPlayer animation="inProcessingZip" />
                      )}
                      {compressStatus === 'READY' && (
                      <DoneZipIcon className={classes.folderIcon} />
                      )}
                      {compressStatus === 'NONE' && (
                        <>
                          {isEncrypted
                            ? (
                              <Tooltip
                                title="Folder is encrypted"
                                aria-label="Virus"
                              >
                                <div className={classes.folderIconEncryptedWrapper}>
                                  <VerifiedUserIcon className={classes.folderIconEncrypted} />
                                  <FolderIcon className={classes.folderIcon} />
                                </div>
                              </Tooltip>
                            )
                            : <FolderIcon className={classes.folderIcon} />
                        }
                        </>
                      )}
                    </>
                  )
                )}
                {viruses && !viruses.length && !compressStatus && (
                <FolderIcon className={classes.folderIcon} />
                )}
              </div>
            )}
            <div className={classes.folderContentWrapper}>{folderName}</div>

          </div>
        </Tooltip>
        {/* {viruses && viruses.length ? ( */}
        {/*  <Tooltip */}
        {/*    title={`Folder contains files infected with: ${viruses.join(', ')}`} */}
        {/*    aria-label="Virus" */}
        {/*  > */}
        {/*    <div */}
        {/*      style={{ */}
        {/*        marginRight: 10, */}
        {/*        justifyContent: 'center', */}
        {/*        alignItems: 'center', */}
        {/*        display: 'flex', */}
        {/*      }} */}
        {/*    > */}
        {/*      <HelpIcon style={{ color: '#FF4D63' }} /> */}
        {/*    </div> */}
        {/*  </Tooltip> */}
        {/* ) : null} */}
        {compressStatus && (
          <div className={classes.folderMenuWrapper}>
            <Button
              classes={{ root: classes.buttonRoot }}
              onClick={handleClick}
            >
              <MoreHorizIcon style={{ color: '#666666' }} />
            </Button>
            <Menu
              // id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              getContentAnchorEl={null}
              open={open}
              onClose={handleClose}
              className={classes.folderMenu}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              {!isSyncing && (
                <div>
                  <MenuItem onClick={handleRenameModalOpen}>
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Rename" />
                  </MenuItem>
                  {/* {compressStatus === 'NONE' && targetStorage === 'NZBCLOUD' && ( */}
                  {/*  <MenuItem onClick={handlePrepareZipFolder}> */}
                  {/*    <ListItemIcon> */}
                  {/*      <ArchiveIcon fontSize="small" /> */}
                  {/*    </ListItemIcon> */}
                  {/*    <ListItemText primary="Prepare Zip" /> */}
                  {/*  </MenuItem> */}
                  {/* )} */}
                  {targetStorage === 'NZBCLOUD' && (
                  <MenuItem onClick={zipDownloadAvailable ? () => handleDownloadFolder() : console.log('not available in Free plan')}>


                    <ListItemIcon>
                      <GetAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={zipDownloadAvailable ? 'Download' : (
                      <>
                        Download
                        <Chip variant="outlined" size="small" icon={<StarIcon style={{ color: '#f5cc00' }} />} label="PRO" style={{ color: '#f5cc00', marginLeft: 15 }} onClick={() => handlePaidModalOpen()} />
                      </>
                    )}
                    />


                  </MenuItem>
                  )}
                  {/* {targetStorage === 'NZBCLOUD' && ( */}
                  {/*  <MenuItem onClick={() => handleDownloadFolder()}> */}


                  {/*    <ListItemIcon> */}
                  {/*      <GetAppIcon fontSize="small" /> */}
                  {/*    </ListItemIcon> */}
                  {/*    <ListItemText primary="Download" /> */}
                  {/*    <Typography variant="body2" style={{ paddingLeft: 15 }}> */}
                  {/*      <StarIcon style={{ color: '#f5cc00' }} /> */}
                  {/*    </Typography> */}

                  {/*  </MenuItem> */}
                  {/* )} */}
                  <Divider />
                </div>
              )}
              <OnlyPaidModal
                open={paidModalOpen}
                handleOpen={handlePaidModalOpen}
                handleClose={handlePaidModalClose}
              />
              <MenuItem
                disabled={
                  compressStatus === 'COMPRESSING'
                  || type === 'FOLDER_SYNC_PROCESS'
                }
                onClick={handleDeleteFolder}
              >
                <ListItemIcon>
                  <DeleteForeverIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Delete" />
              </MenuItem>
            </Menu>
          </div>
        )}

      </div>
    </>
  );
}
