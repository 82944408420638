import history from '../../config/history';

export const VERIFY_USER_EMAIL_SUBMIT = 'VERIFY_USER_EMAIL_SUBMIT';
export const VERIFY_USER_EMAIL_SUCCESS = 'VERIFY_USER_EMAIL_SUCCESS';
export const VERIFY_USER_EMAIL_FAILURE = 'VERIFY_USER_EMAIL_FAILURE';

export const verifyUserEmailSubmit = payload => ({
  type: VERIFY_USER_EMAIL_SUBMIT,
  payload,
});

export const verifyUserEmailSuccess = (payload) => {
  const t = () => history.push('/dashboard/downloads');

  setTimeout(t, 4000);
  return {
    type: VERIFY_USER_EMAIL_SUCCESS,
    payload,
  };
};

export const verifyUserEmailFailure = payload => ({
  type: VERIFY_USER_EMAIL_FAILURE,
  payload,
});
