import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

import HelpIcon from '@material-ui/icons/Help';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import FileIcon from '../../../atoms/FileIcon';
import EncodeStatusReadyBadge from '../EncodeStatusBadges/EncodeStatusReadyBadge';
import EncodeStatusProgressBadge from '../EncodeStatusBadges/EncodeStatusProgressBadge';
import FileSubtitleIcon from '../../../atoms/FileSubtitleIcon';
import FileSubtitleBrokenIcon from '../../../atoms/FileSubtitleBrokenIcon';
import InfectedFile from '../../../atoms/InfectedFileIcon';
import formatBytes from '../../../../helpers/formatBytes';
import appConsts from '../../../../config/appConsts';
import streamSaver from 'streamsaver';

const useStyles = makeStyles(theme => ({
  // FILE
  fileWrapper: {
    height: 240,
    width: '24%',
    display: 'flex',
    alignItems: 'center',
    // backgroundColor: '#FFFFFF',
    border: '1px solid #EDEDED',
    borderRadius: 2,
    // cursor: 'pointer',
    marginRight: '1%',
    marginBottom: 20,
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
    '&:hover': {
      // opacity: 0.7,
      // backgroundColor: 'rgba(0, 0, 0, 0.04)'
      boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.05)',
    },
    // '&:last-child': {
    //   marginBottom: 150,
    // },
    [theme.breakpoints.down('sm')]: {
      width: '49%',
      height: 150,
      marginRight: 0,
    },
  },
  fileClickableWrapper: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 148,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    [theme.breakpoints.down('sm')]: {
      height: 116,
    },
  },

  folderIconWrapper: {
    // marginRight: 10,
  },
  fileIconWrapper: {
    width: 56,
    height: 56,
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40,
    },
  },
  fileContentWrapper: {
    width: '100%',
    color: 'rgba(0,0,0,0.87)',
    // fontFamily: 'Roboto, san-serif',
    fontSize: 16,
    letterSpacing: 0.15,
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 6,
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 10,
    [theme.breakpoints.down('sm')]: {
      padding: '6px 8px 6px 8px',
    },
  },
  fileMenuWrapper: {
    display: 'flex',
    height: 92,
    color: '#00000099',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      height: 50,
    },
  },
  buttonRoot: {
    minWidth: 'auto',
    height: '100%',
    width: '100%',
  },
  fileMenu: {
    width: '20%',
    borderLeft: '1px solid #00000026',
  },
  fileSize: {
    // fontFamily: 'Roboto, san-serif',
    fontSize: 14,
    letterSpacing: 0.25,
    color: 'rgba(0,0,0,0.38)',
  },
  fileDataWrapper: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  fileClickableIconWrapper: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  fileName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: 5,
    paddingRight: 8,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  encodeStatusContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
    [theme.breakpoints.down('sm')]: {
      top: 8,
      right: 8,
    },
  },
}));
const tempUrl = 'valfiles.nzbcloud.com';
export default function File(props) {
  const classes = useStyles();
  const { data, onMediaFileClick } = props;
  const {
    previewUrl,
    type,
    viruses,
    transcodeStatus,
    isSubtitleValid,
    name,
    size,
    externalStorageId,
    targetStorage,
  } = data;
  const handleFileClick = () => {
    onMediaFileClick(data);
  };
  const token = localStorage.getItem('@accessToken');
  const date = moment(data.created_at).format('D.MM.YYYY');
  const handleDownload = async (e) => {
    // dispatch(getStorageFolderArchiveSubmit(data._id));
    // handleClose();
    e.persist();
    if (targetStorage === 'NZBCLOUD') {
      const response = await fetch(`${appConsts.FILES_URL}/files/${data._id}/download?token=${token}`);
      const downloadLink = await response.text();

      const link = document.createElement('a');

      // link.href = `${appConsts.API_URL}/files/${data._id}/download?token=${token}`;
      link.href = downloadLink;
      link.target = '_blank';
      link.download = 'proposed_file_name';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }else {
      const url = `${appConsts.FILES_URL}/files/${data._id}/download-external?token=${token}`;
      const fileStream = streamSaver.createWriteStream(name);

      fetch(url)
        .then((res) => {
          const readableStream = res.body;

          // more optimized
          if (window.WritableStream && readableStream.pipeTo) {
            return readableStream.pipeTo(fileStream)
              .then(() => console.log('done'));
          }

          const writer = fileStream.getWriter();

          const reader = res.body.getReader();
          const pump = () => reader.read()
            .then(res => (res.done
              ? writer.close()
              : writer.write(res.value)
                .then(pump)));

          pump();
        });
    }
  };
  return (
    <div
      className={classes.fileWrapper}
      style={{
        cursor: previewUrl || type === 'VIDEO' ? 'pointer' : 'inherit',
        backgroundColor:
          viruses && viruses.length
            ? 'rgba(255, 77, 99, 0.07)'
            : '#FFFFFF',
      }}
    >
      <div className={classes.encodeStatusContainer}>
        {transcodeStatus === 'finished' && <EncodeStatusReadyBadge />}
        {transcodeStatus === 'executing' || transcodeStatus === 'queued' ? <EncodeStatusProgressBadge /> : null}
      </div>

      <div
        className={classes.fileClickableWrapper}
        style={previewUrl ? { backgroundImage: `url(${previewUrl})` } : {}}
      >
        {previewUrl || type === 'VIDEO' ? (
          <div
            className={classes.fileClickableIconWrapper}
            onClick={handleFileClick}
          >
            <PlayCircleFilledWhiteIcon
              style={{
                color: '#E2E5E7',
                fontSize: 40,
              }}
            />
          </div>
        ) : (
          viruses && viruses.length ? (
            <div className={classes.fileIconWrapper}>
              <InfectedFile />
            </div>
          )
            : (
              <div className={classes.fileIconWrapper}>

                {type === 'SUBTITLE' && isSubtitleValid && <FileSubtitleIcon />}
                {type === 'SUBTITLE' && isSubtitleValid === false && (
                <FileSubtitleBrokenIcon />
                )}
                {type === 'FILE' && <FileIcon />}
              </div>
            )
        )}
      </div>
      <div className={classes.fileMenuWrapper}>
        <div className={classes.fileContentWrapper}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className={classes.fileName}>{name}</div>
            <Tooltip title={externalStorageId === undefined ? 'Download option is disabled while sync in progress.. ' : 'Download file'}>
              <div>
                <IconButton onClick={handleDownload} disabled={externalStorageId === undefined}>


                  <GetAppIcon fontSize="small" />

                </IconButton>
              </div>
            </Tooltip>
            {viruses && viruses.length ? (
              <Tooltip
                title={`File infected with: ${viruses.join(', ')}`}
                aria-label="Virus"
              >
                <div>
                  <HelpIcon style={{ color: '#FF4D63' }} />
                </div>
              </Tooltip>
            ) : null}
          </div>

          <div className={classes.fileDataWrapper}>
            <div className={classes.fileSize}>
              {size > 0 ? formatBytes(size) : '<1 MB'}
              {/* MB */}
            </div>

            <div className={classes.fileSize}>{date}</div>

          </div>
        </div>
      </div>
    </div>
  );
}
