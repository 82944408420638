import React from 'react';
import './progressStyles.css';
import 'react-circular-progressbar/dist/styles.css';

export default function StorageCircularProgress(props) {
  const {
    sqSize, strokeWidth, percentage, color = '#fcb643',
  } = props;
  const radius = (sqSize - strokeWidth) / 2;
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;
  return (
    <div
      style={{
        position: 'relative',
        height: 48,
      }}
    >
      <div
        style={{
          backgroundColor: color,
          width: 4,
          height: 4,
          left: 22,
          position: 'absolute',
          top: 0,
        }}
      />
      <div
        style={{
          backgroundColor: color,
          width: 4,
          height: 4,
          left: 22,
          position: 'absolute',
          bottom: 0,
        }}
      />
      <div
        style={{
          backgroundColor: color,
          width: 4,
          height: 4,
          left: 0,
          position: 'absolute',
          top: 22,
        }}
      />
      <div
        style={{
          backgroundColor: color,
          width: 4,
          height: 4,
          right: 0,
          position: 'absolute',
          top: 22,
        }}
      />
      <svg width={sqSize} height={sqSize} viewBox={viewBox}>
        <circle
          className="circle-background"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          className="circle-progress"
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
        <text
          className="circle-text"
          x="45%"
          y="50%"
          dy=".3em"
          textAnchor="middle"
        >
          {`${percentage}`}
        </text>
        <text
          className="circle-text-pers"
          x="75%"
          y="55%"
          dy=".3em"
          textAnchor="middle"
        >
          %
        </text>
      </svg>
    </div>
  );
}
