
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useUserback } from '@userback/react';
import { useDispatch, useSelector } from 'react-redux';
import { BugReport } from '@material-ui/icons';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ReportErrorModal from './reportModal';

import { bugReportSendMessageSubmit } from '../../../redux/contacts/contactsActions';
import {
  setReportModalClose,
  setReportModalOpen,
} from '../../../redux/viewsEffects/viewsEffectsActions';
import BugIcon from '../../atoms/BugIcon';

const useStyles = makeStyles(theme => ({
  betaBannerWrapper: {
    backgroundColor: '#FCEED6',
    display: 'flex',
    justifyContent: 'center',
    height: 44,
    alignItems: 'center',
    width: '100%',
  },
  betaBannerGotErrorWrapper: {
    marginLeft: 8,
    marginRight: 16,
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 500,
  },
  betaBannerIconWrapper: {
    display: 'flex',
  },
  betaBannerIcon: {
    color: '#EF4056',
    fontSize: 18,
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    // },
  },
  betaBannerHelpUsWrapper: {
    color: 'rgba(0,0,0,0.6)',
    display: 'flex',
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  betaBannerSendReportWrapper: {
    color: '#2158EB',
    cursor: 'pointer',
  },
}));
export default function BettaBanner() {
  const classes = useStyles();
  const { email, id } = useSelector(state => state.user);
  const { reportLoading, reportLoadingComplete } = useSelector(
    state => state.contacts,
  );
  const { bugReportModalOpen } = useSelector(state => state.viewsEffects);
  const dispatch = useDispatch();
  // const { open, show } = useUserback();
  const handleReportModalOpen = () => {
    // dispatch(setReportModalOpen(true));
    // show()
    // open('bug');
  };
  const handleReportModalClose = () => {
    dispatch(setReportModalClose(false));
  };
  const handleSendReport = (data) => {
    dispatch(bugReportSendMessageSubmit({ ...data, id }));
  };

  return (
    <>
      {/* // <div className={classes.betaBannerWrapper}> */}
      {/* //   <div className={classes.betaBannerIconWrapper}> */}
      {/* //     <ReportProblemIcon className={classes.betaBannerIcon} /> */}
      {/* //   </div> */}
      {/* //   <div className={classes.betaBannerGotErrorWrapper}>Got an error?</div> */}
      {/* //   <div className={classes.betaBannerHelpUsWrapper}> */}
      {/* //     Help us make NZB Cloud better! */}
      {/* //   </div> */}
      {/* //   <div */}
      {/* //     className={classes.betaBannerSendReportWrapper} */}
      {/* //     onClick={handleReportModalOpen} */}
      {/* //   > */}
      {/* //     Send report */}
      {/* //   </div> */}
      <ListItem button onClick={() => handleReportModalOpen()}>
        <ListItemIcon>
          <BugReport />
        </ListItemIcon>
        <ListItemText primary="Report a bug" />
      </ListItem>
      <ReportErrorModal
        handleClose={handleReportModalClose}
        open={bugReportModalOpen}
        email={email}
        id={id}
        handleSendReport={handleSendReport}
        loadingComplete={reportLoadingComplete}
        reportLoading={reportLoading}
      />
    </>
    // </div>
  );
}
