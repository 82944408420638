import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import _ from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import { setHdrSearchQuery } from '../../../redux/hydraSearch/hydraSearchActions';

import ResultsList from './ResultList';

const result = [
  {
    age: '3h',
    age_precise: true,
    category: 'XXX',
    comments: 0,
    cover: null,
    date: '06-08-2020 08:38',
    details_link:
      'https://newz69.keagaming.com/details/94f628e574cb6bebdc9bd593025a80f1',
    downloadType: 'NZB',
    downloadedAt: null,
    episode: null,
    epoch: 1596703085,
    files: 55,
    grabs: 0,
    hasNfo: 'NO',
    hash: 290,
    indexer: 'newznab',
    indexerguid: '94f628e574cb6bebdc9bd593025a80f1',
    indexerscore: null,
    link: 'http://localhost:41526/getnzb/user/8938661199292734650',
    originalCategory: 'N/A',
    peers: null,
    poster: 'Steris@casper.boo (Steris)',
    searchResultId: '8938661199292734650',
    season: null,
    seeders: null,
    showtitle: null,
    size: 2417586836,
    source: null,
    title: 'Dakota Diamond 3 (Antifa Peaceful Protestor) - Ghe',
  },
];
const CssTextField = withStyles({
  root: {
    backgroundColor: '#F0F0F0',
    borderRadius: 2,
    '& label.Mui-focused': {
      color: '#F0F0F0',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F0F0F0',
      },
    },
  },
})(TextField);
const useStyles = makeStyles(theme => ({
  storageContainer: {
    display: 'flex',
    borderRadius: 2,
    backgroundColor: '#FFFFFF',
    height: 550,
    marginBottom: 16,
  },
  wrapperDisabled: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    top: 0,
    alignItems: 'center',
    flexDirection: 'column',

    borderRadius: 2,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: 8,
      backgroundColor: '#fdeed6',
    },
  },
  secondaryText: {
    color: '#2E5373',
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default function Search() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { searchResults, isLoading, isDisabled } = useSelector(
    state => state.hydraSearch,
  );

  const onInputChange = _.debounce((value) => {
    if (value) {
      dispatch(setHdrSearchQuery(value));
    }
  }, 1000);

  return (
    <div style={{ height: '55vh' }}>
      <Typography
        variant="h6"
        style={{
          color: 'rgba(0,0,0,0.87)',
          fontSize: 34,
          marginRight: 5,
          fontWeight: 400,
          lineHeight: 1.43,
        }}
        display="block"
      >
        Search
      </Typography>

      <div style={{ marginTop: 20, position: 'relative' }}>
        <CssTextField
          className={classes.margin}
          // label="Custoыыыm CSS"
          variant="outlined"
          placeholder="Search for NZB"
          onChange={e => onInputChange(e.target.value)}
          fullWidth
          // disabled={isDisabled}
          InputProps={{
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </React.Fragment>
            ),
            startAdornment: (
              <div style={{ marginRight: 10 }}>
                <SearchIcon />
              </div>
            ),
          }}
        />

        {isDisabled && (
          <div className={classes.wrapperDisabled}>
            {' '}
            <div
              style={{
                color: '#0086FA',
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  marginRight: 15,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <SettingsEthernetIcon />
              </div>
              Connecting to NZBCloud Search Engine
            </div>
            <div className={classes.secondaryText}>
              If connecting takes more than 60 seconds, please reload the page.
            </div>
          </div>
        )}
      </div>

      <div style={{ marginTop: 24, marginBottom: 24 }}>
        <Typography
          variant="h6"
          style={{
            color: 'rgba(0,0,0,0.6)',
            fontSize: 15,
            marginRight: 5,
            fontWeight: 500,
            lineHeight: 1.43,
          }}
          display="block"
        >
          Search results
        </Typography>
        <div style={{ height: 4 }}>{isLoading && <LinearProgress />}</div>
      </div>

      {searchResults && searchResults.length !== 0 ? (
        <ResultsList searchResults={searchResults} />
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          Nothing Found
        </div>
      )}
    </div>
  );
}
