import React from 'react';

const DropZoneFolderIcon = () => (
  <svg
    width="88px"
    height="88px"
    viewBox="0 0 88 88"
    version="1.1"
  >
    <title>988103B1-8E7B-4E08-A7FD-71DA62ABD3CC</title>
    <desc>Created with sketchtool.</desc>
    <g
      id="Desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Drag&amp;Drop-Drag-Over-1280"
        transform="translate(-596.000000, -228.000000)"
      >
        <g id="Drop-area-active" transform="translate(12.000000, 168.000000)">
          <g id="folder" transform="translate(584.000000, 60.000000)">
            <g id="Group-3">
              <path
                d="M38.1911474,19.5978436 L38.1911474,19.5978436 C38.1911474,17.3509853 36.380059,15.5294118 34.1461339,15.5294118 L10.7744252,15.5294118 C8.54050013,15.5294118 6.72941176,17.3509853 6.72941176,19.5978436 L6.72941176,83.9315682 C6.72941176,86.1784265 8.54050013,88 10.7744252,88 L77.7432218,88 C79.9771469,88 81.7882353,86.1784265 81.7882353,83.9315682 L81.7882353,27.7347072 C81.7882353,25.4878489 79.9771469,23.6662754 77.7432218,23.6662754 L42.2361609,23.6662754 C40.0022358,23.6662754 38.1911474,21.8447018 38.1911474,19.5978436 Z"
                id="Path"
                fill="#EBA42F"
                fillRule="nonzero"
              />
              <rect
                id="Rectangle"
                fill="#EBF0F3"
                fillRule="nonzero"
                x="13.9764706"
                y="0"
                width="60.5647059"
                height="72.9882353"
              />
              <path
                d="M29.2430072,25.3647059 L29.2430072,16.168649 L34.7773945,25.3647059 L37.6204017,25.3647059 L37.6204017,11.3882353 L34.7868712,11.3882353 L34.7868712,20.6034906 L29.2430072,11.3882353 L26.4,11.3882353 L26.4,25.3647059 L29.2430072,25.3647059 Z M50.0727732,25.3647059 L50.0727732,23.0512928 L43.1168823,23.0512928 L49.9211462,13.0393019 L49.9211462,11.3882353 L39.5726,11.3882353 L39.5726,13.7208468 L46.3863406,13.7208468 L39.5631233,23.6752424 L39.5631233,25.3647059 L50.0727732,25.3647059 Z M57.2276747,25.3647059 C58.7944875,25.3519069 60.0011861,24.9999354 60.8477705,24.3087912 C61.6943549,23.6176471 62.1176471,22.6001293 62.1176471,21.2562379 C62.1176471,20.4691015 61.9138982,19.8035553 61.5064005,19.2595992 C61.0989028,18.7156432 60.5413575,18.3540724 59.8337646,18.1748869 C60.4529084,17.9317065 60.9362196,17.5493374 61.2836983,17.0277796 C61.6311769,16.5062217 61.8049163,15.9030705 61.8049163,15.2183258 C61.8049163,13.9640271 61.3721474,13.0121041 60.5066096,12.3625566 C59.6410719,11.713009 58.3711954,11.3882353 56.69698,11.3882353 L56.69698,11.3882353 L51.8638678,11.3882353 L51.8638678,25.3647059 L57.2276747,25.3647059 Z M56.8107003,17.2437621 L54.706875,17.2437621 L54.706875,13.7208468 L56.69698,13.7208468 C57.4803864,13.7208468 58.0537262,13.8600356 58.4169994,14.1384131 C58.7802725,14.4167906 58.9619091,14.8727537 58.9619091,15.5063025 C58.9619091,16.639011 58.2448395,17.2181642 56.8107003,17.2437621 L56.8107003,17.2437621 Z M57.1423845,23.0512928 L54.706875,23.0512928 L54.706875,19.2787977 L57.3129649,19.2787977 C58.6207482,19.2979961 59.2746399,19.9475436 59.2746399,21.2274402 C59.2746399,21.7969942 59.0866855,22.2433581 58.7107768,22.566532 C58.334868,22.8897059 57.8120706,23.0512928 57.1423845,23.0512928 L57.1423845,23.0512928 Z"
                id="NZB"
                fill="#D5D6DB"
                fillRule="nonzero"
              />
              <g
                id="Group"
                transform="translate(17.600000, 34.164706)"
                fill="#D5D6DB"
                fillRule="nonzero"
              >
                <rect
                  id="Rectangle"
                  x="0.299327141"
                  y="0.0789915966"
                  width="52.7283467"
                  height="1.6177479"
                />
                <rect
                  id="Rectangle"
                  x="0.299327141"
                  y="5.69055462"
                  width="52.7283467"
                  height="1.6177479"
                />
                <rect
                  id="Rectangle"
                  x="0.299327141"
                  y="11.317916"
                  width="52.7283467"
                  height="1.6177479"
                />
                <rect
                  id="Rectangle"
                  x="0.299327141"
                  y="16.9357983"
                  width="52.7283467"
                  height="1.6177479"
                />
                <rect
                  id="Rectangle"
                  x="0.299327141"
                  y="22.56"
                  width="52.7283467"
                  height="1.6177479"
                />
              </g>
              <path
                d="M78.2377536,88 L9.76224635,88 C7.80787471,88 6.13162759,86.6025282 5.7760032,84.6768163 L0.0688255141,44.1419917 C-0.392111194,41.6451419 1.52069802,39.3411765 4.05506866,39.3411765 L83.9449313,39.3411765 C86.479302,39.3411765 88.3921112,41.6448288 87.9311745,44.1419917 L82.2239968,84.6768163 C81.8686849,86.6025282 80.1924378,88 78.2377536,88 Z"
                id="Path"
                fill="#FCB643"
                fillRule="nonzero"
              />
              <g
                id="get_app-24px"
                transform="translate(44.058824, 63.611765) scale(1, -1) translate(-44.058824, -63.611765) translate(31.058824, 50.611765)"
              >
                <polygon
                  id="Path"
                  points="0 0 25.8823529 0 25.8823529 25.8823529 0 25.8823529"
                />
                <polygon
                  id="Shape"
                  fill="#EBA42F"
                  points="25.6987902 12.1151439 18.3562787 12.1151439 18.3562787 1.10137672 7.34251147 1.10137672 7.34251147 12.1151439 0 12.1151439 12.8493951 24.964539"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DropZoneFolderIcon;
