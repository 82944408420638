import {
  call, all, put, takeLatest,
} from 'redux-saga/effects';

import { GET_USER_SUCCESS } from '../user/userActions';
import notificationsService from '../../services/notificationsService';
import {
  getNotificationsFailure,
  getNotificationsSuccess,
  HIDE_ALL_NOTIFICATIONS_SUBMIT,
  hideAllNotificationsFailure,
  hideAllNotificationsSuccess,
  MARK_ALL_READ_SUBMIT,
  markAllNotificationsReadFailure,
  markAllNotificationsReadSuccess,
} from './notificationsActions';
import unreadCountFilter from '../../helpers/unreadCountFilter';
import { IO_FORCE_NOTIFICATIONS_UPDATE } from '../sockets/socketsActions';

function* getUserNotifications() {
  try {
    const response = yield call(notificationsService.getNotifications);
    const result = unreadCountFilter(response);
    yield put(getNotificationsSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getNotificationsFailure(errorMessage));
  }
}
function* markAllRead() {
  try {
    const response = yield call(notificationsService.markAllRead);
    const result = unreadCountFilter(response);
    yield put(markAllNotificationsReadSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(markAllNotificationsReadFailure(errorMessage));
  }
}

function* hideAll() {
  try {
    const response = yield call(notificationsService.hideAll);
    const result = unreadCountFilter(response);
    yield put(hideAllNotificationsSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(hideAllNotificationsFailure(errorMessage));
  }
}

export default function* notificationsSaga() {
  yield all([
    takeLatest(
      [GET_USER_SUCCESS, IO_FORCE_NOTIFICATIONS_UPDATE],
      getUserNotifications,
    ),
  ]);
  yield all([takeLatest(MARK_ALL_READ_SUBMIT, markAllRead)]);
  yield all([takeLatest(HIDE_ALL_NOTIFICATIONS_SUBMIT, hideAll)]);
}
