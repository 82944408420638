import React from 'react';

const OneDriveIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9568 7.63875C11.5515 7.559 11.1393 7.51875 10.725 7.51875C7.50288 7.51875 4.83144 9.92144 4.40837 13.0293C1.90987 13.4493 0 15.6277 0 18.2438C0 21.0624 2.21713 23.3727 4.99894 23.5231C4.87744 23.0246 4.8125 22.5041 4.8125 21.9688C4.8125 20.4673 5.3345 18.9986 6.28225 17.8331C6.90506 17.0672 7.69362 16.4529 8.57631 16.0346C9.136 12.2704 12.1913 9.31 16.0001 8.89425L17.8751 7.17919L16.0001 5.46413C14.4618 5.743 13.0501 6.49813 11.9568 7.63875Z"
      fill="#007CAA"
    />
    <path
      d="M10.3594 17.2812C10.3594 17.3181 10.3597 17.3549 10.3603 17.3919C8.26175 17.8567 6.6875 19.7324 6.6875 21.9687C6.6875 24.5534 8.79031 26.6562 11.375 26.6562H16L19.75 18.7201L16 10.7839C12.8164 11.2334 10.3594 13.9756 10.3594 17.2812Z"
      fill="#007CAA"
    />
    <path
      d="M28.1849 17.3626C27.7714 14.6658 25.4357 12.5938 22.625 12.5938C22.2831 12.5938 21.9427 12.6248 21.6074 12.6864C20.3765 11.4292 18.6999 10.7188 16.9219 10.7188C16.609 10.7188 16.3013 10.7413 16 10.7838V26.6562H27.3125C29.8972 26.6562 32 24.5534 32 21.9688C32 19.6821 30.3543 17.7726 28.1849 17.3626Z"
      fill="#015E82"
    />
    <path
      d="M22.2403 10.7287C22.3685 10.7221 22.4968 10.7188 22.625 10.7188C23.2888 10.7188 23.9444 10.8073 24.5762 10.9779C23.7587 7.74413 20.8249 5.34375 17.3406 5.34375C16.8864 5.34375 16.4381 5.38469 16 5.46412V8.89419C16.3029 8.86112 16.6104 8.84369 16.9219 8.84369C18.8869 8.84375 20.7431 9.50712 22.2403 10.7287Z"
      fill="#015E82"
    />
  </svg>
);

export default OneDriveIcon;
