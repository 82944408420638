

export const IO_CONNECT_SUBMIT = 'IO_CONNECT_SUBMIT';
export const IO_DOWNLOADS_UPDATE = 'IO_DOWNLOADS_UPDATE';
export const NZB_FILES_COMPRESS_COMPLETE = 'NZB_FILES_COMPRESS_COMPLETE';
export const MEDIA_FILES_TRANSCODE_COMPLETE = 'MEDIA_FILES_TRANSCODE_COMPLETE';
export const IO_TRANSCODE_JOBS_UPDATE = 'IO_TRANSCODE_JOBS_UPDATE';
export const IO_COMPRESS_JOBS_UPDATE = 'IO_COMPRESS_JOBS_UPDATE';
export const HDR_SEARCH_READY = 'HDR_SEARCH_READY';
export const SOCKETS_CONNECTED = 'SOCKETS_CONNECTED';
export const SOCKETS_DISCONNECTED = 'SOCKETS_DISCONNECTED';
export const IO_EXTERNAL_DRIVE_PURGE_COMPLETE = 'IO_EXTERNAL_DRIVE_PURGE_COMPLETE';
export const IO_FORCE_NOTIFICATIONS_UPDATE = 'IO_FORCE_NOTIFICATIONS_UPDATE';
export const IO_FORCE_NODE_UPDATE = 'IO_FORCE_NODE_UPDATE';
export const IO_FORCE_USER_UPDATE = 'IO_FORCE_USER_UPDATE';


export const ioForceUserUpdate = payload => ({
  type: IO_FORCE_USER_UPDATE,
  payload,
});

export const ioForceNodeUpdate = payload => ({
  type: IO_FORCE_NODE_UPDATE,
  payload,
});
export const ioForceNotificationsUpdate = payload => ({
  type: IO_FORCE_NOTIFICATIONS_UPDATE,
  payload,
});
export const ioExternalDrivePurgeComplete = payload => ({
  type: IO_EXTERNAL_DRIVE_PURGE_COMPLETE,
  payload,
});

export const ioConnectSuccess = () => ({
  type: SOCKETS_CONNECTED,
});
export const ioConnectSubmit = () => ({
  type: IO_CONNECT_SUBMIT,
});

export const ioTranscodeJobsUpdate = payload => ({
  type: IO_TRANSCODE_JOBS_UPDATE,
  payload,
});
export const ioCompressJobsUpdate = payload => ({
  type: IO_COMPRESS_JOBS_UPDATE,
  payload,
});

export const nzbFileCompressComplete = payload => ({
  type: NZB_FILES_COMPRESS_COMPLETE,
  payload,
});


export const mediaFileTranscodeUpdate = payload => ({
  type: MEDIA_FILES_TRANSCODE_COMPLETE,
  payload,
});

export const ioDownloadsUpdate = payload => ({
  type: IO_DOWNLOADS_UPDATE,
  payload,
});

export const ioHdrSearchIsReady = payload => ({
  type: HDR_SEARCH_READY,
  payload,
});
