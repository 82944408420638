import numeral from 'numeral';


function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
function fPrice(number, symbol) {
  const format = number ? numeral(number).divide(100).format('0,0.00 $').replace('$', symbol) : 'Free';
  return result(format, '.00');
}


export default fPrice;
