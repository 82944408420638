import {
  GET_NOTIFICATIONS_SUBMIT,
  GET_NOTIFICATIONS_SUCCESS, HIDE_ALL_NOTIFICATIONS_SUCCESS,
  MARK_ALL_READ_SUCCESS,
} from './notificationsActions';

const initState = {
  notificationsLoading: true,
  notifications: [],
  unreadCount: 0,
};

function notificationsReducer(state = initState, { type, payload }) {
  switch (type) {
    case GET_NOTIFICATIONS_SUBMIT: {
      return {
        ...state,
        notificationsLoading: true,
      };
    }
    case HIDE_ALL_NOTIFICATIONS_SUCCESS:
    case GET_NOTIFICATIONS_SUCCESS:
    case MARK_ALL_READ_SUCCESS: {
      const { notifications, unreadCount } = payload;
      return {
        ...state,
        notificationsLoading: false,
        notifications,
        unreadCount,
      };
    }

    default: {
      return state;
    }
  }
}

export default notificationsReducer;
