const {
  REACT_APP_API_URL,
  REACT_APP_IO_URL,
  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_ONEDRIVE_REDIRECT_URI,
  REACT_APP_DROPBOX_CLIENT_ID,
  REACT_APP_DROPBOX_REDIRECT_URI,
  REACT_APP_ONEDRIVE_AUTHORITY,
  REACT_APP_ONEDRIVE_CLIENT_ID,
  REACT_APP_FILES_URL,
} = process.env;

class AppConsts {
  // optional backend: location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '') + "/profiles/";
  // static BACKEND_URL = localStorage.backUrl;

  static API_URL = REACT_APP_API_URL;

  static FILES_URL = REACT_APP_FILES_URL;

  static IO_URL = REACT_APP_IO_URL;

  static GOOGLE_CLIENT_ID = REACT_APP_GOOGLE_CLIENT_ID;

  static DROPBOX_CLIENT_ID = REACT_APP_DROPBOX_CLIENT_ID;

  static DROPBOX_REDIRECT_URI = REACT_APP_DROPBOX_REDIRECT_URI;

  static ONEDRIVE_CLIENT_ID = REACT_APP_ONEDRIVE_CLIENT_ID;

  static ONEDRIVE_AUTHORITY = REACT_APP_ONEDRIVE_AUTHORITY;

  static ONEDRIVE_REDIRECT_URI = REACT_APP_ONEDRIVE_REDIRECT_URI;
}

export default AppConsts;
