import {
  USER_LOGIN_SUBMIT,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  CHANGE_LOGIN_INPUT,
  USER_LOGOUT_SUBMIT, USER_LINK_LOGIN_SUCCESS,
} from './loginActions';
import { GET_USER_SUCCESS } from '../user/userActions';

const initState = {
  loginLoading: true,
  loginError: false,
  email: '',
  password: '',
  isAuthenticated: false,
};

function loginReducer(state = initState, { type, payload }) {
  switch (type) {
    case CHANGE_LOGIN_INPUT: {
      return {
        ...state,
        [payload.name]: payload.value,
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
      };
    }
    case USER_LOGIN_SUBMIT: {
      return {
        ...state,
        loginLoading: true,
      };
    }
    case USER_LINK_LOGIN_SUCCESS:
    case USER_LOGIN_SUCCESS: {
      return {
        ...state,
        loginLoading: false,
        isAuthenticated: true,
      };
    }

    case USER_LOGIN_FAILURE: {
      return {
        ...state,
        loginLoading: false,
        loginError: payload,
      };
    }

    case USER_LOGOUT_SUBMIT: {
      return {
        ...state,
        isAuthenticated: false,
      };
    }

    default: {
      return state;
    }
  }
}

export default loginReducer;
