import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch } from 'react-redux';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Image from 'material-ui-image';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CheckIcon from '@material-ui/icons/Check';
import List from '@material-ui/core/List';

const useStyles = makeStyles(theme => ({
  textPrimary: {
    fontWeight: 500,
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  textSecondary: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: 12,
    fontWeight: 400,
  },
  avatarList: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default function AvatarList(props) {
  const classes = useStyles();
  const { avatars, userAvatarId, handleAvatarCLick } = props;


  const setAvatarSubmit = (id) => {
    handleAvatarCLick(id);
  };

  return (
    <List disablePadding className={classes.avatarList}>
      {avatars.map(e => (
        <ListItem
          key={e.id}
          button
          style={{
            height: 80,
            borderBottom: '1px solid rgb(247, 247, 247)',
            paddingLeft: 32,
            paddingRight: 50,
          }}
          onClick={userAvatarId === e.id ? null : () => setAvatarSubmit(e.id)}
        >
          <ListItemAvatar>
            <div
              style={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                overflow: 'hidden',
              }}
            >
              <Image src={e.url} />
            </div>
          </ListItemAvatar>
          <ListItemText
            classes={{
              primary: classes.textPrimary,
              secondary: classes.textSecondary,
            }}
            primary={e.name}
            secondary={e.description}
          />
          <ListItemSecondaryAction style={{ right: 28 }}>
            {userAvatarId === e.id ? (
              <CheckIcon
                style={{ color: '#2158eb' }}
                edge="end"
                aria-label="delete"
              />
            ) : null}
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}
