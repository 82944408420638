import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';
import GetAppIcon from '@material-ui/icons/GetApp';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { toast } from 'react-toastify';
import {
  getStorageFolderArchiveSubmit,
  onStorageSelectFolder,
} from '../../../../redux/userStorage/userStorageActions';

import DoneZipIcon from '../../../atoms/DoneZipFolderIcon';

import DeleteFolderModal from '../deleteModal';
import LottieAnimationPlayer from '../../../atoms/LottieAnimationPlayer';
import ChangeNodeNameModal from '../changeNodeNameModal';
import appConsts from '../../../../config/appConsts';
import InfectedFolder from '../../../atoms/InfectedFolderIcon';
import { Chip } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import OnlyPaidModal from '../../onlyPaidModal';

const useStyles = makeStyles(theme => ({
  // FOLDER
  folderWrapper: {
    // position: 'relative',
    height: 88,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    // backgroundColor: '#FFFFFF',
    border: '1px solid #EDEDED',
    borderRadius: 2,
    cursor: 'pointer',
    // marginRight: 24,
    marginBottom: 8,
    // borderLeft: '0rem',
    transition: 'border-left 300ms ease-in-out, padding-left 300ms ease-in-out',
    '&:hover': {
      // opacity: 0.7,
      // backgroundColor: 'rgba(0, 0, 0, 0.04)'
      boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.05)',
    },
    '&:last-child': {
      marginBottom: 100,
    },
    [theme.breakpoints.down('sm')]: {
      height: 66,
    },
  },

  folderClickableWrapper: {
    padding: '16px 5px 16px 16px',
    display: 'flex',
    alignItems: 'center',
    width: '91%',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
  },

  folderIconWrapper: {
    marginRight: 10,
    width: 60,
    height: 60,
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40,
    },
  },
  folderIcon: {
    fontSize: 60,
    color: '#FCB643',
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
  },
  folderContentWrapper: {
    // width: 168,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgba(0,0,0,0.87)',
    // fontFamily: 'Roboto, san-serif',
    fontSize: 16,
    letterSpacing: 0.15,
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  folderMenuWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    // borderLeft: '1px solid #00000026',
    color: '#00000099',
    justifyContent: 'center',
    width: '9%',
    [theme.breakpoints.down('sm')]: {
      width: '15%',
    },
  },
  buttonRoot: {
    width: '100%',
    height: '100%',
  },
  folderSyncProcessIconWrapper: {
    position: 'absolute',
    width: 40,
    height: 40,
    top: 12,
    right: 9,
    [theme.breakpoints.down('sm')]: {
      width: 25,
      height: 25,
      position: 'absolute',
      top: 8,
      right: 8,
    },
  },
  folderSyncErrorIconWrapper: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    top: 12,
    right: 9,
    [theme.breakpoints.down('sm')]: {
      width: 25,
      height: 25,
      top: 8,
      right: 8,
    },
  },
  errorIcon: {
    color: '#EF4056',
    fontSize: 26,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  folderMenu: {
    // width: 280,
    // borderRadius: 2,
    // backgroundColor: '#FFFFFF',
  },
  folderIconEncryptedWrapper: {
    position: 'relative',
  },
  folderIconEncrypted: {
    position: 'absolute',
    color: '#2158EB',
    top: '30%',
    left: '30%',
    [theme.breakpoints.down('sm')]: {
      top: '30%',
      left: '30%',
      height: 16,
      width: 16,
    },
  },
}));

export default function Folder(props) {
  const classes = useStyles();
  const { data } = props;
  const {
    customName, _id, name, viruses, type, targetStorage, compressStatus, isEncrypted,
  } = data;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = localStorage.getItem('@accessToken');
  const [modalOpen, setOpen] = React.useState(false);
  const [renameModalOpen, setRenameOpen] = React.useState(false);
  const folderName = customName || name;
  const { zipDownloadAvailable } = useSelector(
    state => state.user,
  );
  const [paidModalOpen, setPaidModalOpen] = React.useState(false);
  const handlePaidModalOpen = () => {
    setPaidModalOpen(true);
  };
  const handlePaidModalClose = () => {
    setPaidModalOpen(false);
  };
  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleWrapperClick = () => {
    dispatch(onStorageSelectFolder(data));
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePrepareZipFolder = () => {
    dispatch(getStorageFolderArchiveSubmit(data._id));
    handleClose();
  };
  const handleRenameModalOpen = () => {
    setRenameOpen(true);
    setAnchorEl(null);
  };
  const handleRenameModalClose = () => {
    setRenameOpen(false);
  };
  const toastErrorText = 'Something went wrong with external storage. Please Delete folder and try again.';
  const toastSyncText = 'Folder is Syncing with storage. Please wait...';
  const handleClickWhileSync = () => {
    toast.warn(type === 'FOLDER_SYNC_ERROR' ? toastErrorText : toastSyncText);
  };
  const handleDownloadFolder = () => {
    // dispatch(getStorageFolderArchiveSubmit(data._id));
    // handleClose();

    const link = document.createElement('a');

    link.href = `${appConsts.FILES_URL}/files/${data._id}/downloadzip?token=${token}`;
    // link.target = '_blank';
    link.download = 'proposed_file_name';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const open = Boolean(anchorEl);
  const isSyncing = type === 'FOLDER_SYNC_PROCESS' || type === 'FOLDER_SYNC_ERROR';
  return (
    <div
      className={classes.folderWrapper}
      style={{
        backgroundColor:
          viruses && viruses.length ? 'rgba(255, 77, 99, 0.07)' : '#FFFFFF',
      }}
    >
      <div
        role="button"
        className={classes.folderClickableWrapper}
        onClick={handleWrapperClick}
        // onClick={isSyncing ? handleClickWhileSync : handleWrapperClick}
      >
        {isSyncing ? (
          <div
            className={classes.folderIconWrapper}
            style={{ position: 'relative' }}
          >
            {type === 'FOLDER_SYNC_PROCESS' && (
              <>
                <FolderIcon className={classes.folderIcon} />
                <div className={classes.folderSyncProcessIconWrapper}>
                  <LottieAnimationPlayer animation="inProcessingCloud" />
                </div>
              </>
            )}
            {type === 'FOLDER_SYNC_ERROR' && (
              <>
                <FolderIcon className={classes.folderIcon} />
                <div className={classes.folderSyncErrorIconWrapper}>
                  <ReportProblemIcon className={classes.errorIcon} />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className={classes.folderIconWrapper}>
            {viruses && viruses.length ? (
              <InfectedFolder className={classes.folderIcon} />
            ) : (
              compressStatus && (
                <>
                  {compressStatus === 'COMPRESSING' && (
                    <LottieAnimationPlayer animation="inProcessingZip" />
                  )}
                  {compressStatus === 'READY' && (
                    <DoneZipIcon className={classes.folderIcon} />
                  )}
                  {compressStatus === 'NONE' && (
                    <>
                      {isEncrypted
                        ? (
                          <Tooltip
                            title="Folder is encrypted"
                            aria-label="Virus"
                          >
                            <div className={classes.folderIconEncryptedWrapper}>
                              <VerifiedUserIcon className={classes.folderIconEncrypted} />
                              <FolderIcon className={classes.folderIcon} />
                            </div>
                          </Tooltip>
                        )
                        : <FolderIcon className={classes.folderIcon} />
                  }
                    </>
                  )}
                </>
              )
            )}
            {viruses && !viruses.length && !compressStatus && (
              <>

                <FolderIcon className={classes.folderIcon} />
              </>
            )}
          </div>
        )}
        <div className={classes.folderContentWrapper}>{folderName}</div>
      </div>
      {/* {isEncrypted && ( */}
      {/* <Tooltip */}
      {/*  title="Folder is encrypted" */}
      {/*  aria-label="Virus" */}
      {/* > */}
      {/*  <div */}
      {/*    style={{ */}
      {/*      marginRight: 10, */}
      {/*      justifyContent: 'center', */}
      {/*      alignItems: 'center', */}
      {/*      display: 'flex', */}
      {/*    }} */}
      {/*  > */}
      {/*    <EnhancedEncryptionIcon style={{ color: '#FF4D63' }} /> */}
      {/*  </div> */}
      {/* </Tooltip> */}
      {/* )} */}
      {compressStatus && (
        <div role="button" className={classes.folderMenuWrapper}>
          <Button classes={{ root: classes.buttonRoot }} onClick={handleClick}>
            <MoreHorizIcon style={{ color: '#666666' }} />
          </Button>
          <Menu
            // id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            open={open}
            onClose={handleClose}
            className={classes.folderMenu}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
          >
            {!isSyncing && (
              <div>
                <MenuItem onClick={handleRenameModalOpen}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Rename" />
                </MenuItem>
                {/* {compressStatus === 'NONE' && targetStorage === 'NZBCLOUD' && ( */}
                {/*  <MenuItem onClick={handlePrepareZipFolder}> */}
                {/*    <ListItemIcon> */}
                {/*      <ArchiveIcon fontSize="small" /> */}
                {/*    </ListItemIcon> */}
                {/*    <ListItemText primary="Prepare Zip" /> */}
                {/*  </MenuItem> */}
                {/* )} */}
                {targetStorage === 'NZBCLOUD' && (
                <MenuItem onClick={zipDownloadAvailable ? () => handleDownloadFolder() : console.log('not available in Free plan')}>
                  <ListItemIcon>
                    <GetAppIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={zipDownloadAvailable ? 'Download' : (
                    <>
                      Download
                      <Chip variant="outlined" size="small" icon={<StarIcon style={{ color: '#f5cc00' }} />} label="PRO" style={{ color: '#f5cc00', marginLeft: 15 }} onClick={() => handlePaidModalOpen()}/>
                    </>
                  )}
                  />
                </MenuItem>
                )}
                <Divider />
              </div>
            )}
            <MenuItem
              disabled={
                compressStatus === 'COMPRESSING'
                || type === 'FOLDER_SYNC_PROCESS'
              }
              onClick={handleModalOpen}
            >
              <ListItemIcon>
                <DeleteForeverIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </MenuItem>
          </Menu>
          <OnlyPaidModal
            open={paidModalOpen}
            handleOpen={handlePaidModalOpen}
            handleClose={handlePaidModalClose}
          />
        </div>
      )}
      <ChangeNodeNameModal
        open={renameModalOpen}
        handleClose={handleRenameModalClose}
        folderName={folderName}
        id={_id}
      />
      <DeleteFolderModal
        open={modalOpen}
        handleOpen={handleModalOpen}
        handleClose={handleModalClose}
        folderName={data.name}
        id={_id}
      />
    </div>
  );
}
