import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { forgotPasswordSubmit } from '../../../redux/user/userActions';
import Copyright from '../../Copyright';
import useStyles from '../styles';

export default function ForgotPassword(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { history } = props;
  const handleFormSubmit = (valuesFromForm) => {
    const { email } = valuesFromForm;

    dispatch(forgotPasswordSubmit({ email }));
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Use only a valid email address. “Valid” means “correct”.')
      .required('Please enter email'),
  });

  return (
    <div className={classes.paper}>
      <Typography className={classes.loginTitle}>Reset Password</Typography>
      <Typography className={classes.loginCaption}>
        Forgot your password? Enter your email and we will send you access.
      </Typography>

      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={values => handleFormSubmit(values)}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
        }) => (
          <>
            <form>
              <div className={classes.formInput}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  error={!!(touched.email && errors.email)}
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={values.email}
                  onChange={handleChange('email')}
                />
                <div style={{ color: 'red' }}>
                  {touched.email && errors.email}
                </div>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
                style={{ boxShadow: 'none' }}
              >
                Reset
              </Button>
            </form>
          </>
        )}
      </Formik>
      <Grid container>
        <Grid item xs />
        <Grid item>
          <Link
            component="button"
            onClick={() => history.push('/login')}
            variant="body2"
            className={classes.linksOpt}
          >
            Go Back to Sign In?
          </Link>
        </Grid>
      </Grid>
      <Box mt={5}>
        <Copyright />
      </Box>
    </div>
  );
}
