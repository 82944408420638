import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import TextField from '@material-ui/core/TextField';

import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import { setWelcomeModalStep } from '../../../../redux/viewsEffects/viewsEffectsActions';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 2,
  },
  formInput: {
    padding: '0px 24px',
    // width: 380,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formInputHalf: {
    padding: '0px 24px',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  halfWidthBlock: {
    display: 'flex',
  },
  formInputPicker: {
    padding: '5px 24px',
  },
  errorModalSubTitle: {
    // width: 380,
    padding: '0px 10px 10px 24px',
    fontSize: 15,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  reportFilesLabel: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
  },
  fileNameList: {
    fontSize: 16,
    marginLeft: 8,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 230,
  },
  submit: {
    marginLeft: 20,
    backgroundColor: '#2158eb',
  },
  doneModalWrapper: {
    // width: 380,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  doneContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '80px 50px',
    flexDirection: 'column',
  },
  doneIconWrapper: {
    width: 120,
    height: 120,
  },
  doneContentText: {
    fontSize: 24,
    color: 'rgba(0,0,0,0.87)',
    textAlign: 'center',
    marginTop: 30,
    fontWeight: 500,
  },
  doneModalFooterText: {
    fontSize: 20,
    color: 'rgba(0,0,0,0.87)',
    marginBottom: 6,
    fontWeight: 500,
  },
  doneModalFooterSubText: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 500,
  },
}));
const validationSchema = Yup.object().shape({
  verificationCode: Yup.string().required('Required'),
});
export default function Step0(props) {
  const dispatch = useDispatch();

  const classes = useStyles();

  // const handleDeleteIndexer = (name) => {
  //   dispatch(hdrSearchDeleteIndexerSubmit(name));
  // };

  const handleFormSubmit = (valuesFromForm) => {

    dispatch(setWelcomeModalStep(1))
  };
  const { email } = useSelector(state => state.user);

  return (
    <div className={classes.stepWrapper}>
      <div className={classes.errorModalSubTitle}>
        We have sent a verification code to <span style={{fontWeight: 'bold'}}>{email}</span>
      </div>

      <div>
        <Formik
          initialValues={{
            verificationCode: '',
          }}
          validationSchema={validationSchema}
          onSubmit={values => handleFormSubmit(values)}
        >
          {({
            handleChange,
            // testFunc,
            handleBlur,
            handleSubmit,
            values,
            touched,
            errors,
            setFieldValue,
            setValues,
          }) => (
            <>
              <form>
                <div className={classes.errorModalSubTitle}>Please paste code below</div>

                <div className={classes.formInput}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    error={
                      !!(touched.verificationCode && errors.verificationCode)
                    }
                    fullWidth
                    autoComplete='off'
                    inputProps={{style: {textTransform: 'uppercase'}}}
                    id="verificationCode"
                    label="Code"
                    name="verificationCode"
                    style={{ margin: 0 }}
                    value={values.verificationCode}
                    onChange={handleChange('verificationCode')}
                  />
                  <div style={{ color: 'red', height: 20 }}>
                    {touched.verificationCode && errors.verificationCode}
                  </div>
                </div>
                <div />

                <div style={{ height: 4 }}>
                  {/* {modalIsLoading && */}
                  <LinearProgress />
                  {/* } */}
                </div>

                <div
                  style={{
                    padding: '12px 32px',
                    backgroundColor: '#F7F7F7',
                    borderTop: '1px solid #E0E0E0',
                    justifyContent: 'flex-end',
                    display: 'flex',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    // disabled={modalIsLoading}
                    className={classes.submit}
                    disableElevation
                    onClick={handleSubmit}
                  >
                    VERIFY
                  </Button>
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
}
