const downloadsStatusFilter = (downloads) => {
  const filesDownloadingCount = downloads.filter(
    file => file.status === 'DOWNLOADING',
  ).length;
  const filesQueuedCount = downloads.filter(file => file.status === 'QUEUED' || file.status === 'UNPACKING' || file.status === 'EXTERNAL_STORAGE_SYNC_PROCESS')
    .length;
  return {
    downloads,
    filesDownloadingCount,
    filesQueuedCount,
  };
};
export default downloadsStatusFilter;
