import React from 'react';

const FileSubtitleBrokenIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    version="1.1"

  >
    <g
      id="icon/action/Sub"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="📍Item-Icon">
        <rect id="Rectangle" x="0" y="0" width="24" height="24" />
        <g
          id="iso"
          transform="translate(3.000000, 0.000000)"
          fillRule="nonzero"
        >
          <path
            d="M1.5,0 C0.675,0 0,0.675 0,1.5 L0,22.5 C0,23.325 0.675,24 1.5,24 L16.5,24 C17.325,24 18,23.325 18,22.5 L18,6 L12,0 L1.5,0 Z"
            id="Path"
            fill="#E2E5E7"
          />
          <path
            d="M13.5,6 L18,6 L12,0 L12,4.5 C12,5.325 12.675,6 13.5,6 Z"
            id="Path"
            fill="#B0B7BD"
          />
          <polygon id="Path" fill="#CAD1D8" points="18 11 13 6 18 6" />
        </g>
        <rect
          id="Rectangle"
          fill="#B0B7BD"
          fillRule="nonzero"
          x="7.85"
          y="10"
          width="8.32"
          height="7.33333333"
          rx="1"
        />
        <path
          d="M10.842929,13.2209295 L10.842929,12.9908569 L9.92111112,12.9908569 L9.92111112,14.3712491 L10.842929,14.3712491 L10.842929,14.1411765 L11.5342871,14.1411765 L11.5342871,14.6013001 C11.5342871,14.8543648 11.3291914,15.0614236 11.0733889,15.0614236 L9.69067285,15.0614236 C9.43484877,15.0614236 9.22975309,14.8543863 9.22975309,14.6013216 L9.22975309,12.7608059 C9.22975309,12.5077196 9.43484877,12.3006608 9.69065124,12.3006608 L11.0733673,12.3006608 C11.3291698,12.3006608 11.5342871,12.5077196 11.5342871,12.7607844 L11.5342871,13.2209295 L10.842929,13.2209295 Z"
          id="Path"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <path
          d="M14.7606173,13.2209295 L14.0692593,13.2209295 L14.0692593,12.9908569 L13.1474414,12.9908569 L13.1474414,14.3712491 L14.0692593,14.3712491 L14.0692593,14.1411765 L14.7606173,14.1411765 L14.7606173,14.6013001 C14.7606173,14.8543863 14.5555216,15.0614452 14.2997192,15.0614452 L12.9170031,15.0614452 C12.6612006,15.0614452 12.4561049,14.8543863 12.4561049,14.6013216 L12.4561049,12.7608059 C12.4561049,12.5077412 12.6612006,12.3006824 12.9170031,12.3006824 L14.2997192,12.3006824 C14.5555216,12.3006824 14.7606173,12.5077412 14.7606173,12.7608059 L14.7606173,13.2209295 Z"
          id="Path"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export default FileSubtitleBrokenIcon;
