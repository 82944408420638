import { toast } from 'react-toastify';

export const USER_REQUEST_LINK_LOGIN_SUBMIT = 'USER_REQUEST_LINK_LOGIN_SUBMIT';
export const USER_REQUEST_LINK_LOGIN_SUCCESS = 'USER_REQUEST_LINK_LOGIN_SUCCESS';
export const USER_REQUEST_LINK_LOGIN_FAILURE = 'USER_REQUEST_LINK_LOGIN_FAILURE';

export const USER_LINK_LOGIN_SUBMIT = 'USER_LINK_LOGIN_SUBMIT';
export const USER_LINK_LOGIN_SUCCESS = 'USER_LINK_LOGIN_SUCCESS';
export const USER_LINK_LOGIN_FAILURE = 'USER_LINK_LOGIN_FAILURE';
export const USER_LOGIN_SUBMIT = 'USER_LOGIN_SUBMIT';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const CHANGE_LOGIN_INPUT = 'CHANGE_LOGIN_INPUT';
export const USER_LOGOUT_SUBMIT = 'USER_LOGOUT_SUBMIT';
export const USER_SEND_GOOGLE_AUTH_TOKEN = 'USER_SEND_GOOGLE_AUTH_TOKEN';
export const USER_SEND_DROPBOX_AUTH_TOKEN = 'USER_SEND_DROPBOX_AUTH_TOKEN';

export const requestLinkLoginSubmit = payload => ({
  type: USER_REQUEST_LINK_LOGIN_SUBMIT,
  payload,
});
export const requestLinkLoginSuccess = payload => ({
  type: USER_REQUEST_LINK_LOGIN_SUCCESS,
  payload,
});
export const requestLinkLoginFail = payload => ({
  type: USER_REQUEST_LINK_LOGIN_FAILURE,
  payload,
});

export const linkLoginSubmit = payload => ({
  type: USER_LINK_LOGIN_SUBMIT,
  payload,
});
export const linkLoginSuccess = payload => ({
  type: USER_LINK_LOGIN_SUCCESS,
  payload,
});
export const linkLoginFail = payload => ({
  type: USER_LINK_LOGIN_FAILURE,
  payload,
});
export const loginSubmit = payload => ({
  type: USER_LOGIN_SUBMIT,
  payload,
});

export const loginSuccess = payload => ({
  type: USER_LOGIN_SUCCESS,
  payload,
});

export const loginFailure = (payload) => {
  toast.warn(payload);

  return {
    type: USER_LOGIN_FAILURE,
    payload,
  };
};

export const changeInput = payload => ({
  type: CHANGE_LOGIN_INPUT,
  payload,
});
export const userLogOut = payload => ({
  type: USER_LOGOUT_SUBMIT,
  payload,
});
export const userSendGoogleAuthToken = payload => ({
  type: USER_SEND_GOOGLE_AUTH_TOKEN,
  payload,
});
export const userSendDropboxAuthToken = payload => ({
  type: USER_SEND_DROPBOX_AUTH_TOKEN,
  payload,
});
