import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';

import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import HelpIcon from '@material-ui/icons/Help';
import CachedIcon from '@material-ui/icons/Cached';
import Tooltip from '@material-ui/core/Tooltip';

import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import {
  onDownloadingDelete,
  onDownloadingPause,
  onDownloadingRestart,
  onDownloadingResume,
} from '../../redux/files/filesActions';
import { getStorageRootFolderDataSubmit } from '../../redux/userStorage/userStorageActions';
import DownloadsPasswordModal from './downloadsPasswordModal';
import FileIcon from '../atoms/FileIcon';
import storageOptions from '../../helpers/storageIconOptions';
import LottieAnimationPlayer from '../atoms/LottieAnimationPlayer';
import AnimatedEllipsis from '../atoms/AnimatedEllipsis';
import DeleteFolderModal from './FilesTab/deleteModal';
import DeleteDownloadModal from './deleteDownloadModal';

const useStyles = makeStyles(theme => ({
  listItemWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-between',
    minWidth: 310,
    zIndex: 2,
  },
  statusIconWrapper: {
    display: 'flex',
  },
  listItem: {
    height: 64,
    marginBottom: 6,
    padding: 0,
    backgroundColor: '#ffffff',
    borderRadius: 2,
    overflow: 'hidden',
  },
  statusLinkWrapper: {
    cursor: 'pointer',
    display: 'flex',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: '#EF4056',
    },
  },
}));
export default function DownloadsItem(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalOpen, setOpen] = React.useState(false);
  const [deleteDownloadModalOpen, setDeleteDownloadModalOpen] = React.useState(false);
  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  const handleDeleteDownloadModalOpen = () => {
    setDeleteDownloadModalOpen(true);
  };

  const handleDeleteDownloadModalClose = () => {
    setDeleteDownloadModalOpen(false);
  };
  const handlePauseClick = (id) => {
    dispatch(onDownloadingPause(id));
  };
  const handleResumeClick = (id) => {
    dispatch(onDownloadingResume(id));
  };
  const handleRestartClick = (id) => {
    dispatch(onDownloadingRestart(id));
  };
  const handleDeleteClick = (id) => {
    dispatch(onDownloadingDelete(id));
  };
  const handleFolderClick = (data) => {
    dispatch(getStorageRootFolderDataSubmit(data));
  };
  const { file } = props;

  const {
    total,
    downloaded,
    _id,
    fileName,
    status,
    progress,
    nodeId,
    downloadTime,
    targetStorage,
    nzbStatus,
    syncProgress,
    postProgress,
    isDeleted,
  } = file;
  let itemBorderColor;
  let itemBackgroundColor;
  let progressWidth;
  let downloadSpeed;
  let eta;
  let etaUnits;
  const totalSize = total >= 1000 ? `${(total * 0.001).toFixed(2)} GB` : `${total} MB`;
  const downloadedSize = downloaded >= 1000
    ? `${(downloaded * 0.001).toFixed(2)} GB`
    : `${downloaded} MB`;

  if (status === 'DOWNLOADING' && downloaded > 0) {
    downloadSpeed = (downloaded / downloadTime).toFixed(2);
    eta = ((total - downloaded) / downloadSpeed).toFixed(2);
    if (eta >= 60) {
      etaUnits = `${parseInt((eta / 60).toString(), 0)}m`;
    } else {
      etaUnits = `${parseInt(eta.toString(), 0)}s`;
    }
    if (eta >= 3600) {
      etaUnits = `${parseInt((eta / 3600).toString(), 0)}h`;
    }
  }

  switch (status) {
    case 'PAUSED':
      itemBorderColor = 'rgba(176,183,189,0.5)';
      itemBackgroundColor = '#F2F2F2';
      progressWidth = progress;
      break;

    case 'DOWNLOADING':
      itemBorderColor = '#2158eb';
      itemBackgroundColor = '#F2F2FD';
      progressWidth = progress;
      break;
    case 'EXTERNAL_STORAGE_SYNC_PROCESS':
      itemBorderColor = '#25a5d5';
      itemBackgroundColor = '#F2F2FD';
      progressWidth = 100;
      break;
    case 'SUCCESS':
      itemBorderColor = '#50BCB6';
      itemBackgroundColor = '#FFFFFF';
      progressWidth = 100;
      break;
    case 'REPAIR':
    case 'WARNING':
    case 'ERROR':
    case 'NOT_ENOUGH_STORAGE_SPACE':
    case 'CORRUPTED_LINK':
    case 'EXTERNAL_STORAGE_SYNC_ERROR':
      itemBorderColor = '#ef4056';
      itemBackgroundColor = '#FFFFFF';
      progressWidth = 100;
      break;
    default: {
      itemBackgroundColor = '#FFFFFF';
      itemBorderColor = '#FFFFFF';
      progressWidth = 100;
    }
  }

  const storageIcon = useMemo(() => storageOptions[targetStorage].icon, [targetStorage]);
  return (
    <>

      <ListItem className={classes.listItem}>
        <div
          style={{
            backgroundColor: itemBackgroundColor,
            zIndex: 1,
            height: '100%',
            width: `${progressWidth}%`,
            position: 'absolute',
            borderBottom: `2px solid ${itemBorderColor}`,
            transition: 'width 2s',
          }}
        />

        <div
          style={{
            marginRight: 10,
            marginLeft: 10,
            height: 34,
            width: 34,
            position: 'relative',
            zIndex: 2,
          }}
        >
          <FileIcon />

          <div style={{
            width: 16, height: 16, position: 'absolute', top: 10, left: 9,
          }}
          >
            {storageIcon}
          </div>
        </div>
        <div className={classes.listItemWrapper}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '75%',
            }}
          >
            {fileName ? (
              <Typography
                variant="h6"
                style={{
                  fontSize: 14,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {fileName}
              </Typography>
            ) : (
              <div style={{ display: 'flex' }}>
                {file.status === 'CORRUPTED_LINK' ? 'Corrupted Link' : (
                  <>
                    Processing nzb file
                    <AnimatedEllipsis numberOfDots={3} animDelay={0.1} />
                  </>
                )}

              </div>
            )}
            <div style={{ display: 'flex' }}>
              <Typography
                variant="caption"
                display="block"
                style={{
                  fontSize: 12,
                  color: 'rgba(18,18,18,0.38)',
                  letterSpacing: 0,
                  whiteSpace: 'nowrap',
                }}
              >
                {fileName
                  ? (
                    <div style={{ marginRight: 16 }}>
                      {' '}
                      {status === 'SUCCESS'
              || status === 'WARNING'
              || status === 'REPAIR'
                || status === 'EXTERNAL_STORAGE_SYNC_PROCESS'
                        ? totalSize
                        : `${downloadedSize} of ${totalSize}`}
                      {status === 'DOWNLOADING' && downloaded > 0
                        ? ` • ${downloadSpeed * 8} Mbps • ${etaUnits} left`
                        : null}
                      {status === 'PAUSED' && ' • Paused'}
                    </div>
                  ) : null}
              </Typography>
              {isDeleted
                ? (
                  <div
                    style={{
                      fontSize: 12,
                      color: '#50BCB6',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className={classes.statusIconWrapper}>
                        <ErrorIcon style={{ color: '#EF4056', fontSize: 15 }} />
                      </div>
                      <div
                        style={{
                          marginLeft: 5,
                          letterSpacing: 0,
                          marginRight: 5,
                          color: '#EF4056',
                        }}
                      >
                        Deleted
                      </div>
                      <Tooltip
                        title="The was a problem with file and it was deleted due to inactivity"
                        aria-label="add"
                      >
                        <div className={classes.statusIconWrapper}>
                          <HelpIcon
                            style={{ color: 'rgba(18,18,18,0.38)', fontSize: 15 }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: 12,
                      color: '#50BCB6',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {status === 'SUCCESS' && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.statusIconWrapper}>
                          <CheckCircleIcon
                            style={{ color: '#50BCB6', fontSize: 15 }}
                          />
                        </div>
                        <div style={{ marginLeft: 5, letterSpacing: 0 }}>
                          Complete
                        </div>
                      </div>
                    )}
                    {status === 'EXTERNAL_STORAGE_SYNC_PROCESS' && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ height: 19, width: 19 }}>
                          <LottieAnimationPlayer
                            animation="inProcessingCloud"
                          />
                        </div>
                        <div style={{ color: '#2158eb', marginLeft: 5, letterSpacing: 0 }}>
                          Syncing with storage
                        </div>
                        <div style={{ color: '#2158eb', marginLeft: 5, letterSpacing: 0 }}>
                          {syncProgress ? ` ${syncProgress} %` : null }
                        </div>
                      </div>
                    )}
                    {status === 'UNPACKING' && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ height: 19, width: 19 }}>
                          <LottieAnimationPlayer
                            animation="inProcessingCloud"
                          />
                        </div>
                        <div style={{ color: '#2158eb', marginLeft: 5, letterSpacing: 0 }}>
                          Unpacking
                        </div>
                        <div style={{ color: '#2158eb', marginLeft: 5, letterSpacing: 0 }}>
                          {postProgress ? ` ${postProgress} %` : null }
                        </div>
                      </div>
                    )}

                    {status === 'EXTERNAL_STORAGE_SYNC_ERROR' && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.statusIconWrapper}>
                          <ReportProblemIcon
                            style={{ color: '#EF4056', fontSize: 15 }}
                          />
                        </div>
                        <div style={{ color: '#EF4056', marginLeft: 5, letterSpacing: 0 }}>
                          External storage error
                        </div>
                      </div>
                    )}
                    {status === 'ERROR'
                    || status === 'REPAIR'
                    || status === 'CORRUPTED_LINK' ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.statusIconWrapper}>
                          <ErrorIcon style={{ color: '#EF4056', fontSize: 15 }} />
                        </div>
                        <div
                          style={{
                            marginLeft: 5,
                            letterSpacing: 0,
                            marginRight: 5,
                            color: '#EF4056',
                          }}
                        >
                          Warning
                        </div>
                        <Tooltip
                          title="File is broken. Maybe, another one? "
                          aria-label="add"
                        >
                          <div className={classes.statusIconWrapper}>
                            <HelpIcon
                              style={{ color: 'rgba(18,18,18,0.38)', fontSize: 15 }}
                            />
                          </div>
                        </Tooltip>
                      </div>
                      ) : null}
                    {status === 'PASSWORD' && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.statusLinkWrapper} onClick={handleModalOpen}>
                          <div className={classes.statusIconWrapper}>
                            <LockIcon style={{ color: '#EF4056', fontSize: 15 }} />
                          </div>
                          <div
                            style={{
                              marginLeft: 5,
                              color: '#EF4056',
                              letterSpacing: 0,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            Password required
                          </div>
                        </div>
                      </div>
                    )}
                    {status === 'NOT_ENOUGH_STORAGE_SPACE' && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={classes.statusIconWrapper}>
                          <ErrorIcon style={{ color: '#EF4056', fontSize: 15 }} />
                        </div>
                        <div
                          style={{
                            marginLeft: 5,
                            color: '#EF4056',
                            letterSpacing: 0,
                          }}
                        >
                          Not enough space
                        </div>
                      </div>
                    )}
                    {status === 'QUEUED' && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ height: 19, width: 19 }}>
                          <LottieAnimationPlayer
                            animation="inProcessingCloud"
                          />
                        </div>
                        <div style={{ color: '#2158eb', marginLeft: 5, letterSpacing: 0 }}>
                          Pending
                        </div>
                      </div>
                    )}
                    {status === 'REPAIR_PROCESS' && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ height: 19, width: 19 }}>
                          <LottieAnimationPlayer
                            animation="inProcessingCloud"
                          />
                        </div>
                        <div style={{ color: '#2158eb', marginLeft: 5, letterSpacing: 0 }}>
                          Attempt to repair
                        </div>
                      </div>
                    )}
                  </div>

                )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              right: 12,
            }}
          >
            <div style={{ marginRight: 5 }}>
              {/* {status === 'DOWNLOADING' && ( */}
              {/*  <Tooltip title="Pause downloading"> */}
              {/*    <IconButton size="small" onClick={() => handlePauseClick(_id)}> */}
              {/*      <PauseIcon /> */}
              {/*    </IconButton> */}
              {/*  </Tooltip> */}
              {/* )} */}
              {/* {status === 'PAUSED' && ( */}
              {/*  <Tooltip title="Resume downloading" aria-label="add"> */}
              {/*    <IconButton size="small" onClick={() => handleResumeClick(_id)}> */}
              {/*      <PlayArrowIcon /> */}
              {/*    </IconButton> */}
              {/*  </Tooltip> */}
              {/* )} */}
              {status === 'ERROR' || status === 'WARNING' || status === 'REPAIR' || status === 'NOT_ENOUGH_STORAGE_SPACE' || isDeleted ? (
                <Tooltip title="Restart downloading">
                  <IconButton
                    size="small"
                    onClick={() => handleRestartClick(_id)}
                  >
                    <CachedIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
              {status === 'SUCCESS' && nodeId && (
              <Tooltip title="Open folder" aria-label="add">
                <IconButton
                  size="small"
                  onClick={() => handleFolderClick({ _id: nodeId, name: fileName })
                  }
                >
                  <FolderOpenIcon />
                </IconButton>
              </Tooltip>
              )}
              {status === 'WARNING' && (
              <IconButton size="small">
                <ErrorOutlineIcon />
              </IconButton>
              )}
              {status === 'PASSWORD' || status === 'DOWNLOADING' && !isDeleted && (
              <Tooltip title="Input password">
                <IconButton size="small" onClick={handleModalOpen}>
                  <LockOpenIcon />
                </IconButton>
              </Tooltip>
              )}
            </div>
            <div>
              <Tooltip title="Remove">
                <span>
                  <IconButton
                    size="small"
                    disabled={status === 'DOWNLOADING' && progress > 80 || status === 'EXTERNAL_STORAGE_SYNC_PROCESS'}
                    onClick={() => (fileName ? handleDeleteDownloadModalOpen() : handleDeleteClick({ _id, nodeId }))}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
        <DownloadsPasswordModal
          open={modalOpen}
          handleOpen={handleModalOpen}
          handleClose={handleModalClose}
          name={fileName}
          id={_id}
        />
        <DeleteDownloadModal
          open={deleteDownloadModalOpen}
          handleOpen={handleDeleteDownloadModalOpen}
          handleClose={handleDeleteDownloadModalClose}
          name={fileName}
          id={{
            _id,
            nodeId,
          }}
        />
      </ListItem>
    </>
  );
}
