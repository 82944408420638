import {
  GET_USER_PAYMENTS_SUBMIT,
  GET_USER_PAYMENTS_FAILURE,
  GET_USER_PAYMENTS_SUCCESS,
  GET_PLANS_SUCCESS,
  GET_ADDONS_FAILURE,
  GET_ADDONS_SUCCESS,
  PAYMENT_ORDER_REVIEW_SUBMIT,
  PAYMENT_ORDER_REVIEW_DISCARD,
  PAYMENT_ORDER_REVIEW_SUCCESS, GET_PLANS_SUBMIT, GET_ADDONS_SUBMIT,
} from './paymentsActions';

const initState = {
  userPaymentsLoading: true,
  userPaymentsError: false,
  userPayments: [],
  plans: [],
  addons: [],
  plansLoading: true,
  addonsLoading: true,
  reviewOrder: false,
  isOrderValid: true,
  reviewOrderLoading: false,
};

function paymentsReducer(state = initState, { type, payload }) {
  switch (type) {
    case GET_USER_PAYMENTS_SUBMIT: {
      return {
        ...state,
        userPaymentsLoading: true,
      };
    }

    case GET_USER_PAYMENTS_SUCCESS: {
      return {
        ...state,
        userPaymentsLoading: false,
        userPayments: payload,
      };
    }

    case GET_USER_PAYMENTS_FAILURE: {
      return {
        ...state,
        userPaymentsLoading: false,
        userPaymentsError: payload,
      };
    }
    case GET_PLANS_SUCCESS: {
      return {
        ...state,
        plans: payload,
        plansLoading: false,
      };
    }
    case GET_ADDONS_SUCCESS: {
      return {
        ...state,
        addons: payload,
        addonsLoading: false,
      };
    }
    case GET_PLANS_SUBMIT: {
      return {
        ...state,
        plansLoading: true,
        reviewOrder: false,
        isOrderValid: true,
      };
    }
    case GET_ADDONS_SUBMIT: {
      return {
        ...state,
        addonsLoading: true,
        reviewOrder: false,
        isOrderValid: true,
      };
    }
    case PAYMENT_ORDER_REVIEW_SUBMIT: {
      return {
        ...state,
        reviewOrder: true,
        reviewOrderLoading: true,
      };
    }

    case PAYMENT_ORDER_REVIEW_SUCCESS: {
      return {
        ...state,
        reviewOrderLoading: false,
        ...payload,
      };
    }

    case PAYMENT_ORDER_REVIEW_DISCARD: {
      return {
        ...state,
        reviewOrder: false,
        isOrderValid: true,
      };
    }
    default: {
      return state;
    }
  }
}

export default paymentsReducer;
