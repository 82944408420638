import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';
import DownloadsInnerList from './downloadsInnerList';
import DownloadsItem from './downloadsItem';
import ActiveDownloadsItem from './activeDownloadsItem';
import { nzbFilesClearCompletedSubmit } from '../../redux/files/filesActions';

const StyledSwitch = withStyles({
  switchBase: {
    color: '#FF4D63',
    '&$checked': {
      color: '#50BCB6',
    },
    '&$checked + $track': {
      backgroundColor: '#50BCB6',
    },
  },
  checked: {},
  track: { backgroundColor: '#FF4D63' },
})(Switch);
const useStyles = makeStyles(theme => ({
  container: {
    padding: 20,
  },
  typography: {
    padding: theme.spacing(2),
  },
  subHeaderWrapper: {
    display: 'flex',
    padding: 10,
    // borderBottom: '1px solid #F7F7F7',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    borderBottom: '3px solid #f7f7f7;',
    zIndex: 99,
  },
  emptyWrapperTab: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // backgroundColor: '#f3f3f3',
    // width: 420,
    // height: 420,
    justifyContent: 'center',
    // borderRadius: '50%',
    // marginTop: 100,
    [theme.breakpoints.down('sm')]: {
      // marginTop: 20,
      // backgroundColor: 'inherit',
      // borderRadius: 0,
    },
  },
  emptyWrapperPopper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  downloadsList: {
    borderRadius: 2,
    // width: 480,
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    'downloadsList div li:last-child': {
      marginBottom: 80,
    },
  },
}));
export default function ActiveDownloadsSection(props) {
  const classes = useStyles();
  const { downloads } = useSelector(state => state.files);
  const dispatch = useDispatch();
  const arrayWithoutSuccess = downloads.filter(download => download.status !== 'SUCCESS').reverse();
  const sortedDownloads = [...arrayWithoutSuccess, ..._.differenceWith(downloads, arrayWithoutSuccess, _.isEqual).reverse()];
  const [showCompleted, setShowCompleted] = React.useState(false);
  const downloadsArray = showCompleted ? [...sortedDownloads] : [...arrayWithoutSuccess];
  const handleShowCompleted = () => {
    setShowCompleted(!showCompleted);
  };
  const handleClearCompleted = () => {
    dispatch(nzbFilesClearCompletedSubmit());
  };
  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography>Active downloads</Typography>

        {/*<div style={{ display: 'flex', alignItems: 'center', paddingRight: 6 }}>*/}
        {/*  <StyledSwitch*/}
        {/*    checked={showCompleted}*/}
        {/*    onChange={handleShowCompleted}*/}
        {/*  />*/}
        {/*  <Typography style={{ color: '#1212129c' }}>Show completed</Typography>*/}
        {/*</div>*/}

      </div>

      <>
        <List
          className={classes.downloadsList}
          style={{
            // width: containerWidth,
            marginBottom: 0,
          }}

        >
          <div
            style={{
              padding: 0,
              // backgroundColor: '#f7f7f7',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',

            }}
          >
            {downloadsArray.length ? (
              downloadsArray
              && downloadsArray.map(file => (
                <ActiveDownloadsItem file={file} key={file._id} />
              ))
            ) : (
              <div
                className={
                  classes.emptyWrapperTab
                }
              >
                {/* <img */}
                {/*  style={tab ? { transform: 'scaleX(-1)' } : {}} */}
                {/*  src={EmptyDownloadsImage} */}
                {/*  alt="Empty" */}
                {/* /> */}
                <div>
                  No active downloads
                </div>
              </div>
            )}
          </div>
          {/* {isMobile && ( */}
          {/* <div */}
          {/*  style={{ */}
          {/*    display: 'flex', */}
          {/*    borderTop: '1px solid #F7F7F7', */}
          {/*    padding: '5px 10px 5px 10px', */}
          {/*    justifyContent: 'space-around', */}
          {/*    alignItems: 'baseline', */}
          {/*  }} */}
          {/* > */}
          {/*  /!*<Button*!/ */}
          {/*  /!*  disabled={!downloads.length}*!/ */}
          {/*  /!*  color="secondary"*!/ */}
          {/*  /!*  onClick={handleClearCompleted}*!/ */}
          {/*  /!*>*!/ */}
          {/*  /!*  Clear completed*!/ */}
          {/*  /!*</Button>*!/ */}
          {/*  <div style={{ display: 'flex', alignItems: 'center', paddingRight: 6 }}> */}

          {/*  </div> */}
          {/* </div> */}
          {/* )} */}
        </List>
      </>

    </div>
  );
}
