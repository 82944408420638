import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // [theme.breakpoints.down('sm')]: {
    //   marginTop: theme.spacing(3),
    // },
  },
  boldHeader: {
    fontWeight: 700,
    fontFamily: "Poppins",
    marginTop: 10,
    marginBottom: 10,
    // color: 'rgba(0,0,0,0.38)',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 42,
    },
  },
  subtitle1: {
    fontWeight: 500,
    color: 'rgba(0,0,0,0.38)',

  },
  subtitle2: {
    // color: 'rgba(0,0,0,0.38)',
    fontWeight: 600,
  },
}));

export default function ServicePageHeaders(props) {
  const classes = useStyles();
  const { pageName, boldHeader, subtitle } = props;
  return (
    <div className={classes.paper}>
      <Typography variant="subtitle1" className={classes.subtitle1} gutterBottom>
        {pageName}
      </Typography>
      <Typography component="h1" variant="h3" className={classes.boldHeader}>
        {boldHeader}
      </Typography>
      <Typography component="h2" variant="h3" className={classes.subtitle2} align="center">
        {subtitle}
      </Typography>
    </div>
  );
}
