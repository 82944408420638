import { IO_COMPRESS_JOBS_UPDATE, IO_TRANSCODE_JOBS_UPDATE } from '../sockets/socketsActions';
import { CLEAR_FINISHED_JOBS_SUCCESS, GET_ACTIVE_JOBS_SUCCESS } from './jobsActions';

const initState = {
  transcodingJobs: [],
  compressingJobs: [],
};

function jobsReducer(state = initState, { type, payload }) {
  switch (type) {
    case IO_TRANSCODE_JOBS_UPDATE: {
      return {
        ...state,
        transcodingJobs: payload,
      };
    }
    case IO_COMPRESS_JOBS_UPDATE: {
      return {
        ...state,
        compressingJobs: payload,
      };
    }
    case GET_ACTIVE_JOBS_SUCCESS:
    case CLEAR_FINISHED_JOBS_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default jobsReducer;
