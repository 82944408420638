import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  Table, TableCell, TableContainer, TableRow,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';

const useStyles = makeStyles(theme => ({
  table: {
    maxWidth: 650,
  },
}));

export default function ReviewOrderTable(props) {
  const classes = useStyles();
  const {
    selectedEmail, productName, productPrice, selectedAddons,
  } = props;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <TableContainer component={Paper} className={classes.table}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                E-mail:
              </TableCell>

              <TableCell align="right">{selectedEmail}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Product:
              </TableCell>

              <TableCell align="right">{productName}</TableCell>
            </TableRow>

            {selectedAddons.map(addon => (
              <TableRow key={addon.id}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: 600 }}
                >
                  {addon.name}
                </TableCell>

                <TableCell align="right">{addon.price}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell component="th" scope="row" style={{ fontWeight: 600 }}>
                Order total:
              </TableCell>

              <TableCell align="right">
                {productPrice === 0 ? 'FREE' : `${productPrice} $`}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
