import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';

import { Formik } from 'formik';
import * as Yup from 'yup';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { userChangePasswordSubmit } from '../../../redux/user/userActions';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 2,
  },
  formInput: {
    padding: '0px 24px',
    width: 600,
    '& fieldset': {
      borderRadius: 2,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  submit: {
    marginLeft: 20,
    backgroundColor: '#2158eb',
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
    },
  },
  secondaryButton: {
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
      color: '#fff',
    },
  },
  inputLabelFocus: {
    backgroundColor: '#fafafa',
    paddingRight: 5,
  },
  inputLabel: {
    backgroundColor: '#fafafa',
    paddingRight: 5,
  },
}));
const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().label('Confirm password'),
  newPassword: Yup.string()
    .label('Password')
    .min(6)
    .required('Enter your new password. Make it strong and beautiful.'),
  confirmPassword: Yup.string()
    .required('Please enter your password one more time.')
    .label('Confirm password')
    .test(
      'passwords-match',
      'Passwords do not match',
      function (value) {
        return this.parent.newPassword === value;
      },
    ),
});
export default function ChangePasswordModal(props) {
  const classes = useStyles();
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const [showPasswordValue, setFormShowPassword] = React.useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setFormShowPassword({ showPassword: !showPasswordValue.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleFormSubmit = (valuesFromForm) => {
    const { oldPassword, newPassword } = valuesFromForm;
    dispatch(userChangePasswordSubmit({ oldPassword, newPassword }));
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change Password</DialogTitle>
        <div>
          <Formik
            initialValues={{
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            }}
            validationSchema={validationSchema}
            onSubmit={values => handleFormSubmit(values)}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
            }) => (
              <>
                <form>
                  <div className={classes.formInput}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        error={!!(touched.oldPassword && errors.oldPassword)}
                        classes={{
                          focused: classes.inputLabelFocus,
                          root: classes.inputLabel,
                        }}
                        htmlFor="outlined-adornment-oldPassword"
                      >
                        Current password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-oldPassword"
                        type={
                          showPasswordValue.showPassword ? 'text' : 'password'
                        }
                        value={values.oldPassword}
                        name="oldPassword"
                        fullWidth
                        error={!!(touched.oldPassword && errors.oldPassword)}
                        onChange={handleChange('oldPassword')}
                        autoComplete="new-password"
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPasswordValue.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )}
                        labelWidth={70}
                      />
                      <div
                        style={{
                          color: 'red',
                          marginTop: 4,
                          marginBottom: 6,
                          height: 20,
                        }}
                      >
                        {touched.oldPassword && errors.oldPassword}
                      </div>
                    </FormControl>
                  </div>
                  <div className={classes.formInput}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        error={!!(touched.newPassword && errors.newPassword)}
                        classes={{
                          focused: classes.inputLabelFocus,
                          root: classes.inputLabel,
                        }}
                        htmlFor="outlined-adornment-newPassword"
                      >
                        New Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-newPassword"
                        type={
                          showPasswordValue.showPassword ? 'text' : 'password'
                        }
                        value={values.newPassword}
                        name="newPassword"
                        fullWidth
                        error={!!(touched.newPassword && errors.newPassword)}
                        onChange={handleChange('newPassword')}
                        autoComplete="new-password"
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPasswordValue.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )}
                        labelWidth={70}
                      />
                      <div
                        style={{
                          color: 'red',
                          marginTop: 4,
                          marginBottom: 6,
                          height: 20,
                        }}
                      >
                        {touched.newPassword && errors.newPassword}
                      </div>
                    </FormControl>
                  </div>
                  <div className={classes.formInput}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        error={
                          !!(touched.confirmPassword && errors.confirmPassword)
                        }
                        classes={{
                          focused: classes.inputLabelFocus,
                          root: classes.inputLabel,
                        }}
                        htmlFor="outlined-adornment-confirmPassword"
                      >
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-confirmPassword"
                        type={
                          showPasswordValue.showPassword ? 'text' : 'password'
                        }
                        value={values.confirmPassword}
                        name="confirmPassword"
                        fullWidth
                        error={
                          !!(touched.confirmPassword && errors.confirmPassword)
                        }
                        onChange={handleChange('confirmPassword')}
                        autoComplete="new-password"
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPasswordValue.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )}
                        labelWidth={70}
                      />
                      <div
                        style={{
                          color: 'red',
                          marginTop: 4,
                          marginBottom: 6,
                          height: 20,
                        }}
                      >
                        {touched.confirmPassword && errors.confirmPassword}
                      </div>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      padding: '18px 32px',
                      backgroundColor: '#F7F7F7',
                      borderTop: '1px solid #E0E0E0',
                      justifyContent: 'flex-end',
                      display: 'flex',
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      color="primary"
                      component="span"
                      className={classes.secondaryButton}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleSubmit}
                      style={{ boxShadow: 'none' }}
                    >
                      SAVE
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}
