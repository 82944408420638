import React, { createRef } from 'react';

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';

import { Paper } from '@material-ui/core';
import { compose } from 'redux';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getUserSubmit } from '../../redux/user/userActions';
import { getDownloadsSubmit } from '../../redux/files/filesActions';
import DownloadsPopOver from './downloadsPopOver';
import DownloadsInnerList from './downloadsInnerList';
import DownloadStatus from './downloadStatusButton';
import FilesTab from './FilesTab';
import UtilsMenuPopOver from './utilsMenuPopOver';
import SettingsTab from '../SettingsTab';

import {
  setDashboardTab,
  setDownloadsPopoverOpen,
} from '../../redux/viewsEffects/viewsEffectsActions';
import {
  getUserStorageSubmit,
} from '../../redux/userStorage/userStorageActions';
import BettaBanner from './BettaBanner/BettaBanner';
import LogoIcon from '../Images/Logo.png';
import IdleLogo from '../IdleTrakerLogo';
import HdrSearchTab from './HdrSearchTab';
import IntroTour from './IntroTour';
import WelcomeModal from './WelcomeModal';
import NotificationBar from './notificationBar';
import LimitsBanner from './LimitsBanner/LimitsBanner';
import DownloadsTab from './DownloadsTab';
import WarningBanner from './WarningBanner';
import NZBCredentialsBanner from './NZBCredentialsBanner';

// TODO REFACTORING REQUIRED
const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    backgroundColor: '#FFF',
    minHeight: 80,
    alignItems: 'center',
    boxShadow: '0 2px 5px -2px rgb(0 0 0 / 10%)',

  },
  downloadsDesktopButtonWrapper: {
    minWidth: 170,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  downloadsMobileButtonWrapper: {
    padding: 16,
    width: '100%',
    cursor: 'pointer',
    color: 'rgba(0,0,0,0.87)',
    position: 'fixed',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 20,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  downloadsMobileIconWrapper: {
    padding: 5,
    cursor: 'pointer',
  },
  toolbar: {
    paddingRight: 12,
    paddingLeft: 12,
    justifyContent: 'space-between',
    backgroundColor: 'white',
    height: 80,
    maxWidth: 1200,
    width: '100%',
    '&::before': {
      content: '" "',
      position: 'absolute',
      top: 0,
      right: -10,
      width: '30%',
      height: 80,
      background: 'white',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 1140,
    },
    [theme.breakpoints.down('sm')]: {
      // height: 68,
      paddingRight: 8,
      paddingLeft: 8,
    },
  },
  drawerPaper: {
    width: '100%',
  },
  logo: {
    color: '#2158eb',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 70,
    backgroundImage: `url(${LogoIcon})`,
    height: 48,
    width: 48,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },

  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(0,0,0,0.38)',
  },
  inputRoot: {
    color: '#F7F7F7',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1.5, 1, 1.5, 7),
    transition: theme.transitions.create('width'),
    color: 'rgba(0,0,0,0.38)',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',

    },


  },
  container: {
    // paddingTop: 40,
    maxWidth: 1200,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      maxWidth: 'unset',
      marginBottom: 200,

    },
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  tabRoot: {
    minWidth: 80,
    // padding: '23px 12px',
    fontSize: 12,

    fontWeight: 600,
    color: '#0f0c0c',
    padding: '0 15px',
    height: 80,

    textDecoration: 'none',
    '&:hover': {
      color: '#2158eb',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 100,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 60,
    },
  },
  utilsMenuIconWrapper: {
    paddingLeft: 10,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
    },
  },
  notificationsIconWrapper: {
    marginTop: 9,
    paddingLeft: 24,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 4,
    },
  },
  logoWrapper: {
    display: 'flex',
    width: 200,
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
    },
  },
  toolBarTabsContainer: {
    marginLeft: 75,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  tabContainer: {
    padding: '0px 12px 12px 12px',
    height: '100%',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  operationWrapper: { display: 'flex' },
  selected: {
    color: '#2158EB !important',
  },
  indicator: {
    backgroundColor: '#2158EB',
  },
});

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    switch (props.location.pathname) {
      // case '/dashboard/files': {
      //   props.setTab(0);
      //   break;
      // }
      case '/dashboard/downloads': {
        props.setTab(0);
        break;
      }
      case '/dashboard/settings/profile': {
        props.setTab(2);
        break;
      }
      case '/dashboard/settings/payments': {
        props.setTab(2);
        break;
      }
      case '/dashboard/settings/external-storages': {
        props.setTab(2);
        break;
      }
      case '/dashboard/settings/usenet-providers': {
        props.setTab(2);
        break;
      }
      case '/dashboard/settings/search-settings': {
        props.setTab(2);
        break;
      }
      case '/dashboard/files': {
        props.setTab(1);
        break;
      }
      default: {
        // history.push('/dashboard/downloads');
        props.setTab(0);
      }
    }
    this.state = {
      popoverOpen: false,
      mobileOpen: false,
      notificationPopOverOpen: false,
    };
    this.setRef = this.setRef.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.handleGetFiles = this.handleGetFiles.bind(this);
  }


  componentDidMount() {
    const { getUserSubmit, getDownloadsSubmit } = this.props;
    getUserSubmit();
    getDownloadsSubmit();
    window.addEventListener('beforeunload', () => { this.componentCleanUp(); });
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', () => { this.componentCleanUp(); });
  }

  setRef(ref) {
    this.downloadStatusButtonRef = ref;
  }

  handleChange(event, value) {
    const { setTab } = this.props;
    switch (value) {
      case 0: {
        window.history.replaceState({}, '', '/dashboard/downloads');
        setTab(0);
        return;
      }
      case 2: {
        window.history.replaceState(
          { path: '/dashboard/settings/profile' },
          '',
          '/dashboard/settings/profile',
        );
        setTab(2);
        return;
      }
      case 4: {
        window.history.replaceState(
          { path: '/dashboard/settings/usenet-providers' },
          '',
          '/dashboard/settings/usenet-providers',
        );
        setTab(2);
        return;
      }
      case 1: {
        window.history.replaceState({}, '', '/dashboard/files');
        setTab(1);
        return;
      }
      case 3: {
        window.history.replaceState({}, '', '/dashboard/search');
        setTab(3);
        return;
      }
      default: {
        window.history.replaceState({}, '', '/dashboard/downloads');
        setTab(0);
      }
    }
  }

  handleClick(e) {
    const { setDownloadsPopoverOpen, viewsEffects } = this.props;
    const { downloadsPopoverOpen } = viewsEffects;
    setDownloadsPopoverOpen(!downloadsPopoverOpen);
    // this.setState({ el: e.currentTarget });
    // this.popOverRef.current = e.currentTarget;
  }

  handleClose() {
    const { setDownloadsPopoverOpen } = this.props;
    setDownloadsPopoverOpen(false);
  }


  handleNotificationOpen() {
    this.setState({ notificationPopOverOpen: true });
  }

  componentCleanUp() {
    this.handleClose();
  }

  handleDrawerToggle() {
    const { mobileOpen } = this.state;
    this.setState({ mobileOpen: !mobileOpen });
  }

  handleGetFiles() {
    const { getStorageSubmit } = this.props;
    getStorageSubmit();
  }

  render() {
    const {
      classes, user, files, width, viewsEffects,
    } = this.props;

    const { downloads } = files;
    const { isDownloadLimitExceeded, isUsenetConfigExist, isSubscriptionActive } = user;
    const { dashboardTab, downloadsPopoverOpen, userDataLoading } = viewsEffects;
    const { popoverOpen, mobileOpen } = this.state;
    const isMobile = isWidthDown('sm', width);
    return (
      <div className={classes.root}>
        {userDataLoading ? (
          <div style={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >
            <CircularProgress size={80} />
          </div>
        )
          : (
            <>
              <IntroTour />
              {/* <WelcomeModal /> */}
              <CssBaseline />
              <AppBar
                elevation={0}
                color="transparent"
                position="relative"
                className={classes.appBar}
              >
                <Toolbar className={classes.toolbar}>
                  <IdleLogo />
                  <div className={classes.operationWrapper}>
                    <Tabs
                      value={dashboardTab}
                      onChange={this.handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      style={{ justifySelf: 'flex-end' }}
                      classes={{
                        root: classes.toolBarTabsContainer,
                        indicator: classes.indicator,
                      }}
                    >
                      {/* <Tab */}
                      {/*  classes={{ root: classes.tabRoot }} */}
                      {/*  icon={isMobile ? <DescriptionIcon /> : false} */}
                      {/*  label={isMobile ? false : 'files'} */}
                      {/*  {...a11yProps(0)} */}
                      {/* /> */}
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.selected,
                        }}
                        icon={isMobile ? <CloudDownloadIcon /> : false}
                        label={isMobile ? false : 'Dashboard'}
                        {...a11yProps(0)}
                      />
                      <Tab
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.selected,
                        }}
                        icon={isMobile ? <CloudDownloadIcon /> : false}
                        label={isMobile ? false : 'Downloads'}
                        {...a11yProps(1)}
                      />
                      <Tab
                        style={{ display: 'none' }}
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.selected,
                        }}
                        icon={isMobile ? <SearchIcon /> : false}
                        label={isMobile ? false : 'NZB Search'}
                        {...a11yProps(3)}
                      />
                      <Tab style={{ display: 'none' }} {...a11yProps(2)} />
                    </Tabs>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {/* // OLD POPOVER */}
                      {/* <div className={classes.downloadsDesktopButtonWrapper}> */}
                      {/*  <DownloadStatus */}
                      {/*    files={files} */}
                      {/*    onClickHandler={this.handleClick} */}
                      {/*    popoverOpen={downloadsPopoverOpen && !isMobile} */}
                      {/*    setRef={this.setRef} */}
                      {/*  /> */}
                      {/*  <DownloadsPopOver */}
                      {/*    handleClose={this.handleClose} */}
                      {/*    open={downloadsPopoverOpen && !isMobile} */}
                      {/*    downloads={downloads} */}
                      {/*    el={this.downloadStatusButtonRef} */}
                      {/*  /> */}
                      {/* </div> */}
                      {/* <NotificationBar /> */}
                      <div className={classes.utilsMenuIconWrapper}>
                        <div className="step6">
                          <UtilsMenuPopOver
                            handleSettingsTab={this.handleChange}
                            user={user}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Toolbar>
                {isDownloadLimitExceeded && <LimitsBanner />}

              </AppBar>
              <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                  <Drawer
                    variant="temporary"
                    anchor="bottom"
                    open={mobileOpen}
                    onClose={this.handleDrawerToggle}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true,
                    }}
                  >
                    <div>
                      <DownloadsInnerList
                        isMobile
                        closeDrawerHandler={this.handleDrawerToggle}
                      />
                    </div>
                  </Drawer>
                </Hidden>
              </nav>
              <main className={classes.content}>
                <div className={classes.appBarSpacer} />

                <div className={classes.container}>

                  <div className={classes.tabContainer}>
                    {!isSubscriptionActive && <WarningBanner />}
                    {!isUsenetConfigExist
                && <NZBCredentialsBanner handleSettingsTab={this.handleChange} />}
                    {dashboardTab === 0 && <FilesTab />}
                    {/* {dashboardTab === 1 && <HdrSearchTab />} */}
                    {dashboardTab === 1 && <DownloadsTab />}
                    {dashboardTab === 2 && <SettingsTab />}
                  </div>

                  <Paper
                    className={classes.downloadsMobileButtonWrapper}
                    onClick={this.handleDrawerToggle}
                  >
                    <DownloadStatus
                      files={files}
                      mobile
                      onClickHandler={() => null}
                    />
                    <div className={classes.downloadsMobileIconWrapper}>
                      <ExpandLessIcon style={{ color: 'rgba(0,0,0,0.6)' }} />
                    </div>
                  </Paper>
                </div>
              </main>
            </>
          )}
      </div>
    );
  }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
  getUserSubmit: () => {
    dispatch(getUserSubmit());
  },
  setTab: (tab) => {
    dispatch(setDashboardTab(tab));
  },
  getDownloadsSubmit: () => {
    dispatch(getDownloadsSubmit());
  },
  setDownloadsPopoverOpen: (isOpen) => {
    dispatch(setDownloadsPopoverOpen(isOpen));
  },
  getStorageSubmit: () => {
    dispatch(getUserStorageSubmit());
  },
});

export default compose(
  withStyles(styles),
  withWidth(),
  connect(mapStateToProps, mapDispatchToProps),
)(Dashboard);
