export const GET_USER_PAYMENTS_SUBMIT = 'GET_USER_PAYMENTS_SUBMIT';
export const GET_USER_PAYMENTS_SUCCESS = 'GET_USER_PAYMENTS_SUCCESS';
export const GET_USER_PAYMENTS_FAILURE = 'GET_USER_PAYMENTS_FAILURE';

export const GET_PLANS_SUBMIT = 'GET_PLANS_SUBMIT';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
export const GET_PLANS_FAILURE = 'GET_PLANS_FAILURE';

export const GET_ADDONS_SUBMIT = 'GET_ADDONS_SUBMIT';
export const GET_ADDONS_SUCCESS = 'GET_ADDONS_SUCCESS';
export const GET_ADDONS_FAILURE = 'GET_ADDONS_FAILURE';

export const GET_PAYMENT_URL_SUBMIT = 'GET_PAYMENT_URL_SUBMIT';
export const GET_PAYMENT_URL_SUCCESS = 'GET_PAYMENT_URL_SUCCESS';
export const GET_PAYMENT_URL_FAILURE = 'GET_PAYMENT_URL_FAILURE';

export const PAYMENT_ORDER_REVIEW_SUBMIT = 'PAYMENT_ORDER_REVIEW_SUBMIT';
export const PAYMENT_ORDER_REVIEW_SUCCESS = 'PAYMENT_ORDER_REVIEW_SUCCESS';
export const PAYMENT_ORDER_REVIEW_FAILURE = 'PAYMENT_ORDER_REVIEW_FAILURE';
export const PAYMENT_ORDER_REVIEW_DISCARD = 'PAYMENT_ORDER_REVIEW_DISCARD';



export const paymentOrderReviewDiscard = payload => ({
  type: PAYMENT_ORDER_REVIEW_DISCARD,
  payload,
});

export const paymentOrderReviewSubmit = payload => ({
  type: PAYMENT_ORDER_REVIEW_SUBMIT,
  payload,
});
export const paymentOrderReviewSuccess = payload => ({
  type: PAYMENT_ORDER_REVIEW_SUCCESS,
  payload,
});
export const paymentOrderReviewFailure = payload => ({
  type: PAYMENT_ORDER_REVIEW_FAILURE,
  payload,
});

export const getPlansSubmit = payload => ({
  type: GET_PLANS_SUBMIT,
  payload,
});
export const getPlansSuccess = payload => ({
  type: GET_PLANS_SUCCESS,
  payload,
});
export const getPlansFailure = payload => ({
  type: GET_PLANS_FAILURE,
  payload,
});
export const getPaymentUrlSubmit = payload => ({
  type: GET_PAYMENT_URL_SUBMIT,
  payload,
});
export const getPaymentUrlSuccess = payload => ({
  type: GET_PAYMENT_URL_SUCCESS,
  payload,
});
export const getPaymentUrlFailure = payload => ({
  type: GET_PAYMENT_URL_FAILURE,
  payload,
});
export const getAddonsSubmit = payload => ({
  type: GET_ADDONS_SUBMIT,
  payload,
});
export const getAddonsSuccess = payload => ({
  type: GET_ADDONS_SUCCESS,
  payload,
});
export const getAddonsFailure = payload => ({
  type: GET_ADDONS_FAILURE,
  payload,
});

export const getUserPaymentsSubmit = payload => ({
  type: GET_USER_PAYMENTS_SUBMIT,
  payload,
});

export const getUserPaymentsSuccess = payload => ({
  type: GET_USER_PAYMENTS_SUCCESS,
  payload,
});

export const getUserPaymentsFailure = payload => ({
  type: GET_USER_PAYMENTS_FAILURE,
  payload,
});
