import {
  call, put, all, takeLatest, select,
} from 'redux-saga/effects';

import { toast } from 'react-toastify';
import {
  USENET_ADD_CONFIG_SUBMIT,
  USENET_DELETE_CONFIG_SUBMIT,
  USENET_EDIT_CONFIG_SUBMIT,
  USENET_GET_PROVIDER_CREDENTIALS_SUBMIT,
  USENET_VALIDATE_PROVIDER_CREDENTIALS_SUBMIT,
  usenetAddConfigFail,
  usenetAddConfigSuccess,
  usenetDeleteConfigFail,
  usenetDeleteConfigSubmit,
  usenetDeleteConfigSuccess,
  usenetEditConfigFail,
  usenetEditConfigSuccess,
  usenetGetProviderCredentialsFail,
  usenetGetProviderCredentialsSuccess,
  usenetValidateCredentialsFail,
  usenetValidateCredentialsSuccess,
} from './usenetProvidersActions';

import usenetProvidersService from '../../services/usenetProvidersService';
import { GET_USER_SUCCESS } from '../user/userActions';

function* getUsenetCredentials() {
  try {
    const result = yield call(usenetProvidersService.getCredentials);
    if (result.error && result.status === 'NZB_SEARCHER_STATUS_ERROR') {
      yield put(usenetGetProviderCredentialsFail('NZB_SEARCHER_STATUS_ERROR'));
    } else yield put(usenetGetProviderCredentialsSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(usenetGetProviderCredentialsFail(errorMessage));
  }
}

function* usenetAddConfig(action) {
  const { payload } = action;
  try {
    const result = yield call(usenetProvidersService.addConfig, payload);
    if (result.error && result.status) {
      yield put(usenetAddConfigFail(result.message));
    } else {
      const { validationStatus } = result;
      if (validationStatus === 'invalid') {
        toast.success('Config is not valid');
      } else if (validationStatus === 'valid') {
        toast.error('Config is valid');
      }
      yield put(usenetAddConfigSuccess(result));
    }
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(usenetAddConfigFail(errorMessage));
  }
}
function* usenetEditConfig(action) {
  const { payload } = action;
  try {
    const result = yield call(usenetProvidersService.editConfig, payload);
    if (result.error && result.status) {
      yield put(usenetEditConfigFail(result.message));
    } else {
      // const { validationStatus } = result;
      // if (validationStatus) {
      //   toast.success('Config is valid');
      // } else toast.error('Config is not valid');
      yield put(usenetEditConfigSuccess(result));
    }
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(usenetEditConfigFail(errorMessage));
  }
}

function* usenetDeleteConfig(action) {
  const { payload } = action;

  try {
    const result = yield call(usenetProvidersService.deleteConfig, payload);
    if (result.error && result.status) {
      yield put(usenetDeleteConfigSubmit(result.message));
    } else {
      yield put(usenetDeleteConfigSuccess(result));
    }
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(usenetDeleteConfigFail(errorMessage));
  }
}

function* usenetValidateCredentials({ payload }) {
  try {
    const result = yield call(
      usenetProvidersService.validateCredentials,
      payload,
    );
    if (result.error && result.status) {
      yield put(usenetValidateCredentialsFail(result.message));
    } else {
      const { validationStatus } = result;
      if (validationStatus) {
        toast.success('Config is valid');
      } else toast.error('Config is not valid');

      yield put(usenetValidateCredentialsSuccess(result));
    }
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(usenetValidateCredentialsFail(errorMessage));
  }
}

export default function* usenetSaga() {
  yield all([
    takeLatest(
      [USENET_GET_PROVIDER_CREDENTIALS_SUBMIT, GET_USER_SUCCESS],
      getUsenetCredentials,
    ),
  ]);
  yield all([
    takeLatest(
      [USENET_VALIDATE_PROVIDER_CREDENTIALS_SUBMIT],
      usenetValidateCredentials,
    ),
  ]);
  yield all([takeLatest([USENET_EDIT_CONFIG_SUBMIT], usenetEditConfig)]);
  yield all([takeLatest([USENET_DELETE_CONFIG_SUBMIT], usenetDeleteConfig)]);
  yield all([takeLatest(USENET_ADD_CONFIG_SUBMIT, usenetAddConfig)]);
}
