import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import history from '../config/history';

import { userLogOut } from '../redux/login/loginActions';

export default function (ComposedComponent) {
  class Authenticate extends React.Component {
    componentDidMount() {
      this.checkAndRedirect();
    }

    checkAndRedirect() {
      const accessToken = localStorage.getItem('@accessToken');
      const { logUserOut } = this.props;
      if (!accessToken) {
        logUserOut();
        // history.push('/login');
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }
  const mapDispatchToProps = dispatch => ({
    logUserOut: () => {
      dispatch(userLogOut());
    },
  });

  return connect(
    null,
    mapDispatchToProps,
  )(Authenticate);
}
