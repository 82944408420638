import React from 'react';

const FileSubtitleIcon = () => (
  <svg width="100%" height="100%" viewBox="0 0 24 24" version="1.1">
    <g
      id="icon/action/Sub-Broken"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="📍Item-Icon">
        <rect id="Rectangle" x="0" y="0" width="24" height="24" />
        <g
          id="iso"
          transform="translate(3.000000, 0.000000)"
          fillRule="nonzero"
        >
          <path
            d="M1.5,0 C0.675,0 0,0.675 0,1.5 L0,22.5 C0,23.325 0.675,24 1.5,24 L16.5,24 C17.325,24 18,23.325 18,22.5 L18,6 L12,0 L1.5,0 Z"
            id="Combined-Shape"
            fill="#EF4056"
          />
          <rect
            id="Rectangle"
            fill="#FFFFFF"
            x="3.16666667"
            y="10"
            width="11.6666667"
            height="7.33333333"
            rx="1"
          />
          <path
            d="M9.53292904,13.2209295 L9.53292904,12.9908569 L8.61111112,12.9908569 L8.61111112,14.3712491 L9.53292904,14.3712491 L9.53292904,14.1411765 L10.2242871,14.1411765 L10.2242871,14.6013001 C10.2242871,14.8543648 10.0191914,15.0614236 9.76338892,15.0614236 L8.38067285,15.0614236 C8.12484877,15.0614236 7.91975309,14.8543863 7.91975309,14.6013216 L7.91975309,12.7608059 C7.91975309,12.5077196 8.12484877,12.3006608 8.38065124,12.3006608 L9.76336731,12.3006608 C10.0191698,12.3006608 10.2242871,12.5077196 10.2242871,12.7607844 L10.2242871,13.2209295 L9.53292904,13.2209295 Z"
            id="Path"
            fill="#EF4056"
          />
          <path
            d="M13.4506173,13.2209295 L12.7592593,13.2209295 L12.7592593,12.9908569 L11.8374414,12.9908569 L11.8374414,14.3712491 L12.7592593,14.3712491 L12.7592593,14.1411765 L13.4506173,14.1411765 L13.4506173,14.6013001 C13.4506173,14.8543863 13.2455216,15.0614452 12.9897192,15.0614452 L11.6070031,15.0614452 C11.3512006,15.0614452 11.1461049,14.8543863 11.1461049,14.6013216 L11.1461049,12.7608059 C11.1461049,12.5077412 11.3512006,12.3006824 11.6070031,12.3006824 L12.9897192,12.3006824 C13.2455216,12.3006824 13.4506173,12.5077412 13.4506173,12.7608059 L13.4506173,13.2209295 Z"
            id="Path"
            fill="#EF4056"
          />
          <polygon
            id="Rectangle"
            fill="#EF4056"
            transform="translate(5.675088, 13.668911) rotate(-45.000000) translate(-5.675088, -13.668911) "
            points="4.3796086 13.3440204 6.97002254 13.346199 6.9705672 13.9938025 4.38015326 13.9916239"
          />
          <polygon
            id="Rectangle-Copy"
            fill="#EF4056"
            transform="translate(5.675088, 13.668911) rotate(45.000000) translate(-5.675088, -13.668911) "
            points="4.38015326 13.346199 6.9705672 13.3440204 6.97002254 13.9916239 4.3796086 13.9938025"
          />
          <path
            d="M13.5,6 L18,6 L12,0 L12,4.5 C12,5.325 12.675,6 13.5,6 Z"
            id="Path"
            fill="#B33040"
          />
          <polygon id="Path" fill="#D6394D" points="18 11 13 6 18 6" />
        </g>
      </g>
    </g>
  </svg>
);

export default FileSubtitleIcon;
