import React from 'react';
import lottie from 'lottie-web';
import Idle from './Idle.json';
import IdleStart from './IdleStart.json';
import IdleEnd from './IdleEnd.json';
import inProcessingZip from './ProcessingZip.json';
import inProcessingCloud from './Processing.json';
import EncDone from './EncDone.json';
import EncProgress from './EncProgress.json';
import PhoneAndCards from './PhoneAndCards.json';
import PricingIcons from './PricingIcons.json';


const animObj = {
  Idle,
  IdleStart,
  IdleEnd,
  inProcessingZip,
  inProcessingCloud,
  EncDone,
  EncProgress,
  PricingIcons,
  PhoneAndCards,

};

class LottiePlayer extends React.Component {
  componentDidMount() {
    const { animation, loop, autoplay } = this.props;
    lottie.loadAnimation({
      container: this.animBox,
      renderer: 'svg',
      loop: loop !== false,
      autoplay: autoplay !== false,
      animationData: animObj[animation],
    });
  }

  render() {
    return (
      <div
        style={{ width: '100%', height: '100%', margin: '0 auto' }}
        ref={ref => (this.animBox = ref)}
      />
    );
  }
}

export default LottiePlayer;
