import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';

import LinearProgress from '@material-ui/core/LinearProgress';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

const usenetProvidersPresets = [
  {
    name: 'Custom',
    serverHostSSL: '',
    serverHostPlain: '',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: '5 Euro usenet',
    serverHostSSL: 'reader.5eurousenet.com',
    serverHostPlain: 'reader.5eurousenet.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Newshosting',
    serverHostSSL: 'news.newshosting.com',
    serverHostPlain: 'news.newshosting.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Easynews',
    serverHostSSL: 'secure.news.easynews.com',
    serverHostPlain: 'secure.news.easynews.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Giganews EU',
    serverHostSSL: 'news-europe.giganews.com',
    serverHostPlain: 'news-europe.giganews.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Giganews US',
    serverHostSSL: 'news.giganews.com',
    serverHostPlain: 'news.giganews.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Hitnews',
    serverHostSSL: '',
    serverHostPlain: 'news.hitnews.com',
    portSSl: '',
    portPlain: 119,
  },
  {
    name: 'Hitnews ssl',
    serverHostSSL: 'ssl.hitnews.com',
    serverHostPlain: '',
    portSSl: 563,
    portPlain: '',
  },
  {
    name: 'Eweka',
    serverHostSSL: '',
    serverHostPlain: 'newsreader.eweka.nl',
    portSSl: '',
    portPlain: 119,
  },
  {
    name: 'Eweka ssl',
    serverHostSSL: 'sslreader.eweka.nl',
    serverHostPlain: '',
    portSSl: 563,
    portPlain: '',
  },
  {
    name: 'Snelnl',
    serverHostSSL: 'reader.snelnl.com',
    serverHostPlain: 'reader.snelnl.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Sunnyusenet',
    serverHostSSL: 'news.sunnyusenet.com',
    serverHostPlain: 'news.sunnyusenet.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Usenetserver',
    serverHostSSL: '',
    serverHostPlain: 'news.Usenetserver.com',
    portSSl: '',
    portPlain: 119,
  },
  {
    name: 'Usenetserver ssl',
    serverHostSSL: '',
    serverHostPlain: 'secure.Usenetserver.com',
    portSSl: 563,
    portPlain: '',
  },
  {
    name: 'Astraweb',
    serverHostSSL: '',
    serverHostPlain: 'eu.astraweb.com',
    portSSl: '',
    portPlain: 119,
  },
  {
    name: 'Astraweb ssl',
    serverHostSSL: '',
    serverHostPlain: 'ssl-eu.astraweb.com',
    portSSl: 563,
    portPlain: '',
  },
  {
    name: 'Tweaknews',
    serverHostSSL: 'news.tweaknews.eu',
    serverHostPlain: 'news.tweaknews.eu',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Usenetbucket',
    serverHostSSL: 'reader.usenetbucket.com',
    serverHostPlain: 'reader.usenetbucket.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Extremeusenet',
    serverHostSSL: 'reader.extremeusenet.nl',
    serverHostPlain: 'reader.extremeusenet.nl',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'XS news',
    serverHostSSL: 'reader.xsnews.nl',
    serverHostPlain: 'reader.xsnews.nl',
    portSSl: 443,
    portPlain: 119,
  },
  {
    name: 'UseNeXT',
    serverHostSSL: 'high.usenext.de',
    serverHostPlain: 'high.usenext.de',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Pureusenet',
    serverHostSSL: 'news.pureusenet.nl',
    serverHostPlain: 'news.pureusenet.nl',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Newsgroupdirect',
    serverHostSSL: 'nl.newsgroupdirect.com',
    serverHostPlain: 'nl.newsgroupdirect.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Newsdemon',
    serverHostSSL: 'eu.newsdemon.com',
    serverHostPlain: 'eu.newsdemon.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Usenet.farm',
    serverHostSSL: '',
    serverHostPlain: 'news.usenetfarm.eu',
    portSSl: '',
    portPlain: 119,
  },

];
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 2,
  },
  formInput: {
    padding: '0px 24px',
    width: 380,
    '& fieldset': {
      borderRadius: 2,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formInputHalf: {
    padding: '0px 24px',
    width: 190,
    '& fieldset': {
      borderRadius: 2,
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  halfWidthBlock: {
    display: 'flex',
  },
  formInputPicker: {
    padding: '5px 24px',
    '& fieldset': {
      borderRadius: 2,
    },
  },
  errorModalSubTitle: {
    width: 380,
    padding: '0px 10px 10px 24px',
    fontSize: 15,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  reportFilesLabel: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
  },
  fileNameList: {
    fontSize: 16,
    marginLeft: 8,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 230,
  },

  doneModalWrapper: {
    width: 380,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  doneContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '80px 50px',
    flexDirection: 'column',
  },
  doneIconWrapper: {
    width: 120,
    height: 120,
  },
  doneContentText: {
    fontSize: 24,
    color: 'rgba(0,0,0,0.87)',
    textAlign: 'center',
    marginTop: 30,
    fontWeight: 500,
  },
  doneModalFooterText: {
    fontSize: 20,
    color: 'rgba(0,0,0,0.87)',
    marginBottom: 6,
    fontWeight: 500,
  },
  doneModalFooterSubText: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 500,
  },
  secondaryButton: {
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
      color: '#fff',
    },
  },
  submit: {
    marginLeft: 20,
    backgroundColor: '#2158eb',
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
    },
  },
}));
const validationSchema = Yup.object().shape({
  serverHost: Yup.string().required('Host is required'),
  serverPort: Yup.number().required('Port is required '),
  serverUsername: Yup.string().required('Username is required'),
  serverPassword: Yup.string().required('Password is required'),
  serverConnections: Yup.number().required('Connections are required'),
});

const priorityObj = {
  High: 0,
  Medium: 1,
  Low: 2,
};
const selectObj = {
  0: 'High',
  1: 'Medium',
  2: 'Low',
};
const findSelected = (value) => {
  for (let i = 0; i < usenetProvidersPresets.length; i++) {
    if (usenetProvidersPresets[i].name === value.name) return i;
  }
};

export default function NZBCredentialsModal(props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    handleSubmit,
    modalIsLoading,
    data = {
      serverHost: '',
      serverPort: '',
      serverUsername: '',
      serverPassword: '',
      serverConnections: 4,
      encryption: false,
      select: {
        name: 'Custom',
        serverHostSSL: '',
        serverHostPlain: '',
        portSSl: 563,
        portPlain: 119,
      },
      priority: 0,
      id: null,
    },
    required,
  } = props;

  const [showPasswordValue, setFormShowPassword] = React.useState({
    showPassword: false,
  });
  const [showAdvOptions, setAdvOptions] = React.useState(
    false,
  );
  const handleClickShowPassword = () => {
    setFormShowPassword({ showPassword: !showPasswordValue.showPassword });
  };
  const handleShowAdvOptions = () => {
    setAdvOptions(!showAdvOptions);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleFormSubmit = (valuesFromForm) => {
    handleSubmit(valuesFromForm);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={!modalIsLoading ? handleClose : () => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={required}
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            Change Usenet Credentials
            {' '}
            {!required && (
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </DialogTitle>
        <div>
          <Formik
            initialValues={{
              serverHost: data.serverHost,
              serverPort: data.serverPort,
              serverUsername: data.serverUsername,
              serverPassword: data.serverPassword,
              serverConnections:
                data.serverConnections === '0' ? 4 : data.serverConnections,
              encryption: data.encryption || false,
              select: {
                name: 'Custom',
                serverHostSSL: '',
                serverHostPlain: '',
                portSSl: 563,
                portPlain: 119,
              },
              priority: data.priority ? data.priority : 0,
              id: data._id,
            }}
            validationSchema={validationSchema}
            onSubmit={values => handleFormSubmit(values)}
          >
            {({
              handleChange,
              // testFunc,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
              setFieldValue,
              setValues,
            }) => (
              <>
                <form>
                  <div className={classes.errorModalSubTitle}>
                    Add your own usenet credentials
                  </div>
                  <div
                    className={classes.formInput}
                    style={{ marginBottom: 20 }}
                  >
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <InputLabel id="selectPresets">Presets</InputLabel>
                      <Select
                        id="selectPresets"
                        fullWidth
                        label="Presets"
                        onChange={({ target }) => {
                          const { value } = target;
                          const {
                            name,
                            portSSl,
                            portPlain,
                            serverHostSSL,
                            serverHostPlain,
                            advOptions,
                          } = value;
                          if (name === 'Custom') {
                            setFieldValue('select', value);
                          } else {
                            setFieldValue('select', value);
                            setFieldValue(
                              'serverHost',
                              portSSl ? serverHostSSL : serverHostPlain,
                            );
                            setFieldValue('encryption', !!portSSl);
                            setFieldValue('serverPort', portSSl || portPlain);
                          }
                        }}
                        value={
                          usenetProvidersPresets[findSelected(values.select)]
                        }
                      >
                        {usenetProvidersPresets.map(e => (
                          <MenuItem key={e.name} value={e}>
                            {e.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className={classes.formInput}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      error={
                        !!(touched.serverUsername && errors.serverUsername)
                      }
                      fullWidth
                      id="serverUsername"
                      autoComplete="off"
                      label="Username"
                      name="serverUsername"
                      style={{ margin: 0 }}
                      value={values.serverUsername}
                      onChange={handleChange('serverUsername')}
                    />
                    <div style={{ color: 'red', height: 20 }}>
                      {touched.serverUsername && errors.serverUsername}
                    </div>
                  </div>
                  <div className={classes.formInput}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        error={touched.password && errors.password}
                        htmlFor="serverPassword"
                      >
                        Password
                      </InputLabel>
                      <OutlinedInput
                        type={
                          showPasswordValue.showPassword ? 'text' : 'password'
                        }
                        variant="outlined"
                        margin="none"
                        error={
                          !!(touched.serverPassword && errors.serverPassword)
                        }
                        fullWidth
                        id="serverPassword"
                        label="Password"
                        autoComplete="off"
                        name="serverPassword"
                        style={{ marginBottom: 0 }}
                        value={values.serverPassword}
                        onChange={handleChange('serverPassword')}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPasswordValue.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )}
                        labelWidth={70}
                      />
                    </FormControl>
                    <div style={{ color: 'red', height: 20 }}>
                      {touched.serverPassword && errors.serverPassword}
                    </div>
                  </div>
                  <div className={classes.formInput}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={showAdvOptions}
                          onChange={handleShowAdvOptions}
                          name="advOptions"
                          color="primary"

                        />
                      )}
                      label="Advanced options"
                    />
                  </div>
                  <div
                    className={classes.formInput}
                    // style={{ marginBottom: 20, display: 'none' }}
                    style={showAdvOptions ? { marginBottom: 20 } : { marginBottom: 20, display: 'none' }}
                  >
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <InputLabel id="priority">Priority</InputLabel>
                      <Select
                        id="priority"
                        fullWidth
                        label="Priority"
                        onChange={handleChange('priority')}
                        value={values.priority}
                      >
                        <MenuItem value={0}>High</MenuItem>
                        <MenuItem value={1}>Medium</MenuItem>
                        <MenuItem value={2}>Low</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.formInput} style={showAdvOptions ? {} : { display: 'none' }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      error={!!(touched.serverHost && errors.serverHost)}
                      fullWidth
                      id="serverHost"
                      label="Host"
                      name="serverHost"
                      style={{ margin: 0 }}
                      value={values.serverHost}
                      onChange={handleChange('serverHost')}
                    />
                    <div style={{ color: 'red', height: 20 }}>
                      {touched.serverHost && errors.serverHost}
                    </div>
                  </div>
                  <div>
                    <div className={classes.formInput} style={showAdvOptions ? {} : { display: 'none' }}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={values.encryption}
                            onChange={handleChange('encryption')}
                            name="ssl"
                            color="primary"
                            disabled={values?.select?.portSSl === ''}
                          />
                        )}
                        label="Use SSL connection"
                      />

                      <div style={{ color: 'red', height: 20 }}>
                        {touched.encryption && errors.encryption}
                      </div>
                    </div>
                    <div className={classes.halfWidthBlock} style={showAdvOptions ? {} : { display: 'none' }}>
                      <div className={classes.formInputHalf}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          error={!!(touched.serverPort && errors.serverPort)}
                          fullWidth
                          placeholder="1234"
                          id="serverPort"
                          label="Port"
                          type="number"
                          autoComplete="off"
                          name="serverPort"
                          style={{ margin: 0 }}
                          value={values.serverPort}
                          onChange={handleChange('serverPort')}
                        />
                        <div style={{ color: 'red', height: 20 }}>
                          {touched.serverPort && errors.serverPort}
                        </div>
                      </div>

                      <div
                        className={classes.formInputHalf}
                        style={showAdvOptions ? {} : { display: 'none' }}
                      >
                        <TextField
                          variant="outlined"
                          // margin="normal"
                          error={
                            !!(
                              touched.serverConnections
                              && errors.serverConnections
                            )
                          }
                          InputProps={{ inputProps: { min: 0, max: 10 } }}
                          fullWidth
                          placeholder="1-100"
                          id="serverConnections"
                          label="Connections"
                          autoComplete="off"
                          type="number"
                          name="serverConnections"
                          style={{ marginBottom: 0 }}
                          value={values.serverConnections}
                          onChange={handleChange('serverConnections')}
                        />
                        <div style={{ color: 'red', height: 20 }}>
                          {touched.serverConnections
                            && errors.serverConnections}
                        </div>
                      </div>
                    </div>
                  </div>

                  {!showAdvOptions && (
                  <div className={classes.formInput}>
                    {
                      Object.keys(errors).map((error, i) => {
                        if (touched[error]) {
                          return (
                            <div style={{ color: 'red', height: 20 }} key={error}>
                              {touched[error] && errors[error]}
                            </div>
                          );
                        }
                      })
                    }
                  </div>
                  )}

                  <div style={{ height: 4 }}>
                    {modalIsLoading && <LinearProgress />}
                  </div>

                  <div
                    style={{
                      padding: '12px 32px',
                      backgroundColor: '#F7F7F7',
                      borderTop: '1px solid #E0E0E0',
                      justifyContent: 'flex-end',
                      display: 'flex',
                    }}
                  >
                    {!required && (
                      <Button
                        onClick={modalIsLoading ? () => {} : handleClose}
                        variant="outlined"
                        color="primary"
                        component="span"
                        className={classes.secondaryButton}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={modalIsLoading}
                      className={classes.submit}
                      disableElevation
                      onClick={handleSubmit}
                    >
                      {required ? 'SET' : 'CHANGE'}
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}
