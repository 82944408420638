import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import CryptoJS from 'crypto-js';
import { setWelcomeModalStep } from '../../../../redux/viewsEffects/viewsEffectsActions';
import {
  usenetAddConfigSubmit, usenetEditConfigSubmit,
  usenetValidateCredentialsSubmit,
} from '../../../../redux/usenet/usenetProvidersActions';

const usenetProvidersPresets = [
  {
    name: 'Custom',
    serverHostSSL: '',
    serverHostPlain: '',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Astraweb',
    serverHostSSL: 'ssl-eu.astraweb.com',
    serverHostPlain: 'eu.astraweb.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Blocknews EU',
    serverHostSSL: 'eunews.blocknews.net',
    serverHostPlain: 'eunews.blocknews.net',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: '4UX',
    serverHostSSL: 'news.4ux.nl',
    serverHostPlain: 'news.4ux.nl',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: '@Home v1',
    serverHostSSL: 'news.home.nl',
    serverHostPlain: 'newnews.home.nl',
    portSSl: '',
    portPlain: 119,
  },
  {
    name: '@Home v2',
    serverHostSSL: 'newnews.home.nl',
    serverHostPlain: 'newnews.home.nl',
    portSSl: '',
    portPlain: 119,
  },
  {
    name: 'Blocknews US',
    serverHostSSL: 'usnews.blocknews.net',
    serverHostPlain: 'usnews.blocknews.net',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Casema',
    serverHostSSL: 'news.casema.nl',
    serverHostPlain: 'news.casema.nl',
    portSSl: '',
    portPlain: 119,
  },
  {
    name: 'Cheapnews (block)',
    serverHostSSL: 'block.cheapnews.eu',
    serverHostPlain: 'block.cheapnews.eu',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Cheapnews',
    serverHostSSL: 'news.cheapnews.eu',
    serverHostPlain: 'news.cheapnews.eu',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Dommel',
    serverHostSSL: 'textnews.dommel.be',
    serverHostPlain: 'textnews.dommel.be',
    portSSl: '',
    portPlain: 119,
  },
  {
    name: 'Download2day',
    serverHostSSL: 'reader.download2day.com',
    serverHostPlain: 'reader.download2day.com',
    portSSl: '',
    portPlain: 119,
  },
  {
    name: 'EasyNews',
    serverHostSSL: 'secure.news.easynews.com',
    serverHostPlain: 'secure.news.easynews.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'EasyUsenet',
    serverHostSSL: 'reader.easyusenet.nl',
    serverHostPlain: 'reader.easyusenet.nl',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'Hitnews EU',
    serverHostSSL: 'ssl.hitnews.eu',
    serverHostPlain: 'news.hitnews.eu',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'I-Telligent',
    serverHostSSL: 'reader.i-telligent.com',
    serverHostPlain: 'news.i-telligent.com',
    portSSl: 563,
    portPlain: 119,
  },
  {
    name: 'KPN',
    serverHostSSL: 'textnews.kpn.nl',
    serverHostPlain: 'textnews.kpn.nl',
    portSSl: '',
    portPlain: 119,
  },
];
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 2,
  },
  formInput: {
    padding: '0px 24px',
    marginBottom: 4,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formInputHalf: {
    padding: '0px 24px',
    width: '50%',
    marginBottom: 4,
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
  halfWidthBlock: {
    display: 'flex',
  },
  formInputPicker: {
    padding: '5px 24px',
  },
  errorModalSubTitle: {
    // width: 380,
    padding: '0px 10px 10px 24px',
    fontSize: 15,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  reportFilesLabel: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
  },
  fileNameList: {
    fontSize: 16,
    marginLeft: 8,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 230,
  },
  submit: {
    marginLeft: 20,
    backgroundColor: '#2158eb',
  },
  doneModalWrapper: {
    // width: 380,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  doneContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '80px 50px',
    flexDirection: 'column',
  },
  doneIconWrapper: {
    width: 120,
    height: 120,
  },
  doneContentText: {
    fontSize: 24,
    color: 'rgba(0,0,0,0.87)',
    textAlign: 'center',
    marginTop: 30,
    fontWeight: 500,
  },
  doneModalFooterText: {
    fontSize: 20,
    color: 'rgba(0,0,0,0.87)',
    marginBottom: 6,
    fontWeight: 500,
  },
  doneModalFooterSubText: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 500,
  },
}));
const validationSchema = Yup.object().shape({
  serverHost: Yup.string().required('Required'),
  serverPort: Yup.number().required('Required '),
  serverUsername: Yup.string().required('Required'),
  serverPassword: Yup.string().required('Required'),
  serverConnections: Yup.number().required('Required'),
});
const findSelected = (value) => {
  for (let i = 0; i < usenetProvidersPresets.length; i++) {
    if (usenetProvidersPresets[i].name === value.name) return i;
  }
};
export default function Step1(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { credentials, isLoading, validationPassed } = useSelector(
    state => state.usenetReducer,
  );
  const {
    serverUsername = '',
    serverPassword = '',
    serverHost,
    serverPort,
    serverConnections,
    validationStatus,
    encryption,
    priority = 0,
    _id,
  } = credentials[0];
  const { email } = useSelector(state => state.user);

  const { Utf8 } = CryptoJS.enc;
  const originalServerUsername = CryptoJS.AES.decrypt(
    serverUsername,
    email,
  ).toString(Utf8);
  const originalServerPassword = CryptoJS.AES.decrypt(
    serverPassword,
    email,
  ).toString(Utf8);
  const originalCredentials = {
    serverUsername: originalServerUsername,
    serverPassword: originalServerPassword,
    serverHost,
    serverPort,
    serverConnections,
    validationStatus,
    encryption,
    priority: priority || 1,
    id: _id,
  };
  const { modalIsLoading } = props;
  const [showPasswordValue, setFormShowPassword] = React.useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setFormShowPassword({ showPassword: !showPasswordValue.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleFormSubmit = (valuesFromForm) => {
    const { submitBtn } = valuesFromForm;
    if (submitBtn === 'validate') {
      dispatch(usenetValidateCredentialsSubmit(valuesFromForm));
    } else if (submitBtn === 'save') {
      dispatch(usenetEditConfigSubmit(valuesFromForm));
    }
  };
  return (
    <div>
      <div>
        <Formik
          initialValues={{
            submitBtn: 'validate',
            serverHost: originalCredentials.serverHost,
            serverPort: originalCredentials.serverPort,
            serverUsername: originalCredentials.serverUsername,
            serverPassword: originalCredentials.serverPassword,
            serverConnections: 4,
            encryption: false,
            select: {
              name: 'Custom',
              serverHostSSL: '',
              serverHostPlain: '',
              portSSl: 563,
              portPlain: 119,
            },
            priority: 0,
            id: originalCredentials.id
          }}
          validationSchema={validationSchema}
          onSubmit={values => handleFormSubmit(values)}
        >
          {({
            handleChange,
            testFunc,
            handleBlur,
            handleSubmit,
            values,
            touched,
            errors,
            setFieldValue,

            setValues,
            validateForm,
            setStatus,
            setErrors,
          }) => (
            <>
              <form>
                <div className={classes.errorModalSubTitle}>
                  Add your own usenet credentials
                </div>
                <div className={classes.formInput} style={{ marginBottom: 20 }}>
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel id="selectPresets">Presets</InputLabel>
                    <Select
                      id="selectPresets"
                      fullWidth
                      label="Presets"
                      onChange={({ target }) => {
                        const { value } = target;
                        const {
                          name,
                          portSSl,
                          portPlain,
                          serverHostSSL,
                          serverHostPlain,
                        } = value;
                        if (name === 'Custom') {
                          setFieldValue('select', value);
                        } else {
                          setFieldValue('select', value);
                          setFieldValue(
                            'serverHost',
                            portSSl ? serverHostSSL : serverHostPlain,
                          );
                          setFieldValue('encryption', !!portSSl);
                          setFieldValue('serverPort', portSSl || portPlain);
                        }
                      }}
                      value={
                        usenetProvidersPresets[findSelected(values.select)]
                      }
                    >
                      {usenetProvidersPresets.map(e => (
                        <MenuItem key={e.name} value={e}>
                          {e.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div
                  className={classes.formInput}
                  style={{ marginBottom: 20 }}
                >
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel id="priority">Priority</InputLabel>
                    <Select
                      id="priority"
                      fullWidth
                      label="Priority"
                      onChange={handleChange('priority')}
                      value={values.priority}
                    >
                      <MenuItem value={0}>High</MenuItem>
                      <MenuItem value={1}>Medium</MenuItem>
                      <MenuItem value={2}>Low</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className={classes.formInput}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    error={!!(touched.serverHost && errors.serverHost)}
                    fullWidth
                    id="serverHost"
                    label="Host"
                    name="serverHost"
                    style={{ margin: 0 }}
                    value={values.serverHost}
                    onChange={handleChange('serverHost')}
                    onBlur={handleBlur}
                  />
                  <div style={{ color: 'red', height: 20 }}>
                    {touched.serverHost && errors.serverHost}
                  </div>
                </div>
                <div>
                  <div className={classes.formInput}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={values.encryption}
                          onChange={handleChange('encryption')}
                          name="ssl"
                          color="primary"
                        />
                      )}
                      label="Use SSL connection"
                    />
                  </div>
                  <div className={classes.halfWidthBlock}>
                    <div className={classes.formInputHalf}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        error={!!(touched.serverPort && errors.serverPort)}
                        fullWidth
                        placeholder="1234"
                        id="serverPort"
                        label="Port"
                        type="number"
                        autoComplete="off"
                        name="serverPort"
                        style={{ margin: 0 }}
                        value={values.serverPort}
                        onChange={handleChange('serverPort')}
                        onBlur={handleBlur}
                      />
                      <div style={{ color: 'red', height: 20 }}>
                        {touched.serverPort && errors.serverPort}
                      </div>
                    </div>

                    <div className={classes.formInputHalf}>
                      <TextField
                        variant="outlined"
                        // margin="normal"
                        error={
                          !!(
                            touched.serverConnections
                            && errors.serverConnections
                          )
                        }
                        InputProps={{ inputProps: { min: 0, max: 10 } }}
                        fullWidth
                        placeholder="1-100"
                        id="serverConnections"
                        label="Connections"
                        autoComplete="off"
                        type="number"
                        name="serverConnections"
                        style={{ marginBottom: 0 }}
                        value={values.serverConnections}
                        onChange={handleChange('serverConnections')}
                        onBlur={handleBlur}
                      />
                      <div style={{ color: 'red', height: 20 }}>
                        {touched.serverConnections && errors.serverConnections}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.formInput}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    error={Boolean(
                      touched.serverUsername && errors.serverUsername,
                    )}
                    fullWidth
                    id="serverUsername"
                    autoComplete="off"
                    label="Username"
                    name="serverUsername"
                    style={{ margin: 0 }}
                    value={values.serverUsername}
                    onChange={handleChange('serverUsername')}
                    onBlur={handleBlur}
                  />
                  <div style={{ color: 'red', height: 20 }}>
                    {touched.serverUsername && errors.serverUsername}
                  </div>
                </div>
                <div className={classes.formInput}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      error={Boolean(
                        touched.serverPassword && errors.serverPassword,
                      )}
                      htmlFor="serverPassword"
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      type={
                        showPasswordValue.showPassword ? 'text' : 'password'
                      }
                      variant="outlined"
                      margin="none"
                      error={Boolean(
                        touched.serverPassword && errors.serverPassword,
                      )}
                      fullWidth
                      id="serverPassword"
                      label="Password"
                      autoComplete="off"
                      name="serverPassword"
                      style={{ marginBottom: 0 }}
                      value={values.serverPassword}
                      onChange={handleChange('serverPassword')}
                      onBlur={handleBlur}
                      endAdornment={(
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordValue.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )}
                      labelWidth={70}
                    />
                  </FormControl>
                  <div style={{ color: 'red', height: 20 }}>
                    {touched.serverPassword && errors.serverPassword}
                  </div>
                </div>

                <div style={{ height: 4 }}>
                  {isLoading && <LinearProgress />}
                </div>

                <div
                  style={{
                    padding: '12px 32px',
                    backgroundColor: '#F7F7F7',
                    borderTop: '1px solid #E0E0E0',
                    justifyContent: 'flex-end',
                    display: 'flex',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    className={classes.submit}
                    disableElevation
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldValue('submitBtn', 'validate');
                      handleSubmit();
                    }}
                  >
                    VALIDATE
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                    className={classes.submit}
                    disableElevation
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldValue('submitBtn', 'save');
                      handleSubmit();
                    }}
                  >
                    SAVE
                  </Button>
                </div>
              </form>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
}
