import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { makeStyles } from '@material-ui/core/styles';
import AnimatedEllipsis from '../atoms/AnimatedEllipsis';
import LottieAnimationPlayer from '../atoms/LottieAnimationPlayer';

const useStyles = makeStyles(theme => ({
  inProgressButton: {
    textTransform: 'none',
    color: 'rgba(0,0,0,0.6)',
    backgroundColor: 'rgb(247, 247, 247)',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: 'rgb(235, 235, 235)',
    },
  },
  inProgressButtonMobile: {
    textTransform: 'none',
    color: 'rgba(0,0,0,0.6)',

    borderRadius: 2,
  },
}));
export default function DownloadStatus(props) {
  const classes = useStyles();
  const {
    onClickHandler, popoverOpen, mobile, files, setRef
  } = props;
  const { filesDownloadingCount, filesQueuedCount } = files;
  const inProcess = Boolean(filesQueuedCount);
  const filesDownloading = Boolean(filesDownloadingCount);

  const startIcon = filesDownloading ? (
    <CircularProgress disableShrink size={15} style={{ marginLeft: 6 }} />
  ) : (
    <CloudDownloadIcon style={{ marginLeft: 6 }} />
  );

  const inProgressButton = (
    <Button
      className={
        mobile ? classes.inProgressButtonMobile : classes.inProgressButton
      }
      startIcon={(
        <div style={{ width: 25, height: 25 }}>
          <LottieAnimationPlayer animation="inProcessingCloud" />
        </div>
      )}
      onClick={onClickHandler}
      ref={setRef}
      endIcon={
        !mobile
        && (
        <div style={{ width: 25, height: 25 }}>
          {popoverOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </div>
        )
      }
    >
      Processing
      <AnimatedEllipsis numberOfDots={3} animDelay={0.1} />
    </Button>
  );
  const downloadingButton = (
    <Button
      className={
        mobile ? classes.inProgressButtonMobile : classes.inProgressButton
      }
      startIcon={startIcon}
      onClick={onClickHandler}
      ref={setRef}
      endIcon={
        !mobile
          && (
          <div style={{ width: 25, height: 25 }}>
            {popoverOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </div>
          )

      }
    >
      {filesDownloading
        ? `Files downloading: ${filesDownloadingCount}`
        : 'Downloads'}
    </Button>
  );

  if (inProcess) {
    return inProgressButton;
  }
  return downloadingButton;
}
