import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import PublicIcon from '@material-ui/icons/Public';
import ListItemText from '@material-ui/core/ListItemText';

import List from '@material-ui/core/List';
import LinkIcon from '@material-ui/icons/Link';

const useStyles = makeStyles(theme => ({
  textPrimary: {
    fontWeight: 500,
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  textSecondary: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: 12,
    fontWeight: 400,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  avatarList: {
    width: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const recommendedIndexers = [
  {
    name: 'abNZB',
    url: 'https://abnzb.com/',
    rating: 1,
  },
  {
    name: 'Animetosho (Newznab)',
    url: 'https://feed.animetosho.org',
    rating: 1,
  },
  {
    name: 'FastNZB',
    url: 'https://fastnzb.com',
    rating: 1,
  },
  {
    name: 'miatrix',
    url: 'https://www.miatrix.com',
    rating: 1,
  },
  {
    name: 'NZB Finder',
    url: 'https://nzbfinder.ws',
    rating: 1,
  },
  {
    name: 'nzb.su',
    url: 'https://api.nzb.su',
    rating: 1,
  },
  {
    name: 'NZBGeek',
    url: 'https://api.nzbgeek.info',
    rating: 1,
  },
  {
    name: 'NzbNdx',
    url: 'https://www.nzbndx.com',
    rating: 1,
  },
  {
    name: 'NzBNooB',
    url: 'https://www.nzbnoob.com',
    rating: 1,
  },
  {
    name: 'spotweb.com',
    url: 'https://spotweb.me',
    rating: 1,
  },
  {
    name: 'newz69',
    url: 'https://newz69.keagaming.com/',
    rating: 1,
  },
];

export default function RecommendedIndexersList() {
  const classes = useStyles();

  const handleIndClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <List disablePadding className={classes.avatarList}>
      {recommendedIndexers.map(e => (
        <ListItem
          key={e.name}
          button
          style={{
            height: 80,
            borderBottom: '1px solid rgb(247, 247, 247)',
            paddingLeft: 32,
            paddingRight: 50,
          }}
          onClick={() => handleIndClick(e.url)}
        >
          <ListItemAvatar>
            <PublicIcon />
          </ListItemAvatar>
          <ListItemText
            classes={{
              primary: classes.textPrimary,
              secondary: classes.textSecondary,
            }}
            primary={e.name}
            secondary={e.url}
          />
          <div style={{ right: 28 }}>
            <LinkIcon style={{ color: 'rgb(0, 134, 250)' }} edge="end" />
          </div>
        </ListItem>
      ))}
    </List>
  );
}
