import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import 'vimond-replay/index.css';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import streamSaver from 'streamsaver';
import FileIcon from '../../../atoms/FileIcon';
import EncodeStatusReadyBadge from '../EncodeStatusBadges/EncodeStatusReadyBadge';
import EncodeStatusProgressBadge from '../EncodeStatusBadges/EncodeStatusProgressBadge';
import FileSubtitleIcon from '../../../atoms/FileSubtitleIcon';
import FileSubtitleBrokenIcon from '../../../atoms/FileSubtitleBrokenIcon';
import InfectedFile from '../../../atoms/InfectedFileIcon';
import formatBytes from '../../../../helpers/formatBytes';
import appConsts from '../../../../config/appConsts';


const useStyles = makeStyles(theme => ({
  // FILE
  fileWrapper: {
    height: 88,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // backgroundColor: '#FFFFFF',
    // border: '2px solid #FFFFFF',
    border: '1px solid #EDEDED',
    borderRadius: 2,
    // cursor: 'pointer',
    // padding: '16px 5px 16px 13px',
    marginBottom: 8,
    overflow: 'hidden',
    '&:hover': {
      // opacity: 0.7,
      // backgroundColor: 'rgba(0, 0, 0, 0.04)'
      boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.05)',

    },
    '&:last-child': {
      marginBottom: 100,
    },
    [theme.breakpoints.down('sm')]: {
      height: 66,
    },
  },
  fileClickableWrapper: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '60%',
  },

  fileIconWrapper: {
    width: 88,
    height: 88,
    [theme.breakpoints.down('sm')]: {
      height: 66,
      width: 66,
      minWidth: 66,
    },
  },
  fileContentWrapper: {
    // width: '90%',
    flex: 1,
    color: 'rgba(0,0,0,0.87)',
    // fontFamily: 'Roboto, san-serif',
    fontSize: 16,
    letterSpacing: 0.15,
    paddingLeft: 16,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 24,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 12,
      paddingLeft: 8,
    },
  },
  previewWrapper: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  fileMenuWrapper: {
    display: 'flex',
    height: '40%',
    color: '#00000099',
    // justifyContent: 'center',
    width: '100%',
    // borderTop: '1px solid #00000026',
  },
  buttonRoot: {
    minWidth: 'auto',
    height: '100%',
    width: '100%',
  },
  fileMenu: {
    width: '20%',
    borderLeft: '1px solid #00000026',
  },
  fileSize: {
    // fontFamily: 'Roboto, san-serif',
    fontSize: 16,
    letterSpacing: 0.25,
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 400,
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  fileDataWrapper: {
    color: 'rgba(0,0,0,0.87)',
    display: 'flex',
    justifyContent: 'space-between',
    // width: '20%',
    alignItems: 'center',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      // display: 'none',
    },
  },
  modal: {
    position: 'absolute',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  fileIcon: {
    color: '#E2E5E7',
    width: 56,
    height: 56,
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40,
    },
  },
  iconMask: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fileNameWrapper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 400,
    // display: "flex",
    alignItems: 'center',
    paddingRight: 60,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 265,
      paddingRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 165,
    },
  },
  encodeStatusContainer: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      right: 0,
    },

  },
}));

export default function File(props) {
  const classes = useStyles();
  const {
    data,
    onMediaFileClick,
  } = props;
  const {
    viruses, externalStorageId, targetStorage, name,
  } = data;
  const date = moment(data.created_at)
    .format('D.MM.YYYY');


  const token = localStorage.getItem('@accessToken');
  const handleDownload = async (e) => {
    // dispatch(getStorageFolderArchiveSubmit(data._id));
    // handleClose();
    e.persist();
    if (targetStorage === 'NZBCLOUD') {
      const response = await fetch(`${appConsts.FILES_URL}/files/${data._id}/download?token=${token}`);
      const downloadLink = await response.text();
      console.log(data);
      const link = document.createElement('a');
      // link.href = `${appConsts.API_URL}/files/${data._id}/download?token=${token}`;

      link.href = downloadLink;
      e.stopPropagation();
      link.target = '_blank';
      link.download = 'proposed_file_name';
      document.body.appendChild(link);
      link.click();
      // document.body.removeChild(link);
    } else {
      const url = `${appConsts.FILES_URL}/files/${data._id}/download-external?token=${token}`;
      const fileStream = streamSaver.createWriteStream(name);

      fetch(url)
        .then((res) => {
          const readableStream = res.body;

          // more optimized
          if (window.WritableStream && readableStream.pipeTo) {
            return readableStream.pipeTo(fileStream)
              .then(() => console.log('done'));
          }

          const writer = fileStream.getWriter();

          const reader = res.body.getReader();
          const pump = () => reader.read()
            .then(res => (res.done
              ? writer.close()
              : writer.write(res.value)
                .then(pump)));

          pump();
        });
    }
  };
  return (
    <>
      <div
        className={classes.fileWrapper}

        style={{
          position: 'relative',
          backgroundColor:
            viruses && viruses.length ? 'rgba(255, 77, 99, 0.07)' : '#FFFFFF',
        }}
      >

        <div
          className={classes.fileIconWrapper}
          onClick={
            data.type !== 'FILE' || data.type !== 'SUBTITLE'
              ? () => onMediaFileClick(data)
              : () => {
              }
          }
          style={{

            cursor: data.type !== 'FILE' ? 'pointer' : 'inherit',

          }}
        >
          <div
            className={classes.previewWrapper}
            style={
              data.previewUrl
                ? { backgroundImage: `url(${data.previewUrl})` }
                : {}
            }
          >
            {viruses && viruses.length ? (
              <div className={classes.fileIcon}>
                <InfectedFile />
              </div>
            ) : data.type === 'VIDEO' || data.type === 'IMAGE' ? (
              <div className={classes.iconMask}>
                <PlayCircleFilledWhiteIcon className={classes.fileIcon} />
              </div>
            ) : (
              <div className={classes.fileIcon}>
                {data.type === 'SUBTITLE' && data.isSubtitleValid === true && (
                  <FileSubtitleIcon />
                )}
                {data.type === 'SUBTITLE' && data.isSubtitleValid === false && (
                  <FileSubtitleBrokenIcon />
                )}
                {data.type === 'FILE' && <FileIcon />}
              </div>
            )}
          </div>
        </div>

        <div className={classes.fileContentWrapper}>
          <div
            className={classes.fileNameWrapper}
            style={{
              color:
                viruses && viruses.length ? '#B23040' : 'rgba(0, 0, 0, 0.87)',
            }}
          >
            {data.name}
          </div>

          <div className={classes.fileDataWrapper}>
            <div
              className={classes.fileSize}
              style={{
                color:
                  viruses && viruses.length
                    ? 'rgba(178, 48, 64, 1)'
                    : 'rgba(0, 0, 0, 0.38)',
              }}
            >
              {data.size > 0 ? formatBytes(data.size) : '<1 MB'}
              {/* MB */}
            </div>
            <div
              className={classes.fileSize}
              style={{
                paddingLeft: 65,
                paddingRight: 65,
                color:
                  viruses && viruses.length
                    ? 'rgba(178, 48, 64, 1)'
                    : 'rgba(0, 0, 0, 0.38)',
              }}
            >
              {date}
            </div>
            {viruses && viruses.length ? (
              <Tooltip
                title={`File infected with: ${viruses.join(', ')}`}
                aria-label="Virus"
              >
                <div
                  style={{
                    marginRight: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <HelpIcon style={{ color: '#FF4D63' }} />
                </div>
              </Tooltip>
            ) : null}
            <div className={classes.encodeStatusContainer}>
              {data.transcodeStatus === 'finished' && <EncodeStatusReadyBadge />}
              {data.transcodeStatus === 'executing' || data.transcodeStatus === 'queued' ? (
                <EncodeStatusProgressBadge />
              ) : null}
            </div>
            <Tooltip title={externalStorageId === undefined ? 'Download option is disabled while sync in progress.. ' : 'Download file'}>
              <div>
                <IconButton style={{ marginLeft: 5 }} onClick={handleDownload} disabled={externalStorageId === undefined}>

                  <GetAppIcon fontSize="small" />

                </IconButton>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
}
