import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';
import { nzbFilesClearCompletedSubmit } from '../../redux/files/filesActions';
import DownloadsItem from './downloadsItem';
import EmptyDownloadsImage from '../atoms/EmptyDownloadsImage/EmptyDownload.svg';

const StyledSwitch = withStyles({
  switchBase: {
    color: '#FF4D63',
    '&$checked': {
      color: '#50BCB6',
    },
    '&$checked + $track': {
      backgroundColor: '#50BCB6',
    },
  },
  checked: {},
  track: { backgroundColor: '#FF4D63' },
})(Switch);
const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  subHeaderWrapper: {
    display: 'flex',
    padding: 10,
    // borderBottom: '1px solid #F7F7F7',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    borderBottom: '3px solid #f7f7f7;',
    zIndex: 99,
  },
  emptyWrapperTab: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#f3f3f3',
    width: 420,
    height: 420,
    justifyContent: 'center',
    borderRadius: '50%',
    marginTop: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      backgroundColor: 'inherit',
      borderRadius: 0,
    },
  },
  emptyWrapperPopper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  downloadsList: {
    borderRadius: 2,
    width: 480,
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    'downloadsList div li:last-child': {
      marginBottom: 80,
    },
  },
}));
export default function DownloadsInnerList(props) {
  const classes = useStyles();
  const {
    isMobile,
    closeDrawerHandler,
    containerWidth,
    containerBackgroundColor,
    tab,
  } = props;
  const { downloads } = useSelector(state => state.files);
  const dispatch = useDispatch();
  const arrayWithoutSuccess = downloads.filter(download => download.status !== 'SUCCESS').reverse();
  const sortedDownloads = [...arrayWithoutSuccess, ..._.differenceWith(downloads, arrayWithoutSuccess, _.isEqual).reverse()];
  const [showCompleted, setShowCompleted] = React.useState(true);
  const downloadsArray = showCompleted ? [...sortedDownloads] : [...arrayWithoutSuccess];
  const handleShowCompleted = () => {
    setShowCompleted(!showCompleted);
  };
  const handleClearCompleted = () => {
    dispatch(nzbFilesClearCompletedSubmit());
  };
  return (
    <>
      <List
        className={classes.downloadsList}
        style={{
          width: containerWidth,
          marginBottom: !tab ? 0 : 70,
        }}
        subheader={(
          <>
            <div
              className={classes.subHeaderWrapper}
              style={tab ? { padding: 0, marginBottom: 20 } : {}}
            >
              <div>
                <Typography
                  variant="h6"
                  style={
                    tab
                      ? {
                        color: 'rgba(0,0,0,0.87)',
                        fontSize: 34,
                        marginRight: 5,
                        fontWeight: 400,
                        lineHeight: 1.43,
                      }
                      : { fontSize: 16, paddingLeft: 6 }
                  }
                  display="block"
                >
                  Downloads
                </Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', paddingRight: 6 }}>
                <StyledSwitch
                  checked={showCompleted}
                  onChange={handleShowCompleted}
                />
                <Typography style={{ color: '#1212129c' }}>Show completed</Typography>
              </div>

              {isMobile ? (
                <div>
                  <IconButton onClick={closeDrawerHandler}>
                    <CloseIcon />
                  </IconButton>
                </div>
              ) : null}

            </div>
          </>
        )}
      >
        <div
          style={{
            padding: tab ? 0 : 8,
            backgroundColor: containerBackgroundColor || '#f7f7f7',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingBottom: 80,
          }}
        >
          {downloadsArray.length ? (
            downloadsArray
            && downloadsArray.map(file => (
              <DownloadsItem file={file} key={file._id} />
            ))
          ) : (
            <div
              className={
                tab ? classes.emptyWrapperTab : classes.emptyWrapperPopper
              }
            >
              {/* <img */}
              {/*  style={tab ? { transform: 'scaleX(-1)' } : {}} */}
              {/*  src={EmptyDownloadsImage} */}
              {/*  alt="Empty" */}
              {/* /> */}
              <div
                style={{
                  color: 'rgba(0,0,0,0.6)',
                  marginTop: 20,
                  marginBottom: 20,
                  fontWeight: 500,
                }}
              >
                No Downloads
              </div>
            </div>
          )}
        </div>
        {/* {isMobile && ( */}
        {/* <div */}
        {/*  style={{ */}
        {/*    display: 'flex', */}
        {/*    borderTop: '1px solid #F7F7F7', */}
        {/*    padding: '5px 10px 5px 10px', */}
        {/*    justifyContent: 'space-around', */}
        {/*    alignItems: 'baseline', */}
        {/*  }} */}
        {/* > */}
        {/*  /!*<Button*!/ */}
        {/*  /!*  disabled={!downloads.length}*!/ */}
        {/*  /!*  color="secondary"*!/ */}
        {/*  /!*  onClick={handleClearCompleted}*!/ */}
        {/*  /!*>*!/ */}
        {/*  /!*  Clear completed*!/ */}
        {/*  /!*</Button>*!/ */}
        {/*  <div style={{ display: 'flex', alignItems: 'center', paddingRight: 6 }}> */}
        {/*    <StyledSwitch */}
        {/*      checked={showCompleted} */}
        {/*      onChange={handleShowCompleted} */}
        {/*    /> */}
        {/*    <Typography>Show completed</Typography> */}
        {/*  </div> */}
        {/* </div> */}
        {/* )} */}
      </List>
    </>
  );
}
