import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import StorageCircularProgress from './StorageCircularProgress';
import history from '../../../config/history';
import formatBytes from '../../../helpers/formatBytes';

const useStyles = makeStyles(theme => ({
  spaceTrackerWrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FCB643',
    borderRadius: 2,
    display: 'flex',
    cursor: 'pointer',
    userSelect: 'none',
  },
  progressWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 12,
  },
  infoWrapper: {
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
    padding: '8px 0px 8px 12px',
    width: '60%',
  },
  infoCta: {
    color: '#A66700',
    fontSize: 11,
  },
  iconWrapper: {
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoData: {
    color: 'rgba(255,255,255,0.87)',
    fontSize: 14,
  },
  progressCircleBreaker: {
    position: 'absolute',
    width: 5,
    height: 5,
    top: 0,
    left: 0,
  },
}));
export default function StorageSpaceTracker(props) {
  const classes = useStyles();
  const { user } = props;
  const { activePlan, storageLeft } = user;

  // const spaceTotal = activePlan.storage >= 1000
  //   ? `${(activePlan.storage * 0.001).toFixed(0)} GB`
  //   : `${activePlan.storage} MB`;
  const spaceUsed = activePlan.storage - storageLeft >= 1000
    ? `${((activePlan.storage - storageLeft) * 0.001).toFixed(2)} GB`
    : `${activePlan.storage - storageLeft} MB`;
  const percentage = Math.ceil(
    ((activePlan.storage - storageLeft) / activePlan.storage) * 100,
  );
  const spaceTotal = formatBytes(activePlan.storage * 1024 * 1024);


  return (
    <div className={classes.spaceTrackerWrapper} onClick={() => history.push('/pricing')}>
      <div className={classes.progressWrapper}>
        <div className={classes.progressCircleBreaker}> </div>
        <StorageCircularProgress
          strokeWidth="2"
          sqSize="48"
          percentage={percentage}
        />
      </div>
      <div className={classes.infoWrapper}>
        <div className={classes.infoData}>
          {spaceUsed}
          {' '}
          of
          {' '}
          {spaceTotal}
        </div>
        <div className={classes.infoCta}>
          Upgrade storage
          {' '}
          <br />
          to get more features
        </div>
      </div>
      <div className={classes.iconWrapper}>
        <KeyboardArrowRightIcon style={{ color: 'rgba(255,255,255,0.87)' }} />
      </div>
    </div>
  );
}
