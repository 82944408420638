import React from 'react';

const GoogleDriveIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3682 20.8964L10.1007 26.2041L5.16956 29.9506L0 20.8374L10.7876 2.04938L15.8384 5.51625L16.0049 11.1359L15.9572 11.1634L10.3682 20.8964Z"
      fill="#28B446"
    />
    <path
      d="M10.7876 2.04938L13.8573 14.8203L16.0048 11.1359L15.8383 3.73838L10.7876 2.04938Z"
      fill="#219B38"
    />
    <path
      d="M21.2115 2.04938L32 20.8374L26.1823 23.7798L21.5752 20.8657V20.8374L16.0048 11.1359L10.7876 2.04938H21.2115Z"
      fill="#FFD837"
    />
    <path
      d="M31.9999 20.8374L26.8336 29.8916L5.16943 29.9506L10.3681 20.8964L21.5751 20.8657L31.9999 20.8374Z"
      fill="#518EF8"
    />
    <path
      d="M5.16943 29.9506L14.2342 20.8858L10.3681 20.8964L5.16943 29.9506Z"
      fill="#3A5BBC"
    />
    <path
      d="M31.9999 20.8374L20.1724 18.3944L21.5751 20.8657L31.9999 20.8374Z"
      fill="#FBBB00"
    />
  </svg>
);

export default GoogleDriveIcon;
