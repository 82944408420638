import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider } from '@material-ui/core/styles';
import TagManager from 'react-gtm-module';
import { UserbackProvider } from '@userback/react';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './config/store';
import RootRouter from './config/rootRouter';
import 'typeface-poppins';
import 'react-toastify/dist/ReactToastify.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-jinke-music-player/assets/index.css';
import './index.css';
import { version } from '../package.json';
import theme from './config/muiTheme';

const tagManagerArgs = {
  gtmId: 'GTM-PBC2MVQ',
};

TagManager.initialize(tagManagerArgs);


function Version() {
  this.NZBCloud = version;
  const vr = localStorage.getItem('vr');

  if (vr !== version || !vr) {
    localStorage.clear();
  }
  localStorage.setItem('vr', version);
}
const me = new Version();

console.table(me);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={<CircularProgress />} persistor={persistor}>
        <UserbackProvider token="37848|74146|x480h1s7CioCwIeNfMZmyASXC">
          <RootRouter />
        </UserbackProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
