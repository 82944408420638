import api from '../helpers/api';

class HdrSearchService {
  async getSearchResults(payload) {
    return api
      .sendRequest(`/search-nzb/${payload}/search`, 'get')
      .then(response => response.data);
  }

  async getIndexers() {
    return api
      .sendRequest('/search-nzb/listIndexers', 'get')
      .then(response => response.data);
  }

  async addIndexer(data) {
    return api
      .sendRequest('/search-nzb/addIndexer', 'post', data)
      .then(response => response.data);
  }

  async deleteIndexer(name) {
    return api
      .sendRequest(`/search-nzb/${name}/delete`, 'delete')
      .then(response => response.data);
  }
}

export default new HdrSearchService();
