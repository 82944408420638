import React from 'react';

import { Route, Switch } from 'react-router-dom';
import { fade, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import HelpIcon from '@material-ui/icons/Help';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloudIcon from '@material-ui/icons/Cloud';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import Link from '@material-ui/core/Link';
import TermsOfService from '../TermsOfService';
import { getUserSubmit } from '../../redux/user/userActions';
import { getDownloadsSubmit } from '../../redux/files/filesActions';
import Contacts from '../Contacts';
import Faq from '../Faq';
import Pricing from '../Pricing';
import PrivacyPolicy from '../PrivacyPolicy';
import AuthContainer from '../AuthContainer';
import LogoIcon from '../Images/Logo.png';
import IdleLogo from '../IdleTrakerLogo';
import OrderComplete from '../OrderComplete';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    justifyContent: 'space-between',
    backgroundColor: 'white',
    paddingRight: 12,
    paddingLeft: 12,
    height: 80,
    maxWidth: 1200,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: 1140,
    },
    [theme.breakpoints.down('sm')]: {
      // height: 68,
      paddingRight: 8,
      paddingLeft: 8,
    },
  },
  logo: {
    color: '#2158eb',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 70,
    backgroundImage: `url(${LogoIcon})`,
    height: 48,
    width: 48,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: '0 2px 5px -2px rgb(0 0 0 / 10%)',
    minHeight: 80,
    backgroundColor: '#fff',
    alignItems: 'center',
  },
  tabContainer: {
    display: 'flex',
    marginLeft: 20,
    minHeight: 80,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  menuButtonHidden: {
    display: 'none',
  },
  title: {
    color: '#2158eb',
    display: 'flex',
    alignItems: 'center',
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#F7F7F7',
    '&:hover': {
      backgroundColor: fade('#eae8e8', 0.89),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 360,
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(0,0,0,0.38)',
  },
  inputRoot: {
    color: '#F7F7F7',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1.5, 1, 1.5, 7),
    transition: theme.transitions.create('width'),
    color: 'rgba(0,0,0,0.38)',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  logoWrapper: {
    display: 'flex',
    width: 50,
    height: 50,
  },

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    // paddingBottom: 40,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  tabRoot: {
    minWidth: 80,
    // padding: '23px 12px',

    // fontFamily: '"Work Sans",sans-serif',
    fontSize: 12,

    fontWeight: 600,
    color: '#0f0c0c',
    padding: '0 0',
    height: 80,

    textDecoration: 'none',
    '&:hover': {
      color: '#2158eb',
    },
  },
  selected: {
    color: '#2158EB !important',
  },
  indicator: {
    backgroundColor: '#2158EB',
  },
  button: {
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',
    minWidth: 90,
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
      color: '#fff',
    },
  },
  backButton: {
    // backgroundColor: '#2158eb',
    backgroundColor: '#2158eb',
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
    },
  },
  navContainer: {
    display: 'flex',
  },
  bottomNavBar: {
    display: 'none',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 100,

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
});

class ServicesContainer extends React.Component {
  constructor(props) {
    super(props);
    switch (props.location.pathname) {
      case '/login': {
        this.state = { value: 4 };
        break;
      }
      case '/pricing': {
        window.location.href = 'https://checkout.nzbcloud.com/checkout';
        break;
      }
      case '/contacts': {
        this.state = { value: 2 };
        break;
      }
      case '/registration': {
        this.state = { value: 5 };
        break;
      }
      case '/faq': {
        this.state = { value: 3 };
        break;
      }
      case '/terms': {
        this.state = { value: 6 };
        break;
      }
      case '/order-complete': {
        this.state = { value: 7 };
        break;
      }
      default: {
        this.state = { value: 2 };
      }
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, value) {
    this.setState({ value });
    const { history } = this.props;
    switch (value) {
      case 0: {
        window.location.href = 'https://nzbcloud.com/';
        return;
      }
      case 1: {
        window.location.href = 'https://checkout.nzbcloud.com/checkout';
        return;
      }
      case 2: {
        history.push('/contacts');
        return;
      }
      case 3: {
        history.push('/faq');
        return;
      }
      case 4: {
        history.push('/login');
        return;
      }
      case 5: {
        history.push('/registration');
        return;
      }
      case 6: {
        history.push('/terms');
        return;
      }
      case 7: {
        history.push('/order-complete');
        return;
      }
      default: {
        history.push('/pricing');
      }
    }
  }

  render() {
    const {
      classes, auth, location, history,
    } = this.props;
    const { value } = this.state;
    // const { pathname } = location;
    const { isAuthenticated } = auth;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar elevation={0} position="absolute" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IdleLogo serviceCont history={history} isAuthenticated={isAuthenticated}/>
            <div className={classes.navContainer}>
              <Tabs
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                style={{ justifySelf: 'flex-end' }}
                classes={{ root: classes.tabContainer, indicator: classes.indicator }}
              >
                <Tab classes={{ root: classes.tabRoot, selected: classes.selected }} label="Home" />
                <Tab classes={{ root: classes.tabRoot, selected: classes.selected }} label="Pricing" />
                <Tab classes={{ root: classes.tabRoot, selected: classes.selected }} label="Contacts" />
                <Tab classes={{ root: classes.tabRoot, selected: classes.selected }} label="FAQ" />
                <Tab style={{ display: 'none' }} label="Login" />
                <Tab style={{ display: 'none' }} label="Registration" />
                <Tab style={{ display: 'none' }} label="Terms Of Service" />
                <Tab style={{ display: 'none' }} label="Order Complete" />
              </Tabs>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ paddingLeft: 24 }}>
                  {isAuthenticated ? (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        startIcon={<CloudIcon />}
                        onClick={() => history.push('/dashboard/downloads')}
                        disableElevation
                        className={classes.backButton}
                      >
                        Back to Storage
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        className={classes.button}
                        onClick={() => this.handleChange(4, 4)}
                        style={{ marginRight: 10 }}
                      >
                        Log In
                      </Button>
                      <Link
                        href="https://checkout.nzbcloud.com/checkout"
                        target="_blank"
                        style={{textDecoration: 'none'}}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          className={classes.button}
                        // onClick={() => this.handleChange(4, 5)}
                          disableElevation
                          style={{ backgroundColor: '#2158eb' }}
                        >
                          Sign up
                        </Button>
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <BottomNavigation
          value={value}
          onChange={this.handleChange}
          className={classes.bottomNavBar}
        >
          <BottomNavigationAction label="Home" value={0} icon={<HomeIcon />} />
          <BottomNavigationAction
            label="Pricing"
            value={1}
            icon={<CreditCardIcon />}
          />
          <BottomNavigationAction
            label="Contacts"
            value={2}
            icon={<ContactMailIcon />}
          />
          <BottomNavigationAction label="FAQ" value={3} icon={<HelpIcon />} />
        </BottomNavigation>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />

          <Switch>
            <Route path="/contacts" component={Contacts} />
            <Route path="/faq" component={Faq} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/terms" component={TermsOfService} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/order-complete" component={OrderComplete} />
            <Route
              path="/(login|registration|registration|link-login|password)/"
              component={AuthContainer}
            />
          </Switch>
        </main>
      </div>
    );
  }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
  getUserSubmit: () => {
    dispatch(getUserSubmit());
  },
  getDownloadsSubmit: () => {
    dispatch(getDownloadsSubmit());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ServicesContainer));
