import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    position: 'absolute',
    width: '95%',
    top: '2%',
    left: '2.5%',
    outline: 'none',
    borderRadius: 16,
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down('sm')]: {
      width: '99%',
      left: '0.5%',
      // maxHeight: '70vh'
    },
  },
  imageModal: {
    width: '80%',
    height: '50%',
  },
  image: {
    width: 500,
    height: 500,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  encodeMessage: {
    padding: '16px 24px',
    textAlign: 'center',
    backgroundColor: '#FCEED6',
    height: 78,
    display: 'flex',
    // borderRadius: 16,
    // marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'unset',
    },
  },
  encodeBadgeWrapper: {
    opacity: 0.7,
    top: 25,
    left: 0,
    width: '100%',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      top: 15,
    },
  },
  encodeOnErrorMessage: {
    padding: '16px 24px',
    textAlign: 'center',
    backgroundColor: '#FCEED6',
    height: 78,
    display: 'flex',
    position: 'absolute',
    top: '50%',
    width: '100%',

    // marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'unset',
      top: '8%',
    },
  },
  textBlock: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    paddingLeft: 20,
    fontSize: '0.875rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  iconWrapper: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 'auto',
    },
  },
  encodeButton: {
    height: 36,
    width: 100,
    borderRadius: 2,
    backgroundColor: '#2158eb',
    color: 'rgb(255, 255, 255)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  encodeButtonWrapper: {
    alignItems: 'center',
    marginLeft: 'auto',
    display: 'flex',
    // backgroundColor: 'rgb(255, 255, 255)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: 8,
    },
  },
  pauseButton: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 80,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    opacity: 0.9,
    visibility: 'hidden',
    animation: '$fadeinout 0.5s',
    borderRadius: '50%',
    color: 'white',
  },
  playButton: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 80,
    height: 80,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    opacity: 0.9,
    visibility: 'hidden',
    borderRadius: '50%',
    animation: '$fadeinout2 0.5s',
    color: 'white',

  },
  '@keyframes fadeinout': {
    '0%': {
      visibility: 'visible',
      opacity: 0,
      transform: 'translate(-50%,-50%) scale(.8)',

    },

    '12%': {
      opacity: 0.12,

      transform: 'translate(-50%,-50%) scale(.8)',

    },
    //
    '25%': {
      opacity: 0.25,

      transform: 'translate(-50%,-50%) scale(.9)',

    },
    //
    '50%': {
      opacity: 0.5,

      transform: 'translate(-50%,-50%) scale(1)',

    },
    //
    '75%': {
      opacity: 0.75,

      transform: 'translate(-50%,-50%) scale(1.1);',

    },

    to: {
      opacity: 0.8,

      transform: 'translate(-50%,-50%) scale(1.2)',

      visibility: 'hidden',
    },
  },
  '@keyframes fadeinout2': {
    '0%': {
      visibility: 'visible',
      opacity: 0,
      transform: 'translate(-50%,-50%) scale(.8)',

    },

    '12%': {
      opacity: 0.12,

      transform: 'translate(-50%,-50%) scale(.8)',

    },
    //
    '25%': {
      opacity: 0.25,

      transform: 'translate(-50%,-50%) scale(.9)',

    },
    //
    '50%': {
      opacity: 0.5,

      transform: 'translate(-50%,-50%) scale(1)',

    },
    //
    '75%': {
      opacity: 0.75,

      transform: 'translate(-50%,-50%) scale(1.1);',

    },

    to: {
      opacity: 0.8,

      transform: 'translate(-50%,-50%) scale(1.2)',

      visibility: 'hidden',
    },
  },

  titleOverlay: {
    position: 'absolute',
    // top: 0,
    // left: 0,
    width: '100%',
    height: '100%',
    // color: '#bbb',
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '0.5em 1em',
    margin: 0,
    fontFamily: 'sans-serif',
    fontSize: '2em',
    // transition: 'visibility 0.5s, opacity 0.5s, color 0.5s',
    // visibility: 'hidden',
    // opacity: 0,
    cursor: 'pointer',
  },
}));

export default useStyles;
