import { all } from 'redux-saga/effects';
import loginSagas from './login/loginSagas';
import usersSaga from './user/userSagas';
import filesSaga from './files/filesSagas';
import registrationSaga from './registration/registrationSagas';
import emailSaga from './verifyEmail/verifyEmailSagas';
import paymentsSaga from './payments/paymentsSagas';
import contactsSaga from './contacts/contactsSagas';
import userStorageSaga from './userStorage/userStorageSagas';
import viewEffectsSaga from './viewsEffects/viewsEffectsSagas';
import socketsSaga from './sockets/socketsSagas';
import jobsSaga from './jobs/jobsSagas';
import usenetSaga from './usenet/usenetProvidersSagas';
import hydraSearchSaga from './hydraSearch/hydraSearchSagas';
import notificationsSaga from './notifications/notificationsSagas';

export default function* () {
  yield all([
    loginSagas(),
    registrationSaga(),
    usersSaga(),
    filesSaga(),
    emailSaga(),
    paymentsSaga(),
    contactsSaga(),
    userStorageSaga(),
    viewEffectsSaga(),
    socketsSaga(),
    jobsSaga(),
    hydraSearchSaga(),
    usenetSaga(),
    notificationsSaga(),
  ]);
}
