import {
  USER_SET_GOOGLE_CREDENTIALS,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_DATA_SUCCESS,
  USER_SENT_VER_LINK_SUBMIT,
  USER_SENT_VER_LINK_SUCCESS,
  SET_NEW_AVATAR_SUCCESS,
  USER_UPDATE_ANTIVIRUS_SETTINGS_SUCCESS,
  USER_SET_DROPBOX_CREDENTIALS,
  USER_SET_ONEDRIVE_CREDENTIALS,
  USER_UNSET_DROPBOX_CREDENTIALS,
  USER_UNSET_GOOGLE_CREDENTIALS,
  USER_UNSET_ONEDRIVE_CREDENTIALS,
  USER_UPDATE_ENCRYPTION_SETTINGS_SUCCESS,
  SET_WELCOME_MODAL_WIZARD_FINISHED_SUCCESS,
} from './userActions';
import { USER_LOGOUT_SUBMIT } from '../login/loginActions';

const initState = {
  userLoading: true,
  getUserError: false,
  email: '',
  role: '',
  storageLeft: 0,
  emailVerified: false,
  activePlan: {},
  externalStorages: [],
  avatar: {
    createdAt: '2020-05-04T10:12:12.708Z',
    description: 'How To Meet That Special Someone',
    id: 1,
    name: 'Medical sloth',
    updatedAt: '2020-05-04T10:12:12.708Z',
    url: 'https://avatars-321.s3.nl-ams.scw.cloud/medicalmaskslothwithgloves.jpg',
  },
  settings: {
    id: 7,
    createdAt: '2020-10-07T12:06:41.779Z',
    updatedAt: '2020-10-07T12:06:41.779Z',
    antivirusEnabled: false,
    externalStorageEncryptionEnabled: false,
  },
  wizardFinished: true,
  isUsenetConfigExist: true,
  isSubscriptionActive: true,
};

function userReducer(state = initState, { type, payload }) {
  switch (type) {
    case USER_LOGOUT_SUBMIT: {
      return {
        ...initState,
        // userLoading: true,
      };
    }

    case USER_SET_GOOGLE_CREDENTIALS: {
      return {
        ...state,
        userLoading: true,
      };
    }
    case USER_UNSET_GOOGLE_CREDENTIALS: {
      return {
        ...state,
        userLoading: true,
      };
    }

    case USER_SET_DROPBOX_CREDENTIALS: {
      return {
        ...state,
        userLoading: true,
      };
    }
    case USER_UNSET_DROPBOX_CREDENTIALS: {
      return {
        ...state,
        userLoading: true,
      };
    }

    case USER_SET_ONEDRIVE_CREDENTIALS: {
      return {
        ...state,
        userLoading: true,
      };
    }
    case USER_UNSET_ONEDRIVE_CREDENTIALS: {
      return {
        ...state,
        userLoading: true,
      };
    }

    case USER_SENT_VER_LINK_SUBMIT: {
      return {
        ...state,
        userLoading: true,
      };
    }

    case USER_SENT_VER_LINK_SUCCESS: {
      return {
        ...state,
        userLoading: false,
      };
    }
    case SET_WELCOME_MODAL_WIZARD_FINISHED_SUCCESS:
    case GET_USER_DATA_SUCCESS:
    case SET_NEW_AVATAR_SUCCESS:
    case GET_USER_SUCCESS: {
      return {
        ...state,
        userLoading: false,
        ...payload,

        getUserError: false,
      };
    }

    case GET_USER_FAILURE: {
      return {
        ...state,
        userLoading: false,
        getUserError: payload,
      };
    }
    case USER_UPDATE_ENCRYPTION_SETTINGS_SUCCESS:
    case USER_UPDATE_ANTIVIRUS_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: payload,

      };
    }
    default: {
      return state;
    }
  }
}

export default userReducer;
