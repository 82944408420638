import {
  call, put, all, takeLatest, select,
} from 'redux-saga/effects';
import { delay } from '@redux-saga/core/effects';
import {
  SET_HDR_SEARCH_QUERY,
  hdrSearchStart,
  hdrSearchFinish,
  hdrSearchFail,
  hdrSearchGetIndexersFail,
  hdrSearchGetIndexersSuccess,
  HDR_SEARCH_GET_INDEXERS_SUBMIT,
  HDR_ADD_INDEXER_SUBMIT,
  hdrSearchAddIndexerSuccess,
  hdrSearchAddIndexerFail,
  hdrSearchDeleteIndexerFail,
  hdrSearchDeleteIndexerSuccess,
  HDR_DELETE_INDEXER_SUBMIT,
} from './hydraSearchActions';
import hdrSearchService from '../../services/hdrSearchService';
import { HDR_SEARCH_READY } from '../sockets/socketsActions';

function* getSearchResults() {
  const { searchQuery } = yield select(state => state.hydraSearch);
  if (searchQuery) {
    try {
      yield put(hdrSearchStart());
      const result = yield call(hdrSearchService.getSearchResults, searchQuery);
      if (result.error && result.status === 'NZB_SEARCHER_STATUS_ERROR') {
        yield put(hdrSearchFail('NZB_SEARCHER_STATUS_ERROR'));
      } else yield put(hdrSearchFinish(result));
    } catch (error) {
      const errorMessage = error.response || error;
      yield put(hdrSearchFail(errorMessage));
    }
  }
}
function* getHdrSearchIndexers() {
  try {
    const result = yield call(hdrSearchService.getIndexers);
    if (result.error && result.status === 'NZB_SEARCHER_STATUS_ERROR') {
      yield put(hdrSearchGetIndexersFail('NZB_SEARCHER_STATUS_ERROR'));
    } else yield put(hdrSearchGetIndexersSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(hdrSearchGetIndexersFail(errorMessage));
  }
}

function* addHdrSearchIndexer({ payload }) {
  try {
    const result = yield call(hdrSearchService.addIndexer, payload);
    if (result.error || result.status || result.message) {
      yield put(hdrSearchAddIndexerFail(result.message));
    } else yield put(hdrSearchAddIndexerSuccess(result));
  } catch (error) {
    const { message } = error.response || error;
    yield put(hdrSearchAddIndexerFail(message));
  }
}

function* deleteHdrSearchIndexer({ payload }) {
  try {
    const result = yield call(hdrSearchService.deleteIndexer, payload);
    if (result.error && result.status) {
      yield put(hdrSearchDeleteIndexerFail(result.message));
    } else yield put(hdrSearchDeleteIndexerSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(hdrSearchDeleteIndexerFail(errorMessage));
  }
}

export default function* hydraSearchSaga() {
  yield all([takeLatest(SET_HDR_SEARCH_QUERY, getSearchResults)]);
  yield all([
    takeLatest(
      [HDR_SEARCH_GET_INDEXERS_SUBMIT, HDR_SEARCH_READY],
      getHdrSearchIndexers,
    ),
  ]);
  yield all([takeLatest(HDR_ADD_INDEXER_SUBMIT, addHdrSearchIndexer)]);
  yield all([takeLatest(HDR_DELETE_INDEXER_SUBMIT, deleteHdrSearchIndexer)]);
}
