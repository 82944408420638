import axios from 'axios';
import { store } from '../config/store';
import { USER_LOGOUT_SUBMIT } from '../redux/login/loginActions';
import appConsts from '../config/appConsts';
import getAuthHeader from './getAuthHeader';

const LOGIN_URL = '/login';

class Api {
  constructor() {
    this.adapter = axios.create({

      baseURL: appConsts.API_URL,
      crossdomain: true,
    });

    this.adapter.interceptors.response.use(undefined, (error) => {
      console.log(error.response);
      if (error.response && error.response.data.statusCode === 401) {
        const { data } = error.response;
        const url = data ? data.path : '';

        if (url !== LOGIN_URL) {
          localStorage.removeItem('@accessToken');
          // store.dispatch({ type: USER_LOGOUT_SUBMIT });
          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
      return Promise.reject(error);
    });
  }

  async sendRequest(url, type, payload, params, headers) {
    const authHeader = getAuthHeader();

    return this.adapter.request({
      url,
      method: type.toUpperCase(),
      data: payload,
      headers: {
        ...authHeader,
        ...headers,
      },
      params,
    });
  }

  handleApiError(err) {
    if (err.response && err.response.data) {
      console.log(err.response.data);
      Promise.reject(new Error(err.response.data));
    }

    return Promise.reject(new Error(err.message));
  }
}

export default new Api();
