import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { Fade } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  registrationSubmit,
  setRegistrationStep,
} from '../../../redux/registration/registrationActions';
import Copyright from '../../Copyright';
import useStyles from '../styles';
import { loginSubmit } from '../../../redux/login/loginActions';
import { getExternalDrivesListSubmit } from '../../../redux/userStorage/userStorageActions';

const validationSchemaStep0 = Yup.object().shape({
  email: Yup.string()
    .email('Use only a valid email address. “Valid” means “correct”.')
    .required('Please enter email'),
});

const validationSchemaStep1 = Yup.object().shape({
  email: Yup.string()
    .email('Use only a valid email address. “Valid” means “correct”.')
    .required('Please enter email'),
  password: Yup.string()
    .label('Password')
    .min(6)
    .required('Enter password from email.'),
});
export default function SignUp(props) {
  const classes = useStyles();
  const store = useStore();
  const { auth, registration } = store.getState();
  const { registrationStep, registrationLoading } = registration
  const dispatch = useDispatch();
  const { history } = props;
  const [showPasswordValue, setFormShowPassword] = React.useState({
    showPassword: false,
  });
  useEffect(() => {
    dispatch(setRegistrationStep(0));
  }, []);
  useEffect(() => {
    if (auth.isAuthenticated) {
      const { state } = history.location;
      if (state && state.goBack && !state.goBack.includes('/login')) {
        history.push(state.goBack);
      } else { history.push('dashboard/downloads'); }
    }
  }, [auth.isAuthenticated, dispatch, history]);
  const handleClickShowPassword = () => {
    setFormShowPassword({ showPassword: !showPasswordValue.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleFormSubmit = (valuesFromForm) => {
    const { email } = valuesFromForm;

    if (!registrationStep) {
      dispatch(registrationSubmit(email));
    } else {
      dispatch(loginSubmit(valuesFromForm));
    }
  };

  return (
    <div className={classes.paper}>
      <Typography className={classes.loginTitle}>Sign up</Typography>
      <Typography className={classes.loginCaption}>
        Create account now and download to a secured cloud.
      </Typography>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={
          !registrationStep ? validationSchemaStep0 : validationSchemaStep1
        }
        onSubmit={values => handleFormSubmit(values)}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
        }) => (
          <>
            <form>
              <div className={classes.formInput}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={!!(touched.email && errors.email)}
                  autoFocus
                  value={values.email}
                  onChange={handleChange('email')}
                />
                <div style={{ color: 'red' }}>
                  {touched.email && errors.email}
                </div>
              </div>
              <Fade in={Boolean(registrationStep)} timeout={1000}>
                <div className={classes.formInput}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      error={!!(touched.password && errors.password)}
                      classes={{ focused: classes.inputLabel }}
                      htmlFor="outlined-adornment-password"
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={
                        showPasswordValue.showPassword ? 'text' : 'password'
                      }
                      value={values.password}
                      name="password"
                      fullWidth
                      error={!!(touched.password && errors.password)}
                      onChange={handleChange('password')}
                      autoComplete="off"
                      endAdornment={(
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordValue.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )}
                      labelWidth={70}
                    />
                    <div
                      style={{
                        color:
                          touched.password && errors.password
                            ? 'red'
                            : 'inherit',
                        marginTop: 8,
                        marginBottom: 8,
                      }}
                    >
                      {touched.password && errors.password
                        ? touched.password && errors.password
                        : 'We have sent you an email with your password. Paste it in a field above'}
                    </div>
                  </FormControl>
                </div>
              </Fade>

              {/* <div className={classes.formInput}> */}
              {/*  <FormControl fullWidth variant="outlined"> */}
              {/*    <InputLabel */}
              {/*      error={ */}
              {/*        !!(touched.confirmPassword && errors.confirmPassword) */}
              {/*      } */}
              {/*      classes={{ */}
              {/*        focused: classes.inputLabelFocus, */}
              {/*        root: classes.inputLabel, */}
              {/*      }} */}
              {/*      htmlFor="outlined-adornment-confirm-password" */}
              {/*    > */}
              {/*      Confirm Password */}
              {/*    </InputLabel> */}
              {/*    <OutlinedInput */}
              {/*      id="outlined-adornment-confirm-password" */}
              {/*      type={showPasswordValue.showPassword ? 'text' : 'password'} */}
              {/*      value={values.confirmPassword} */}
              {/*      error={ */}
              {/*        !!(touched.confirmPassword && errors.confirmPassword) */}
              {/*      } */}
              {/*      name="confirmPassword" */}
              {/*      fullWidth */}
              {/*      onChange={handleChange('confirmPassword')} */}
              {/*      autoComplete="new-password" */}
              {/*      endAdornment={( */}
              {/*        <InputAdornment position="end"> */}
              {/*          <IconButton */}
              {/*            aria-label="toggle password visibility" */}
              {/*            onClick={handleClickShowPassword} */}
              {/*            onMouseDown={handleMouseDownPassword} */}
              {/*            edge="end" */}
              {/*          > */}
              {/*            {showPasswordValue.showPassword ? ( */}
              {/*              <Visibility /> */}
              {/*            ) : ( */}
              {/*              <VisibilityOff /> */}
              {/*            )} */}
              {/*          </IconButton> */}
              {/*        </InputAdornment> */}
              {/*      )} */}
              {/*      labelWidth={70} */}
              {/*    /> */}
              {/*    <div style={{ color: 'red', marginTop: 8 }}> */}
              {/*      {touched.confirmPassword && errors.confirmPassword} */}
              {/*    </div> */}
              {/*  </FormControl> */}
              {/* </div> */}
              <div style={{ height: 4 }}>
                {registrationLoading && <LinearProgress />}
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
                style={{ boxShadow: 'none' }}
              >
                Create Account
              </Button>
            </form>
          </>
        )}
      </Formik>
      <Grid container>
        <Grid item xs />
        <Grid item>
          <Link
            component="button"
            onClick={() => history.push('/login')}
            variant="body2"
            className={classes.linksOpt}
          >
            Already registered?
          </Link>
        </Grid>
      </Grid>
      <Box mt={5}>
        <Copyright />
      </Box>
    </div>
  );
}
