import React, { useEffect, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

import {
  getExternalDrivesListSubmit,
  setBrowseStorageTypeSubmit,
} from '../../../../redux/userStorage/userStorageActions';
import { setDashboardTab } from '../../../../redux/viewsEffects/viewsEffectsActions';
import storageOptions from '../../../../helpers/storageIconOptions';
import formatBytes from '../../../../helpers/formatBytes';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  controlRowContainer: {
    display: 'flex',
    // width: '100%',
    // paddingTop: 7,
  },
  fileTypeTitle: {
    color: 'rgba(0,0,0,0.6)',
    // fontFamily: 'Roboto, san-serif',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
  },
  foldersContainer: {
    display: 'flex',
    marginTop: 12,
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      // marginBottom: 150,
    },
  },
  filesContainer: {
    display: 'flex',
    marginTop: 12,
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      justifyContent: 'space-between',
      marginBottom: 200,
    },
  },
  gridHeadersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileViewTypeContainer: {
    // borderLeft: '1px solid rgba(0,0,0,0.1)',
    paddingLeft: 10,
  },
  menuIcon: {
    width: 25,
    height: 25,
    minWidth: 25,
    marginRight: 5,
  },
  menuItem: {
    borderBottom: '1px solid #F7F7F7',
    borderRadius: 2,

  },
  menuItemCaption: {
    padding: 10,
    textAlign: 'center',
    maxWidth: 200,
    whiteSpace: 'normal',
    borderRadius: 2,
  },
}));

export default function BrowseStorageSelector(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { externalStorages = [{ type: '' }], targetStorage = 'NZBCLOUD' } = useSelector(
    state => state.userStorage,
  );
  const { storageLeft } = useSelector(state => state.user);
  const { storageIsLoading } = useSelector(state => state.viewsEffects);
  const [storageSelectorOpen, setStorageSelectorOpen] = React.useState({
    anchorEl: null,
  });
  const getSelectedStorageObject = () => externalStorages.find(storage => storage.type === targetStorage);

  const selectedStorageObj = useMemo(() => getSelectedStorageObject(), [
    getSelectedStorageObject,
    targetStorage,
  ]);
  const calculateStorageLeft = () => {
    const value = selectedStorageObj
      ? selectedStorageObj?.totalSpace - selectedStorageObj?.usedSpace
      : storageLeft * 1024 * 1024;

    if (value > 0) {
      return formatBytes(value);
    } return 'exceeded';
  };
  const handleClick = (event) => {
    setStorageSelectorOpen({ anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setStorageSelectorOpen({ anchorEl: null });
  };
  const handleSelectStorageMenuItemClick = (drive) => {
    dispatch(setBrowseStorageTypeSubmit(drive.type));
    handleClose();
  };
  useEffect(() => {
    dispatch(getExternalDrivesListSubmit());
  }, [dispatch]);
  const setTab = () => {
    handleClose();
    window.history.replaceState(
      { path: '/dashboard/settings/external-storages' },
      '',
      '/dashboard/settings/external-storages',
    );

    dispatch(setDashboardTab(2));
  };
  return (
    <div className={classes.controlRowContainer}>
      <div className="step4">
        <Button
          aria-owns={storageSelectorOpen ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={handleClick}
          style={{ textTransform: 'none', borderRadius: 2 }}
          startIcon={(
            <div
              style={{
                width: 40,
                height: 40,
                backgroundColor: '#FFFFFF',
                border: '1px solid #EDEDED',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 2,
                display: 'flex',
                padding: 7,
              }}
            >
              {storageIsLoading ? (
                <CircularProgress disableShrink size={18} />
              ) : (
                storageOptions[targetStorage].icon
              )}
            </div>
          )}
          endIcon={(
            <div style={{ width: 25, height: 25 }}>
              {storageSelectorOpen.anchorEl ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon />
              )}
            </div>
          )}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'baseline',
              width: 130,
            }}
          >
            <div
              style={{
                fontSize: 10,
                fontWeight: 500,
                letterSpacing: 1.5,
                color: 'rgba(0, 0, 0, 0.6)',
                width: '100%',
                textAlign: 'left',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div> STORAGE </div>
              <div style={{ whiteSpace: 'nowrap' }}>
                {calculateStorageLeft()}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: 24,
                alignItems: 'center',
              }}
            >
              {storageIsLoading ? (
                <LinearProgress style={{ width: '100%' }} />
              ) : (
                storageOptions[targetStorage].name
              )}
            </div>
          </div>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={storageSelectorOpen.anchorEl}
          open={Boolean(storageSelectorOpen.anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          getContentAnchorEl={null}
        >
          <MenuItem
            onClick={() => (targetStorage !== 'NZBCLOUD'
              ? handleSelectStorageMenuItemClick({ type: 'NZBCLOUD' })
              : null)
            }
            className={classes.menuItem}
          >
            <ListItemIcon className={classes.menuIcon}>
              {storageOptions.NZBCLOUD.icon}
            </ListItemIcon>
            <ListItemText primary={storageOptions.NZBCLOUD.name} />
          </MenuItem>
          {Array.isArray(externalStorages) && externalStorages.length ? (
            externalStorages.map(drive => (
              <MenuItem
                key={drive._id}
                onClick={() => handleSelectStorageMenuItemClick(drive)}
                className={classes.menuItem}
              >
                <ListItemIcon className={classes.menuIcon}>
                  {storageOptions[drive.type].icon}
                </ListItemIcon>
                <ListItemText primary={storageOptions[drive.type].name} />
              </MenuItem>
            ))
          ) : (
            <MenuItem
              className={classes.menuItemCaption}
              onClick={() => setTab()}
            >
              <span style={{ fontSize: 12, fontWeight: 400 }}>
                You can add third-party storage in
                {' '}
                <span style={{ color: '#0086FA' }}>Settings</span>
              </span>
            </MenuItem>
          )}
        </Menu>
      </div>
    </div>
  );
}
