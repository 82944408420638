import React from 'react';
import Tour from 'reactour';
import './styles.css';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

class IntroTour extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // stepsEnabled: window.location.pathname === '/dashboard/downloads',
      initialStep: 0,
      steps: [
        {
          selector: '.step1',
          content: 'Welcome, Traveler. From this point your journey with NZBCLOUD begins. Follow my guidance...',
        },
        {
          selector: '.step2',
          content: 'This is your Dashboard where you can operate with Files and Downloads',
        },
        {
          selector: '.step3',
          content: 'Main controls are here..',
        },
        {
          selector: '.step4',
          content: 'Storage Selector - place where you can put downloads, external storages could be added via Settings',
        },
        {
          selector: '.step5',
          content: 'Buttons for uploading NZB files and also Links are here..',
        },
        {
          selector: '.step6',
          content: 'So may your journey begins with a small step, lets put some credentials for your NZB provider ',
        },
        {
          selector: '.step7',
          content: `Just click here, and Good Luck!...${String.fromCodePoint(0x1F680)}`,
        },
      ],

    };
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
    localStorage.setItem('intro', 'true');
  };

  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  isStepsEnabled = () => {
    const { width } = this.props
    const isClosed = localStorage.getItem('intro');
    const isMobile = isWidthDown('sm', width);
    return Boolean(!isClosed && window.location.pathname === '/dashboard/downloads' && !isMobile);

  }

  render() {
    const {

      steps,

    } = this.state;

    return (

      // <Steps
      //   enabled={stepsEnabled}
      //   steps={steps}
      //   initialStep={initialStep}
      //   onExit={this.onExit}
      // />
      <Tour
        steps={steps}
        rounded={5}
        accentColor="#0086fa"
        isOpen={this.isStepsEnabled()}
        onRequestClose={this.onExit}
      />

    );
  }
}
export default compose(

  withWidth(),

)(IntroTour);
