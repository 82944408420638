import { toast } from 'react-toastify';

export const GET_USER_STORAGE_SUBMIT = 'GET_USER_STORAGE_SUBMIT';
export const GET_USER_STORAGE_SUCCESS = 'GET_USER_STORAGE_SUCCESS';
export const GET_USER_STORAGE_FAILURE = 'GET_USER_STORAGE_FAILURE';
export const STORAGE_SELECT_FOLDER = 'STORAGE_SELECT_FOLDER';
export const GET_STORAGE_FOLDER_DATA_SUBMIT = 'GET_STORAGE_FOLDER_DATA_SUBMIT';
export const GET_STORAGE_FOLDER_DATA_SUCCESS = 'GET_STORAGE_FOLDER_DATA_SUCCESS';
export const GET_STORAGE_FOLDER_DATA_FAILURE = 'GET_STORAGE_FOLDER_DATA_FAILURE';

export const GET_STORAGE_ROOT_FOLDER_DATA_SUBMIT = 'GET_STORAGE_ROOT_FOLDER_DATA_SUBMIT';
export const GET_STORAGE_ROOT_FOLDER_DATA_SUCCESS = 'GET_STORAGE_ROOT_FOLDER_DATA_SUCCESS';
export const GET_STORAGE_ROOT_FOLDER_DATA_FAILURE = 'GET_STORAGE_ROOT_FOLDER_DATA_FAILURE';

export const STORAGE_GET_FOLDER_ARCHIVE_SUBMIT = 'STORAGE_GET_FOLDER_ARCHIVE_SUBMIT';
export const STORAGE_GET_FOLDER_ARCHIVE_SUCCESS = 'STORAGE_GET_FOLDER_ARCHIVE_SUCCESS';
export const STORAGE_GET_FOLDER_ARCHIVE_FAILURE = 'STORAGE_GET_FOLDER_ARCHIVE_FAILURE';

export const DELETE_STORAGE_ROOT_FOLDER_SUBMIT = 'DELETE_STORAGE_ROOT_FOLDER_SUBMIT';
export const DELETE_STORAGE_ROOT_FOLDER_SUCCESS = 'DELETE_STORAGE_ROOT_FOLDER_SUCCESS';
export const DELETE_STORAGE_ROOT_FOLDER_FAILURE = 'DELETE_STORAGE_ROOT_FOLDER_FAILURE';

export const RENAME_STORAGE_ROOT_FOLDER_SUBMIT = 'RENAME_STORAGE_ROOT_FOLDER_SUBMIT';
export const RENAME_STORAGE_ROOT_FOLDER_SUCCESS = 'RENAME_STORAGE_ROOT_FOLDER_SUCCESS';
export const RENAME_STORAGE_ROOT_FOLDER_FAILURE = 'RENAME_STORAGE_ROOT_FOLDER_FAILURE';

export const STORAGE_MEDIA_TRANSCODING_SUBMIT = 'STORAGE_MEDIA_TRANSCODING_SUBMIT';
export const STORAGE_MEDIA_TRANSCODING_SUCCESS = 'STORAGE_MEDIA_TRANSCODING_SUCCESS';
export const STORAGE_MEDIA_TRANSCODING_FAILURE = 'STORAGE_MEDIA_TRANSCODING_FAILURE';

export const GET_EXTERNAL_DRIVES_LIST_SUBMIT = 'GET_EXTERNAL_DRIVES_LIST_SUBMIT';
export const GET_EXTERNAL_DRIVES_LIST_SUCCESS = 'GET_EXTERNAL_DRIVES_LIST_SUCCESS';
export const GET_EXTERNAL_DRIVES_LIST_FAILURE = 'GET_EXTERNAL_DRIVES_LIST_FAILURE';

export const SET_DOWNLOAD_DESTINATION_SUBMIT = 'SET_DOWNLOAD_DESTINATION_SUBMIT';
export const SET_DOWNLOAD_DESTINATION_SUCCESS = 'SET_DOWNLOAD_DESTINATION_SUCCESS ';
export const SET_DOWNLOAD_DESTINATION_FAILURE = 'SET_DOWNLOAD_DESTINATION_FAILURE ';

export const SET_BROWSE_STORAGE_TYPE_SUBMIT = 'SET_BROWSE_STORAGE_TYPE_SUBMIT';
export const SET_BROWSE_STORAGE_TYPE_SUCCESS = 'SET_BROWSE_STORAGE_TYPE_SUCCESS ';
export const SET_BROWSE_STORAGE_TYPE_FAILURE = 'SET_BROWSE_STORAGE_TYPE_FAILURE ';

export const PURGE_EXTERNAL_DRIVE_SUBMIT = 'PURGE_EXTERNAL_DRIVE_SUBMIT';
export const PURGE_EXTERNAL_DRIVE_SUCCESS = 'PURGE_EXTERNAL_DRIVE_SUCCESS ';
export const PURGE_EXTERNAL_DRIVE_FAILURE = 'PURGE_EXTERNAL_DRIVE_FAILURE ';


export const purgeExternalDriveSubmit = payload => ({
  type: PURGE_EXTERNAL_DRIVE_SUBMIT,
  payload,
});
export const purgeExternalDriveFailure = () => ({
  type: PURGE_EXTERNAL_DRIVE_FAILURE,
});
export const purgeExternalDriveSuccess = payload => ({
  type: PURGE_EXTERNAL_DRIVE_SUCCESS,
  payload,
});

export const onMediaTranscodingSubmit = payload => ({
  type: STORAGE_MEDIA_TRANSCODING_SUBMIT,
  payload,
});
export const onMediaTranscodingFailure = () => ({
  type: STORAGE_MEDIA_TRANSCODING_FAILURE,
});
export const onMediaTranscodingSuccess = (payload) => {
  toast.success('Transcoding started');
  return {
    type: STORAGE_MEDIA_TRANSCODING_SUCCESS,
    payload,
  };
};
export const onDeleteRootFolderSubmit = payload => ({
  type: DELETE_STORAGE_ROOT_FOLDER_SUBMIT,
  payload,
});
export const deleteRootFolderFailure = () => ({
  type: DELETE_STORAGE_ROOT_FOLDER_FAILURE,
});
export const deleteRootFolderSuccess = payload => ({
  type: DELETE_STORAGE_ROOT_FOLDER_SUCCESS,
  payload,
});

export const onRenameRootFolderSubmit = payload => ({
  type: RENAME_STORAGE_ROOT_FOLDER_SUBMIT,
  payload,
});
export const renameRootFolderFailure = () => ({
  type: RENAME_STORAGE_ROOT_FOLDER_FAILURE,
});
export const renameRootFolderSuccess = payload => ({
  type: RENAME_STORAGE_ROOT_FOLDER_SUCCESS,
  payload,
});


export const onStorageSelectFolder = payload => ({
  type: STORAGE_SELECT_FOLDER,
  payload,
});
export const getUserStorageSubmit = () => ({
  type: GET_USER_STORAGE_SUBMIT,
});
export const getUserStorageSuccess = payload => ({
  type: GET_USER_STORAGE_SUCCESS,
  payload,
});

export const getUserStorageFailure = () => ({
  type: GET_USER_STORAGE_FAILURE,
});
export const getStorageFolderDataSubmit = () => ({
  type: GET_STORAGE_FOLDER_DATA_SUBMIT,
});
export const getStorageFolderDataSuccess = payload => ({
  type: GET_STORAGE_FOLDER_DATA_SUCCESS,
  payload,
});
export const getStorageFolderDataFailure = () => ({
  type: GET_STORAGE_FOLDER_DATA_FAILURE,
});

export const getStorageRootFolderDataSubmit = payload => ({
  type: GET_STORAGE_ROOT_FOLDER_DATA_SUBMIT,
  payload,
});
export const getStorageRootFolderDataSuccess = payload => ({
  type: GET_STORAGE_ROOT_FOLDER_DATA_SUCCESS,
  payload,
});
export const getStorageRootFolderDataFailure = () => ({
  type: GET_STORAGE_ROOT_FOLDER_DATA_FAILURE,
});

export const getStorageFolderArchiveSubmit = payload => ({
  type: STORAGE_GET_FOLDER_ARCHIVE_SUBMIT,
  payload,
});
export const getStorageFolderArchiveSuccess = () => ({
  type: STORAGE_GET_FOLDER_ARCHIVE_SUBMIT,
});
export const getStorageFolderArchiveFailure = () => ({
  type: STORAGE_GET_FOLDER_ARCHIVE_SUBMIT,
});

export const getExternalDrivesListSubmit = () => ({
  type: GET_EXTERNAL_DRIVES_LIST_SUBMIT,
});
export const getExternalDrivesListSuccess = payload => ({
  type: GET_EXTERNAL_DRIVES_LIST_SUCCESS,
  payload,
});

export const getExternalDrivesListFailure = payload => ({
  type: GET_EXTERNAL_DRIVES_LIST_FAILURE,
  payload,
});


export const setDownloadDestinationSubmit = payload => ({
  type: SET_DOWNLOAD_DESTINATION_SUBMIT,
  payload,
});
export const setDownloadDestinationSuccess = payload => ({
  type: SET_DOWNLOAD_DESTINATION_SUCCESS,
  payload,
});

export const setDownloadDestinationFailure = () => ({
  type: SET_DOWNLOAD_DESTINATION_FAILURE,
});

export const setBrowseStorageTypeSubmit = payload => ({
  type: SET_BROWSE_STORAGE_TYPE_SUBMIT,
  payload,
});
export const setBrowseStorageTypeSuccess = payload => ({
  type: SET_BROWSE_STORAGE_TYPE_SUCCESS,
  payload,
});

export const setBrowseStorageTypeFailure = () => ({
  type: SET_BROWSE_STORAGE_TYPE_SUCCESS,
});
