import {
  USENET_ADD_CONFIG_SUBMIT,
  USENET_ADD_CONFIG_SUCCESS, USENET_DELETE_CONFIG_SUCCESS, USENET_EDIT_CONFIG_SUCCESS,
  USENET_GET_PROVIDER_CREDENTIALS_FAIL,
  USENET_GET_PROVIDER_CREDENTIALS_SUBMIT,
  USENET_GET_PROVIDER_CREDENTIALS_SUCCESS,
  USENET_INVALID_CREDENTIALS,
  USENET_VALIDATE_PROVIDER_CREDENTIALS_FAIL,
  USENET_VALIDATE_PROVIDER_CREDENTIALS_SUBMIT,
  USENET_VALIDATE_PROVIDER_CREDENTIALS_SUCCESS,
} from './usenetProvidersActions';

const initState = {
  credentials: [{
    serverHost: '',
    serverPassword: '',
    serverPort: '',
    serverUsername: '',
    serverConnections: '',
    validationStatus: false,
  }],
  isLoading: false,
  validationPassed: false,
  usenetModalIsLoading: false,
  isDisabled: true,
  usenetError: '',
};

function usenetProvidersSettingsReducer(state = initState, { type, payload }) {
  switch (type) {
    case USENET_VALIDATE_PROVIDER_CREDENTIALS_SUBMIT: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case USENET_VALIDATE_PROVIDER_CREDENTIALS_SUCCESS: {
      const { validationStatus } = payload;
      return {
        ...state,
        isLoading: false,
        validationPassed: validationStatus,
      };
    }
    case USENET_VALIDATE_PROVIDER_CREDENTIALS_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case USENET_GET_PROVIDER_CREDENTIALS_SUBMIT: {
      return {
        ...state,
        isLoading: true,
        usenetError: '',
      };
    }
    case USENET_DELETE_CONFIG_SUCCESS:
    case USENET_EDIT_CONFIG_SUCCESS:
    case USENET_ADD_CONFIG_SUCCESS:
    case USENET_GET_PROVIDER_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        credentials: payload,
        isLoading: false,
        usenetModalIsLoading: false,
      };
    }
    case USENET_ADD_CONFIG_SUBMIT: {


      return {
        ...state,
        usenetModalIsLoading: true,
      };
    }
    case USENET_GET_PROVIDER_CREDENTIALS_FAIL: {
      return {
        ...state,
        isLoading: false,
        usenetError: payload,
      };
    }
    case USENET_INVALID_CREDENTIALS: {


      return {
        ...state,
        credentials: payload,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

export default usenetProvidersSettingsReducer;
