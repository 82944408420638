import {
  HDR_ADD_INDEXER_FAIL,
  HDR_ADD_INDEXER_SUBMIT, HDR_ADD_INDEXER_SUCCESS, HDR_DELETE_INDEXER_SUCCESS,
  HDR_SEARCH_GET_INDEXERS_SUBMIT,
  HDR_SEARCH_GET_INDEXERS_SUCCESS,
} from './hydraSearchActions';
import { HDR_SEARCH_READY } from '../sockets/socketsActions';

const initState = {
  indexers: [],
  isLoading: false,
  addIndexerModalIsLoading: false,
  isDisabled: true,
  hdrError: '',
};

function hydraSearchSettingsReducer(state = initState, { type, payload }) {
  switch (type) {
    case HDR_SEARCH_GET_INDEXERS_SUBMIT: {
      return {
        ...state,
        isDisabled: true,
        hdrError: '',
      };
    }
    case HDR_ADD_INDEXER_SUBMIT: {
      return {
        ...state,
        addIndexerModalIsLoading: true,
        hdrError: '',
      };
    }
    case HDR_ADD_INDEXER_SUCCESS:
    case HDR_DELETE_INDEXER_SUCCESS: {
      return {
        ...state,
        addIndexerModalIsLoading: false,
        indexers: payload,
      };
    }
    case HDR_ADD_INDEXER_FAIL: {
      return {
        ...state,
        addIndexerModalIsLoading: false,
        hdrError: payload,
      };
    }
    case HDR_SEARCH_GET_INDEXERS_SUCCESS: {
      return {
        ...state,
        indexers: payload,
        isDisabled: false,
      };
    }
    case HDR_SEARCH_READY: {
      return {
        ...initState,
        isDisabled: false,
      };
    }
    default: {
      return state;
    }
  }
}

export default hydraSearchSettingsReducer;
