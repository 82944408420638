import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ServicePageHeaders from '../ServicePageHeaders';
import { contactsSendMessageSubmit } from '../../redux/contacts/contactsActions';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#2158eb',
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8,
      paddingRight: 8,
      height: '130%',
    },
  },
  formInput: {
    '& fieldset': {
      borderRadius: 2,
    },
  },
}));
const PageName = 'Contacts';
const BoldHeader = 'Need help?';
const Subtitle = '';
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Use only a valid email address. “Valid” means “correct”.')
    .required('Please enter email'),
  message: Yup.string()
    .label('Message')
    .min(10, 'Don’t be shy, tell us more.')
    .required('We appreciate your feedback. Tell us everything.'),
});
export default function Contacts() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleFormSubmit = (valuesFromForm) => {
    dispatch(contactsSendMessageSubmit(valuesFromForm));
  };

  const preventDefault = event => event.preventDefault();
  return (
    <>
      <CssBaseline />
      <Container className={classes.container} component="main" maxWidth="sm">
        <ServicePageHeaders
          pageName={PageName}
          boldHeader={BoldHeader}
          subtitle={Subtitle}
        />

        <Paper elevation={0}>
          <div style={{ padding: 32 }}>
            <Typography
              variant="h6"
              style={{ fontWeight: 500, marginTop: 10, marginBottom: 10 }}
            >
              Contacts
            </Typography>
            {/* <div style={{ display: 'flex' }}> */}
            {/*  <div style={{ width: '50%' }}> */}
            {/*    <Typography */}
            {/*      variant="subtitle2" */}
            {/*      style={{ fontWeight: 500, marginTop: 10, marginBottom: 10 }} */}
            {/*    > */}
            {/*      Address */}
            {/*    </Typography> */}
            {/*    <Typography */}
            {/*      variant="subtitle2" */}
            {/*      style={{ */}
            {/*        fontWeight: 400, */}
            {/*        marginTop: 10, */}
            {/*        marginBottom: 10, */}
            {/*        color: 'rgba(0,0,0,0.87)', */}
            {/*      }} */}
            {/*    > */}
            {/*      20 Williamson Garden Apt. 713 */}
            {/*    </Typography> */}
            {/*    <Typography */}
            {/*      variant="subtitle2" */}
            {/*      style={{ */}
            {/*        fontWeight: 400, */}
            {/*        marginTop: 10, */}
            {/*        marginBottom: 10, */}
            {/*        color: 'rgba(0,0,0,0.87)', */}
            {/*      }} */}
            {/*    > */}
            {/*      Amsterdam, Netherland */}
            {/*    </Typography> */}
            {/*  </div> */}
            {/*  <div style={{ width: '50%' }}> */}
            {/*    <Typography */}
            {/*      variant="subtitle2" */}
            {/*      style={{ fontWeight: 500, marginTop: 10, marginBottom: 10 }} */}
            {/*    > */}
            {/*      Phone */}
            {/*    </Typography> */}
            {/*    <div style={{ fontWeight: 500 }}> */}
            {/*      <Link */}
            {/*        style={{ */}
            {/*          display: 'flex', */}
            {/*          alignItems: 'center', */}
            {/*          color: '#0086FA', */}
            {/*          fontWeight: 400, */}
            {/*        }} */}
            {/*        underline="none" */}
            {/*        href="tel:123-456-7890" */}
            {/*        onClick={preventDefault} */}
            {/*      > */}
            {/*        <PhoneIcon color="primary" style={{ color: '#0086FA' }} /> */}
            {/*        123-456-7890 */}
            {/*      </Link> */}
            {/*      <Link */}
            {/*        style={{ */}
            {/*          display: 'flex', */}
            {/*          alignItems: 'center', */}
            {/*          marginTop: 13, */}
            {/*          color: '#0086FA', */}
            {/*          fontWeight: 400, */}
            {/*        }} */}
            {/*        underline="none" */}
            {/*        href="tel:123-456-7890" */}
            {/*        onClick={preventDefault} */}
            {/*      > */}
            {/*        <PhoneIcon color="primary" style={{ color: '#0086FA' }} /> */}
            {/*        123-456-7890 */}
            {/*      </Link> */}
            {/*    </div> */}
            {/*  </div> */}
            {/* </div> */}
          </div>
          <Divider />
          <Formik
            initialValues={{ email: '', message: '' }}
            validationSchema={validationSchema}
            onSubmit={values => handleFormSubmit(values)}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
            }) => (
              <>
                <form className={classes.form}>
                  <div style={{ padding: '5px 32px 32px 32px' }}>
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 500,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      Drop Us a Letter
                    </Typography>
                    <div className={classes.formInput}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        error={!!(touched.email && errors.email)}
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={values.email}
                        onChange={handleChange('email')}
                      />
                      <div style={{ color: 'red' }}>
                        {touched.email && errors.email}
                      </div>
                    </div>
                    <div className={classes.formInput}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        error={!!(touched.message && errors.message)}
                        name="message"
                        label="Message"
                        type="password"
                        id="message"
                        multiline
                        rows="4"
                        value={values.message}
                        onChange={handleChange('message')}
                      />
                      <div style={{ color: 'red' }}>
                        {touched.message && errors.message}
                      </div>
                    </div>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleSubmit}
                      disableElevation
                    >
                      Send
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </Paper>
      </Container>
    </>
  );
}
