import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import UnderConstruction from '../atoms/UnderConstruction/UnderConstruction.svg';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: 32,
    borderRadius: 2,
  },
  settingsTab: {
    textTransform: 'none',
  },
  accountInput: {
    width: '30%',
  },
  textField: {
    width: '30%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  emptyWrapperTab: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#f3f3f3',
    width: 420,
    height: 420,
    justifyContent: 'center',
    borderRadius: '50%',
    marginTop: 100,
    marginBottom: 70,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      backgroundColor: 'inherit',
      borderRadius: 0,
      marginBottom: 0,
    },
  },
});

class PaymentsTab extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={classes.emptyWrapperTab}>
          <img src={UnderConstruction} alt="Empty" />
          <div
            style={{
              color: 'rgba(0,0,0,0.6)',
              marginTop: 20,
              marginBottom: 20,
              fontWeight: 500,
            }}
          >
            Under construction
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(withStyles(styles)(PaymentsTab));
