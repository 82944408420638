export const GET_ACTIVE_JOBS_SUBMIT = 'GET_ACTIVE_JOBS_SUBMIT';
export const GET_ACTIVE_JOBS_SUCCESS = 'GET_ACTIVE_JOBS_SUCCESS';
export const GET_ACTIVE_JOBS_FAILURE = 'GET_ACTIVE_JOBS_FAILURE';


export const CLEAR_FINISHED_JOBS_SUBMIT = 'CLEAR_FINISHED_JOBS_SUBMIT';
export const CLEAR_FINISHED_JOBS_SUCCESS = 'CLEAR_FINISHED_JOBS_SUCCESS';
export const CLEAR_FINISHED_JOBS_FAILURE = 'CLEAR_FINISHED_JOBS_FAILURE';


export const getActiveJobsSubmit = payload => ({
  type: GET_ACTIVE_JOBS_SUBMIT,
  payload,
});

export const getActiveJobsSuccess = payload => ({
  type: GET_ACTIVE_JOBS_SUCCESS,
  payload,
});

export const getActiveJobsFailure = payload => ({
  type: GET_ACTIVE_JOBS_FAILURE,
  payload,
});
export const clearFinishedJobsSubmit = payload => ({
  type: CLEAR_FINISHED_JOBS_SUBMIT,
  payload,
});

export const clearFinishedJobsSuccess = payload => ({
  type: CLEAR_FINISHED_JOBS_SUCCESS,
  payload,
});

export const clearFinishedJobsFailure = payload => ({
  type: CLEAR_FINISHED_JOBS_FAILURE,
  payload,
});
