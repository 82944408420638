import {
  call, put, all, takeLatest, delay,
} from 'redux-saga/effects';
import {
  verifyUserEmailSuccess,
  verifyUserEmailFailure,
  VERIFY_USER_EMAIL_SUBMIT,
} from './verifyEmailActions';

import userService from '../../services/usersService';

function* verifyEmail(action) {
  try {
    const result = yield call(userService.verifyUserEmail, action.payload);
    delay(2000);
    yield put(verifyUserEmailSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(verifyUserEmailFailure(errorMessage));
  }
}
export default function* emailSaga() {
  yield all([takeLatest([VERIFY_USER_EMAIL_SUBMIT], verifyEmail)]);
}
