import { VERIFY_USER_EMAIL_FAILURE } from './verifyEmailActions';

const initState = {
  emailVerifyInProgress: true,
  error: false,
};

function verifyEmailReducer(state = initState, { type, payload }) {
  switch (type) {
    case VERIFY_USER_EMAIL_FAILURE: {
      return {
        ...state,
        error: true,
        emailVerifyInProgress: true,
      };
    }

    default: {
      return state;
    }
  }
}

export default verifyEmailReducer;
