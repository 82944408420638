import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import {
  setNZBCredentialsModalOpen,
  setWelcomeModalOpen,
} from '../../../redux/viewsEffects/viewsEffectsActions';
import UsenetModal from '../../SettingsTab/UsenetProvidersTab/usenetModal';
import NZBCredentialsModal from './NZBCredentialsModal';
import { usenetAddConfigSubmit } from '../../../redux/usenet/usenetProvidersActions';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  storageContainer: {
    paddingTop: 20,
  },
  uploadButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    // padding: 20,
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none',
    // },
  },
  rootStorageButton: {
    color: 'rgba(0,0,0,0,6)',
    fontSize: 34,
    cursor: 'pointer',
    marginRight: 5,
  },

  uploadButtonSecondary: {
    backgroundColor: '#cb3134',
    // height: 50,
    width: 185,
    borderRadius: 2,
    color: '#ffffff',
    height: 52,
    animation: '$blinker 0.4s',
    transition: 'all .3s ease-in-out',
    fontSize: 15,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#d22d31',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
      textDecoration: 'none',
      color: '#ffffff',
    },
  },
  downloadBlock: {
    display: 'flex',
    border: '1px solid #EDEDED',
    flexDirection: 'column',
    backgroundColor: '#e89a9a',
    borderRadius: 3,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  rootFolder: {
    textTransform: 'none',
    color: 'rgba(0,0,0,0.6)',
    display: 'flex',
    height: 35,
    padding: '4px 10px',
    backgroundColor: '#F0F0F0',
    border: '1px solid #EDEDED',
    '&:hover': {
      backgroundColor: '#E5E5E5',

    },
  },
  '@keyframes blinker': {
    '50%': {
      opacity: 0.7,
    },
  },
}));

export default function NZBCredentialsBanner(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    usenetModalIsLoading,
    usenetError,
  } = useSelector(state => state.usenetReducer);
  const {
    nzbCredentialModalOpen = false,
  } = useSelector(state => state.viewsEffects);

  const handleNZBCredentialModalOpen = () => {
    dispatch(setNZBCredentialsModalOpen({ nzbCredentialModalOpen: true }));
  };
  const handleNZBCredentialModalClose = () => {
    dispatch(setNZBCredentialsModalOpen({ nzbCredentialModalOpen: false }));
  };
  const handleChangeCredentials = (data) => {
    dispatch(usenetAddConfigSubmit(data));
  };
  return (

    <div
      className={classes.downloadBlock}
    >

      <div
        style={{
          display: 'flex',

          padding: '11px 20px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <AccountTreeIcon style={{
            marginRight: 8,
            color: '#ffffff',
          }}
          />
          <span style={{
            fontWeight: 500,
            fontSize: 16,
            color: '#ffffff',
          }}
          >
            {' '}
            Connect your Usenet account to start using NZBCloud
          </span>
        </div>
        <div className={classes.uploadButtonContainer}>
          <Button
            variant="contained"
            disableElevation
            className={classes.uploadButtonSecondary}
            color="primary"
            component="span"
            onClick={() => handleNZBCredentialModalOpen()}
          >
            Link your Usenet
          </Button>


        </div>
      </div>
      <NZBCredentialsModal
        handleClose={handleNZBCredentialModalClose}
        open={nzbCredentialModalOpen}
        handleSubmit={handleChangeCredentials}
        modalIsLoading={usenetModalIsLoading}
        error={usenetError}

      />
    </div>


  );
}
