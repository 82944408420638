import {
  call, put, all, takeLatest,
} from 'redux-saga/effects';
import {
  GET_USER_PAYMENTS_SUBMIT,
  getUserPaymentsSuccess,
  getUserPaymentsFailure,
  GET_PLANS_SUBMIT,
  getPlansSuccess,
  getPlansFailure,
  getAddonsFailure,
  getAddonsSuccess,
  getPaymentUrlSuccess,
  getPaymentUrlFailure,
  GET_PAYMENT_URL_SUBMIT,
  paymentOrderReviewSuccess,
  paymentOrderReviewFailure,
  PAYMENT_ORDER_REVIEW_SUBMIT,
} from './paymentsActions';
import history from '../../config/history';
import userService from '../../services/usersService';
import paymentsService from '../../services/paymentsService';

function* getUserPayments() {
  try {
    const result = yield call(userService.getUserPayments);
    yield put(getUserPaymentsSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getUserPaymentsFailure(errorMessage));
  }
}
function* getPlans() {
  try {
    const result = yield call(paymentsService.getPlans);
    yield put(getPlansSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getPlansFailure(errorMessage));
  }
}
function* getAddons() {
  try {
    const result = yield call(paymentsService.getAddons);
    yield put(getAddonsSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getAddonsFailure(errorMessage));
  }
}

function* orderReview(action) {
  const { payload } = action;
  try {
    const result = yield call(paymentsService.orderReview, payload);
    yield put(paymentOrderReviewSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(paymentOrderReviewFailure(errorMessage));
  }
}

function* getPaymentUrl({ payload }) {
  try {
    const result = yield call(paymentsService.getPaymentUrl, payload);
    const {
      redirect, payment_url, email, isNewUser,
    } = result;
    if (!redirect) {
      history.push({
        pathname: '/order-complete',
        search: `?email=${encodeURI(email)}&isNewUser=${isNewUser}`,
      });
    } else {
      window.location.replace(payment_url);
    }
    yield put(getPaymentUrlSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getPaymentUrlFailure(errorMessage));
  }
}

export default function* paymentsSaga() {
  yield all([takeLatest(GET_USER_PAYMENTS_SUBMIT, getUserPayments)]);
  yield all([takeLatest(GET_PLANS_SUBMIT, getPlans)]);
  yield all([takeLatest(GET_PLANS_SUBMIT, getAddons)]);
  yield all([takeLatest(GET_PAYMENT_URL_SUBMIT, getPaymentUrl)]);
  yield all([takeLatest(PAYMENT_ORDER_REVIEW_SUBMIT, orderReview)]);
}
