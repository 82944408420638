import {
  USER_REGISTRATION_SUBMIT,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_FAILURE,
  USER_SET_REGISTRATION_STEP,
} from './registrationActions';
import { USER_LOGIN_SUCCESS } from '../login/loginActions';

const initState = {
  registrationLoading: false,
  registrationError: false,
  isAuthenticated: false,
  registrationStep: 0,
};

function registrationReducer(state = initState, { type, payload }) {
  switch (type) {
    // case CHANGE_REGISTRATION_INPUT: {
    //   return {
    //     ...state,
    //     [payload.name]: payload.value,
    //   };
    // }
    case USER_REGISTRATION_SUBMIT: {
      return {
        ...state,
        registrationLoading: true,
        loginLoading: true,
      };
    }

    case USER_REGISTRATION_SUCCESS: {
      return {
        ...state,
        registrationLoading: false,
        registrationStep: 1,
      };
    }
    case USER_LOGIN_SUCCESS:
    case USER_SET_REGISTRATION_STEP: {
      return {
        ...state,
        registrationLoading: false,
        registrationError: '',
        registrationStep: payload,
      };
    }

    case USER_REGISTRATION_FAILURE: {
      return {
        ...state,
        registrationLoading: false,
        registrationError: payload,
      };
    }

    default: {
      return state;
    }
  }
}

export default registrationReducer;
