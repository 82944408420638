import api from '../helpers/api';

class NotificationsService {
  getNotifications() {
    return api
      .sendRequest('notifications', 'get')
      .then(response => response.data);
  }

  markAllRead() {
    return api
      .sendRequest('notifications/markAsRead', 'post')
      .then(response => response.data);
  }

  hideAll() {
    return api
      .sendRequest('notifications/hideAll', 'post')
      .then(response => response.data);
  }

  orderReview(data) {
    return api
      .sendRequest('payment/order-review', 'post', data)
      .then(response => response.data);
  }
}

export default new NotificationsService();
