import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

const useStyles = makeStyles(theme => ({

  warnButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    // padding: 20,
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none',
    // },
  },


  warnButtonSecondary: {
    backgroundColor: '#cb3134',
    // height: 50,
    width: 185,
    borderRadius: 2,
    color: '#ffffff',
    height: 52,
    // animation: '$blinker 0.4s',
    transition: 'all .3s ease-in-out',
    fontSize: 15,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#d22d31',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      // transform: 'translateY(-3px)',
      textDecoration: 'none',
      color: '#ffffff',
    },
  },
  warnBlock: {
    display: 'flex',
    border: '1px solid #EDEDED',
    flexDirection: 'column',
    backgroundColor: '#e89a9a',
    borderRadius: 3,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },


}));

export default function WarningBanner(props) {
  const classes = useStyles();

  const handleClickAction = () => {
    window.open('https://checkout.nzbcloud.com/checkout', '_blank');
  };

  return (

    <div
      className={classes.warnBlock}
    >

      <div
        style={{
          display: 'flex',

          padding: '11px 20px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <AccountBalanceWalletIcon style={{
            marginRight: 8,
            color: '#ffffff',
          }}
          />
          <span style={{
            fontWeight: 500,
            fontSize: 16,
            color: '#ffffff',
          }}
          >
            {' '}
            Subscription expired. All files in NZBCloud Storage will be deleted in 5 days.
          </span>
        </div>
        <div className={classes.warnButtonContainer}>
          <Button
            // variant="contained"
            disableElevation
            className={classes.warnButtonSecondary}
            color="primary"
            component="div"
            onClick={() => handleClickAction()}
          >
            Renew subscription
          </Button>


        </div>
      </div>


    </div>


  );
}
