import {
  call, put, all, takeLatest, select,
} from 'redux-saga/effects';

import { toast } from 'react-toastify';
import {
  GET_USER_STORAGE_SUBMIT,
  getStorageFolderDataFailure,
  getStorageFolderDataSuccess,
  getUserStorageFailure,
  getUserStorageSuccess,
  STORAGE_GET_FOLDER_ARCHIVE_SUBMIT,
  STORAGE_SELECT_FOLDER,
  GET_STORAGE_ROOT_FOLDER_DATA_SUBMIT,
  DELETE_STORAGE_ROOT_FOLDER_SUBMIT,
  deleteRootFolderSuccess,
  deleteRootFolderFailure,
  STORAGE_MEDIA_TRANSCODING_SUBMIT,
  onMediaTranscodingSuccess,
  onMediaTranscodingFailure,
  renameRootFolderFailure,
  renameRootFolderSuccess,
  RENAME_STORAGE_ROOT_FOLDER_SUBMIT,
  GET_EXTERNAL_DRIVES_LIST_SUBMIT,
  getExternalDrivesListSuccess,
  getExternalDrivesListFailure,
  setDownloadDestinationFailure,
  SET_BROWSE_STORAGE_TYPE_SUBMIT,
  setBrowseStorageTypeSuccess,
  PURGE_EXTERNAL_DRIVE_SUBMIT,
} from './userStorageActions';
import filesService from '../../services/filesService';
import {
  NZB_FILES_DOWNLOADING_COMPLETE,
  NZB_FILES_DOWNLOADING_DELETE_SUCCESS,
} from '../files/filesActions';
import fileTypeFilter from '../../helpers/fileTypeFilter';
import { IO_EXTERNAL_DRIVE_PURGE_COMPLETE, IO_FORCE_NODE_UPDATE } from '../sockets/socketsActions';


function* getUserStorageAfterDownloadDelete(action) {
  const state = yield select();
  const { path } = state.userStorage;
  const { payload } = action;
  const { nodeId } = payload;

  try {
    if (!path.length || path[0]._id === nodeId) {
      const response = yield call(filesService.getUserStorage);
      const { files, targetStorage } = response;
      yield put(
        getUserStorageSuccess({ ...fileTypeFilter(files), targetStorage }),
      );
    }
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getUserStorageFailure(errorMessage));
  }
}
function* getUserStorageOnComplete() {
  const state = yield select();
  const { path } = state.userStorage;
  try {
    if (!path.length) {
      const response = yield call(filesService.getUserStorage);
      const { files, targetStorage } = response;
      yield put(
        getUserStorageSuccess({ ...fileTypeFilter(files), targetStorage }),
      );
    }
    // else {
    //   const id = path[path.length - 1]._id;
    //   console.log(path)
    //   const storage = yield call(filesService.getUserStorageFolderData, id);
    //   const { children } = storage;
    //
    //   const files = fileTypeFilter(children);
    //
    //   yield put(getStorageFolderDataSuccess({ ...files, breadCrumb: path }));
    // }
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getUserStorageFailure(errorMessage));
  }
}
function* getUserStorage() {
  try {
    const response = yield call(filesService.getUserStorage);
    const { files, targetStorage } = response;
    yield put(
      getUserStorageSuccess({ ...fileTypeFilter(files), targetStorage }),
    );
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getUserStorageFailure(errorMessage));
  }
}
function* getStorageRootFolderData({ payload }) {
  const id = payload._id;
  const breadCrumb = [];
  breadCrumb.push(payload);

  try {
    const storage = yield call(filesService.getUserStorageFolderData, id);
    const { children } = storage;

    const files = fileTypeFilter(children);
    yield put(getStorageFolderDataSuccess({ ...files, breadCrumb }));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getStorageFolderDataFailure(errorMessage));
  }
}
function* getStorageFolderData({ payload }) {
  const id = payload._id;
  const state = yield select();
  const { path } = state.userStorage;
  const breadCrumb = [...path];
  breadCrumb.push(payload);

  const crumbIndex = breadCrumb.findIndex(x => x._id === payload._id);
  const newPath = breadCrumb.slice(0, crumbIndex + 1);

  try {
    const storage = yield call(filesService.getUserStorageFolderData, id);
    const { children } = storage;

    const files = fileTypeFilter(children);

    yield put(getStorageFolderDataSuccess({ ...files, breadCrumb: newPath }));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getStorageFolderDataFailure(errorMessage));
  }
}
function* getFolderArchive({ payload }) {
  try {
    const storage = yield call(filesService.prepareZipFolder, payload);
    yield put(getUserStorageSuccess(fileTypeFilter(storage)));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getStorageFolderDataFailure(errorMessage));
  }
}
function* transcodeMedia({ payload }) {
  const { _id } = payload;
  const state = yield select();
  const { path } = state.userStorage;
  try {
    const response = yield call(filesService.mediaTranscode, _id);
    const { children, error } = response;
    if (error) {
      toast.error('Sync in progress. Please wait');
    } else {
      const files = fileTypeFilter(children);
      yield put(onMediaTranscodingSuccess({ ...files, breadCrumb: path }));
    }
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(onMediaTranscodingFailure(errorMessage));
  }
}
function* deleteRootFolder({ payload }) {
  try {
    const storage = yield call(filesService.deleteRootFolder, payload);
    yield put(deleteRootFolderSuccess(fileTypeFilter(storage)));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(deleteRootFolderFailure(errorMessage));
  }
}
function* renameRootFolder({ payload }) {
  try {
    const storage = yield call(filesService.renameRootFolder, payload);
    yield put(renameRootFolderSuccess(fileTypeFilter(storage)));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(renameRootFolderFailure(errorMessage));
  }
}

function* getExternalDrivesList() {
  try {
    const externalStorages = yield call(filesService.getExternalDrivesList);
    const selectedDownloadDestination = externalStorages.find(
      entry => entry.selectedToDownload === true,
    );

    yield put(
      getExternalDrivesListSuccess({
        externalStorages,
        selectedDownloadDestination: selectedDownloadDestination || {
          type: 'NZBCLOUD',
        },
      }),
    );
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getExternalDrivesListFailure(errorMessage));
  }
}

// function* setDownloadDestination(action) {
//   const { payload } = action;
//   try {
//     const externalStorages = yield call(
//       filesService.setDownloadDestination,
//       payload,
//     );
//     const selectedDownloadDestination = externalStorages.find(
//       entry => entry.selectedToDownload === true,
//     );
//     yield put(
//       setDownloadDestinationSuccess({
//         externalStorages,
//         selectedDownloadDestination: selectedDownloadDestination || {
//           type: 'NZBCLOUD',
//         },
//       }),
//     );
//   } catch (error) {
//     const errorMessage = error.response || error;
//     yield put(setDownloadDestinationFailure(errorMessage));
//   }
// }

function* setBrowseStorageType(action) {
  const { payload } = action;

  try {
    const response = yield call(filesService.setStorageType, payload);
    const { files, targetStorage } = response;
    yield put(
      setBrowseStorageTypeSuccess({
        ...fileTypeFilter(files),
        targetStorage,
      }),
    );
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(setDownloadDestinationFailure(errorMessage));
  }
}

function* purgeExternalStorage(action) {
  const { payload } = action;

  try {
    yield call(filesService.purgeExternalStorage, payload);
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(setDownloadDestinationFailure(errorMessage));
  }
}

export default function* userStorageSaga() {
  yield all([
    takeLatest([DELETE_STORAGE_ROOT_FOLDER_SUBMIT], deleteRootFolder),
  ]);
  yield all([
    takeLatest([RENAME_STORAGE_ROOT_FOLDER_SUBMIT], renameRootFolder),
  ]);
  yield all([takeLatest([GET_USER_STORAGE_SUBMIT], getUserStorage)]);
  yield all([takeLatest([STORAGE_MEDIA_TRANSCODING_SUBMIT], transcodeMedia)]);
  yield all([
    takeLatest(
      [
        NZB_FILES_DOWNLOADING_COMPLETE,
        IO_EXTERNAL_DRIVE_PURGE_COMPLETE,
      ],
      getUserStorageOnComplete,
    ),
  ]);
  yield all([
    takeLatest(
      [
        NZB_FILES_DOWNLOADING_DELETE_SUCCESS,
      ],
      getUserStorageAfterDownloadDelete,
    ),
  ]);
  yield all([takeLatest(STORAGE_SELECT_FOLDER, getStorageFolderData)]);
  yield all([takeLatest(STORAGE_GET_FOLDER_ARCHIVE_SUBMIT, getFolderArchive)]);
  yield all([
    takeLatest(GET_STORAGE_ROOT_FOLDER_DATA_SUBMIT, getStorageRootFolderData),
  ]);
  yield all([
    takeLatest(GET_EXTERNAL_DRIVES_LIST_SUBMIT, getExternalDrivesList),
  ]);
  yield all([takeLatest(PURGE_EXTERNAL_DRIVE_SUBMIT, purgeExternalStorage)]);
  yield all([takeLatest(SET_BROWSE_STORAGE_TYPE_SUBMIT, setBrowseStorageType)]);
  yield all([takeLatest(IO_FORCE_NODE_UPDATE, getUserStorageOnComplete)]);
}
