import { SET_HDR_SEARCH_QUERY, HDR_SEARCH_START, HDR_SEARCH_FINISH } from './hydraSearchActions';
import { GET_USER_SUCCESS } from '../user/userActions';
import { HDR_SEARCH_READY } from '../sockets/socketsActions';

const initState = {
  searchResults: [],
  searchQuery: '',
  isLoading: false,
  isDisabled: true,
};

function hydraSearchReducer(state = initState, { type, payload }) {
  switch (type) {
    case GET_USER_SUCCESS: {
      return {
        ...initState,
        isDisabled: true,
      };
    }
    case HDR_SEARCH_READY: {
      return {
        ...initState,
        isDisabled: false,
      };
    }
    case SET_HDR_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: payload,
      };
    }
    case HDR_SEARCH_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case HDR_SEARCH_FINISH: {
      return {
        ...state,
        isLoading: false,
        searchResults: payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default hydraSearchReducer;
