import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { userChangeEmailSubmit } from '../../../redux/user/userActions';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 2,
  },
  formInput: {
    padding: '0px 24px',
    width: 600,
    '& fieldset': {
      borderRadius: 2,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  secondaryButton: {
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
      color: '#fff',
    },
  },
  submit: {
    marginLeft: 20,
    backgroundColor: '#2158eb',
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
    },
  },

}));
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Come on, man. Enter your email here. ')
    .required('Come on, man. Enter your email here. '),
});
export default function ChangeEmailModal(props) {
  const classes = useStyles();
  const { open, handleOpen, handleClose } = props;
  const dispatch = useDispatch();

  const handleFormSubmit = (valuesFromForm) => {
    const { email } = valuesFromForm;

    dispatch(userChangeEmailSubmit({ email }));
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Change E-mail</DialogTitle>
        <div>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            onSubmit={values => handleFormSubmit(values)}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
            }) => (
              <>
                <form>
                  <div className={classes.formInput}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      error={!!(touched.email && errors.email)}
                      fullWidth
                      id="new-email"
                      label="E-mail"
                      name="email"
                      value={values.email}
                      onChange={handleChange('email')}
                    />
                    <div style={{ color: 'red', height: 20 }}>
                      {touched.email && errors.email}
                    </div>
                  </div>
                  <div
                    style={{
                      padding: '18px 32px',
                      backgroundColor: '#F7F7F7',
                      borderTop: '1px solid #E0E0E0',
                      justifyContent: 'flex-end',
                      display: 'flex',
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      color="primary"
                      component="span"
                      className={classes.secondaryButton}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleSubmit}
                      style={{ boxShadow: 'none' }}
                    >
                      SAVE
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}
