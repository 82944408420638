import api from '../helpers/api';

class AuthService {
  async login(payload) {
    const { email, password } = payload;

    const response = await api.sendRequest('auth/login', 'post', {
      email,
      password,
    });

    const { accessToken, expiresIn } = response.data;

    await localStorage.setItem('@accessToken', accessToken);
    await localStorage.setItem('@expiresIn', expiresIn);
  }

  async linkLogin({ loginToken }) {
    const response = await api.sendRequest('auth/loginByLink', 'post', {
      loginToken,
    });

    const { accessToken, expiresIn } = response.data;

    await localStorage.setItem('@accessToken', accessToken);
    await localStorage.setItem('@expiresIn', expiresIn);
  }

  async registration(email) {
    return api.sendRequest('user', 'post', {
      email,
    });
  }

  async registrationAlpha(payload) {
    const { email, password, token } = payload;

    const response = await api.sendRequest(
      '/alpha-requests/registration',
      'post',
      {
        email,
        password,
        token,
      },
    );

    const { accessToken, expiresIn } = response.data;

    await localStorage.setItem('@accessToken', accessToken);
    await localStorage.setItem('@expiresIn', expiresIn);
  }

  async getMe() {
    return api.sendRequest('/user/me', 'get').then(response => response.data);
  }
}

export default new AuthService();
