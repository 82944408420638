import api from '../helpers/api';

class ContactsService {
  async sendMessage(payload) {
    const { email, message } = payload;

    const response = await api.sendRequest('/contacts', 'post', {
      email,
      message,

    });
  }

  sendReport(data) {
    return api
      .sendRequest('/contacts/bug-report', 'post', data)
      .then(response => response);
  }
}

export default new ContactsService();
