import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Button from '@material-ui/core/Button';
import Image from 'material-ui-image';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails, Chip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import withStyles from '@material-ui/core/styles/withStyles';
import StorageIcon from '@material-ui/icons/Storage';
import SecurityIcon from '@material-ui/icons/Security';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import BlurOffIcon from '@material-ui/icons/BlurOff';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import StarIcon from '@material-ui/icons/Star';
import ChangePasswordModal from './changePasswordModal';
import {
  userSentVerLinkSubmit,
  userSetAvatarSubmit,
  userUpdateAntivirusSettingsSubmit,
  userUpdateEncryptionSettingsSubmit,
} from '../../../redux/user/userActions';
import ChangeEmailModal from './changeEmailModal';
import { getAvatarsSubmit } from '../../../redux/viewsEffects/viewsEffectsActions';
import AvatarList from './AvatarList';
import ProtectorIconRed from '../../atoms/ProtectorIconRed';
import ProtectorIconGreen from '../../atoms/ProtectorIconGreen';
import OnlyPaidModal from '../../Dashboard/onlyPaidModal';

const useStyles = makeStyles(theme => ({
  accountTabContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  emailWarningWrapper: {
    backgroundColor: '#FCEED6',
    borderRadius: 2,
    padding: 24,
    marginLeft: 32,
    marginRight: 32,
    // width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '16px 24px 16px 24px',
    },
  },
  emailWarningButtonWrapper: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      width: '100%',
    },
  },
  sectionWrapperExt: {
    // margin: '5px 32px 24px 32px',
    padding: '24px 32px 24px 32px',
    // width: '100%',

    borderRadius: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '0 0 10px 0',
    },
  },
  sectionWrapper: {
    padding: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderLeft: '1px solid rgb(237, 237, 237)',
    borderRight: '1px solid rgb(237, 237, 237)',
    borderBottom: '1px solid rgb(237, 237, 237)',
  },
  sectionIconWrapper: {
    height: 48,
    width: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
    borderRadius: 2,
  },
  sectionIconWrapperExt: {
    height: 64,
    width: 64,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: '#F7F7F7',
    // borderRadius: 2,
  },
  sectionContentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionDataWrapper: {
    marginLeft: 24,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      width: 120,
    },
  },
  sectionDataProtectionWrapper: {
    marginLeft: 24,
    // whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      width: 150,
    },
  },
  sectionTitle: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 500,
  },
  sectionSubTitle: {
    fontSize: 14,
    color: 'rgba(0,0,0,0.38)',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  emailWarningTitle: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 2,
      textAlign: 'center',
    },
  },
  emailWarningText: {
    color: 'rgba(0,0,0,0.6)',
    fontWeight: 400,
  },

  divider: {
    position: 'relative',
    '&:before': {
      content: "''",
      height: 1,
      background: '#F7F7F7',
      width: '100%',
      position: 'absolute',
      top: 0,
    },
  },
  generalInfoContainer: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',

    // marginLeft: 16,
    flex: 1,
    borderRadius: 2,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 16,
      marginBottom: 100,
    },
  },
  profileContainer: {
    backgroundColor: theme.palette.background.paper,
    width: 330,
    borderRadius: 2,
    // height: 470,
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mobileProfileContainer: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  profileEmail: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      maxWidth: 156,
    },
  },
  profileAvatarWrapper: {
    width: 140,
    height: 140,
    borderRadius: '50%',
    backgroundColor: 'black',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 24,
    marginTop: 24,
  },
  profileActivePlan: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  expContent: {
    flexDirection: 'column',
  },
  expRoot: {
    padding: '0 32px 0 32px',
  },
  details: {
    borderTop: '1px solid rgb(247, 247, 247)',
    padding: 0,
  },
  avatarList: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  noneOnSM: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  changeButton: {
    marginLeft: 20,
    backgroundColor: '#2158eb',
    borderRadius: 2,
    height: 52,
    color: '#fff',
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
    },
  },
}));

const StyledSwitch = withStyles({
  switchBase: {
    color: 'rgb(87,86,86)',
    '&$checked': {
      color: '#2c5ce2',
    },
    '&$checked + $track': {
      backgroundColor: '#2C5CE276',
    },
  },
  checked: {},
  track: { backgroundColor: 'rgba(0,0,0,0.29)' },
})(Switch);
export default function AccountTab(props) {
  const dispatch = useDispatch();
  const { user, viewsEffects } = useSelector(state => state);
  const {
    emailVerified, email, activePlan, avatar, settings,
  } = user;
  const { antivirusEnabled, externalStorageEncryptionEnabled } = settings;
  const { antivirusAvailable } = user;
  // const { avatars } = viewsEffects;
  const { width } = props;
  const classes = useStyles();
  const [changeEmailModalOpen, setEmailModalOpen] = React.useState(false);
  const [changePasswordModalOpen, setPasswordModalOpen] = React.useState(false);
  const [paidModalOpen, setPaidModalOpen] = React.useState(false);
  // const getAvatarsHandler = () => {
  //   // dispatch(getAvatarsSubmit());
  // };
  // const setAvatarSubmit = (id) => {
  //   dispatch(userSetAvatarSubmit(id));
  // };
  const handlePaidModalOpen = () => {
    setPaidModalOpen(true);
  };
  const handlePaidModalClose = () => {
    setPaidModalOpen(false);
  };
  const handleSentVerLinkEmail = () => {
    dispatch(userSentVerLinkSubmit());
  };
  const handlePasswordModalOpen = () => {
    setPasswordModalOpen(true);
  };
  const handlePasswordModalClose = () => {
    setPasswordModalOpen(false);
  };
  const handleEmailModalOpen = () => {
    setEmailModalOpen(true);
  };

  const handleEmailModalClose = () => {
    setEmailModalOpen(false);
  };

  const handleAVSettingsChange = () => {
    dispatch(userUpdateAntivirusSettingsSubmit(!antivirusEnabled));
  };
  const handleEncryptionSettingsChange = () => {
    dispatch(
      userUpdateEncryptionSettingsSubmit(!externalStorageEncryptionEnabled),
    );
  };
  // useEffect(() => getAvatarsHandler(), [dispatch]);
  const vl = localStorage.getItem('vl');
  let ms = Date.now() - vl;
  ms = 1000 * Math.round(ms / 1000);
  const d = new Date(ms).getUTCMinutes();

  return (
    <div>
      <div
        className={classes.accountTabContainer}
        style={{
          display: 'flex',
        }}
      >
        {/* <div className={classes.profileContainer}> */}
        {/*  <div */}
        {/*    style={{ */}
        {/*      paddingTop: 32, */}
        {/*      paddingBottom: 20, */}
        {/*      paddingRight: 32, */}
        {/*      paddingLeft: 32, */}
        {/*      borderBottom: '1px solid rgb(247, 247, 247)', */}
        {/*    }} */}
        {/*  > */}
        {/*    <Typography variant="h6">Profile</Typography> */}
        {/*    <div className={classes.profileAvatarWrapper}> */}
        {/*      <Image src={avatar.url} /> */}
        {/*    </div> */}
        {/*    <div className={classes.profileEmail}>{email}</div> */}
        {/*    <div className={classes.profileActivePlan}>{activePlan.name}</div> */}
        {/*  </div> */}

        {/*  <AvatarList */}
        {/*    avatars={avatars} */}
        {/*    userAvatarId={avatar.id} */}
        {/*    handleAvatarCLick={setAvatarSubmit} */}
        {/*  /> */}
        {/* </div> */}
        {/* <div className={classes.mobileProfileContainer}> */}
        {/*  <Accordion defaultExpanded={false} elevation={0}> */}
        {/*    <AccordionSummary */}
        {/*      expandIcon={<ExpandMoreIcon />} */}
        {/*      aria-controls="panel1c-content" */}
        {/*      id="panel1c-header" */}
        {/*      classes={{ content: classes.expContent, root: classes.expRoot }} */}
        {/*    > */}
        {/*      <div style={{ paddingTop: 18 }}> */}
        {/*        <Typography variant="h6">Profile</Typography> */}
        {/*      </div> */}
        {/*      <div */}
        {/*        style={{ paddingTop: 16, paddingBottom: 16, display: 'flex' }} */}
        {/*      > */}
        {/*        <div style={{ width: 64, height: 64 }}> */}
        {/*          <Image src={avatar.url} /> */}
        {/*        </div> */}
        {/*        <div */}
        {/*          style={{ */}
        {/*            display: 'flex', */}
        {/*            flexDirection: 'column', */}
        {/*            justifyContent: 'center', */}
        {/*            paddingLeft: 16, */}
        {/*          }} */}
        {/*        > */}
        {/*          <div className={classes.profileEmail}>{email}</div> */}
        {/*          <div className={classes.profileActivePlan}> */}
        {/*            {activePlan.name} */}
        {/*          </div> */}
        {/*        </div> */}
        {/*      </div> */}
        {/*    </AccordionSummary> */}
        {/*    <AccordionDetails className={classes.details}> */}
        {/*      <AvatarList */}
        {/*        avatars={avatars} */}
        {/*        userAvatarId={avatar.id} */}
        {/*        handleAvatarCLick={setAvatarSubmit} */}
        {/*      /> */}
        {/*    </AccordionDetails> */}
        {/*  </Accordion> */}
        {/* </div> */}
        <div className={classes.generalInfoContainer}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            // borderBottom: '1px solid rgb(237, 237, 237)',
            padding: 20,
            border: '1px solid rgb(237, 237, 237)',
          }}
          >
            <AccountBoxIcon style={{
              marginRight: 8,
              color: '#2c5ce2',
            }}
            />
            <span style={{
              fontWeight: 500,
              fontSize: 16,
            }}
            >
              {' '}
              Profile
            </span>
          </div>
          {!emailVerified && (
            <div className={classes.emailWarningWrapper}>
              <div
                style={{
                  fontSize: 14,
                  color: 'rgba(0,0,0,0.87)',
                  fontWeight: 500,
                }}
              >
                <div className={classes.emailWarningTitle}>
                  Verify your e-mail
                </div>
                <div
                  className={classes.emailWarningText}
                  style={{ color: 'rgba(0,0,0,0.6)', fontWeight: 400 }}
                >
                  {d < 5
                    ? 'Verification link sent'
                    : 'We will send an email to your address with a verification link.'}
                </div>
              </div>
              <div className={classes.emailWarningButtonWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={user.userLoading || d < 5}
                  fullWidth={width === 'sm' || width === 'xs'}
                  disableElevation
                  onClick={handleSentVerLinkEmail}
                  style={{
                    boxShadow: 'none',
                    backgroundColor: '#2158eb',
                    color: '#F7F7F7',
                    minWidth: 105,
                  }}
                >
                  {d < 5 ? `${5 - d} minutes` : 'send link'}
                </Button>
              </div>
            </div>
          )}
          <div className={classes.sectionWrapper}>
            <div className={classes.sectionContentWrapper}>
              <div className={classes.sectionIconWrapper}>
                <EmailIcon style={{ color: 'rgba(0,0,0,0.6)' }} />
              </div>
              <div className={classes.sectionDataWrapper}>
                <div className={classes.sectionTitle}>E-mail</div>
                <div className={classes.sectionSubTitle}>{email}</div>
              </div>
            </div>
            {/* <div> */}
            {/*  <Button */}
            {/*    className={classes.changeButton} */}
            {/*    color="primary" */}
            {/*    variant='contained' */}
            {/*    onClick={handleEmailModalOpen} */}
            {/*    disabled */}
            {/*  > */}
            {/*    CHANGE */}
            {/*  </Button> */}
            {/* </div> */}
          </div>
          {/* <div className={classes.divider} /> */}
          <div className={classes.sectionWrapper}>
            <div className={classes.sectionContentWrapper}>
              <div className={classes.sectionIconWrapper}>
                <LockIcon style={{ color: 'rgba(0,0,0,0.6)' }} />
              </div>
              <div className={classes.sectionDataWrapper}>
                <div className={classes.sectionTitle}>Password</div>
                <div className={classes.sectionSubTitle}>**********</div>
              </div>
            </div>
            <div>
              <Button
                className={classes.changeButton}
                color="primary"
                onClick={handlePasswordModalOpen}
              >
                CHANGE
              </Button>
            </div>
          </div>
          {/* <div className={classes.divider} /> */}
          <div style={{ width: '100%', height: 20, backgroundColor: '#fafafa' }} />
          <div style={{ border: '1px solid rgb(237, 237, 237)' }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid rgb(237, 237, 237)',
              // border: '1px solid rgb(237, 237, 237)',
              padding: 20,
            }}
            >
              <SecurityIcon style={{
                marginRight: 8,
                color: '#2c5ce2',
              }}
              />
              <span style={{
                fontWeight: 500,
                fontSize: 16,
              }}
              >
                {' '}
                Protection
              </span>
            </div>
            <div
              className={classes.sectionWrapper}
            >
              <div className={classes.sectionContentWrapper}>
                <div className={classes.sectionIconWrapper}>
                  {antivirusEnabled ? (
                    <BlurCircularIcon style={{ color: '#2c5ce2' }} />
                  ) : (
                    <BlurOffIcon style={{ color: 'rgba(0,0,0,0.6)' }} />
                  )}
                </div>
                <div
                  className={classes.sectionDataProtectionWrapper}
                  style={{ whiteSpace: 'normal' }}
                >
                  <div
                    className={classes.sectionTitle}
                    // style={{
                    //   color: antivirusEnabled ? ' #50BCB6' : ' #FF4D63',
                    // }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      NZBCloud Protector(C)
                      {' '}
                      <span className={classes.noneOnSM}>Anti-malware</span>

                    </div>
                  </div>
                  <div
                    className={classes.sectionSubTitle}
                    // style={{
                    //   color: antivirusEnabled ? '#2E736F' : '#732E36',
                    // }}
                  >
                    Scan downloaded files
                  </div>
                </div>
              </div>

              <div>
                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    {/* <Grid item></Grid> */}
                    <Grid item>

                      {antivirusAvailable ? (
                        <StyledSwitch
                          checked={antivirusEnabled}
                          onChange={handleAVSettingsChange}
                        />
                      ) : (
                        <div style={{ paddingLeft: 15 }}>
                          <Chip bgcolor="purple" variant="outlined" size="small" icon={<StarIcon style={{ color: '#f5cc00' }} />} label="PRO" style={{ color: '#f5cc00' }} onClick={() => handlePaidModalOpen()} />
                        </div>
                      )}

                    </Grid>
                    {/* <Grid item>On</Grid> */}
                  </Grid>
                </Typography>
              </div>
            </div>
            <div
              className={classes.sectionWrapper}
            >
              <div className={classes.sectionContentWrapper}>
                <div className={classes.sectionIconWrapper}>
                  {externalStorageEncryptionEnabled ? (
                    <EnhancedEncryptionIcon style={{ color: '#2c5ce2' }} />
                  ) : (
                    <NoEncryptionIcon style={{ color: 'rgba(0,0,0,0.6)' }} />
                  )}
                </div>
                <div
                  className={classes.sectionDataProtectionWrapper}
                  style={{ whiteSpace: 'normal' }}
                >
                  <div
                    className={classes.sectionTitle}
                    // style={{
                    //   color: externalStorageEncryptionEnabled
                    //     ? ' #50BCB6'
                    //     : ' #FF4D63',
                    // }}
                  >
                    NZBCloud Cryptor(C)
                  </div>
                  <div
                    className={classes.sectionSubTitle}
                    // style={{
                    //   color: externalStorageEncryptionEnabled
                    //     ? '#2E736F'
                    //     : '#732E36',
                    // }}
                  >
                    Encrypt downloaded files
                  </div>
                </div>
              </div>
              <div>
                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    {/* <Grid item></Grid> */}
                    <Grid item>
                      <StyledSwitch
                        checked={externalStorageEncryptionEnabled}
                        onChange={handleEncryptionSettingsChange}
                      />
                    </Grid>
                    {/* <Grid item>On</Grid> */}
                  </Grid>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OnlyPaidModal
        open={paidModalOpen}
        handleOpen={handlePaidModalOpen}
        handleClose={handlePaidModalClose}
      />
      <ChangeEmailModal
        open={changeEmailModalOpen}
        handleOpen={handleEmailModalOpen}
        handleClose={handleEmailModalClose}
      />
      <ChangePasswordModal
        open={changePasswordModalOpen}
        handleOpen={handlePasswordModalOpen}
        handleClose={handlePasswordModalClose}
      />
    </div>
  );
}
