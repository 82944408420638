import {
  call, put, all, takeLatest,
} from 'redux-saga/effects';
import {
  GET_AVATARS_SUBMIT,
  getAvatarsSuccess,
  getAvatarsFail,
} from './viewsEffectsActions';

import userService from '../../services/usersService';


function* getAvatarList() {
  try {
    const result = yield call(userService.getAvatars);
    yield put(getAvatarsSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getAvatarsFail(errorMessage));
  }
}



export default function* viewEffectsSaga() {
  yield all([takeLatest(GET_AVATARS_SUBMIT, getAvatarList)]);
}
