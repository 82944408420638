import React from 'react';

const NZBCloudStorageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path d="M3.44,2.77h42A2.59,2.59,0,0,1,48,5.37V42.22a3.44,3.44,0,0,1-3.44,3.44H3.44A3.44,3.44,0,0,1,0,42.22v-36A3.44,3.44,0,0,1,3.44,2.77Z" style={{ fill: '#3d64f4' }} />
    <rect x="8.81" y="7.01" width="34.11" height="34.42" rx="1.98" style={{ fill: '#fafafa' }} />
    <path d="M33.14,1.56,11.93,6.82A3.31,3.31,0,0,0,9.38,10V38.4A3.29,3.29,0,0,0,12,41.58l21.46,5.18L33,48l4-.66h0a3.32,3.32,0,0,0,2.67-3.2V3.1A2.47,2.47,0,0,0,37.77.71h0l-.11,0L33.59,0Z" transform="translate(0 0)" style={{ fill: '#23026d' }} />
    <path d="M31.59,47.9,7.38,42.06a3.39,3.39,0,0,1-2.64-3.27V9.62A3.39,3.39,0,0,1,7.35,6.36L32.65.08A2.59,2.59,0,0,1,35.9,2.53v42.1A3.45,3.45,0,0,1,31.59,47.9Z" transform="translate(0 0)" style={{ fill: '#9fc2ff' }} />
    <path d="M28.25,42.78,9.83,38.5a2.69,2.69,0,0,1-2-2.62V12.45a2.68,2.68,0,0,1,2-2.61L29.06,5.21a2,2,0,0,1,2.48,2v33A2.67,2.67,0,0,1,28.25,42.78Z" transform="translate(0 0)" style={{ fill: '#fff' }} />
    <path d="M28.67,24c0-6.85-3.52-12.4-7.87-12.4H17.13V13c-2.5,2.08-4.21,6.21-4.21,11s1.71,8.89,4.21,11v1.42h3.75C25.19,36.32,28.67,30.8,28.67,24Z" transform="translate(0 0)" style={{ fill: '#23026d' }} />
    <ellipse cx="17.21" cy="24" rx="7.88" ry="12.39" style={{ fill: '#fafafa' }} />
    <path d="M21.5,24c0-4.37-2.23-7.91-5-8v0a2.4,2.4,0,0,0-.49.07,3.64,3.64,0,0,0-1.15.35,38.09,38.09,0,0,0-4,1.88L11.36,24l-.48,5.71a38.09,38.09,0,0,0,4,1.88,3.64,3.64,0,0,0,1.15.35,2.4,2.4,0,0,0,.49.07v0C19.27,31.9,21.5,28.36,21.5,24Z" transform="translate(0 0)" style={{ fill: '#23026d' }} />
    <ellipse cx="12.06" cy="24" rx="3.82" ry="6.01" style={{ fill: '#fafafa' }} />
  </svg>
);

export default NZBCloudStorageIcon;
