import { useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 2,
  },
  formInput: {
    padding: '0px 24px',
    width: 600,
    '& fieldset': {
      borderRadius: 2,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  secondaryButton: {
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
      color: '#fff',
    },
  },
  submit: {
    marginLeft: 20,
    backgroundColor: '#2158eb',
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
    },
  },

}));
export default function OnlyPaidModal(props) {
  const classes = useStyles();
  const { open, handleOpen, handleClose } = props;
  const dispatch = useDispatch();

  const handleUpgrade = () => {
    window.open('https://checkout.nzbcloud.com/', '_blank');
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Available only in PRO PACK. Would you like to upgrade your subscription?</DialogTitle>
        <div>


          <div
            style={{
              padding: '18px 32px',
              backgroundColor: '#F7F7F7',
              borderTop: '1px solid #E0E0E0',
              justifyContent: 'flex-end',
              display: 'flex',
            }}
          >
            <Button
              onClick={handleClose}
              variant="outlined"
              color="primary"
              component="span"
              className={classes.secondaryButton}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => handleUpgrade()}
              style={{ boxShadow: 'none' }}
            >
              Upgrade
            </Button>
          </div>

        </div>
      </Dialog>
    </div>
  );
}
