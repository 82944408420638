import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  ellipsisDot: {
    color: '#2158eb',
    opacity: 0,
    animation: '$ellipsisDot 1s infinite',
  },
  '@keyframes ellipsisDot': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
});
const AnimatedEllipsis = (props) => {
  const classes = useStyles();
  const { numberOfDots, animDelay } = props;
  const dots = [];
  for (let i = 0; i < numberOfDots; i += 1) {
    dots.push(
      <span
        className={classes.ellipsisDot}
        style={{ animationDelay: `${i * animDelay}s` }}
        key={i}
      >
        .
      </span>,
    );
  }
  return (
    <span style={{ display: 'flex', alignItems: 'flex-end' }}>{dots}</span>
  );
};

export default AnimatedEllipsis;
