import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Container from '@material-ui/core/Container';

import { toast } from 'react-toastify';
import Link from '@material-ui/core/Link';

import Button from '@material-ui/core/Button';
import Copyright from '../Copyright';

import getQueryParam from '../../helpers/getQueryParam';

import ServicePageHeaders from '../ServicePageHeaders';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  linksOpt: {
    color: '#2158eb',
    marginTop: 20,
  },
  changeButton: {
    marginLeft: 20,
    backgroundColor: '#2158eb',
    borderRadius: 2,
    height: 52,
    color: '#fff',
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
    },
  },
}));

export default function OrderComplete(props) {
  const classes = useStyles();

  const { location, history } = props;

  function unwrapTextBoolean(tB) {
    if (tB === 'true') return true;
    if (tB === 'false') return false;
    return null;
  }

  const email = decodeURIComponent(getQueryParam('email', location));
  const isNewUser = unwrapTextBoolean(getQueryParam('isNewUser', location));

  window.history.replaceState(null, null, window.location.pathname);
  if (isNewUser) {
    toast.info('We have sent you an email with your password.');
  }

  return (
    <Container component="main" maxWidth="lg">
      <div className={classes.paper}>
        <ServicePageHeaders
          pageName=" "
          boldHeader="Thank you for joining NZBCloud"
          subtitle="We're excited to have you!"
        />
        <>
          <div style={{
            fontSize: 20,
            textAlign: 'center',
            color: '#575858',
            fontFamily: 'Poppins',
            fontWeight: 500,
            marginBottom: 40,
          }}
          >
            We've just sent you an email with your NZBCloud password
            <br />
            All you have to do now is log in and start downloading!
          </div>
          <Button
            className={classes.changeButton}
            color="primary"
            disableElevation
            onClick={() => history.push({
              pathname: '/login',
              search: email ? `?email=${encodeURI(email)}` : '',
            })}
            style={{
              boxShadow: 'none',
              backgroundColor: '#2158eb',
              color: '#F7F7F7',
              minWidth: 105,
            }}
          >Log In</Button>
        </>
      </div>

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
