import { toast } from 'react-toastify';
import history from '../../config/history';

export const GET_USER_SUBMIT = 'GET_USER_SUBMIT';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const FORGOT_PASSWORD_SUBMIT = 'FORGOT_PASSWORD_SUBMIT';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const SET_PASSWORD_SUBMIT = 'SET_PASSWORD_SUBMIT';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';


export const SET_NEW_AVATAR_SUBMIT = 'SET_NEW_AVATAR_SUBMIT';
export const SET_NEW_AVATAR_SUCCESS = 'SET_NEW_AVATAR_SUCCESS';
export const SET_NEW_AVATAR_FAILURE = 'SET_NEW_AVATAR_FAILURE';

export const GET_USER_DATA_SUBMIT = 'GET_USER_DATA_SUBMIT';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';

export const USER_CHANGE_PASSWORD_SUBMIT = 'USER_CHANGE_PASSWORD_SUBMIT';
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_FAILURE = 'USER_CHANGE_PASSWORD_FAILURE';

export const USER_CHANGE_EMAIL_SUBMIT = 'USER_CHANGE_EMAIL_SUBMIT';
export const USER_CHANGE_EMAIL_SUCCESS = 'USER_CHANGE_EMAIL_SUCCESS';
export const USER_CHANGE_EMAIL_FAILURE = 'USER_CHANGE_EMAIL_FAILURE';

export const USER_SENT_VER_LINK_SUBMIT = 'USER_SENT_VER_LINK_SUBMIT';
export const USER_SENT_VER_LINK_SUCCESS = 'USER_SENT_VER_LINK_SUCCESS';
export const USER_SENT_VER_LINK_FAILURE = 'USER_SENT_VER_LINK_FAILURE';

export const USER_UPDATE_ANTIVIRUS_SETTINGS_SUBMIT = 'USER_UPDATE_ANTIVIRUS_SETTINGS_SUBMIT';
export const USER_UPDATE_ANTIVIRUS_SETTINGS_SUCCESS = 'USER_UPDATE_ANTIVIRUS_SETTINGS_SUCCESS';
export const USER_UPDATE_ANTIVIRUS_SETTINGS_FAILURE = 'USER_UPDATE_ANTIVIRUS_SETTINGS_FAILURE';

export const USER_UPDATE_ENCRYPTION_SETTINGS_SUBMIT = 'USER_UPDATE_ENCRYPTION_SETTINGS_SUBMIT';
export const USER_UPDATE_ENCRYPTION_SETTINGS_SUCCESS = 'USER_UPDATE_ENCRYPTION_SETTINGS_SUCCESS';
export const USER_UPDATE_ENCRYPTION_SETTINGS_FAILURE = 'USER_UPDATE_ENCRYPTION_SETTINGS_FAILURE';


export const USER_SET_GOOGLE_CREDENTIALS = 'USER_SET_GOOGLE_CREDENTIALS';
export const USER_UNSET_GOOGLE_CREDENTIALS = 'USER_UNSET_GOOGLE_CREDENTIALS';

export const USER_SET_DROPBOX_CREDENTIALS = 'USER_SET_DROPBOX_CREDENTIALS';
export const USER_UNSET_DROPBOX_CREDENTIALS = 'USER_UNSET_DROPBOX_CREDENTIALS';

export const USER_SET_ONEDRIVE_CREDENTIALS = 'USER_SET_ONEDRIVE_CREDENTIALS';
export const USER_UNSET_ONEDRIVE_CREDENTIALS = 'USER_UNSET_ONEDRIVE_CREDENTIALS';

export const SET_WELCOME_MODAL_WIZARD_FINISHED_SUBMIT = 'SET_WELCOME_MODAL_WIZARD_FINISHED_SUBMIT';
export const SET_WELCOME_MODAL_WIZARD_FINISHED_SUCCESS = 'SET_WELCOME_MODAL_WIZARD_FINISHED_SUCCESS';
export const SET_WELCOME_MODAL_WIZARD_FINISHED_FAIL = 'SET_WELCOME_MODAL_WIZARD_FINISHED_FAIL';


export const setWizardFinishedSubmit = payload => ({
  type: SET_WELCOME_MODAL_WIZARD_FINISHED_SUBMIT,
  payload,
});
export const setWizardFinishedFail = () => ({
  type: SET_WELCOME_MODAL_WIZARD_FINISHED_FAIL,
});

export const setWizardFinishedSuccess = payload => ({
  type: SET_WELCOME_MODAL_WIZARD_FINISHED_SUCCESS,
  payload,
});

export const userSetGoogleCredentials = payload => ({
  type: USER_SET_GOOGLE_CREDENTIALS,
  payload,
});
export const userUnsetGoogleCredentials = payload => ({
  type: USER_UNSET_GOOGLE_CREDENTIALS,
  payload,
});

export const userSetDropboxCredentials = payload => ({
  type: USER_SET_DROPBOX_CREDENTIALS,
  payload,
});
export const userUnsetDropboxCredentials = payload => ({
  type: USER_UNSET_DROPBOX_CREDENTIALS,
  payload,
});

export const userSetOnedriveCredentials = payload => ({
  type: USER_SET_ONEDRIVE_CREDENTIALS,
  payload,
});
export const userUnsetOnedriveCredentials = payload => ({
  type: USER_UNSET_ONEDRIVE_CREDENTIALS,
  payload,
});

export const userUpdateAntivirusSettingsSubmit = payload => ({
  type: USER_UPDATE_ANTIVIRUS_SETTINGS_SUBMIT,
  payload,
});
export const userUpdateAntivirusSettingsSuccess = payload => ({
  type: USER_UPDATE_ANTIVIRUS_SETTINGS_SUCCESS,
  payload,
});
export const userUpdateAntivirusSettingsFail = payload => ({
  type: USER_UPDATE_ANTIVIRUS_SETTINGS_FAILURE,
  payload,
});
export const userUpdateEncryptionSettingsSubmit = payload => ({
  type: USER_UPDATE_ENCRYPTION_SETTINGS_SUBMIT,
  payload,
});
export const userUpdateEncryptionSettingsSuccess = payload => ({
  type: USER_UPDATE_ENCRYPTION_SETTINGS_SUCCESS,
  payload,
});
export const userUpdateEncryptionSettingsFail = payload => ({
  type: USER_UPDATE_ENCRYPTION_SETTINGS_FAILURE,
  payload,
});

export const userSetAvatarSubmit = payload => ({
  type: SET_NEW_AVATAR_SUBMIT,
  payload,
});
export const userSetAvatarSuccess = payload => ({
  type: SET_NEW_AVATAR_SUCCESS,
  payload,
});
export const userSetAvatarFail = payload => ({
  type: SET_NEW_AVATAR_FAILURE,
  payload,
});

export const userChangePasswordSubmit = payload => ({
  type: USER_CHANGE_PASSWORD_SUBMIT,
  payload,
});

export const userChangePasswordSuccess = (payload) => {
  toast.success('Password updated. Good job!');
  return {
    type: USER_CHANGE_PASSWORD_SUCCESS,
    payload,
  };
};

export const userChangePasswordFailure = (payload) => {
  toast.warn(`${payload.data.message}`);
  return {
    type: USER_CHANGE_PASSWORD_FAILURE,
    payload,
  };
};


export const userSentVerLinkFailure = payload => ({
  type: USER_SENT_VER_LINK_FAILURE,
  payload,
});
export const userSentVerLinkSuccess = (payload) => {
  toast.success('Verification Link sent');
  return {
    type: USER_SENT_VER_LINK_SUCCESS,
    payload,
  };
};
export const userSentVerLinkSubmit = payload => ({
  type: USER_SENT_VER_LINK_SUBMIT,
  payload,
});
export const userChangeEmailSubmit = payload => ({
  type: USER_CHANGE_EMAIL_SUBMIT,
  payload,
});

export const userChangeEmailSuccess = (payload) => {
  toast.success('Email successfully changed');
  return {
    type: USER_CHANGE_EMAIL_SUCCESS,
    payload,
  };
};

export const userChangeEmailFailure = (payload) => {
  toast.warn('E-mail already registered');
  return {
    type: USER_CHANGE_EMAIL_FAILURE,
    payload,
  };
};

export const getUserDataSubmit = payload => ({
  type: GET_USER_DATA_SUBMIT,
  payload,
});

export const getUserDataSuccess = payload => ({
  type: GET_USER_DATA_SUCCESS,
  payload,
});

export const getUserDataFailure = payload => ({
  type: GET_USER_DATA_FAILURE,
  payload,
});
export const getUserSubmit = payload => ({
  type: GET_USER_SUBMIT,
  payload,
});

export const getUserSuccess = payload => ({
  type: GET_USER_SUCCESS,
  payload,
});

export const getUserFailure = payload => ({
  type: GET_USER_FAILURE,
  payload,
});

export const setPasswordSubmit = payload => ({
  type: SET_PASSWORD_SUBMIT,
  payload,
});

export const setPasswordSuccess = (payload) => {
  toast.success('Password successfully changed');
  history.push('/login');

  return {
    type: SET_PASSWORD_SUCCESS,
    payload,
  };
};

export const setPasswordFailure = (payload) => {
  toast.warn('Oops something went wrong');

  return {
    type: SET_PASSWORD_FAILURE,
    payload,
  };
};

export const forgotPasswordSubmit = payload => ({
  type: FORGOT_PASSWORD_SUBMIT,
  payload,
});

export const forgotPasswordSuccess = payload => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPasswordFailure = payload => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload,
});
