import React from 'react';

const LogoNewDefault = () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 1888 342"
    style={{ enableBackground: 'new 0 0 1888 342' }}

  >

    <g>
      <g>
        <path d="M770.8,86.1v178.5h-33.9l-89-108.4v108.4h-40.8V86.1h34.2L730,194.5V86.1H770.8z" />
        <path d="M920.7,234v30.6H798.3v-24l69.9-82.6h-68.1v-30.6h118.3v24L848.5,234H920.7z" />
        <path d="M1059.5,134.2c10.5,5.9,18.7,14.1,24.6,24.7c5.9,10.6,8.9,23,8.9,37.1c0,14.1-3,26.5-8.9,37.1
			c-6,10.6-14.2,18.9-24.6,24.7c-10.5,5.9-22.1,8.8-34.8,8.8c-18.9,0-33.2-5.9-43.1-17.8v15.8h-38V75.4h39.8v66.6
			c10-11,23.8-16.6,41.3-16.6C1037.5,125.4,1049.1,128.3,1059.5,134.2z M1043,223.7c6.5-6.9,9.8-16.1,9.8-27.7
			c0-11.6-3.3-20.8-9.8-27.7c-6.5-6.9-14.9-10.3-25.1-10.3c-10.2,0-18.6,3.4-25.1,10.3c-6.5,6.9-9.8,16.1-9.8,27.7
			c0,11.6,3.3,20.8,9.8,27.7c6.5,6.9,14.9,10.3,25.1,10.3C1028.1,234,1036.4,230.5,1043,223.7z"
        />
        <path
          d="M1144,257.6c-11.6-6-20.7-14.4-27.3-25.1c-6.5-10.7-9.8-22.9-9.8-36.5c0-13.6,3.3-25.8,9.8-36.5
			c6.5-10.7,15.6-19.1,27.3-25.1c11.6-6,24.8-9.1,39.4-9.1c14.4,0,27.1,3,37.9,9.1c10.8,6,18.7,14.7,23.6,25.9l-30.9,16.6
			c-7.1-12.6-17.4-18.9-30.9-18.9c-10.4,0-19,3.4-25.8,10.2c-6.8,6.8-10.2,16.1-10.2,27.8c0,11.7,3.4,21,10.2,27.8
			c6.8,6.8,15.4,10.2,25.8,10.2c13.6,0,23.9-6.3,30.9-18.9l30.9,16.8c-4.9,10.9-12.8,19.4-23.6,25.5c-10.8,6.1-23.4,9.2-37.9,9.2
			C1168.7,266.6,1155.6,263.6,1144,257.6z"
          fill="#2C5CE2"
        />
        <path d="M1264.2,75.4h39.8v189.2h-39.8V75.4z" fill="#2C5CE2" />
        <path
          className="st0"
          d="M1364.8,257.6c-11.5-6-20.4-14.4-26.9-25.1c-6.5-10.7-9.7-22.9-9.7-36.5c0-13.6,3.2-25.8,9.7-36.5
			c6.5-10.7,15.4-19.1,26.9-25.1c11.5-6,24.4-9.1,38.9-9.1c14.4,0,27.4,3,38.8,9.1c11.4,6,20.3,14.4,26.8,25.1
			c6.5,10.7,9.7,22.9,9.7,36.5c0,13.6-3.2,25.8-9.7,36.5c-6.5,10.7-15.4,19.1-26.8,25.1c-11.4,6-24.3,9.1-38.8,9.1
			C1389.2,266.6,1376.3,263.6,1364.8,257.6z M1428.8,223.7c6.5-6.9,9.8-16.1,9.8-27.7c0-11.6-3.3-20.8-9.8-27.7
			c-6.5-6.9-14.9-10.3-25.1-10.3c-10.2,0-18.6,3.4-25.2,10.3s-9.9,16.1-9.9,27.7c0,11.6,3.3,20.8,9.9,27.7s15,10.3,25.2,10.3
			C1413.9,234,1422.2,230.5,1428.8,223.7z"
          fill="#2C5CE2"
        />
        <path

          d="M1641.1,127.4v137.2h-37.7v-16.3c-5.3,6-11.6,10.5-18.9,13.6c-7.3,3.1-15.2,4.7-23.7,4.7
			c-18,0-32.3-5.2-42.8-15.6c-10.5-10.4-15.8-25.8-15.8-46.2v-77.5h39.8V199c0,22.1,9.3,33.2,27.8,33.2c9.5,0,17.2-3.1,22.9-9.3
			c5.8-6.2,8.7-15.4,8.7-27.7v-67.8H1641.1z"
          fill="#2C5CE2"
        />
        <path

          d="M1815.2,75.4v189.2h-38v-15.8c-9.9,11.9-24.1,17.8-42.8,17.8c-12.9,0-24.6-2.9-35.1-8.7
			c-10.5-5.8-18.7-14-24.6-24.7c-6-10.7-8.9-23.1-8.9-37.2c0-14.1,3-26.5,8.9-37.2c5.9-10.7,14.2-19,24.6-24.7
			c10.5-5.8,22.1-8.7,35.1-8.7c17.5,0,31.2,5.5,41.1,16.6V75.4H1815.2z M1766.3,223.7c6.6-6.9,9.9-16.1,9.9-27.7
			c0-11.6-3.3-20.8-9.9-27.7c-6.6-6.9-15-10.3-25-10.3c-10.2,0-18.6,3.4-25.2,10.3c-6.6,6.9-9.9,16.1-9.9,27.7
			c0,11.6,3.3,20.8,9.9,27.7c6.6,6.9,15,10.3,25.2,10.3C1751.3,234,1759.6,230.5,1766.3,223.7z"
          fill="#2C5CE2"
        />
        <path

          d="M1845.7,259.3c-4.8-4.8-7.1-10.7-7.1-17.9c0-7.3,2.4-13.2,7.1-17.7c4.8-4.5,10.6-6.8,17.6-6.8
			c7,0,12.8,2.3,17.6,6.8c4.8,4.5,7.1,10.4,7.1,17.7c0,7.1-2.4,13.1-7.1,17.9c-4.8,4.8-10.6,7.1-17.6,7.1
			C1856.3,266.5,1850.4,264.1,1845.7,259.3z"
          fill="#2C5CE2"
        />
      </g>
      <g>
        <g>
          <path

            d="M205.2,256.9h-61.5c-32.4,0-58.7-26.3-58.7-58.7c0-32.4,26.3-58.7,58.7-58.7c14.6,0,28.4,5.4,39,14.9
				c2.9-30.3,13.8-58.3,30.5-81.9c-21-11.6-44.8-18-69.6-18C64.5,54.6,0,119,0,198.2c0,79.2,64.5,143.7,143.7,143.7h218.6v-0.3
				c-3,0.2-6,0.3-9.1,0.3C290.1,342,234.9,307.8,205.2,256.9z"
            fill="#194AD1"
          />
          <path

            d="M205.2,256.9L205.2,256.9c0,0,6,48.4,42.5,85h105.2C289.9,341.9,234.8,307.7,205.2,256.9z"
            fill="#07214F"
          />
        </g>
        <g>
          <path

            d="M352.5,0.1c-94.2,0.2-170.8,77-170.6,171.3s77,170.8,171.3,170.6s170.8-77,170.6-171.3
				C523.6,76.4,446.8-0.1,352.5,0.1z M353,256.9c-47.4,0.1-86-38.4-86.1-85.8c-0.1-47.4,38.4-86,85.8-86.1
				c47.4-0.1,86,38.4,86.1,85.8C438.9,218.2,400.4,256.8,353,256.9z M432.4,64.6c-8.9,7.1-13.3,17.7-12.9,28.2
				c-18-15.4-41.4-24.7-66.9-24.6c-56.7,0.1-102.7,46.3-102.6,103c0.1,56.7,46.3,102.7,103,102.6c14.1,0,25.6,11.4,25.6,25.6
				c0,14.1-11.4,25.6-25.6,25.6c-84.9,0.2-154.2-68.8-154.3-153.7S267.6,17.1,352.6,17c40.1-0.1,76.7,15.3,104.2,40.4
				C448.4,56.6,439.6,58.9,432.4,64.6z"
            fill="#2C5CE2"
          />
          <path

            d="M432.4,64.6c-8.9,7.1-13.3,17.7-12.9,28.2c-18-15.4-41.4-24.7-66.9-24.6c-56.7,0.1-102.7,46.3-102.6,103
				c0.1,56.7,46.3,102.7,103,102.6c14.1,0,25.6,11.4,25.6,25.6c0,14.1-11.4,25.6-25.6,25.6c-84.9,0.2-154.2-68.8-154.3-153.7
				S267.6,17.1,352.6,17c40.1-0.1,76.7,15.3,104.2,40.4C448.4,56.6,439.6,58.9,432.4,64.6z"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default LogoNewDefault;
