import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { compose } from 'redux';
import { withStyles, ThemeProvider } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import StorageIcon from '@material-ui/icons/Storage';
import Fab from '@material-ui/core/Fab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Tooltip } from '@material-ui/core';
import {
  downloadByLinkLinkSubmit,
  filesUploadSubmit,
} from '../../../redux/files/filesActions';
import {
  getExternalDrivesListSubmit,
  getUserStorageSubmit,
  onStorageSelectFolder,
  setDownloadDestinationSubmit,
} from '../../../redux/userStorage/userStorageActions';
import FilesGrid from './Grid';
import FilesList from './List';
import StyledDropzone from './DropZone';
import BrowseStorageSelector from './BrowseStorageSelector';
// import NZBCloudStorageIcon from '../../atoms/NZBCloudStorageIcon';
import ActiveJobs from './ActiveJobs/ActiveJobs';
import StorageLeft from './BrowseStorageSelector/storageLeft';
import ActiveDownloadsSection from '../activeDownloadsSection';
import WarningBanner from '../WarningBanner';


const styles = theme => ({
  root: {
    display: 'flex',
  },
  storageContainer: {
    paddingTop: 20,
  },
  fileTypeTitle: {
    color: 'rgba(0,0,0,0.6)',
    // fontFamily: 'Roboto, san-serif',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
  },
  filesContainer: {
    display: 'flex',
    marginTop: 20,
    width: '100%',
    flexWrap: 'wrap',
  },
  uploadButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: 20,
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none',
    // },
  },
  mobileUploadButtonContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  fabUpload: {
    position: 'absolute',
    bottom: theme.spacing(12),
    right: theme.spacing(2),
    backgroundColor: '#4744DC',
  },
  fabUploadSecondary: {
    position: 'absolute',
    bottom: theme.spacing(12),
    right: theme.spacing(2),
    backgroundColor: '#9832ee',
    animation: '$blinker 0.4s',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  breadcrumbWrapper: {
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    paddingTop: 5,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mobileBreadcrumbWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    width: '100%',
    paddingTop: 5,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  rootStorageButton: {
    color: 'rgba(0,0,0,0,6)',
    fontSize: 34,
    cursor: 'pointer',
    marginRight: 5,
  },
  uploadButton: {
    backgroundColor: '#2158eb',
    // height: 50,
    width: 185,
    borderRadius: 2,
    height: 52,
    fontSize: 15,
    textTransform: 'none',

    transition: 'all .3s ease-in-out',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
      textDecoration: 'none',
    },
  },
  uploadButtonSecondary: {
    backgroundColor: '#9832ee',
    // height: 50,
    width: 185,
    borderRadius: 2,
    height: 52,
    animation: '$blinker 0.4s',
    transition: 'all .3s ease-in-out',
    fontSize: 15,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#9832ee',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
      textDecoration: 'none',
    },
  },
  inputPaper: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    border: '1px solid rgba(237, 237, 237, 1)',
    height: 54,
    marginRight: 9,
    borderRadius: 2,
  },
  inputPaperMobile: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 234,
    border: '1px solid rgba(237, 237, 237, 1)',
    height: 52,
    margin: 9,
    position: 'absolute',
    right: 150,
    bottom: 85,
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    boxSizing: 'border-box',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  downloadBlock: {
    display: 'flex',
    border: '1px solid #EDEDED',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: 3,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  rootFolder: {
    textTransform: 'none',
    color: 'rgba(0,0,0,0.6)',
    display: 'flex',
    height: 35,
    padding: '4px 10px',
    backgroundColor: '#F0F0F0',
    border: '1px solid #EDEDED',
    '&:hover': {
      backgroundColor: '#E5E5E5',

    },
  },
  '@keyframes blinker': {
    '50%': {
      opacity: 0.7,
    },
  },
});

class FilesTab extends React.Component {
  constructor(props) {
    super(props);
    this.handleUploadNZB = this.handleUploadNZB.bind(this);
    this.handleGetFiles = this.handleGetFiles.bind(this);
    this.handleSendLink = this.handleSendLink.bind(this);
    this.handleInputLinkChange = this.handleInputLinkChange.bind(this);
    this.handleWhereToSelect = this.handleWhereToSelect.bind(this);
    this.handleMobileBreadCrumb = this.handleMobileBreadCrumb.bind(this);
    this.state = {
      downloadByLinkUrl: '',
    };
  }

  componentDidMount() {
    const {
      getStorageSubmit,
      userStorage,
    } = this.props;
    const { path } = userStorage;
    // getExternalDrivesList();
    if (!path.length) {
      getStorageSubmit();
    } else {
      this.handleBreadCrumbClick(path[path.length - 1]);
    }
  }

  handleUploadNZB(event) {
    const { files } = event.target;
    const { filesUploadSubmit } = this.props;
    if (files.length) {
      filesUploadSubmit(files);
      event.target.value = null;
    }
  }

  handleGetFiles() {
    const { getStorageSubmit } = this.props;
    getStorageSubmit();
  }

  handlePasteFromCp() {
    navigator.clipboard.readText().then(text => this.setState({ downloadByLinkUrl: text }));
  }

  handleClearInput() {
    this.setState({ downloadByLinkUrl: '' });
  }

  handleBreadCrumbClick(data) {
    const { onFolderSelect } = this.props;
    onFolderSelect(data);
  }

  handleWhereToSelect(event) {
    const { setDownloadDestinationSubmit } = this.props;
    event.preventDefault();
    setDownloadDestinationSubmit(event.target.value._id);
  }

  handleSendLink(link) {
    const { downloadByLinkLinkSubmit } = this.props;
    downloadByLinkLinkSubmit(link);
    this.setState({ downloadByLinkUrl: '' });
  }

  handleInputLinkChange(event) {
    this.setState({ downloadByLinkUrl: event.target.value });
  }

  handleMobileBreadCrumb() {
    const { userStorage } = this.props;
    const { path } = userStorage;
    if (path[path.length - 2] === undefined) {
      this.handleGetFiles();
    } else {
      this.handleBreadCrumbClick(path[path.length - 2]);
    }
  }

  render() {
    const {
      userStorage,
      usenetReducer,
      classes,
      user,
    } = this.props;
    const {
      folders,
      files,
      displayType,
      path,
      targetStorage,
    } = userStorage;
    const { isSubscriptionActive, isUsenetConfigExist } = user;
    const { credentials } = usenetReducer;
    const validationStatus = !credentials.length || credentials.find(config => config.validationStatus === true);
    const isDownloadsBlocked = !isUsenetConfigExist || !isSubscriptionActive;

    const { downloadByLinkUrl } = this.state;
    return (
      <div className="step2">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-between',
            // alignItems: 'center',
          }}

        >

          <div
            className={classes.downloadBlock}
          >
            <div className="step3">
              <div
                style={{
                  display: 'flex',
                  borderBottom: '1px solid #EDEDED',
                  padding: '11px 20px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={this.handleGetFiles}
                >
                  <CloudDownloadIcon style={{
                    marginRight: 8,
                    color: '#2c5ce2',
                  }}
                  />
                  <span style={{
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                  >
                    {' '}
                    Download
                  </span>
                </div>
                <BrowseStorageSelector />
              </div>

              <div className="step5">
                <div className={classes.uploadButtonContainer}>


                  <Paper
                    elevation={0}
                    component="form"
                    className={classes.inputPaper}
                  >
                    <InputBase
                      className={classes.input}
                      placeholder="Paste link to NZB file"
                      inputProps={{ 'aria-label': 'Paste link to NZB file' }}
                      onChange={this.handleInputLinkChange}
                      value={downloadByLinkUrl}
                    />
                    <Divider className={classes.divider} orientation="vertical" />


                    {downloadByLinkUrl ? (
                      <Tooltip title="Clear">
                        <IconButton
                          color="primary"
                          className={classes.iconButton}
                          aria-label="directions"
                        // disabled={!downloadByLinkUrl}
                          onClick={() => this.handleClearInput()}
                        >

                          <BackspaceOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Paste from clipboard">
                        <IconButton
                          color="primary"
                          className={classes.iconButton}
                          aria-label="directions"
                        // disabled={!downloadByLinkUrl}
                          onClick={() => this.handlePasteFromCp()}
                        >

                          <AssignmentOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                  </Paper>
                  <input
                    accept=".nzb,.gz,.zip,.gzip,.tar,.tar.gz,.tgz"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={this.handleUploadNZB}
                    disabled={isDownloadsBlocked}
                  />
                  {downloadByLinkUrl ? (
                    <Button
                      variant="contained"
                      disableElevation
                      className={classes.uploadButtonSecondary}
                      color="primary"
                      component="span"
                      onClick={() => this.handleSendLink(downloadByLinkUrl)}
                      disabled={isDownloadsBlocked}
                    >
                      <CloudUploadIcon className={classes.extendedIcon} />
                      Upload link
                    </Button>
                  )
                    : (
                      <label htmlFor="contained-button-file" className="step7">
                        <Button
                          variant="contained"
                          disableElevation
                          className={classes.uploadButton}
                          color="primary"
                          component="div"
                          disabled={isDownloadsBlocked}
                        >
                          <NoteAddOutlinedIcon className={classes.extendedIcon} />
                          Add NZB file
                        </Button>
                       </label>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div style={{
            backgroundColor: 'white',
            marginTop: 20,
            border: '1px solid #EDEDED',
          }}
          >
            <div style={{
              display: 'flex',
              // backgroundColor: 'white',
              flexDirection: 'column',
              // marginTop: 20,
              // border: '1px solid #EDEDED',
            }}
            >
              <div style={{
                display: 'flex',
                borderBottom: '1px solid #EDEDED',
                padding: 20,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              >
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                >
                  <StorageIcon style={{
                    marginRight: 8,
                    color: '#2c5ce2',
                  }}
                  />
                  <span style={{
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                  >
                    {' '}
                    Your downloads
                  </span>
                </div>
                <ActiveJobs onMediaFileClick={() => console.log('F')} />
              </div>
              <ActiveDownloadsSection />
              <div style={{ paddingLeft: 20 }}>
                {' '}
                <Typography>Finished downloads</Typography>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', padding: '10px 20px 0 20px' }}>

                <div className={classes.breadcrumbWrapper}>
                  <Button

                    className={classes.rootFolder}
                    component="div"
                    onClick={this.handleGetFiles}
                  >
                    <HomeIcon style={{ marginRight: 5 }} />
                    {' '}
                    {targetStorage === 'NZBCLOUD' ? 'SafeLoaded' : targetStorage}
                    {' Root Folder'}
                  </Button>
                  {path.map((crumb) => {
                    const crumbName = crumb.customName || crumb.name;
                    return (
                      <div
                        style={{
                          display: 'flex',
                          height: 25,
                        }}
                        key={crumb._id}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: 'rgba(0,0,0,0.38)',
                            paddingLeft: 5,
                          }}
                        >
                          /
                        </div>
                        <Button
                          style={{
                            textTransform: 'none',
                            color: 'rgba(0,0,0,0.38)',

                          }}
                          component="div"
                          onClick={() => this.handleBreadCrumbClick(crumb)}
                        >
                          <div
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '8rem',
                            }}
                          >
                            <Typography noWrap className={classes.heading}>
                              {crumbName}
                            </Typography>
                          </div>
                        </Button>
                      </div>
                    );
                  })}
                </div>
                <div className={classes.mobileBreadcrumbWrapper}>
                  {path.length ? (
                    <div
                      style={{
                        textTransform: 'none',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        width: '100%',
                        overflow: 'hidden',
                      }}
                      className={classes.rootStorageButton}
                      onClick={() => this.handleMobileBreadCrumb()}
                    >
                      <ArrowBackIcon />
                      {/* {path[path.length - 1].customName */}
                      {/* || path[path.length - 1].name} */}
                    </div>
                  ) : (
                    <Button
                      style={{
                        textTransform: 'none',
                        color: 'rgba(0,0,0,0.6)',
                        display: 'flex',
                        height: 48,
                      }}
                      component="div"
                      onClick={this.handleGetFiles}
                    >
                      <HomeIcon style={{ marginRight: 5 }} />
                      {' '}
                      Root Folder
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <StyledDropzone
              displayType={displayType}
              folders={folders}
              files={files}
              isDownloadsBlocked={isDownloadsBlocked}
              childrens={(
                <>
                  {displayType === 'list' ? (
                    <FilesList folders={folders} files={files} />
                  ) : (
                    <FilesGrid folders={folders} files={files} />
                  )}
                </>
              )}
            />
            <div style={{
              padding: 20,
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: '1px solid #EDEDED',
              fontWeight: 500,
            }}
            >
              {' '}
              Free storage:
              <div style={{ color: '#2158eb', paddingLeft: 5 }}>
                <StorageLeft />
              </div>
            </div>
          </div>

        </div>
        <div className={classes.mobileUploadButtonContainer}>
          <Paper
            elevation={0}
            component="form"
            className={classes.inputPaperMobile}
          >
            <InputBase
              className={classes.input}
              placeholder="Paste link to NZB file"
              inputProps={{ 'aria-label': 'Paste link to NZB file' }}
              onChange={this.handleInputLinkChange}
              value={downloadByLinkUrl}
              disabled={isDownloadsBlocked}
            />
            <Divider className={classes.divider} orientation="vertical" />
            <Tooltip title="Clear">
              <span>
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="directions"
                  disabled={isDownloadsBlocked}
                  onClick={() => this.handleClearInput()}
                >

                  <BackspaceOutlinedIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Paste from clipboard">
              <span>
                <IconButton
                  color="primary"
                  className={classes.iconButton}
                  aria-label="directions"
                  disabled={isDownloadsBlocked}
                  onClick={() => this.handlePasteFromCp()}
                >

                  <AssignmentOutlinedIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Paper>
          {downloadByLinkUrl ? (
            // <Button
            //   variant="contained"
            //   disableElevation
            //   className={classes.uploadButton}
            //   color="primary"
            //   component="span"
            //   onClick={() => this.handleSendLink(downloadByLinkUrl)}
            // >
            //   <CloudUploadIcon className={classes.extendedIcon} />
            //
            // </Button>
            <Fab
              variant="extended"
              size="large"
              color="primary"
              component="span"
              className={classes.fabUploadSecondary}
              onClick={() => this.handleSendLink(downloadByLinkUrl)}
              disabled={isDownloadsBlocked}
            >
              <CloudUploadIcon className={classes.extendedIcon} />
              UPLOAD
            </Fab>
          )
            : (
              <label htmlFor="contained-button-file">
                <Fab
                  variant="extended"
                  size="large"
                  color="primary"
                  component="span"
                  className={classes.fabUpload}
                  disabled={isDownloadsBlocked}
                >
                  <CloudUploadIcon className={classes.extendedIcon} />
                  BROWSE
                </Fab>
              </label>
            )}

        </div>
      </div>


    );
  }
}

const mapStateToProps = ({
  userStorage,
  usenetReducer,
  user,
}) => ({
  userStorage,
  usenetReducer,
  user,
});
const mapDispatchToProps = dispatch => ({
  onFolderSelect: (folder) => {
    dispatch(onStorageSelectFolder(folder));
  },
  filesUploadSubmit: (data) => {
    dispatch(filesUploadSubmit(data));
  },
  downloadByLinkLinkSubmit: (data) => {
    dispatch(downloadByLinkLinkSubmit(data));
  },
  getStorageSubmit: () => {
    dispatch(getUserStorageSubmit());
  },
  getExternalDrivesList: () => {
    dispatch(getExternalDrivesListSubmit());
  },
  setDownloadDestinationSubmit: (drive) => {
    dispatch(setDownloadDestinationSubmit(drive));
  },
});

export default compose(
  withStyles(styles),
  withWidth(),
  connect(mapStateToProps, mapDispatchToProps),
)(FilesTab);
