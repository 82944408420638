import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import history from '../../config/history';
import Icon404 from '../atoms/404/404.svg';

const useStyles = makeStyles(theme => ({

  emptyWrapperTab: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // backgroundColor: '#f3f3f3',
    maxWidth: 1250,
    height: 540,
    justifyContent: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 200,
    [theme.breakpoints.down('lg')]: {
      marginTop: 170,
      maxWidth: 830,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      maxWidth: 330,
      textAlign: 'center',
    },
  },
  primaryText: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: 80,
    marginBottom: 14,
    fontWeight: 700,
    fontSize: 24,
    // fontFamily: 'Roboto, san-serif',
    [theme.breakpoints.down('lg')]: {
      marginTop: 60,

    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 50,
    },
  },
}));

export default function Page404(props) {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <div className={classes.emptyWrapperTab}>
        <img style={{ width: '100%' }} src={Icon404} alt="Empty" />
        <div className={classes.primaryText} style={{}}>
          Something went wrong
        </div>
        <div
          style={{
            color: 'rgba(0, 0, 0, 0.6)',
            fontWeight: 400,
            fontSize: 16,
            // fontFamily: 'Roboto, san-serif',
          }}
        >
          The page you are looking for doesn&apos;t exist.
          {' '}
          <span
            style={{ color: 'rgb(0, 134, 250)', cursor: 'pointer' }}
            onClick={() => history.push('/dashboard/downloads')}
          >
            Back to home
          </span>
        </div>
      </div>
    </Container>
  );
}
