import {
  CONTACTS_SEND_MESSAGE_SUBMIT,
  CONTACTS_SEND_MESSAGE_SUCCESS,
  CONTACTS_SEND_MESSAGE_FAILURE,
  BUG_REPORT_SEND_MESSAGE_SUBMIT,
  BUG_REPORT_SEND_MESSAGE_SUCCESS,
} from './contactsActions';
import { SET_BUG_REPORT_MODAL_OPEN } from '../viewsEffects/viewsEffectsActions';

const initState = {
  contactsLoading: false,
  contactsError: false,
  reportLoading: false,
  reportLoadingComplete: false,
};

function contactsReducer(state = initState, { type, payload }) {
  switch (type) {
    case BUG_REPORT_SEND_MESSAGE_SUBMIT: {
      return {
        ...state,
        reportLoading: true,
      };
    }
    case SET_BUG_REPORT_MODAL_OPEN: {
      return {
        ...state,
        reportLoading: false,
        reportLoadingComplete: false,
      };
    }
    case BUG_REPORT_SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        reportLoadingComplete: true,
        reportLoading: false,
      };
    }
    case CONTACTS_SEND_MESSAGE_SUBMIT: {
      return {
        ...state,
        contactsLoading: true,
      };
    }

    case CONTACTS_SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        contactsLoading: false,
      };
    }
    case CONTACTS_SEND_MESSAGE_FAILURE: {
      return {
        ...state,
        contactsError: true,
      };
    }

    default: {
      return state;
    }
  }
}

export default contactsReducer;
