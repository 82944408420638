import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ServicePageHeaders from '../ServicePageHeaders';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
}));
const PageName = 'Privacy Policy';
const BoldHeader = 'Read it carefully';
const Subtitle = '';
export default function PrivacyPolicy() {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container className={classes.container} component="main" maxWidth="md">
        <ServicePageHeaders
          pageName={PageName}
          boldHeader={BoldHeader}
          subtitle={Subtitle}
        />

        <div style={{ padding: '5px 32px 32px 32px' }}>
          <Typography variant="caption">
            NZBCloud is committed to protecting your privacy when you visit
            nzbcloud.com (‘Site’) or use its services (‘Services’) as further
            outlined in this policy. As a condition of using services of
            nzbcloud.com, you agree to provide nzbcloud.com with certain
            personal information. This information includes, but is not limited
            to: email address and your computer's unique IP address (if any),
            and financial information (email address used by your Paypal
            account, etc). Please note that nowhere on the Site do we knowingly
            collect contact information or financial information from children
            under the age of 13. Be kindly informed that we may make some
            changes to this Privacy Policy in the future without any
            notifications. Please review this section periodically to stay up to
            date. Each time you visit the Site, use the Services, or provide us
            with information, you are accepting the practices described in this
            privacy policy at that time. You agree that by using nzbcloud.com
            you are expressly and affirmatively consenting to our use and
            disclosure of the information that you provide, and consenting to
            receive emails, as described below in this privacy policy. This
            policy may be revised over time as new features are added to the
            Site or as we incorporate suggestions from our users. If we are
            going to use or disclose your personally identifiable information in
            a manner materially different from that stated at the time we
            collected the information, you will have a choice as to whether or
            not we use or disclose your information in this new manner. We will
            also post a notification that the Privacy Policy has been amended
            prominently on the Site so that you can always review what
            information we gather, how we might use that information, and under
            which conditions we will disclose it to anyone. We use contact
            information i.e. your email address to help us efficiently perform
            account tasks (changing passwords, retrieving lost passwords), to
            deliver the services you have requested, to perform quality
            assurance, sales analyses and other business analyses, and to
            contact you in connection with any orders that you have placed with
            us. No other use of your financial information will be made except
            to satisfy any law, regulation, governmental request, or judicial
            order. When you make a purchase through the Site, or register with
            the Site, you will provide us with an email address where we, or
            these service providers, may contact you for the purposes described
            in this paragraph. You agree, in consideration for the use of the
            Services provided by nzbcloud.com, to allow the Site to use your
            personal information to send you marketing and promotional
            materials. We may also send you marketing and promotional materials
            that promote third party products. At the same time, nzbcloud.com
            will not rent or sell your personal information for use by third
            parties. These materials may include, but are not limited to:
            newsletters providing you with additional information on how to use
            the Services, and promotional offers for goods and services from
            nzbcloud.com or third parties. NZBCloud may use your demographic
            information to perform business analyses or to tailor the Site and
            communications to your interests. We may share anonymous, aggregated
            demographic information with advertisers and other third parties so
            that they can tailor their advertisements and communications to the
            appropriate audience. Any time that we are allowed under this
            privacy policy to share your contact information or financial
            information, we may also share your demographic information along
            with it. We may also disclose specific contact information when we
            determine that such disclosure is necessary to comply with law, to
            cooperate with or seek assistance from law enforcement or to protect
            the interests or safety of nzbcloud.com or other visitors to the
            site or users of the Services. Also, your contact information may be
            passed on to a third party in the event of a merger, acquisition,
            consolidation, divestiture, or a bankruptcy of nzbcloud.com. Cookies
            A "cookie" is a small file that we save on your computer's hard
            drive that contains non-personal information. These cookies help to
            give you faster access to pages you have already visited. They also
            allow you to personalize your pages, and optimize your experience at
            our sites. We also use cookies to help us figure out how many people
            visit our sites, which of our web pages they visit, and how long
            they stay there. This information helps us figure out which of our
            web features are highly successful and which sites might need
            improvement. You can disable cookies on your computer by indicating
            this in the preferences or options menus in your browser. Most
            browsers accept cookies automatically, but allow you to disable
            them. Disabling cookies may prevent you from properly using the
            Services or accessing the site. Sessions "Session IDs" allow us to
            identify a particular user across multiple web page requests. This
            Session ID is maintained within your cookie file, where possible. If
            cookies are not enabled, or if the user's internet browser program
            does not support cookies then nzbcloud.com will place the Session ID
            in the requested web page. This allows the end user to avoid having
            to continually re-enter certain information such as account name and
            password for every web page request. This Session ID expires
            whenever the user closes their internet browser. IP Address Each
            time you visit the Site, we automatically collect your IP address
            and the web page from which you came. In order to administer and
            optimize the Site for you and to diagnose problems with our Site, we
            use your IP address to help identify you and to gather broad
            demographic information about you. We also use your IP address to
            restrict the access of your account to broader users. Storage You
            understand and agree that nzbcloud.com stores and processes your
            information on computers located in the Netherlands, and by
            providing any data to nzbcloud.com, you consent to the transfer of
            such information to the Netherlands. When you delete a file from
            your NZBCloud account, no link will remain between you and that
            file. Deleted files may stay cached in nzbcloud.com servers to save
            bandwidth in case other people request them.
          </Typography>
        </div>
      </Container>
    </>
  );
}
