import React from 'react';

const DoneZip = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Complete</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Complete"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="icon/action/folder">
        <g id="folder-24px">
          <polygon id="Path" points="0 0 40 0 40 40 0 40" />
          <path
            d="M16.6666667,6.66666667 L6.66666667,6.66666667 C4.83333333,6.66666667 3.35,8.16666667 3.35,10 L3.33333333,30 C3.33333333,31.8333333 4.83333333,33.3333333 6.66666667,33.3333333 L33.3333333,33.3333333 C35.1666667,33.3333333 36.6666667,31.8333333 36.6666667,30 L36.6666667,13.3333333 C36.6666667,11.5 35.1666667,10 33.3333333,10 L20,10 L16.6666667,6.66666667 Z"
            id="Path"
            fill="#FCB643"
          />
        </g>
      </g>
      <g id="Check" transform="translate(13.000000, 13.000000)">
        <rect id="Rectangle" x="0" y="0" width="16" height="16" />
        <g
          id="correct"
          transform="translate(0.000000, 1.764988)"
          fill="#E09D2D"
          fillRule="nonzero"
        >
          <path
            d="M6.12668939,12.1999758 C5.97395971,12.353603 5.76558317,12.4393171 5.54912884,12.4393171 C5.33267451,12.4393171 5.12429797,12.353603 4.97156829,12.1999758 L0.359012002,7.58667153 C-0.119670667,7.10798886 -0.119670667,6.33177501 0.359012002,5.85398989 L0.93657255,5.27627972 C1.41540484,4.79759705 2.19072114,4.79759705 2.66940381,5.27627972 L5.54912884,8.15615433 L13.3305644,0.374569189 C13.8093967,-0.104113481 14.5854609,-0.104113481 15.0633957,0.374569189 L15.6409562,0.952279354 C16.1196389,1.43096202 16.1196389,2.20702626 15.6409562,2.684961 L6.12668939,12.1999758 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DoneZip;
