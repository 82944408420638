import * as React from 'react';
import useStyles from './styles';
import EncodeBadge from './encodeBadge';

const styleHidden = {


};


const TitleOverlay = ({
  isUserActive, isPaused, button, setProperties, handleEncode, encodeBadgeVisible, transcodeStatus,
}) => {
  const classes = useStyles();
  const handleToggle = () => {
    if (setProperties) {
      setProperties({ isPaused: !isPaused, isUserActive: true });
    }
  };

  return (
    <div
      className={classes.titleOverlay}
      onClick={handleToggle}
    >
      <div className={isPaused ? classes.pauseButton : classes.playButton}>
        {button}
      </div>
      {encodeBadgeVisible && (
      <div className={classes.encodeBadgeWrapper}>
        <EncodeBadge handleEncode={handleEncode} transcodeStatus={transcodeStatus} />
      </div>
      )
        }

    </div>
  );
};

export default TitleOverlay;
