import React from 'react';
import ListItem from '@material-ui/core/ListItem';

import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';


import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';

import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FileIcon from '../../../atoms/FileIcon';
import AnimatedEllipsis from '../../../atoms/AnimatedEllipsis';
import EncodeStatusProgressBadge from '../EncodeStatusBadges/EncodeStatusProgressBadge';
import LottieAnimationPlayer from '../../../atoms/LottieAnimationPlayer';

const useStyles = makeStyles(theme => ({
  listItemWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    height: '100%',
    justifyContent: 'space-between',
    minWidth: 310,
    zIndex: 2,
  },
  statusIconWrapper: {
    display: 'flex',
  },
  listItem: {
    height: 64,
    marginBottom: 6,
    padding: 0,
    backgroundColor: '#ffffff',
    borderRadius: 2,
    overflow: 'hidden',

  },
  transcodeIconStatusWrapper: {
    width: 15,
    height: 15,
    marginRight: 2,
  },
}));
export default function JobItem(props) {
  const classes = useStyles();


  const { onMediaFileClick } = props;


  const { file } = props;
  const handleFileClick = () => {
    onMediaFileClick(file);
  };
  const {
    _id, name, transcodeStatus, compressStatus, compressProgress, nodeId, transcodeProgress, customName, transcodeQueuePosition,
  } = file;
  let itemBorderColor;
  let itemBackgroundColor;
  let progressWidth;

  switch (transcodeStatus || compressStatus) {
    case 'executing':

      itemBorderColor = '#2158eb';
      itemBackgroundColor = '#F2F2FD';
      progressWidth = transcodeProgress;
      break;
    case 'COMPRESSING':
      itemBorderColor = '#2158eb';
      itemBackgroundColor = '#F2F2FD';
      progressWidth = compressProgress;
      break;
    case 'finished':
    case 'READY':
      itemBorderColor = '#50BCB6';
      itemBackgroundColor = '#FFFFFF';
      progressWidth = 100;
      break;

    default: {
      itemBackgroundColor = '#FFFFFF';
      itemBorderColor = '#FFFFFF';
      progressWidth = 0;
    }
  }

  return (
    <ListItem className={classes.listItem}>
      <div
        style={{
          backgroundColor: itemBackgroundColor,
          zIndex: 1,
          height: '100%',
          width: `${progressWidth}%`,
          position: 'absolute',
          borderBottom: `2px solid ${itemBorderColor}`,
          transition: 'width 2s',
        }}
      />
      <div
        style={{
          color: '#E2E5E7',
          marginRight: 12,
          zIndex: 2,
          marginLeft: 10,
          height: 24,
          width: 24,
        }}
      >
        <FileIcon />
      </div>
      <div className={classes.listItemWrapper}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '70%',
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontSize: 14,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {customName}
          </Typography>
          <div style={{
            display: 'flex', alignItems: 'center', fontSize: 12, color: 'rgba(18, 18, 18, 0.38)',
          }}
          >
            {transcodeStatus === 'queued' && (
              <>
                <div style={{ height: 19, width: 19, marginRight: 2 }}>
                  <LottieAnimationPlayer
                    animation="inProcessingCloud"
                  />
                </div>
                Position in queue:
                {' '}
                {transcodeQueuePosition || 'Estimating' }
              </>
            )}
            {transcodeStatus === 'executing' && (
              <>
                <div className={classes.transcodeIconStatusWrapper}><LottieAnimationPlayer animation="EncProgress" loop /></div>
                <div>
                  Encoding video file
                  {' '}
                  {transcodeProgress}
                  {' '}
                  %
                </div>

              </>
            )}
            {transcodeStatus === 'failed' && (
              <>
                <div className={classes.transcodeIconStatusWrapper}><ErrorIcon style={{ color: '#FF4D63', fontSize: 16 }} /></div>
                <div>
                  Corrupted source file
                </div>

              </>
            )}
            {transcodeStatus === 'unsupported_codec' && (
              <>
                <div className={classes.transcodeIconStatusWrapper}><ErrorIcon style={{ color: '#FF4D63', fontSize: 16 }} /></div>
                <div>
                  Codec is not supported for encoding
                </div>

              </>
            )}
            {transcodeStatus === 'unsupported_codec_hevc' && (
              <>
                <div className={classes.transcodeIconStatusWrapper}><ErrorIcon style={{ color: '#FF4D63', fontSize: 16 }} /></div>
                <div>
                  Codec is not supported for encoding
                </div>

              </>
            )}
            {transcodeStatus === 'limit_quality_720p' && (
              <>
                <div className={classes.transcodeIconStatusWrapper}><ErrorIcon style={{ color: '#FF4D63', fontSize: 16 }} /></div>
                <div>
                  Video quality above 720p not available in free plan
                </div>

              </>
            )}

            {transcodeStatus === 'limit_exceeded' && (
              <>
                <div className={classes.transcodeIconStatusWrapper}><ErrorIcon style={{ color: '#FF4D63', fontSize: 16 }} /></div>
                <div>
                  Transcoding limit exceeded
                </div>

              </>
            )}
            {transcodeStatus === 'finished' && (
              <>
                <div className={classes.transcodeIconStatusWrapper}><CheckCircleIcon style={{ color: '#50BCB6', fontSize: 16 }} /></div>
                <div>
                  Encoding finished
                </div>

              </>
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            right: 12,
          }}
        >
          {/* <div style={{ marginRight: 5 }}> */}
          {/*  {transcodeStatus === 'finished' && ( */}
          {/*    <Tooltip title="Play" aria-label="add"> */}
          {/*      <IconButton size="small" onClick={() => handleFileClick()}> */}
          {/*        <PlayCircleFilledWhiteIcon /> */}
          {/*      </IconButton> */}
          {/*    </Tooltip> */}
          {/*  )} */}
          {/* </div> */}
        </div>
      </div>
    </ListItem>
  );
}
