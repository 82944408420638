import {
  call, put, all, takeLatest,
} from 'redux-saga/effects';
import {
  CLEAR_FINISHED_JOBS_SUBMIT, clearFinishedJobsFailure,
  clearFinishedJobsSuccess,
  getActiveJobsFailure,
  getActiveJobsSuccess,
} from './jobsActions';


import { GET_DOWNLOADS_SUBMIT } from '../files/filesActions';
import filesService from '../../services/filesService';

function* getJobs() {
  try {
    const result = yield call(filesService.getActiveJobs);
    yield put(getActiveJobsSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getActiveJobsFailure(errorMessage));
  }
}


function* clearFinishedJobs() {
  try {
    const result = yield call(filesService.clearFinishedJobs);
    yield put(clearFinishedJobsSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(clearFinishedJobsFailure(errorMessage));
  }
}


export default function* paymentsSaga() {
  yield all([takeLatest(GET_DOWNLOADS_SUBMIT, getJobs)]);
  yield all([takeLatest(CLEAR_FINISHED_JOBS_SUBMIT, clearFinishedJobs)]);
}
