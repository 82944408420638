import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IdleTimer from 'react-idle-timer';
import { compose } from 'redux';
import withWidth from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import LogoIcon from '../Images/Logo.png';

import LottieAnimationPlayer from '../atoms/LottieAnimationPlayer';
import LogoTextIcon from '../atoms/LogoTextIcon';

import { setDashboardTab } from '../../redux/viewsEffects/viewsEffectsActions';
import LogoNewDefault from '../atoms/LogoNewDefault';

import {
  getUserStorageSubmit,
} from '../../redux/userStorage/userStorageActions';


const styles = theme => ({
  logo: {
    color: '#2158eb',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 70,
    backgroundImage: `url(${LogoIcon})`,
    height: 48,
    width: 48,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },

  logoWrapper: {
    display: 'flex',
    width: 148,
    height: 48,
  },
  logoTextWrapper: {
    marginLeft: 5,
    marginTop: 5,
    // height: 14,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoGroupWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
});

class IdleLogo extends React.Component {
  constructor(props) {
    super(props);

    this.state = { logoStatus: 'default' };
    this.startIdleTimeout = null;
    this.idleTimer = null;

    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
  }

  setStatus(status) {
    this.setState({ logoStatus: status });
  }

  _onActive() {
    this.setStatus('default');
    clearTimeout(this.startIdleTimeout);
  }

  _onIdle() {
    this.setStatus('end');
    this.startIdleTimeout = setTimeout(() => this.setStatus('idle'), 1000);
  }

  clickHandler() {
    const { setTab, getStorageSubmit, history, isAuthenticated } = this.props;

    if (window.location.pathname.includes('dashboard')) {
      window.history.replaceState({}, '', '/dashboard/downloads');
      getStorageSubmit();
      setTab(0);
    }
    else{
      if(isAuthenticated){
        if(history){
          history.push('/dashboard/downloads')
        }
        getStorageSubmit();
      }
      else{
        history.push('/login');
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { logoStatus } = this.state;
    return (
      <div className="step1">
        {/*<IdleTimer*/}
        {/*  ref={(ref) => {*/}
        {/*    this.idleTimer = ref;*/}
        {/*  }}*/}
        {/*  element={document}*/}
        {/*  onActive={this.onActive}*/}
        {/*  onIdle={this.onIdle}*/}
        {/*  debounce={250}*/}
        {/*  timeout={5000}*/}
        {/*/>*/}
        <div className={classes.logoGroupWrapper} onClick={this.clickHandler}>
          {/*//TODO check this*/}
          <div className={classes.logoWrapper}>
            {/* {logoStatus === 'default' && ( */}
            {/*  <LottieAnimationPlayer animation="IdleEnd" loop={false} /> */}
            {/* )} */}
            {/* {logoStatus === 'idle' && ( */}
            {/*  <LottieAnimationPlayer animation="Idle" /> */}
            {/* )} */}
            {/* {logoStatus === 'end' && ( */}
            {/*  <LottieAnimationPlayer animation="IdleStart" loop={false} /> */}
            {/* )} */}
            <LogoNewDefault />
          </div>
          {/*<div className={classes.logoTextWrapper}>*/}
          {/*  /!*<LogoTextIcon />*!/*/}
          {/*  /!*<LogoNewDefault />*!/*/}
          {/*</div>*/}
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => ({

  setTab: (tab) => {
    dispatch(setDashboardTab(tab));
  },
  getStorageSubmit: () => {
    dispatch(getUserStorageSubmit());
  },

});

export default compose(
  withStyles(styles),
  withWidth(),
  connect(
    null,
    mapDispatchToProps,
  ),
)(IdleLogo);
