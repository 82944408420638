import { combineReducers } from 'redux';

import loginReducer from './login/loginReducer';
import contactsReducer from './contacts/contactsReducer';
import userReducer from './user/userReducer';
import filesReducer from './files/filesReducer';

import registrationReducer from './registration/registrationReducer';
import verifyEmailReducer from './verifyEmail/verifyEmailReducer';
import paymentsReducer from './payments/paymentsReducer';
import userStorageReducer from './userStorage/userStorageReducer';
import viewsEffectsReducer from './viewsEffects/viewsEffectsReducer';
import jobsReducer from './jobs/jobsReducer';
import hydraSearchReducer from './hydraSearch/hydraSearchReducer';
import hydraSearchSettingsReducer from './hydraSearch/hydraSearchSettingsReducer';
import usenetProvidersSettingsReducer from './usenet/usenetProvidersSettingsReducer';
import notificationsReducer from './notifications/notificationsReducer';

const rootReducer = combineReducers({
  auth: loginReducer,
  registration: registrationReducer,
  user: userReducer,
  files: filesReducer,
  contacts: contactsReducer,
  verifyEmail: verifyEmailReducer,
  payments: paymentsReducer,
  userStorage: userStorageReducer,
  viewsEffects: viewsEffectsReducer,
  jobs: jobsReducer,
  hydraSearch: hydraSearchReducer,
  hydraSearchSettings: hydraSearchSettingsReducer,
  usenetReducer: usenetProvidersSettingsReducer,
  notifications: notificationsReducer,
});

export default rootReducer;
