import api from '../helpers/api';

class UsenetProvidersService {
  async getCredentials() {
    return api
      .sendRequest('/usenet-config', 'get')
      .then(response => response.data);
  }

  async addConfig(data) {
    const {
      serverHost,
      serverPort,
      serverUsername,
      serverPassword,
      serverConnections,
      encryption,
      priority,
    } = data;
    const port = Number(serverPort);
    const connections = Number(serverConnections);
    return api
      .sendRequest('/usenet-config', 'post', {
        serverHost,
        serverPort: port,
        serverUsername,
        serverPassword,
        serverConnections: connections,
        encryption,
        priority,
      })
      .then(response => response.data);
  }

  async editConfig(data) {
    const {
      serverHost,
      serverPort,
      serverUsername,
      serverPassword,
      serverConnections,
      encryption,
      priority,
      id,
    } = data;
    const port = Number(serverPort);
    const connections = Number(serverConnections);
    return api
      .sendRequest('/usenet-config/update', 'post', {
        configId: id,
        serverHost,
        serverPort: port,
        serverUsername,
        serverPassword,
        serverConnections: connections,
        encryption,
        priority,
      })
      .then(response => response.data);
  }

  async deleteConfig(data) {
    const {
      _id,
    } = data;

    return api
      .sendRequest('/usenet-config/delete', 'post', {
        configId: _id,
      })
      .then(response => response.data);
  }

  async validateCredentials(data) {
    const {
      serverHost,
      serverPort,
      serverUsername,
      serverPassword,
      serverConnections,
      encryption,
    } = data;
    const port = Number(serverPort);
    const connections = Number(serverConnections);
    return api
      .sendRequest('/usenet-config/validate', 'post', {
        serverHost,
        serverPort: port,
        serverUsername,
        serverPassword,
        serverConnections: connections,
        encryption,
      })
      .then(response => response.data);
  }
}

export default new UsenetProvidersService();
