export const SET_HDR_SEARCH_QUERY = 'SET_HDR_SEARCH_QUERY';
export const HDR_SEARCH_START = 'HDR_SEARCH_START';
export const HDR_SEARCH_FINISH = 'HDR_SEARCH_FINISH';
export const HDR_SEARCH_FAIL = 'HDR_SEARCH_FAIL';

export const HDR_SEARCH_GET_INDEXERS_SUBMIT = 'HDR_SEARCH_GET_INDEXERS_SUBMIT';
export const HDR_SEARCH_GET_INDEXERS_SUCCESS = 'HDR_SEARCH_GET_INDEXERS_SUCCESS';
export const HDR_SEARCH_GET_INDEXERS_FAIL = 'HDR_SEARCH_GET_INDEXERS_FAIL';

export const HDR_ADD_INDEXER_SUBMIT = 'HDR_ADD_INDEXER_SUBMIT';
export const HDR_ADD_INDEXER_SUCCESS = 'HDR_ADD_INDEXER_SUCCESS';
export const HDR_ADD_INDEXER_FAIL = 'HDR_ADD_INDEXER_FAIL';

export const HDR_DELETE_INDEXER_SUBMIT = 'HDR_DELETE_INDEXER_SUBMIT';
export const HDR_DELETE_INDEXER_SUCCESS = 'HDR_DELETE_INDEXER_SUCCESS';
export const HDR_DELETE_INDEXER_FAIL = 'HDR_DELETE_INDEXER_FAIL';

export const setHdrSearchQuery = payload => ({
  type: SET_HDR_SEARCH_QUERY,
  payload,
});
export const hdrSearchStart = () => ({
  type: HDR_SEARCH_START,
});
export const hdrSearchFinish = payload => ({
  type: HDR_SEARCH_FINISH,
  payload,
});
export const hdrSearchFail = payload => ({
  type: HDR_SEARCH_FAIL,
  payload,
});

export const hdrSearchGetIndexersSubmit = () => ({
  type: HDR_SEARCH_GET_INDEXERS_SUBMIT,
});
export const hdrSearchGetIndexersSuccess = payload => ({
  type: HDR_SEARCH_GET_INDEXERS_SUCCESS,
  payload,
});
export const hdrSearchGetIndexersFail = payload => ({
  type: HDR_SEARCH_GET_INDEXERS_FAIL,
  payload,
});

export const hdrSearchAddIndexerSubmit = payload => ({
  type: HDR_ADD_INDEXER_SUBMIT,
  payload,
});
export const hdrSearchAddIndexerSuccess = payload => ({
  type: HDR_ADD_INDEXER_SUCCESS,
  payload,
});
export const hdrSearchAddIndexerFail = payload => ({
  type: HDR_ADD_INDEXER_FAIL,
  payload,
});

export const hdrSearchDeleteIndexerSubmit = payload => ({
  type: HDR_DELETE_INDEXER_SUBMIT,
  payload,
});
export const hdrSearchDeleteIndexerSuccess = payload => ({
  type: HDR_DELETE_INDEXER_SUCCESS,
  payload,
});
export const hdrSearchDeleteIndexerFail = payload => ({
  type: HDR_DELETE_INDEXER_FAIL,
  payload,
});
