import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import EmailIcon from '@material-ui/icons/Email';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';

import Copyright from '../Copyright';
import AnimatedEllipsis from '../atoms/AnimatedEllipsis';
import getQueryParam from '../../helpers/getQueryParam';
import { verifyUserEmailSubmit } from '../../redux/verifyEmail/verifyEmailActions';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function VerifyEmail(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { location } = props;
  const { emailVerifyInProgress, error } = useSelector(
    state => state.verifyEmail,
  );

  const token = getQueryParam('confirmToken', location);
  const email = getQueryParam('email', location);
  const handleSubmit = () => {
    dispatch(verifyUserEmailSubmit({ token, email }));
  };

  useEffect(() => handleSubmit());

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.large}>
          <EmailIcon style={{ fontSize: 40 }} />
        </Avatar>
        {emailVerifyInProgress && error === false && (
          <Typography align="center" component="h1" variant="h5">
            Email verification
            <AnimatedEllipsis numberOfDots={50} animDelay={0.3} />
          </Typography>
        )}

        {emailVerifyInProgress && error && (
          <Typography align="center" component="h1" variant="h5">
            Link is broken
            <AnimatedEllipsis numberOfDots={50} animDelay={0.3} />
          </Typography>
        )}
        {!emailVerifyInProgress && !error && (
          <Typography align="center" component="h1" variant="h5">
            Redirecting
            <AnimatedEllipsis numberOfDots={50} animDelay={0.3} />
          </Typography>
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
