import {
  call, put, all, takeLatest,
} from 'redux-saga/effects';

import {
  filesUploadFailure,
  filesUploadSuccess,
  GET_DOWNLOADS_SUBMIT,
  getDownloadsFailure,
  getDownloadsSuccess,
  NZB_FILES_CLEAR_COMPLETED_SUBMIT,
  NZB_FILES_DOWNLOADING_DELETE_SUBMIT,
  NZB_FILES_DOWNLOADING_PAUSE_SUBMIT,
  NZB_FILES_DOWNLOADING_RESUME_SUBMIT,
  NZB_FILES_PASSWORD_APPLY_SUBMIT,
  NZB_FILES_UPLOAD_SUBMIT,
  nzbFilesClearCompletedFailure,
  nzbFilesClearCompletedSuccess,
  onDownloadingDeleteFailure,
  onDownloadingDeleteSuccess,
  onDownloadingPauseSuccess,
  onDownloadingResumeFailure,
  onDownloadingResumeSuccess,
  passwordApplySuccess,
  passwordApplyFailure,
  NZB_FILES_LINK_UPLOAD_SUBMIT,
  filesLinkUploadSuccess,
  filesLinkUploadFailure,
  NZB_FILES_DOWNLOAD_BY_LINK_SUBMIT,
  downloadByLinkLinkSuccess,
  downloadByLinkLinkFailure,
  onDownloadingRestartSuccess, onDownloadingRestartFailure, NZB_FILES_DOWNLOADING_RESTART_SUBMIT,
} from './filesActions';
import filesService from '../../services/filesService';

import downloadsStatusFilter from '../../helpers/downloadsStatusFilter';
import { DELETE_STORAGE_ROOT_FOLDER_SUCCESS } from '../userStorage/userStorageActions';

function* submitFiles({ payload }) {
  try {
    const data = new FormData();
    for (let i = 0; i < payload.length; ++i) {
      data.append('files', payload[i]);
    }

    const downloads = yield call(filesService.uploadNzbFiles, data);

    const result = downloadsStatusFilter(downloads);
    yield put(filesUploadSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(filesUploadFailure(errorMessage));
  }
}
function* getDownloads() {
  try {
    const downloads = yield call(filesService.getDownloads);

    const result = downloadsStatusFilter(downloads);
    yield put(getDownloadsSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getDownloadsFailure(errorMessage));
  }
}

function* fileDownloadPause(action) {
  try {
    const downloads = yield call(
      filesService.pauseFileDownload,
      action.payload,
    );

    const result = downloadsStatusFilter(downloads);

    yield put(onDownloadingPauseSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(getDownloadsFailure(errorMessage));
  }
}

function* fileDownloadResume(action) {
  try {
    const downloads = yield call(
      filesService.resumeFileDownload,
      action.payload,
    );

    const result = downloadsStatusFilter(downloads);
    yield put(onDownloadingResumeSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(onDownloadingResumeFailure(errorMessage));
  }
}

function* fileDownloadRestart(action) {
  try {
    const downloads = yield call(
      filesService.restartFileDownload,
      action.payload,
    );

    const result = downloadsStatusFilter(downloads);
    yield put(onDownloadingRestartSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(onDownloadingRestartFailure(errorMessage));
  }
}


function* onDeleteDownloading(action) {
  const { payload } = action;
  const { _id, nodeId } = payload;
  try {
    const downloads = yield call(
      filesService.deleteFileDownload,
      _id,
    );
    const result = downloadsStatusFilter(downloads);
    yield put(onDownloadingDeleteSuccess({ ...result, nodeId }));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(onDownloadingDeleteFailure(errorMessage));
  }
}
function* onClearCompleted() {
  try {
    const downloads = yield call(filesService.clearCompleted);
    const result = downloadsStatusFilter(downloads);
    yield put(nzbFilesClearCompletedSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(nzbFilesClearCompletedFailure(errorMessage));
  }
}

function* onApplyPassword({ payload }) {
  try {
    const response = yield call(filesService.applyPassword, payload);
    const result = downloadsStatusFilter(response);
    yield put(passwordApplySuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(passwordApplyFailure(errorMessage));
  }
}
function* uploadByLink({ payload }) {
  const { id, title } = payload;
  try {
    const response = yield call(filesService.uploadByLink, {
      downloadNzbUrl: id,
      downloadTitle: title,
    });

    const result = downloadsStatusFilter(response);
    yield put(filesLinkUploadSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(filesLinkUploadFailure(errorMessage));
  }
}
function* downloadByLink({ payload }) {
  try {
    const response = yield call(filesService.downloadByLink, {
      downloadNzbUrl: payload,
    });
    const result = downloadsStatusFilter(response);
    yield put(downloadByLinkLinkSuccess(result));
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(downloadByLinkLinkFailure(errorMessage));
  }
}
export default function* filesSaga() {
  yield all([takeLatest([NZB_FILES_UPLOAD_SUBMIT], submitFiles)]);
  yield all([takeLatest([GET_DOWNLOADS_SUBMIT, DELETE_STORAGE_ROOT_FOLDER_SUCCESS], getDownloads)]);

  yield all([
    takeLatest([NZB_FILES_DOWNLOADING_PAUSE_SUBMIT], fileDownloadPause),
  ]);
  yield all([
    takeLatest([NZB_FILES_DOWNLOADING_RESUME_SUBMIT], fileDownloadResume),
  ]);
  yield all([
    takeLatest([NZB_FILES_DOWNLOADING_DELETE_SUBMIT], onDeleteDownloading),
  ]);
  yield all([takeLatest([NZB_FILES_CLEAR_COMPLETED_SUBMIT], onClearCompleted)]);
  yield all([takeLatest([NZB_FILES_PASSWORD_APPLY_SUBMIT], onApplyPassword)]);
  yield all([takeLatest([NZB_FILES_LINK_UPLOAD_SUBMIT], uploadByLink)]);
  yield all([takeLatest([NZB_FILES_DOWNLOAD_BY_LINK_SUBMIT], downloadByLink)]);
  yield all([takeLatest([NZB_FILES_DOWNLOADING_RESTART_SUBMIT], fileDownloadRestart)]);
}
