
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import history from '../../../config/history';


const useStyles = makeStyles(theme => ({
  betaBannerWrapper: {
    backgroundColor: '#FCEED6',
    display: 'flex',
    justifyContent: 'center',
    height: 44,
    alignItems: 'center',
    width: '100%',
  },
  betaBannerGotErrorWrapper: {
    marginLeft: 8,
    marginRight: 16,
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 500,
  },
  betaBannerIconWrapper: {
    display: 'flex',
  },
  betaBannerIcon: {
    color: '#EF4056',
    fontSize: 18,
    // [theme.breakpoints.down('sm')]: {
    //   width: '100%',
    // },
  },
  betaBannerHelpUsWrapper: {
    color: 'rgba(0,0,0,0.6)',
    display: 'flex',
    marginRight: 5,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  betaBannerSendReportWrapper: {
    color: '#2158EB',
    cursor: 'pointer',
  },
}));
export default function LimitsBanner() {
  const classes = useStyles();
  const { email, id } = useSelector(state => state.user);
  const handleMenuNavigation = (route) => {
    history.push(route);
  };

  return (
    <>
      {' '}
      <div className={classes.betaBannerWrapper}>
        <div className={classes.betaBannerIconWrapper}>
          <ReportProblemIcon className={classes.betaBannerIcon} />
        </div>
        <div className={classes.betaBannerGotErrorWrapper}>Daily download limit exceeded</div>
        {/*<div className={classes.betaBannerHelpUsWrapper}>*/}
        {/*  Free plan download limit is 10GB*/}
        {/*</div>*/}
        {/*<div*/}
        {/*  className={classes.betaBannerSendReportWrapper}*/}
        {/*  onClick={() => handleMenuNavigation('/pricing')}*/}
        {/*>*/}
        {/*  Upgrade your plan*/}
        {/*</div>*/}


      </div>
    </>
  );
}
