import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import history from './history';
import MainContainer from '../components/MainContainer';
import theme from './muiTheme';

function RootRouter() {
  return (
    <div>
      <ToastContainer autoClose={8000} pauseOnFocusLoss={false} pauseOnHover={false} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>

          <Router history={history}>
            <Switch>
              <Route path="/" component={MainContainer} />
            </Switch>
          </Router>

      </MuiPickersUtilsProvider>
    </div>
  );
}

export default RootRouter;
