import React from 'react';

const ProtectorIconRed = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M56.3743 16.4277L56.3194 8.56481L48.5676 7.25099C44.7023 6.59533 38.3493 4.52344 32.0012 0C32.0012 0 32 0 32 0.00124888C25.6657 4.51969 19.4288 6.57409 15.4324 7.25223L7.67808 8.56855L7.62563 16.4315C7.497 35.7254 13.2681 57.0063 31.3456 63.7552L32 64H32.0012L32.6569 63.7552C50.6844 57.02 56.5092 35.8316 56.3743 16.4277Z"
        fill="#FF7B4A"
      />
      <path
        d="M32.6569 63.7552L32.0013 64H32V0.00124888C32 0 32.0013 0 32.0013 0C38.3493 4.52344 44.7023 6.59533 48.5676 7.25099L56.3194 8.56481L56.3744 16.4277C56.5092 35.8316 50.6845 57.02 32.6569 63.7552Z"
        fill="#FF4D63"
      />
      <path
        d="M48.8811 16.5027V14.9241L47.32 14.6606C39.1573 13.2768 32.6894 8.96694 32.0212 8.63849C32.015 8.64224 32.0075 8.64473 32 8.64973C31.2969 9.01815 24.7365 13.3043 16.7237 14.6618L15.1738 14.9253L15.1626 16.4964C15.0664 30.8423 18.4996 48.5714 31.1308 55.4077L32 55.8785L32.0225 55.891L32.9142 55.4077C45.5466 48.5714 48.9797 30.8573 48.8811 16.5027Z"
        fill="#F0F7FF"
      />
      <path
        d="M32.9142 55.4077L32.0225 55.891L32 55.8785V8.64973C32.0075 8.64473 32.015 8.64224 32.0212 8.63849C32.6894 8.96694 39.1573 13.2768 47.32 14.6606L48.8811 14.9241V16.5027C48.9797 30.8573 45.5466 48.5714 32.9142 55.4077Z"
        fill="#DFE7F4"
      />
    </g>
    <g clipPath="url(#clip1)">
      <path
        d="M33.7271 31.6587C33.4966 31.4282 33.1228 31.4282 32.8922 31.6587L31.5642 32.9867L29.6801 31.1026L31.0081 29.7745C31.2387 29.5439 31.2387 29.1701 31.0081 28.9396C30.7776 28.709 30.4037 28.709 30.1731 28.9396L28.8451 30.2677L27.7832 29.2058C27.5527 28.9752 27.1788 28.9752 26.9483 29.2058L24.5999 31.5541C22.9635 33.1905 22.8612 35.7891 24.2931 37.5458C24.2739 37.5607 24.2552 37.5766 24.2376 37.5942L21.5064 40.3254C21.2759 40.556 21.2759 40.9299 21.5064 41.1604C21.6217 41.2757 21.7728 41.3333 21.9239 41.3333C22.075 41.3333 22.2261 41.2757 22.3414 41.1604L25.0726 38.4292C25.0902 38.4116 25.106 38.3929 25.1209 38.3737C25.9407 39.0419 26.9439 39.376 27.947 39.376C29.0934 39.376 30.2399 38.9396 31.1126 38.0668L33.461 35.7185C33.6916 35.4879 33.6916 35.1141 33.461 34.8835L32.3991 33.8217L33.7271 32.4936C33.9577 32.2631 33.9577 31.8892 33.7271 31.6587ZM30.2839 35.3892C30.2071 35.466 30.1063 35.5045 30.0056 35.5045C29.9049 35.5045 29.8041 35.466 29.7273 35.3892L27.2776 32.9395C27.1239 32.7858 27.1239 32.5366 27.2776 32.3829C27.4314 32.2292 27.6805 32.2292 27.8343 32.3829L30.2839 34.8325C30.4376 34.9862 30.4376 35.2355 30.2839 35.3892Z"
        fill="#FF4D63"
      />
      <path
        d="M42.4937 21.0079C42.7243 20.7774 42.7243 20.4035 42.4937 20.1729C42.2632 19.9424 41.8894 19.9424 41.6588 20.1729L38.9276 22.9041C38.9099 22.9217 38.8942 22.9404 38.8793 22.9595C38.0829 22.309 37.0953 21.9552 36.0531 21.9552C34.8573 21.9552 33.7331 22.4209 32.8875 23.2665L30.5391 25.6148C30.3086 25.8454 30.3086 26.2192 30.5391 26.4498L36.2169 32.1275C36.3276 32.2382 36.4778 32.3004 36.6344 32.3004C36.7909 32.3004 36.9411 32.2382 37.0518 32.1275L39.4001 29.7791C40.2457 28.9336 40.7114 27.8093 40.7114 26.6135C40.7114 25.5713 40.3576 24.5837 39.7071 23.7873C39.7262 23.7724 39.7449 23.7566 39.7625 23.739L42.4937 21.0079ZM36.7801 28.8928C36.7033 28.9697 36.6026 29.0081 36.5018 29.0081C36.4011 29.0081 36.3004 28.9697 36.2235 28.8928L33.7739 26.4432C33.6201 26.2895 33.6201 26.0402 33.7739 25.8865C33.9276 25.7328 34.1768 25.7328 34.3305 25.8865L36.7801 28.3361C36.9339 28.4898 36.9339 28.7391 36.7801 28.8928Z"
        fill="#FF4D63"
      />
    </g>
    <mask
      id="mask0"
      maskUnits="userSpaceOnUse"
      x="21"
      y="20"
      width="22"
      height="22"
    >
      <g clipPath="url(#clip2)">
        <path
          d="M33.7271 31.6587C33.4966 31.4282 33.1228 31.4282 32.8922 31.6587L31.5642 32.9867L29.6801 31.1026L31.0081 29.7745C31.2387 29.5439 31.2387 29.1701 31.0081 28.9396C30.7776 28.709 30.4037 28.709 30.1731 28.9396L28.8451 30.2677L27.7832 29.2058C27.5527 28.9752 27.1788 28.9752 26.9483 29.2058L24.5999 31.5541C22.9635 33.1905 22.8612 35.7891 24.2931 37.5458C24.2739 37.5607 24.2552 37.5766 24.2376 37.5942L21.5064 40.3254C21.2759 40.556 21.2759 40.9299 21.5064 41.1604C21.6217 41.2757 21.7728 41.3333 21.9239 41.3333C22.075 41.3333 22.2261 41.2757 22.3414 41.1604L25.0726 38.4292C25.0902 38.4116 25.106 38.3929 25.1209 38.3737C25.9407 39.0419 26.9439 39.376 27.947 39.376C29.0934 39.376 30.2399 38.9396 31.1126 38.0668L33.461 35.7185C33.6916 35.4879 33.6916 35.1141 33.461 34.8835L32.3991 33.8217L33.7271 32.4936C33.9577 32.2631 33.9577 31.8892 33.7271 31.6587ZM30.2839 35.3892C30.2071 35.466 30.1063 35.5045 30.0056 35.5045C29.9049 35.5045 29.8041 35.466 29.7273 35.3892L27.2776 32.9395C27.1239 32.7858 27.1239 32.5366 27.2776 32.3829C27.4314 32.2292 27.6805 32.2292 27.8343 32.3829L30.2839 34.8325C30.4376 34.9862 30.4376 35.2355 30.2839 35.3892Z"
          fill="black"
        />
        <path
          d="M42.4937 21.0079C42.7243 20.7774 42.7243 20.4035 42.4937 20.1729C42.2632 19.9424 41.8894 19.9424 41.6588 20.1729L38.9276 22.9041C38.9099 22.9217 38.8942 22.9404 38.8793 22.9595C38.0829 22.309 37.0953 21.9552 36.0531 21.9552C34.8573 21.9552 33.7331 22.4209 32.8875 23.2665L30.5391 25.6148C30.3086 25.8454 30.3086 26.2192 30.5391 26.4498L36.2169 32.1275C36.3276 32.2382 36.4778 32.3004 36.6344 32.3004C36.7909 32.3004 36.9411 32.2382 37.0518 32.1275L39.4001 29.7791C40.2457 28.9336 40.7114 27.8093 40.7114 26.6135C40.7114 25.5713 40.3576 24.5837 39.7071 23.7873C39.7262 23.7724 39.7449 23.7566 39.7625 23.739L42.4937 21.0079ZM36.7801 28.8928C36.7033 28.9697 36.6026 29.0081 36.5018 29.0081C36.4011 29.0081 36.3004 28.9697 36.2235 28.8928L33.7739 26.4432C33.6201 26.2895 33.6201 26.0402 33.7739 25.8865C33.9276 25.7328 34.1768 25.7328 34.3305 25.8865L36.7801 28.3361C36.9339 28.4898 36.9339 28.7391 36.7801 28.8928Z"
          fill="black"
        />
      </g>
    </mask>
    <g mask="url(#mask0)">
      <rect
        x="21.3335"
        y="20"
        width="10.6667"
        height="21.3333"
        fill="#FF7B4A"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="64" height="64" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect
          width="21.3333"
          height="21.3333"
          fill="white"
          transform="translate(21.3335 20)"
        />
      </clipPath>
      <clipPath id="clip2">
        <rect
          width="21.3333"
          height="21.3333"
          fill="white"
          transform="translate(21.3335 20)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ProtectorIconRed;
