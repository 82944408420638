

export const USENET_GET_PROVIDER_CREDENTIALS_SUBMIT = 'USENET_GET_PROVIDER_CREDENTIALS_SUBMIT';
export const USENET_GET_PROVIDER_CREDENTIALS_SUCCESS = 'USENET_GET_PROVIDER_CREDENTIALS_SUCCESS';
export const USENET_GET_PROVIDER_CREDENTIALS_FAIL = 'USENET_GET_PROVIDER_CREDENTIALS_FAIL';

export const USENET_ADD_CONFIG_SUBMIT = 'USENET_ADD_CONFIG_SUBMIT';
export const USENET_ADD_CONFIG_SUCCESS = 'USENET_ADD_CONFIG_SUCCESS';
export const USENET_ADD_CONFIG_FAIL = 'USENET_ADD_CONFIG_FAIL';
export const USENET_INVALID_CREDENTIALS = 'USENET_INVALID_CREDENTIALS';

export const USENET_EDIT_CONFIG_SUBMIT = 'USENET_EDIT_CONFIG_SUBMIT';
export const USENET_EDIT_CONFIG_SUCCESS = 'USENET_EDIT_CONFIG_SUCCESS';
export const USENET_EDIT_CONFIG_FAIL = 'USENET_EDIT_CONFIG_FAIL';

export const USENET_DELETE_CONFIG_SUBMIT = 'USENET_DELETE_CONFIG_SUBMIT';
export const USENET_DELETE_CONFIG_SUCCESS = 'USENET_DELETE_CONFIG_SUCCESS';
export const USENET_DELETE_CONFIG_FAIL = 'USENET_DELETE_CONFIG_FAIL';

export const HDR_ADD_INDEXER_SUBMIT = 'HDR_ADD_INDEXER_SUBMIT';
export const HDR_ADD_INDEXER_SUCCESS = 'HDR_ADD_INDEXER_SUCCESS';
export const HDR_ADD_INDEXER_FAIL = 'HDR_ADD_INDEXER_FAIL';

export const USENET_VALIDATE_PROVIDER_CREDENTIALS_SUBMIT = 'USENET_VALIDATE_PROVIDER_CREDENTIALS_SUBMIT';
export const USENET_VALIDATE_PROVIDER_CREDENTIALS_SUCCESS = 'USENET_VALIDATE_PROVIDER_CREDENTIALS_SUCCESS';
export const USENET_VALIDATE_PROVIDER_CREDENTIALS_FAIL = 'USENET_VALIDATE_PROVIDER_CREDENTIALS_FAIL';



export const usenetGetProviderCredentialsSubmit = () => ({
  type: USENET_GET_PROVIDER_CREDENTIALS_SUBMIT,
});
export const usenetGetProviderCredentialsSuccess = payload => ({
  type: USENET_GET_PROVIDER_CREDENTIALS_SUCCESS,
  payload,
});
export const usenetGetProviderCredentialsFail = payload => ({
  type: USENET_GET_PROVIDER_CREDENTIALS_FAIL,
  payload,
});

export const usenetAddConfigSubmit = payload => ({
  type: USENET_ADD_CONFIG_SUBMIT,
  payload,
});
export const usenetAddConfigSuccess = payload => ({
  type: USENET_ADD_CONFIG_SUCCESS,
  payload,
});
export const usenetAddConfigFail = payload => ({
  type: USENET_ADD_CONFIG_FAIL,
  payload,
});

export const usenetEditConfigSubmit = payload => ({
  type: USENET_EDIT_CONFIG_SUBMIT,
  payload,
});
export const usenetEditConfigSuccess = payload => ({
  type: USENET_EDIT_CONFIG_SUCCESS,
  payload,
});
export const usenetEditConfigFail = payload => ({
  type: USENET_EDIT_CONFIG_FAIL,
  payload,
});

export const usenetDeleteConfigSubmit = payload => ({
  type: USENET_DELETE_CONFIG_SUBMIT,
  payload,
});
export const usenetDeleteConfigSuccess = payload => ({
  type: USENET_DELETE_CONFIG_SUCCESS,
  payload,
});
export const usenetDeleteConfigFail = payload => ({
  type: USENET_DELETE_CONFIG_FAIL,
  payload,
});

export const usenetInvalidCredentials = payload => ({
  type: USENET_INVALID_CREDENTIALS,
  payload,
});

export const usenetValidateCredentialsSubmit = payload => ({
  type: USENET_VALIDATE_PROVIDER_CREDENTIALS_SUBMIT,
  payload,
});
export const usenetValidateCredentialsSuccess = payload => ({
  type: USENET_VALIDATE_PROVIDER_CREDENTIALS_SUCCESS,
  payload,
});
export const usenetValidateCredentialsFail = payload => ({
  type: USENET_VALIDATE_PROVIDER_CREDENTIALS_FAIL,
  payload,
});
