import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useDispatch } from 'react-redux';

import ReactJkMusicPlayer from 'react-jinke-music-player';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  storageContainer: {
    paddingTop: 20,
    width: '100%',
  },
  fileTypeTitle: {
    color: 'rgba(0,0,0,0.6)',
    // fontFamily: 'Roboto, san-serif',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
  },
  filesContainer: {
    display: 'flex',
    marginTop: 12,
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      marginBottom: 75,
    },
  },
  gridHeadersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileViewTypeContainer: {
    // borderLeft: '1px solid rgba(0,0,0,0.1)',
    paddingLeft: 10,
  },
}));

export default function AudioPlayer(props) {
  const { audioLists, playIndex } = props;
  const options = {
    audioLists,
    bounds: 'body',
    autoPlayInitLoadPlayList: false,
    playIndex,
    preload: true,
    quietUpdate: true,
    clearPriorAudioLists: true,
    // toggleMode: false,
    glassBg: true,
    remember: false,
    remove: false,
    // showDestroy: true,
    showThemeSwitch: false,
    mode: 'full',
    defaultPosition: {
      right: 193,
      bottom: 78,
    },

    defaultPlayMode: 'order',
  };

  // const updateParams = (params) => {
  //   const data = {
  //     ...audioOptions,
  //     ...params,
  //   };
  //   setAudioOptions(
  //     data,
  //   );
  // };
  //
  //
  // updateParams({ playIndex, audioLists });

  return (
    <div>
      <ReactJkMusicPlayer showMediaSession {...options} />
    </div>
  );
}
