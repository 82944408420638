import React from "react";

const InfectedFile = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 0C11.025 0 9 2.025 9 4.5V67.5C9 69.975 11.025 72 13.5 72H58.5C60.975 72 63 69.975 63 67.5V18L45 0H13.5Z"
      fill="#FF4D63"
    />
    <path
      d="M49.5 18H63L45 0V13.5C45 15.975 47.025 18 49.5 18Z"
      fill="#B23040"
    />
    <path d="M63 33L48 18H63V33Z" fill="#D6394D" />
    <path
      d="M38.8861 28.1336L39.4016 26.9736C39.557 26.6243 39.3995 26.2151 39.0502 26.0598C38.7007 25.9046 38.2916 26.0619 38.1364 26.4114L37.3961 28.0772H34.6035L33.8631 26.4113C33.7079 26.0619 33.2988 25.9046 32.9495 26.0598C32.6 26.2151 32.4426 26.6243 32.5979 26.9736L33.1134 28.1336C31.7352 28.404 30.6921 29.6207 30.6921 31.077V32.2308H41.3075V31.077C41.3075 29.6208 40.2643 28.404 38.8861 28.1336Z"
      fill="white"
    />
    <path
      d="M47.3075 38.2307H43.1537V36.7913C45.2435 36.4583 46.8459 34.6436 46.8459 32.4615V31.5384C46.8459 31.1561 46.536 30.8462 46.1537 30.8462C45.7713 30.8462 45.4613 31.1561 45.4613 31.5384V32.4615C45.4613 33.8774 44.475 35.0664 43.1537 35.3799V34.7693C43.1537 34.133 42.636 33.6153 41.9999 33.6153H36.6923V41.6923C36.6923 42.0746 36.3822 42.3845 35.9999 42.3845C35.6175 42.3845 35.3076 42.0746 35.3076 41.6923V33.6153H30C29.3637 33.6153 28.8461 34.133 28.8461 34.7691V35.3799C27.5247 35.0664 26.5385 33.8774 26.5385 32.4615V31.5384C26.5385 31.1561 26.2284 30.8462 25.8462 30.8462C25.4639 30.8462 25.1538 31.1561 25.1538 31.5384V32.4615C25.1538 34.6436 26.7563 36.4583 28.8462 36.7913V38.2307H24.6923C24.3099 38.2307 24 38.5406 24 38.923C24 39.3053 24.3099 39.6152 24.6923 39.6152H28.8461V41.0548C26.7563 41.3876 25.1538 43.2023 25.1538 45.3845V46.3075C25.1538 46.6898 25.4637 46.9999 25.8461 46.9999C26.2284 46.9999 26.5383 46.6898 26.5383 46.3075V45.3845C26.5383 43.9685 27.5246 42.7795 28.8461 42.466V42.8461C28.8461 46.7906 32.0553 49.9999 35.9999 49.9999C39.9444 49.9999 43.1537 46.7906 43.1537 42.8461V42.4661C44.475 42.7796 45.4613 43.9687 45.4613 45.3845V46.3076C45.4613 46.69 45.7713 46.9999 46.1535 46.9999C46.5359 46.9999 46.8459 46.69 46.8459 46.3076V45.3845C46.8459 43.2025 45.2435 41.3878 43.1535 41.0548V39.6154H47.3075C47.6897 39.6154 47.9997 39.3055 47.9997 38.9231C47.9997 38.5408 47.6898 38.2307 47.3075 38.2307Z"
      fill="white"
    />
  </svg>
);

export default InfectedFile;
