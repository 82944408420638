import React, { useEffect } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import LottieAnimationPlayer from '../atoms/LottieAnimationPlayer';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import StarIcon from '@material-ui/icons/StarBorder';
import {
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import ServicePageHeaders from '../ServicePageHeaders';

import {
  getPaymentUrlSubmit,
  getPlansSubmit,
  paymentOrderReviewDiscard,
  paymentOrderReviewSubmit,
} from '../../redux/payments/paymentsActions';
import ReviewOrderTable from './reviewOrderTable';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  pricingColumnWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 40,
  },

  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8,
      paddingRight: 8,
      paddingBottom: 60,
    },
  },
  pricingIcons: {
    // height: '100vh',
    position: 'absolute',
    top: -50,
    left: 0,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  planCard: {
    position: 'relative',
    cursor: 'pointer',
    overflow: 'visible',
    '&:hover': {
      boxShadow: 'inset 0 0 0 5px rgb(70, 68, 220)',
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  altBanner: {
    position: 'absolute',
    padding: '2px 20px 4px',
    top: '16px',
    left: '-10px',
    color: '#ffffff',
    background: 'rgb(70, 68, 220)',
    fontSize: '18px',
    fontWeight: 800,
    fontStyle: 'italic',
    borderRadius: '0 3px 3px 0',
    letterSpacing: '-1px',
    textTransform: 'uppercase',
    '&:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      left: 0,
      bottom: '-14px',
      zIndex: '-1',
      width: 0,
      height: 0,
      borderRight: '20px solid #414141',
      borderTop: '14px solid rgba(0,0,0,0)',
      borderBottom: '14px solid rgba(0,0,0,0)',
    },
  },
  planCardHeader: {
    paddingTop: 60,
  },
  addonCard: {
    height: 50,
    backgroundColor: '#fff',
    cursor: 'pointer',
    margin: 5,
    borderRadius: 2,
    padding: 3,
    border: '1px solid rgb(233 233 233)',
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px rgb(70, 68, 220)',
      border: '1px solid rgb(70, 68, 220)',
    },
  },
}));
const PageName = 'Pricing';
const BoldHeader = 'Safe downloading has never been so close';
const Subtitle = 'Take a look at our offers and choose the plan that suits you best.';
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Use only a valid email address. “Valid” means “correct”.')
    .required('Please enter email'),
});
// const plansB = [
//   {
//     title: 'Free',
//     storage: '500 MB',
//     price: '0',
//     description: [
//       '10 users included',
//       '2 GB of storage',
//       'Help center access',
//       'Email support',
//     ],
//     buttonText: 'Sign up for free',
//     buttonVariant: 'contained',
//     route: '/registration',
//   },
//   {
//     title: 'Essential',
//     storage: '1000 MB',
//     price: '0',
//     description: [
//       '10 users included',
//       '2 GB of storage',
//       'Help center access',
//       'Email support',
//     ],
//     buttonText: 'Sign up for free',
//     buttonVariant: 'contained',
//     route: '/registration',
//   },
//   {
//     title: 'Standard',
//     storage: '10 GB',
//     price: '25',
//     description: [
//       '10 users included',
//       '2 GB of storage',
//       'Help center access',
//       'Email support',
//     ],
//     buttonText: 'Purchase',
//     buttonVariant: 'outlined',
//     route: '/login',
//   },
//   {
//     title: 'Pro',
//     storage: '20 GB',
//     price: '50',
//     description: [
//       '10 users included',
//       '2 GB of storage',
//       'Help center access',
//       'Email support',
//     ],
//     buttonText: 'Purchase',
//     buttonVariant: 'outlined',
//     route: '/login',
//   },
// ];

export default function Pricing() {
  const dispatch = useDispatch();
  const {
    plans = [],
    addons = [],
    plansLoading,
    addonsLoading,
    isOrderValid,
    reviewOrderLoading,

    reviewOrder,
  } = useSelector(state => state.payments);
  const { isAuthenticated } = useSelector(state => state.auth);
  const { email, activePlan } = useSelector(state => state.user);
  const classes = useStyles();
  const [state, setState] = React.useState({
    selectedPlanId: 2,
    selectedAddons: [],
    selectedEmail: email || '',
    reviewOrder: false,
    validationError: '',
  });
  const {
    selectedPlanId,
    selectedAddons,
    selectedEmail,
    // reviewOrder,
    validationError,
  } = state;
  useEffect(() => {
    dispatch(getPlansSubmit());
  }, []);
  const handleChangePlan = (id) => {
    setState({ ...state, selectedPlanId: id });
  };
  const handleChangeAddons = (selectedAddon) => {
    const addonsArr = [...selectedAddons];
    const index = addonsArr.findIndex(addon => addon.id === selectedAddon.id);
    if (index > -1) {
      addonsArr.splice(index, 1);
    } else {
      addonsArr.push(selectedAddon);
    }

    setState({ ...state, selectedAddons: addonsArr });
  };

  const handleEmailInput = (e) => {
    setState({ ...state, selectedEmail: e.target.value, validationError: '' });
  };
  const handleReviewOrder = (value) => {
    validationSchema
      .validate({ email: selectedEmail })
      .then((data) => {
        dispatch(
          paymentOrderReviewSubmit({
            email: selectedEmail,
            planId: selectedPlanId,
          }),
        );
        // setState({ ...state, reviewOrder: value });
      })
      .catch((err) => {
        setState({ ...state, validationError: err.message });
      });

    // console.log(a);
  };

  const handleDiscardReviewOrderInput = (e) => {
    dispatch(paymentOrderReviewDiscard());
  };
  const handleCheckout = () => {
    dispatch(
      getPaymentUrlSubmit({
        email: selectedEmail,
        planId: selectedPlanId,
        additionsIds: selectedAddons.map(addon => addon.id),
      }),
    );
  };

  const getPlanDetailsById = () => plans.find(({ id }) => id === selectedPlanId);

  return (
    <>
      <CssBaseline />
      <Container className={classes.container} component="main" maxWidth="lg">
        <ServicePageHeaders
          pageName={PageName}
          boldHeader={BoldHeader}
          subtitle={Subtitle}
        />
        <div>
          <div style={{
            height: '50vh', position: 'relative', marginLeft: 'auto', marginRight: 'auto',
          }}
          >
            <LottieAnimationPlayer animation="PhoneAndCards" loop={false} />
          </div>
          <div className={classes.pricingIcons}>
            <LottieAnimationPlayer animation="PricingIcons" loop={false} />
          </div>
        </div>
        {/* {reviewOrder ? ( */}
        {/*  <div */}
        {/*    style={{ */}
        {/*      display: 'flex', */}
        {/*      justifyContent: 'center', */}
        {/*      flexDirection: 'column', */}
        {/*      alignItems: 'center', */}
        {/*    }} */}
        {/*  > */}
        {/*    {reviewOrderLoading ? ( */}
        {/*      <CircularProgress */}
        {/*        disableShrink */}
        {/*        size={15} */}
        {/*        style={{ marginLeft: 6 }} */}
        {/*      /> */}
        {/*    ) : ( */}
        {/*      <ReviewOrderTable */}
        {/*        selectedEmail={selectedEmail} */}
        {/*        productName={getPlanDetailsById().name} */}
        {/*        productPrice={getPlanDetailsById().price} */}
        {/*        selectedAddons={selectedAddons} */}
        {/*      /> */}
        {/*    )} */}
        {/*    {!isOrderValid */}
        {/*    && <div style={{ padding: 10, color: 'red' }}>Cannot fulfill your order</div>} */}
        {/*    <div */}
        {/*      style={{ */}
        {/*        display: 'flex', */}
        {/*        marginTop: 15, */}
        {/*        width: '100%', */}
        {/*        justifyContent: 'space-around', */}
        {/*      }} */}
        {/*    > */}

        {/*      <Button */}
        {/*        variant="contained" */}
        {/*        onClick={handleDiscardReviewOrderInput} */}
        {/*      > */}
        {/*        Back */}
        {/*      </Button> */}
        {/*      <Button */}
        {/*        variant="contained" */}
        {/*        color="primary" */}
        {/*        onClick={handleCheckout} */}
        {/*        disabled={!isOrderValid} */}
        {/*      > */}
        {/*        Checkout */}
        {/*      </Button> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* ) : ( */}
        {/*  <div> */}
        {/*    <div className={classes.pricingColumnWrapper} /> */}

        {/*    <Container maxWidth="md" component="main"> */}
        {/*      <Grid container spacing={5} alignItems="flex-end"> */}
        {/*        {plans.map(plan => ( */}
        {/*          <Grid item key={plan.id} xs={12} sm={12} md={4}> */}
        {/*            <Card */}
        {/*              className={classes.planCard} */}
        {/*              elevation={0} */}
        {/*              style={{ */}
        {/*                boxShadow: */}
        {/*                  plan.id === selectedPlanId */}
        {/*                    ? 'inset 0 0 0 5px rgb(70, 68, 220)' */}
        {/*                    : 'inset 0 0 0 2px #e9e9e9', */}
        {/*              }} */}
        {/*              onClick={() => handleChangePlan(plan.id)} */}
        {/*            > */}
        {/*              <CardHeader */}
        {/*                title={plan.name} */}
        {/*                subheader={plan.name} */}
        {/*                titleTypographyProps={{ align: 'center' }} */}
        {/*                subheaderTypographyProps={{ align: 'center' }} */}
        {/*                action={plan.id === 3 ? <StarIcon /> : null} */}
        {/*                className={classes.planCardHeader} */}
        {/*              /> */}
        {/*              <CardContent> */}
        {/*                <div className={classes.cardPricing}> */}
        {/*                  {plan.price === 0 ? ( */}
        {/*                    <Typography */}
        {/*                      component="h2" */}
        {/*                      variant="h3" */}
        {/*                      color="textPrimary" */}
        {/*                    > */}
        {/*                      FREE */}
        {/*                    </Typography> */}
        {/*                  ) : ( */}
        {/*                    <> */}
        {/*                      <Typography */}
        {/*                        component="h2" */}
        {/*                        variant="h3" */}
        {/*                        color="textPrimary" */}
        {/*                      > */}
        {/*                        {plan.price} */}
        {/*                      </Typography> */}
        {/*                      <Typography variant="h6" color="textSecondary"> */}
        {/*                        /mo */}
        {/*                      </Typography> */}
        {/*                    </> */}
        {/*                  )} */}
        {/*                </div> */}
        {/*                <Typography variant="subtitle1" align="center"> */}
        {/*                  {plan.description} */}
        {/*                </Typography> */}
        {/*                {plan.id === 3 ? ( */}
        {/*                  <Typography */}
        {/*                    variant="subtitle1" */}
        {/*                    align="center" */}
        {/*                    className={classes.altBanner} */}
        {/*                  > */}
        {/*                    most popular */}
        {/*                  </Typography> */}
        {/*                ) : null} */}
        {/*              </CardContent> */}
        {/*            </Card> */}
        {/*          </Grid> */}
        {/*        ))} */}
        {/*      </Grid> */}
        {/*    </Container> */}

        {/*    <Container */}
        {/*      maxWidth="md" */}
        {/*      component="main" */}
        {/*      style={{ marginTop: 10, padding: 20 }} */}
        {/*    > */}
        {/*      {addons.map(addon => ( */}
        {/*        <div */}
        {/*          onClick={() => handleChangeAddons(addon)} */}
        {/*          className={classes.addonCard} */}
        {/*          style={{ */}
        {/*            borderColor: */}
        {/*              selectedAddons.findIndex(a => a.id === addon.id) > -1 */}
        {/*                ? 'rgb(70, 68, 220)' */}
        {/*                : 'rgb(233 233 233)', */}
        {/*          }} */}
        {/*          key={addon.id} */}
        {/*        > */}
        {/*          {addon.name} */}
        {/*        </div> */}
        {/*      ))} */}

        {/*      <div */}
        {/*        style={{ */}
        {/*          display: 'flex', */}
        {/*          flexDirection: 'column', */}
        {/*          paddingTop: 15, */}
        {/*        }} */}
        {/*      > */}
        {/*        <TextField */}
        {/*          label="E-MAIL" */}
        {/*          value={email || selectedEmail} */}
        {/*          onChange={handleEmailInput} */}
        {/*          error={Boolean(validationError)} */}
        {/*        /> */}
        {/*        <span style={{ color: 'red', height: 10 }}>{validationError}</span> */}
        {/*      </div> */}
        {/*    </Container> */}
        {/*    <div style={{ marginTop: 35, marginBottom: 40 }}> */}
        {/*      {' '} */}
        {/*      <Button */}
        {/*        fullWidth */}
        {/*        variant="contained" */}
        {/*        color="primary" */}
        {/*        onClick={() => handleReviewOrder(true)} */}
        {/*      > */}
        {/*        REVIEW ORDER */}
        {/*      </Button> */}
        {/*    </div> */}
        {/*  </div> */}

      </Container>
    </>
  );
}
