export const SET_DASHBOARD_TAB = 'SET_DASHBOARD_TAB';
export const SET_BUG_REPORT_MODAL_OPEN = 'SET_BUG_REPORT_MODAL_OPEN';
export const SET_BUG_REPORT_MODAL_CLOSE = 'SET_BUG_REPORT_MODAL_CLOSE';

export const GET_AVATARS_SUBMIT = 'GET_AVATARS_SUBMIT';
export const GET_AVATARS_SUCCESS = 'GET_AVATARS_SUCCESS';
export const GET_AVATARS_FAIL = 'GET_AVATARS_FAIL';

export const SET_ADD_INDEXER_MODAL_OPEN = 'SET_ADD_INDEXER_MODAL_OPEN';
export const SET_ADD_INDEXER_MODAL_CLOSE = 'SET_ADD_INDEXER_MODAL_CLOSE';
export const SET_USENET_MODAL_OPEN = 'SET_USENET_MODAL_OPEN';
export const SET_USENET_MODAL_CLOSE = 'SET_USENET_MODAL_CLOSE';

export const SET_WELCOME_MODAL_OPEN = 'SET_WELCOME_MODAL_OPEN';
export const SET_WELCOME_MODAL_CLOSE = 'SET_WELCOME_MODAL_CLOSE';
export const SET_WELCOME_MODAL_STEP = 'SET_WELCOME_MODAL_STEP';
export const SET_DOWNLOADS_POPOVER_OPEN = 'SET_DOWNLOADS_POPOVER_OPEN';


export const SET_NZB_CREDENTIAL_MODAL_OPEN = 'SET_NZB_CREDENTIAL_OPEN';
export const SET_NZB_CREDENTIAL_MODAL_CLOSE = 'SET_NZB_CREDENTIAL_CLOSE';

export const setDownloadsPopoverOpen = payload => ({
  type: SET_DOWNLOADS_POPOVER_OPEN,
  payload,
});
export const getAvatarsSubmit = () => ({
  type: GET_AVATARS_SUBMIT,
});

export const getAvatarsSuccess = payload => ({
  type: GET_AVATARS_SUCCESS,
  payload,
});
export const getAvatarsFail = payload => ({
  type: GET_AVATARS_FAIL,
  payload,
});

export const setDashboardTab = payload => ({
  type: SET_DASHBOARD_TAB,
  payload,
});

export const setReportModalOpen = payload => ({
  type: SET_BUG_REPORT_MODAL_OPEN,
  payload,
});
export const setReportModalClose = payload => ({
  type: SET_BUG_REPORT_MODAL_CLOSE,
  payload,
});

export const setWelcomeModalOpen = payload => ({
  type: SET_WELCOME_MODAL_OPEN,
  payload,
});
export const setWelcomeModalStep = payload => ({
  type: SET_WELCOME_MODAL_STEP,
  payload,
});

export const setAddIndexerModalOpen = payload => ({
  type: SET_ADD_INDEXER_MODAL_OPEN,
  payload,
});
export const setAddIndexerModalClose = payload => ({
  type: SET_ADD_INDEXER_MODAL_CLOSE,
  payload,
});
export const setUsenetProviderModalOpen = payload => ({
  type: SET_USENET_MODAL_OPEN,
  payload,
});
export const setUsenetProviderModalClose = payload => ({
  type: SET_USENET_MODAL_CLOSE,
  payload,
});


export const setNZBCredentialsModalOpen = payload => ({
  type: SET_NZB_CREDENTIAL_MODAL_OPEN,
  payload,
});
export const setNZBCredentialsModalClose = payload => ({
  type: SET_NZB_CREDENTIAL_MODAL_CLOSE,
  payload,
});
