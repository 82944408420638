import React, { useEffect } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import Divider from '@material-ui/core/Divider';
import { withWidth } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import StorageIcon from '@material-ui/icons/Storage';
import AccountTab from './AccountTab/AccountTab';
import PaymentsTab from './PaymentsTab';
import TuneIcon from '@material-ui/icons/Tune';
import UnavalibleInBetta from './UnavalibleInBetta';
import SearchSettingsTab from './NZBSearchSettingsTab';
import UsenetProvidersTab from './UsenetProvidersTab';
import ExternalStoragesTab from './ExternalStorages';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  root: {
    // backgroundColor: theme.palette.background.paper,
    marginTop: 32,
    borderRadius: 2,
    overflow: 'hidden',
    paddingLeft: 0,
    paddingRight: 0,
  },
  settingsTab: {
    // textTransform: "none",

    minWidth: 80,
    // padding: '23px 12px',

    // fontFamily: '"Work Sans",sans-serif',
    fontSize: 12,

    fontWeight: 600,
    color: '#0f0c0c',
    padding: '0 15px',
    height: 65,

    textDecoration: 'none',
    '&:hover': {
      color: '#2158eb',

    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 100,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 60,
    },
  },
  selected: {
    color: '#2158EB !important',
  },
  indicator: {
    backgroundColor: '#2158EB',
  },
}));

const SettingsTabs = [
  'Profile',
  'Payments',
  'External Storages',
  'Usenet servers',
  // 'NZBSearch Settings',
];

function SettingsTab(props) {
  const { width } = props;
  const { location, history } = window;
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    switch (location.pathname) {
      case '/dashboard/settings/profile': {
        setValue(0);
        break;
      }
      case '/dashboard/settings/payments': {
        setValue(1);
        break;
      }
      case '/dashboard/settings/external-storages': {
        setValue(2);
        break;
      }
      case '/dashboard/settings/usenet-providers': {
        setValue(3);
        break;
      }
      // case '/dashboard/settings/search-settings': {
      //   setValue(4);
      //   break;
      // }
      default: {
        setValue(0);
      }
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0: {
        history.replaceState({}, '', '/dashboard/settings/profile');
        setValue(newValue);
        return;
      }
      case 1: {
        history.replaceState({}, '', '/dashboard/settings/payments');
        setValue(newValue);
        return;
      }
      case 2: {
        history.replaceState({}, '', '/dashboard/settings/external-storages');
        setValue(newValue);
        return;
      }
      case 3: {
        history.replaceState({}, '', '/dashboard/settings/usenet-providers');
        setValue(newValue);
        return;
      }
      // case 4: {
      //   history.replaceState({}, '', '/dashboard/settings/search-settings');
      //   setValue(newValue);
      //   return;
      // }
      default: {
        setValue(0);
      }
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.mainContainer} maxWidth="lg">
        <div style={{
          backgroundColor: '#fff',
          border: '1px solid rgb(237, 237, 237)',


        }}
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid rgb(237, 237, 237)',
            padding: 20,
          }}
          >
            <TuneIcon style={{
              marginRight: 8,
              color: '#2c5ce2',
            }}
            />
            <span style={{
              fontWeight: 500,
              fontSize: 16,
            }}
            >
              {' '}
              Settings
            </span>
          </div>


          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={width === 'sm' || width === 'xs' ? 'on' : 'off'}
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
            classes={{ indicator: classes.indicator }}
          >
            {SettingsTabs.map((settingsTab, i) => (
              <Tab
                key={settingsTab}
                label={settingsTab}
                classes={{ root: classes.settingsTab, selected: classes.selected }}
                {...a11yProps(i)}
              />
            ))}
          </Tabs>

        </div>
        <div className={classes.root}>
          <TabPanel value={value} index={0} dir={theme.direction}>
            <AccountTab width={width} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <PaymentsTab />
          </TabPanel>
          {/* <TabPanel value={value} index={2} dir={theme.direction}>
                <UnavalibleInBetta />
              </TabPanel> */}
          <TabPanel value={value} index={2} dir={theme.direction}>
            <ExternalStoragesTab />
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <UsenetProvidersTab />
          </TabPanel>
          {/* <TabPanel value={value} index={4} dir={theme.direction}> */}
          {/*  <SearchSettingsTab /> */}
          {/* </TabPanel> */}
        </div>


      </Container>
    </React.Fragment>
  );
}
export default withWidth()(SettingsTab);
