import {
  call, put, all, takeLatest, select, delay,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  USER_LOGIN_SUBMIT,
  loginSuccess,
  loginFailure,
  USER_LOGOUT_SUBMIT,
  linkLoginSuccess,
  USER_LINK_LOGIN_SUBMIT,
  USER_REQUEST_LINK_LOGIN_SUBMIT,
  requestLinkLoginSuccess,
} from './loginActions';
import authService from '../../services/authService';
import history from '../../config/history';
import usersService from '../../services/usersService';

function* requestLinkLogin({ payload }) {
  try {
    const { email } = payload;
    yield call(usersService.requestLink, { email });

    // yield delay(3000);
    toast.success('Link was sent');
    yield put(requestLinkLoginSuccess());

    // history.push('/dashboard/downloads');
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    yield put(loginFailure(errorMessage));
  }
}
function* linkLogin(action) {
  try {
    const { payload } = action;
    yield call(authService.linkLogin, payload);
    yield delay(3000);
    yield put(linkLoginSuccess());

    // history.push('/dashboard/downloads');
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    yield put(loginFailure(errorMessage));
  }
}
function* login(action) {
  try {
    const { email, password } = action.payload;
    yield call(authService.login, { email, password });

    yield put(loginSuccess());

    // history.push('/dashboard/downloads');
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error;
    yield put(loginFailure(errorMessage));
  }
}
function* userLogOut() {
  // yield delay(1000);
  try {
    localStorage.removeItem('@accessToken');
    const { location } = window;
    const { pathname, search, href } = location;
    toast.warn('Authorisation required');
    const params = new URL(href).searchParams;
    const { hash } = new URL(href);
    const isUrlParam = params.get('url');

    if (isUrlParam) {
      window.location.href = `https://nzbcloud.com${hash}`;
      yield put('DONE');
    }
    if (history.location.state && history.location.state.goBack) {
      history.push('/login', {
        goBack: history.location.state.goBack,
      });
    } else {
      history.push('/login', {
        goBack: pathname + search,
      });
    }

    // console.log(history);
  } catch (error) {
    const errorMessage = error.response || error;
    yield put(loginFailure(errorMessage));
  }
}

export default function* profileSaga() {
  yield all([takeLatest(USER_LOGIN_SUBMIT, login)]);
  yield all([takeLatest(USER_LOGOUT_SUBMIT, userLogOut)]);
  yield all([takeLatest(USER_LINK_LOGIN_SUBMIT, linkLogin)]);
  yield all([takeLatest(USER_REQUEST_LINK_LOGIN_SUBMIT, requestLinkLogin)]);
}
