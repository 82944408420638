import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { Route } from 'react-router-dom';
import useStyles from './styles';
import SignIn from './Login';

import Registration from './Registration';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import LinkLogin from './LinkLogin';


export default function AuthContainer() {
  const classes = useStyles();

  return (
    <Grid container component="div" className={classes.root}>
      <CssBaseline />
      <Grid item xs={12} sm={12} md={4} elevation={0}>
        <Route path="/login" component={SignIn} />
        <Route path="/link-login" component={LinkLogin} />
         {/*<Route path="/registration" component={Registration} />*/}
        {/*<Route path="/registration-alpha" component={RegistrationAlpha} />*/}
        <Route path="/password/reset" component={ResetPassword} />
        <Route path="/password/forgot" component={ForgotPassword} />
      </Grid>
      <Grid item xs={false} sm={false} md={6} className={classes.image} />
    </Grid>
  );
}
