import { toast } from 'react-toastify';

export const GET_SUPPORT_TICKET_BY_ID_SUCCESS = 'GET_SUPPORT_TICKET_BY_ID_SUCCESS';
export const GET_SUPPORT_TICKET_BY_ID_FAILURE = 'GET_SUPPORT_TICKET_BY_ID_FAILURE';

export const NZB_FILES_UPLOAD_SUBMIT = 'NZB_FILES_UPLOAD_SUBMIT';
export const NZB_FILES_UPLOAD_SUCCESS = 'NZB_FILES_UPLOAD_SUCCESS';
export const NZB_FILES_UPLOAD_FAILURE = 'NZB_FILES_UPLOAD_FAILURE';

export const NZB_FILES_LINK_UPLOAD_SUBMIT = 'NZB_FILES_LINK_UPLOAD_SUBMIT';
export const NZB_FILES_LINK_UPLOAD_SUCCESS = 'NZB_FILES_LINK_UPLOAD_SUCCESS';
export const NZB_FILES_LINK_UPLOAD_FAILURE = 'NZB_FILES_LINK_UPLOAD_FAILURE';

export const NZB_FILES_CLEAR_COMPLETED_SUBMIT = 'NZB_FILES_CLEAR_COMPLETED_SUBMIT';
export const NZB_FILES_CLEAR_COMPLETED_SUCCESS = 'NZB_FILES_CLEAR_COMPLETED_SUCCESS';
export const NZB_FILES_CLEAR_COMPLETED_FAILURE = 'NZB_FILES_CLEAR_COMPLETED_FAILURE';

export const GET_DOWNLOADS_SUBMIT = 'GET_DOWNLOADS_SUBMIT';
export const GET_DOWNLOADS_SUCCESS = 'GET_DOWNLOADS_SUCCESS';
export const GET_DOWNLOADS_FAILURE = 'GET_DOWNLOADS_FAILURE';
export const NZB_FILES_DOWNLOADING_PAUSE_SUBMIT = 'NZB_FILES_DOWNLOADING_PAUSE_SUBMIT';
export const NZB_FILES_DOWNLOADING_PAUSE_SUCCESS = 'NZB_FILES_DOWNLOADING_PAUSE_SUCCESS';
export const NZB_FILES_DOWNLOADING_PAUSE_FAILURE = 'NZB_FILES_DOWNLOADING_PAUSE_FAILURE';

export const NZB_FILES_DOWNLOADING_RESTART_SUBMIT = 'NZB_FILES_DOWNLOADING_RESTART_SUBMIT';
export const NZB_FILES_DOWNLOADING_RESTART_SUCCESS = 'NZB_FILES_DOWNLOADING_RESTART_SUCCESS';
export const NZB_FILES_DOWNLOADING_RESTART_FAILURE = 'NZB_FILES_DOWNLOADING_RESTART_FAILURE';

export const NZB_FILES_DOWNLOADING_RESUME_SUBMIT = 'NZB_FILES_DOWNLOADING_RESUME_SUBMIT';
export const NZB_FILES_DOWNLOADING_RESUME_SUCCESS = 'NZB_FILES_DOWNLOADING_RESUME_SUCCESS';
export const NZB_FILES_DOWNLOADING_RESUME_FAILURE = 'NZB_FILES_DOWNLOADING_RESUME_FAILURE';

export const NZB_FILES_DOWNLOADING_DELETE_SUBMIT = 'NZB_FILES_DOWNLOADING_DELETE_SUBMIT';
export const NZB_FILES_DOWNLOADING_DELETE_SUCCESS = 'NZB_FILES_DOWNLOADING_DELETE_SUCCESS';
export const NZB_FILES_DOWNLOADING_DELETE_FAILURE = 'NZB_FILES_DOWNLOADING_DELETE_FAILURE';
export const STORAGE_SET_DISPLAY_TYPE = 'STORAGE_SET_DISPLAY_TYPE';
export const NZB_FILES_DOWNLOADING_COMPLETE = 'NZB_FILES_DOWNLOADING_COMPLETE';

export const NZB_FILES_PASSWORD_APPLY_SUBMIT = 'NZB_FILES_PASSWORD_APPLY_SUBMIT';
export const NZB_FILES_PASSWORD_APPLY_SUCCESS = 'NZB_FILES_PASSWORD_APPLY_SUCCESS';
export const NZB_FILES_PASSWORD_APPLY_FAILURE = 'NZB_FILES_PASSWORD_APPLY_FAILURE';

export const NZB_FILES_DOWNLOAD_BY_LINK_SUBMIT = 'NZB_FILES_DOWNLOAD_BY_LINK_SUBMIT';
export const NZB_FILES_DOWNLOAD_BY_LINK_SUCCESS = 'NZB_FILES_DOWNLOAD_BY_LINK_SUCCESS';
export const NZB_FILES_DOWNLOAD_BY_LINK_FAILURE = 'NZB_FILES_DOWNLOAD_BY_LINK_FAILURE';

export const nzbFileDownloadingComplete = (payload) => {
  toast.success(`${payload.fileName}  downloaded.`);

  return {
    type: NZB_FILES_DOWNLOADING_COMPLETE,
    payload,
  };
};

export const storageSetDisplayType = payload => ({
  type: STORAGE_SET_DISPLAY_TYPE,
  payload,
});
export const nzbFilesClearCompletedSubmit = payload => ({
  type: NZB_FILES_CLEAR_COMPLETED_SUBMIT,
  payload,
});
export const nzbFilesClearCompletedSuccess = payload => ({
  type: NZB_FILES_CLEAR_COMPLETED_SUCCESS,
  payload,
});
export const nzbFilesClearCompletedFailure = payload => ({
  type: NZB_FILES_CLEAR_COMPLETED_FAILURE,
  payload,
});

export const getDownloadsSubmit = () => ({
  type: GET_DOWNLOADS_SUBMIT,
});
export const getDownloadsSuccess = payload => ({
  type: GET_DOWNLOADS_SUCCESS,
  payload,
});
export const getDownloadsFailure = payload => ({
  type: GET_DOWNLOADS_FAILURE,
  payload,
});

export const passwordApplySubmit = payload => ({
  type: NZB_FILES_PASSWORD_APPLY_SUBMIT,
  payload,
});
export const passwordApplySuccess = payload => ({
  type: NZB_FILES_PASSWORD_APPLY_SUCCESS,
  payload,
});

export const passwordApplyFailure = payload => ({
  type: NZB_FILES_PASSWORD_APPLY_FAILURE,
  payload,
});
export const filesLinkUploadSubmit = payload => ({
  type: NZB_FILES_LINK_UPLOAD_SUBMIT,
  payload,
});
export const filesLinkUploadSuccess = payload => ({
  type: NZB_FILES_LINK_UPLOAD_SUCCESS,
  payload,
});
export const filesLinkUploadFailure = payload => ({
  type: NZB_FILES_LINK_UPLOAD_FAILURE,
  payload,
});

export const downloadByLinkLinkSubmit = payload => ({
  type: NZB_FILES_DOWNLOAD_BY_LINK_SUBMIT,
  payload,
});
export const downloadByLinkLinkSuccess = payload => ({
  type: NZB_FILES_DOWNLOAD_BY_LINK_SUCCESS,
  payload,
});
export const downloadByLinkLinkFailure = payload => ({
  type: NZB_FILES_DOWNLOAD_BY_LINK_FAILURE,
  payload,
});

export const filesUploadSubmit = payload => ({
  type: NZB_FILES_UPLOAD_SUBMIT,
  payload,
});
export const onDownloadingPause = payload => ({
  type: NZB_FILES_DOWNLOADING_PAUSE_SUBMIT,
  payload,
});
export const onDownloadingPauseSuccess = payload => ({
  type: NZB_FILES_DOWNLOADING_PAUSE_SUCCESS,
  payload,
});
export const onDownloadingPauseFailure = payload => ({
  type: NZB_FILES_DOWNLOADING_PAUSE_FAILURE,
  payload,
});

export const onDownloadingRestart = payload => ({
  type: NZB_FILES_DOWNLOADING_RESTART_SUBMIT,
  payload,
});
export const onDownloadingRestartSuccess = payload => ({
  type: NZB_FILES_DOWNLOADING_RESTART_SUCCESS,
  payload,
});
export const onDownloadingRestartFailure = payload => ({
  type: NZB_FILES_DOWNLOADING_RESTART_FAILURE,
  payload,
});

export const onDownloadingResume = payload => ({
  type: NZB_FILES_DOWNLOADING_RESUME_SUBMIT,
  payload,
});
export const onDownloadingResumeSuccess = payload => ({
  type: NZB_FILES_DOWNLOADING_RESUME_SUCCESS,
  payload,
});
export const onDownloadingDeleteFailure = payload => ({
  type: NZB_FILES_DOWNLOADING_RESUME_FAILURE,
  payload,
});

export const onDownloadingDelete = payload => ({
  type: NZB_FILES_DOWNLOADING_DELETE_SUBMIT,
  payload,
});
export const onDownloadingDeleteSuccess = payload => ({
  type: NZB_FILES_DOWNLOADING_DELETE_SUCCESS,
  payload,
});
export const onDownloadingResumeFailure = payload => ({
  type: NZB_FILES_DOWNLOADING_DELETE_FAILURE,
  payload,
});

export const filesUploadSuccess = payload =>
  // window.history.replaceState({}, '', '/dashboard/downloads');
  ({
    type: NZB_FILES_UPLOAD_SUCCESS,
    payload,
  });
export const filesUploadFailure = (payload) => {
  toast.warn('Something went wrong with uploading files');

  return {
    type: NZB_FILES_UPLOAD_FAILURE,
    payload,
  };
};

export const getSupportTicketByIdSuccess = payload => ({
  type: GET_SUPPORT_TICKET_BY_ID_SUCCESS,
  payload,
});

export const getSupportTicketByIdFailure = (payload) => {
  toast.warn('Get support ticket failure');

  return {
    type: GET_SUPPORT_TICKET_BY_ID_FAILURE,
    payload,
  };
};
