import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import DraftsIcon from '@material-ui/icons/Drafts';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import HelpIcon from '@material-ui/icons/Help';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MailIcon from '@material-ui/icons/Mail';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Badge } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import moment from 'moment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ErrorIcon from '@material-ui/icons/Error';
import { userLogOut } from '../../redux/login/loginActions';
import history from '../../config/history';
import Copyright from '../Copyright';
import {
  hideAllNotificationsSubmit,
  markAllNotificationsReadSubmit,
} from '../../redux/notifications/notificationsActions';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  subHeaderWrapper: {
    display: 'flex',
    padding: '10px 20px 10px 10px',
    // width: 280,
    borderBottom: '1px solid #F7F7F7',
    alignItems: 'center',
    flexDirection: 'column',
  },
  bottomButton: {
    textTransform: 'none',
  },
  avatarWrapper: {
    width: 48,
    height: 48,
    '&:hover': {
      opacity: 0.7,
    },
  },
  paper: {
    borderRadius: 2,
    minWidth: 300,
  },
  inline: {
    display: 'inline',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // width: 200,
    whiteSpace: 'nowrap',
  },
  customBadge: {
    backgroundColor: '#da5933',
    color: 'white',
  },
}));

export default function NotificationBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;
  const { notifications, unreadCount } = useSelector(
    state => state.notifications,
  );
  // const { user, handleSettingsTab } = props;
  // const { avatar } = user;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(markAllNotificationsReadSubmit());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleHideAll = () => {
    dispatch(hideAllNotificationsSubmit());
    handleClose();
  };
  const handleSettingsNavigation = () => {
    handleClose();
    // handleSettingsTab(2, 2);
  };
  const handleMenuNavigation = (route) => {
    handleClose();
    history.push(route);
  };
  // <ListItem button>
  //   <ListItem button onClick={() => handleMenuNavigation('/pricing')}>
  //   <ListItemIcon>
  //     {/*<FavoriteIcon />*/}
  //     <NotificationBar />
  //   </ListItemIcon>
  //   <ListItemText primary="Notifications" />
  // </ListItem>
  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <Badge
            classes={{ badge: classes.customBadge }}
            badgeContent={unreadCount}
          >
            <MailIcon style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
          </Badge>
        </ListItemIcon>
        <ListItemText primary="Notifications" />
      </ListItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{ paper: classes.paper }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List
          subheader={(
            <div className={classes.subHeaderWrapper}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    color: 'rgba(0,0,0,0.87)',
                    fontSize: 16,
                    marginRight: 5,

                    lineHeight: 1.43,
                  }}
                  display="block"
                >
                  Notifications
                </Typography>
                <Tooltip
                  placement="left"
                  title={`Clear all notifications: ${notifications.length}`}
                >
                  <IconButton onClick={handleHideAll} edge="end">
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          )}
          className={classes.root}
        >
          {notifications.map(notification => (
            <ListItem key={notification.id}>
              <ListItemIcon style={{ minWidth: 40 }}>
                {notification.notificationStatus === 'DOWNLOAD_SUCCESS' && (
                  <CheckCircleIcon style={{ color: '#50BCB6', fontSize: 20 }} />
                )}
                {notification.notificationStatus === 'USERS_GLOBAL_INFO' && (
                  <HelpIcon style={{ fontSize: 20 }} />
                )}
                {notification.notificationStatus === 'DOWNLOAD_FAILURE' && (
                  <ErrorIcon style={{ color: '#EF4056', fontSize: 15 }} />
                )}
                {notification.notificationStatus === 'DOWNLOAD_OVERSIZE' && (
                  <ReportProblemIcon
                    style={{ color: '#EF4056', fontSize: 15 }}
                  />
                )}
              </ListItemIcon>
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: 200,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {notification.notificationStatus === 'DOWNLOAD_SUCCESS'
                    && notification.data.name}
                  {notification.notificationStatus === 'USERS_GLOBAL_INFO'
                    && notification.data.text}
                  {notification.notificationStatus === 'DOWNLOAD_OVERSIZE'
                    && 'TODO ADD TEXT HERE'}
                  {notification.notificationStatus === 'DOWNLOAD_FAILURE'
                    && notification.data.name}
                </Typography>
                <Typography
                  noWrap
                  component="span"
                  variant="subtitle2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {moment(notification.createdAt).format('YYYY.MM.DD HH:MM')}
                </Typography>
              </div>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
}
