import React from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import DownloadsInnerList from './downloadsInnerList';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: 2,
  },
}));
export default function DownloadsPopOver(props) {
  const classes = useStyles();
  const { open, handleClose, el } = props;
  const id = open ? 'downloads-popover' : undefined;
  const handlePopOverClose = () => {
    handleClose();
  };
  return (
    <div>
      <Popover
        id={id}
        open={open}
        // anchorReference="anchorPosition"
        // anchorPosition={{ top: 70, left: window.innerWidth - 600 }}
        anchorEl={el || false}
        onClose={handlePopOverClose}
        style={{ borderRadius: 2 }}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        // transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <DownloadsInnerList />
      </Popover>
    </div>
  );
}
