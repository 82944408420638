import { toast } from 'react-toastify';

export const USER_REGISTRATION_ALPHA_SUBMIT = 'USER_REGISTRATION_ALPHA_SUBMIT';
export const USER_REGISTRATION_ALPHA_SUCCESS = 'USER_REGISTRATION_ALPHA_SUCCESS';
export const USER_REGISTRATION_ALPHA_FAILURE = 'USER_REGISTRATION_ALPHA_FAILURE';

export const USER_REGISTRATION_SUBMIT = 'USER_REGISTRATION_SUBMIT';
export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS';
export const USER_REGISTRATION_FAILURE = 'USER_REGISTRATION_FAILURE';
export const USER_SET_REGISTRATION_STEP = 'USER_SET_REGISTRATION_STEP';


export const registrationAlphaSubmit = payload => ({
  type: USER_REGISTRATION_ALPHA_SUBMIT,
  payload,
});
export const setRegistrationStep = payload => ({
  type: USER_SET_REGISTRATION_STEP,
  payload,
});
export const registrationAlphaSuccess = payload => ({
  type: USER_REGISTRATION_ALPHA_SUCCESS,
  payload,
});

export const registrationAlphaFailure = (payload) => {
  toast.warn('Sorry, bro. You need to be the chosen one.');
  return {
    type: USER_REGISTRATION_ALPHA_FAILURE,
    payload,
  };
};

export const registrationSubmit = payload => ({
  type: USER_REGISTRATION_SUBMIT,
  payload,
});

export const registrationSuccess = payload => ({
  type: USER_REGISTRATION_SUCCESS,
  payload,
});

export const registrationFailure = (payload) => {
  toast.warn('Nice try! This email has already been registered.');
  return {
    type: USER_REGISTRATION_FAILURE,
    payload,
  };
};
