import React, { useEffect } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useDispatch, useStore } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { loginSubmit } from '../../../redux/login/loginActions';

import Copyright from '../../Copyright';
import useStyles from '../styles';
import getQueryParam from '../../../helpers/getQueryParam';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Use only a valid email address. “Valid” means “correct”.')
    .required('Please enter email'),
  password: Yup.string()
    .label('Password')
    .min(6)
    .required('Enter your new password. Make it strong and beautiful.'),
});
export default function SignIn(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useStore();
  const { auth } = store.getState();
  const { history, location } = props;
  const [showPasswordValue, setFormShowPassword] = React.useState({
    showPassword: false,
  });
  const predefinedEmail = getQueryParam('email', location)
    ? decodeURI(getQueryParam('email', location))
    : '';

  useEffect(() => {
    if (auth.isAuthenticated) {
      const { state } = history.location;
      // console.log(state);
      if (state && state.goBack && !state.goBack.includes('/login')) {
        history.push(state.goBack);
      } else {
        history.push('dashboard/downloads');
      }
    }
  }, [auth.isAuthenticated, dispatch, history]);
  const handleFormSubmit = (valuesFromForm) => {
    // const { email, password } = valuesFromForm;
    // const fieldsToValidate = [
    //   { name: 'Email', value: email, rules: ['min_3', 'email'] },
    //   { name: 'Password', value: password, rules: ['min_6'] },
    // ];
    // const fieldsValid = Validator.validate(fieldsToValidate);
    //
    // if (!fieldsValid) {
    //   return;
    // }
    dispatch(loginSubmit(valuesFromForm));
  };

  const handleClickShowPassword = () => {
    setFormShowPassword({ showPassword: !showPasswordValue.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  window.history.replaceState(null, null, window.location.pathname);
  return (
    <div className={classes.paper}>
      <Typography className={classes.loginTitle}>Sign in</Typography>
      <Typography className={classes.loginCaption}>
        Sign in now and download to a secured cloud.
      </Typography>
      <Formik
        initialValues={{ email: predefinedEmail || '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={values => handleFormSubmit(values)}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          touched,
          errors,
        }) => (
          <>
            <form>
              <div className={classes.formInput}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  error={!!(touched.email && errors.email)}
                  fullWidth
                  id="email"
                  // classes={{ input: classes.textField }}
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={values.email}
                  onChange={handleChange('email')}
                />
                <div style={{ color: 'red' }}>
                  {touched.email && errors.email}
                </div>
              </div>

              <div className={classes.formInput}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    error={touched.password && errors.password}
                    htmlFor="outlined-adornment-password"
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPasswordValue.showPassword ? 'text' : 'password'}
                    value={values.password}
                    name="password"
                    error={touched.password && errors.password}
                    autoComplete="password"
                    fullWidth
                    onChange={handleChange('password')}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPasswordValue.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )}
                    labelWidth={70}
                  />
                  <div style={{ color: 'red', marginTop: 8 }}>
                    {touched.password && errors.password}
                  </div>
                </FormControl>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Sign In
              </Button>

              <Button
                // type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submitSecondary}
                onClick={() => history.push('/link-login')}
              >
                Use a Magic Link
              </Button>
            </form>
          </>
        )}
      </Formik>
      <Grid container>
        <Grid item xs>
          <Link
            component="button"
            onClick={() => history.push('/password/forgot')}
            variant="body2"
            className={classes.linksOpt}
          >
            Forgot password?
          </Link>
        </Grid>
        <Grid item>
          <Link
            // component="button"
            // onClick={() => history.push('/pricing')}
            variant="body2"
            className={classes.linksOpt}
            href="https://checkout.nzbcloud.com/checkout"
            target="_blank"
          >
            Don't have an account?
          </Link>
        </Grid>
      </Grid>
      <Box mt={5}>
        <Copyright />
      </Box>
    </div>
  );
}
