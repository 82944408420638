import React from 'react';
import './styles.css';
import { Router, Route, Switch } from 'react-router-dom';
import Dashboard from '../Dashboard';
import requiresAuth from '../requiresAuth';
import history from '../../config/history';
import ServicesContainer from '../ServicesContainer';
import VerifyEmail from '../EmailVerificationPage';
import Page404 from '../Page404';

export default function MainContainer() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={requiresAuth(Dashboard)} />
        <Route
          path="/dashboard/(downloads|search|files|settings/profile|settings/payments|settings/external-storages|settings/usenet-providers|settings/search-settings)"
          component={requiresAuth(Dashboard)}
        />
        <Route
          path="/(pricing|contacts|login|link-login|faq|terms|privacy|password|order-complete)/"
          component={ServicesContainer}
        />
        <Route path="/verify-email" component={VerifyEmail} />
        <Route component={Page404} />
      </Switch>
    </Router>
  );
}
