import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { purgeExternalDriveSubmit } from '../../../redux/userStorage/userStorageActions';

export default function DeleteFolderModal(props) {
  const { handleClose, purgeModalData, handleSignOut } = props;

  const { drive, open, type } = purgeModalData;
  const dispatch = useDispatch();
  const handleConfirmDelete = () => {
    if (type === 'purge') {
      dispatch(purgeExternalDriveSubmit(drive));
    } else if (type === 'signOut') {
      handleSignOut(drive);
    }

    handleClose();
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete folder?</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: 'rgba(0,0,0,0.87)' }}
          >
            This action will remove all
            {' '}
            <span style={{ fontWeight: 500 }}>NZBCloud Data</span>
            {' '}
            from your
            external drive
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            backgroundColor: '#F7F7F7',
            borderTop: '1px solid #E0E0E0',
            padding: '18px 32px',
          }}
        >
          <div>
            <Button onClick={handleClose} color="default">
              Cancel
            </Button>
            <Button
              onClick={handleConfirmDelete}
              variant="contained"
              color="secondary"
              style={{ marginLeft: 20 }}
              disableElevation
            >
              OK
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
