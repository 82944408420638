import { makeStyles } from '@material-ui/core/styles';
import SideImage from '../Images/Login_page_side.png';

const useStyles = makeStyles(theme => ({
  root: {
    // height: '85vh',
    justifyContent: 'space-evenly',
  },
  image: {
    backgroundImage: `url(${SideImage})`,
    backgroundRepeat: 'no-repeat',
    // backgroundColor: 'white',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    margin: theme.spacing(8, 0, 1),
    // height: 500,
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 1),
    },

    // alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#2158eb',
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
    },
  },
  submitSecondary: {
    margin: theme.spacing(0, 0, 2),
    backgroundColor: '#9421eb',
    borderRadius: 2,
    height: 52,
    transition: 'all .3s ease-in-out',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#b021eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
    },
  },
  imageContainer: {
    margin: theme.spacing(8, 8),
  },
  loginTitle: {
    fontSize: 48,
    fontWeight: 600,
    marginBottom: 30,
    lineHeight: '56px',
  },
  loginCaption: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.6)',
    marginBottom: 5,

  },
  formInput: {
    marginTop: 20,
    '& fieldset': {
      borderRadius: 2,
    },
  },
  formInputPassword: {
    marginTop: 20,
  },
  inputLabelFocus: {
    backgroundColor: '#fafafa',
    paddingRight: 5,
  },
  inputLabel: {
    backgroundColor: '#fafafa',
    paddingRight: 5,
  },
  linksOpt: {
    color: '#2158eb',
  },
  textField: {
    borderRadius: 2,

  },
}));

export default useStyles;
