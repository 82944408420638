import React from 'react';
import GoogleDriveIcon from '../components/atoms/GoogleDriveIcon';
import OneDriveIcon from '../components/atoms/OneDriveIcon';
import DropBoxIcon from '../components/atoms/DropBoxIcon';
import NZBCloudStorageIcon from '../components/atoms/NZBCloudStorageIcon';

const storageOptions = {
  GOOGLEDRIVE: {
    icon: <GoogleDriveIcon />,
    name: 'Google Drive',
  },
  ONEDRIVE: {
    icon: <OneDriveIcon />,
    name: 'OneDrive',
  },
  DROPBOX: {
    icon: <DropBoxIcon />,
    name: 'DropBox',
  },
  NZBCLOUD: {
    icon: <NZBCloudStorageIcon />,
    name: 'SafeLoaded',
  },
};


export default storageOptions;
