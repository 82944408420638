import React from 'react';

const DropBoxIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9985 5.97733L25.4905 11.6387L31.9999 6.60533L22.5852 0.666668L15.9985 5.97733Z"
      fill="#1976D2"
    />
    <path
      d="M16 5.98L9.41333 0.666668L0 6.604L6.51067 11.64L16 5.98Z"
      fill="#1976D2"
    />
    <path
      d="M16 17.3013L22.5853 22.612L32 16.6747L25.4893 11.6387L16 17.3L6.508 11.6387L0 16.6747L9.41333 22.612L16 17.3013Z"
      fill="#1976D2"
    />
    <path
      d="M26.0015 25.5827V22.7493L23.0628 24.6027V24.604C21.9588 25.3067 21.4561 23.8893 16.0015 19.8013C10.4535 23.972 10.0375 25.2947 8.93746 24.6027L6.00146 22.7507V25.5827L16.0215 31.3333L26.0015 25.5827Z"
      fill="#1976D2"
    />
  </svg>
);

export default DropBoxIcon;
