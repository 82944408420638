import {
  DELETE_STORAGE_ROOT_FOLDER_SUCCESS,
  GET_EXTERNAL_DRIVES_LIST_SUCCESS,
  GET_STORAGE_FOLDER_DATA_SUCCESS,
  GET_USER_STORAGE_SUCCESS,
  RENAME_STORAGE_ROOT_FOLDER_SUCCESS,
  SET_BROWSE_STORAGE_TYPE_SUCCESS,
  SET_DOWNLOAD_DESTINATION_SUCCESS,
  STORAGE_MEDIA_TRANSCODING_SUCCESS,
} from './userStorageActions';
import { STORAGE_SET_DISPLAY_TYPE } from '../files/filesActions';

import {
  MEDIA_FILES_TRANSCODE_COMPLETE,
  NZB_FILES_COMPRESS_COMPLETE,
} from '../sockets/socketsActions';
import { USER_LOGOUT_SUBMIT } from '../login/loginActions';
import { GET_USER_DATA_SUCCESS } from '../user/userActions';

const initState = {
  userStorage: [],
  folders: [],
  files: [],
  path: [],
  displayType: 'list',
  externalStorages: [],
  selectedDownloadDestination: {
    type: 'NZBCLOUD',
  },
  targetStorage: 'NZBCLOUD',
};

function userStorageReducer(state = initState, { type, payload }) {
  switch (type) {
    case STORAGE_MEDIA_TRANSCODING_SUCCESS:
    case MEDIA_FILES_TRANSCODE_COMPLETE:
    case GET_STORAGE_FOLDER_DATA_SUCCESS: {
      const { folders, files, breadCrumb } = payload;
      return {
        ...state,
        folders,
        files,
        path: breadCrumb,
      };
    }
    case NZB_FILES_COMPRESS_COMPLETE:
    case RENAME_STORAGE_ROOT_FOLDER_SUCCESS:
    case DELETE_STORAGE_ROOT_FOLDER_SUCCESS: {
      const { folders, files } = payload;
      return {
        ...state,
        folders,
        files,
        path: [],
      };
    }
    case SET_BROWSE_STORAGE_TYPE_SUCCESS:
    case GET_USER_STORAGE_SUCCESS: {
      const { folders, files, targetStorage } = payload;
      return {
        ...state,
        folders,
        files,
        targetStorage,
        path: [],
      };
    }
    case STORAGE_SET_DISPLAY_TYPE: {
      return {
        ...state,
        displayType: payload,
      };
    }
    case GET_USER_DATA_SUCCESS: {
      const { externalStorages } = payload;
      return {
        ...state,
        externalStorages,
      };
    }


    case GET_EXTERNAL_DRIVES_LIST_SUCCESS:
    case SET_DOWNLOAD_DESTINATION_SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }

    case USER_LOGOUT_SUBMIT: {
      return {
        ...initState,
        // userLoading: true,
      };
    }
    default: {
      return state;
    }
  }
}

export default userStorageReducer;
