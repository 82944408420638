import React from 'react';

const InfectedFolder = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6667 6.66666H6.6667C4.83337 6.66666 3.35004 8.16663 3.35004 9.99999L3.33337 30C3.33337 31.8333 4.83337 33.3333 6.6667 33.3333H33.3334C35.1667 33.3333 36.6667 31.8333 36.6667 30V13.3333C36.6667 11.5 35.1667 9.99999 33.3334 9.99999H20L16.6667 6.66666Z"
      fill="#FF4D63"
    />
    <path
      d="M21.3744 16.7303L21.6199 16.1779C21.6939 16.0116 21.6189 15.8167 21.4525 15.7428C21.2861 15.6689 21.0913 15.7438 21.0174 15.9102L20.6649 16.7034H19.3351L18.9825 15.9101C18.9086 15.7438 18.7138 15.6689 18.5475 15.7428C18.381 15.8167 18.3061 16.0116 18.38 16.1779L18.6255 16.7303C17.9692 16.8591 17.4725 17.4384 17.4725 18.1319V18.6814H22.5275V18.1319C22.5275 17.4385 22.0307 16.8591 21.3744 16.7303Z"
      fill="white"
    />
    <path
      d="M25.3846 21.5385H23.4066V20.8531C24.4017 20.6945 25.1648 19.8304 25.1648 18.7913V18.3517C25.1648 18.1696 25.0172 18.0221 24.8351 18.0221C24.6531 18.0221 24.5054 18.1696 24.5054 18.3517V18.7913C24.5054 19.4655 24.0358 20.0317 23.4066 20.181V19.8902C23.4066 19.5872 23.1601 19.3407 22.8571 19.3407H20.3297V23.1869C20.3297 23.3689 20.1821 23.5165 20 23.5165C19.8179 23.5165 19.6703 23.3689 19.6703 23.1869V19.3407H17.1429C16.8399 19.3407 16.5934 19.5872 16.5934 19.8901V20.181C15.9642 20.0317 15.4946 19.4655 15.4946 18.7913V18.3517C15.4946 18.1696 15.3469 18.0221 15.1649 18.0221C14.9828 18.0221 14.8352 18.1696 14.8352 18.3517V18.7913C14.8352 19.8304 15.5983 20.6945 16.5935 20.8531V21.5385H14.6154C14.4333 21.5385 14.2858 21.6861 14.2858 21.8681C14.2858 22.0502 14.4333 22.1978 14.6154 22.1978H16.5934V22.8833C15.5983 23.0418 14.8352 23.9059 14.8352 24.9451V25.3846C14.8352 25.5666 14.9828 25.7143 15.1648 25.7143C15.3469 25.7143 15.4945 25.5666 15.4945 25.3846V24.9451C15.4945 24.2708 15.9641 23.7046 16.5934 23.5553V23.7363C16.5934 25.6146 18.1216 27.1428 20 27.1428C21.8783 27.1428 23.4066 25.6146 23.4066 23.7363V23.5553C24.0358 23.7046 24.5054 24.2708 24.5054 24.9451V25.3846C24.5054 25.5667 24.6531 25.7143 24.8351 25.7143C25.0171 25.7143 25.1648 25.5667 25.1648 25.3846V24.9451C25.1648 23.906 24.4017 23.0418 23.4065 22.8833V22.1978H25.3846C25.5666 22.1978 25.7142 22.0503 25.7142 21.8682C25.7142 21.6861 25.5666 21.5385 25.3846 21.5385Z"
      fill="white"
    />
  </svg>
);

export default InfectedFolder;
