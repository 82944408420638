import api from '../helpers/api';

class PaymentsService {
  getPlans() {
    return api.sendRequest('plan/view', 'get').then(response => response.data);
  }

  getAddons() {
    return api
      .sendRequest('additions/view', 'get')
      .then(response => response.data);
  }

  getPaymentUrl(data) {
    return api
      .sendRequest('payment/get-payment-url', 'post', data)
      .then(response => response.data);
  }

  orderReview(data) {
    return api
      .sendRequest('payment/order-review', 'post', data)
      .then(response => response.data);
  }
}

export default new PaymentsService();
