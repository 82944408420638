import React from 'react';
import Typography from '@material-ui/core/Typography';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { connect } from 'react-redux';
import * as Msal from 'msal';
import BackupIcon from '@material-ui/icons/Backup';
import * as queryString from 'query-string';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { gapi } from 'gapi-script';
import AppConsts from '../../../config/appConsts';
import {
  getUserDataSuccess,
  userSetGoogleCredentials,
  userSetDropboxCredentials,
  userSetOnedriveCredentials,
  userUnsetGoogleCredentials,
  userUnsetDropboxCredentials,
  userUnsetOnedriveCredentials,
} from '../../../redux/user/userActions';
import GoogleDriveIcon from '../../atoms/GoogleDriveIcon';
import OneDriveIcon from '../../atoms/OneDriveIcon';
import DropBoxIcon from '../../atoms/DropBoxIcon';

import PurgeExternalDriveModal from './purgeExternalDriveModal';
import PaymentIcon from '@material-ui/icons/Payment';

const styles = theme => ({
  tabWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRadius: 2,
    backgroundColor: '#fff',
    padding: '0 0 32px 0',
    border: '1px solid rgb(237, 237, 237)',
  },
  storageSection: {
    // textTransform: 'none',
    display: 'flex',
    boxShadow: 'inset 0px -1px 0px #F7F7F7',
    padding: 20,
    justifyContent: 'space-between',
  },
  storageInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  storageIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
  },
  storageNameWrapper: {
    paddingLeft: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  storageAuthButtonWrapper: {},
  storageAuthButton: {
    backgroundColor: '#2158eb',
    borderRadius: 2,
    height: 52,
    color: '#fff',
    transition: 'all .3s ease-in-out',
    '&:hover': {
      backgroundColor: '#2158eb',
      boxShadow: '0 11px 14px -7px rgb(0 0 0 / 25%)',
      transform: 'translateY(-3px)',
      textDecoration: 'none',
    },
    // padding: theme.spacing(2),
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
});

let myMSALObj;

class ExternalStoragesTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      purgeModalData: {
        open: false,
        drive: null,
        type: '',
      },
    };
  }

  componentDidMount() {
    const _onInit = () => {
      console.log('init OK');
    };
    const _onError = (err) => {
      console.log('error', err);
    };
    gapi.load('auth2', () => {
      const { auth2 } = gapi;
      auth2
        .init({
          client_id: AppConsts.GOOGLE_CLIENT_ID,
          scope:
            'https://www.googleapis.com/auth/drive.appdata '
            + 'https://www.googleapis.com/auth/drive.file '
            + 'https://www.googleapis.com/auth/drive.install '
            + 'https://www.googleapis.com/auth/userinfo.profile',
          fetch_basic_profile: true,
        })
        .then(_onInit, _onError);
    });

    myMSALObj = new Msal.UserAgentApplication({
      auth: {
        clientId: AppConsts.ONEDRIVE_CLIENT_ID,
        authority: AppConsts.ONEDRIVE_AUTHORITY,
        redirectUri: AppConsts.ONEDRIVE_REDIRECT_URI,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    });
    this.processOauth();
  }

  processOauth = () => {
    const { userSetDropboxCredentials } = this.props;
    const qs = queryString.parse(window.location.search);
    if (qs.dropbox_response && qs.code) {
      console.log('Found oauth response from dropbox');
      userSetDropboxCredentials({
        token: qs.code,
      });
    }
    window.history.replaceState(
      {},
      '',
      '/dashboard/settings/external-storages',
    );
  };

  handlePurgeModal = (data) => {
    const {
      open,
      drive,
      type,
    } = data;
    this.setState({
      purgeModalData: {
        open,
        drive,
        type,
      },
    });
  };

  googleSignIn = () => {
    const { userSetGoogleCredentials } = this.props;

    const { auth2 } = gapi;
    auth2
      .getAuthInstance()
      .grantOfflineAccess()
      .then(async (googleUser) => {
        if (googleUser.code) {
          userSetGoogleCredentials({ token: googleUser.code });
        }
      });
  };

  onedriveSignIn = () => {
    const { userSetOnedriveCredentials } = this.props;
    const requestObj = {
      scopes: ['user.read', 'files.readwrite', 'offline_access'],
      forceRefresh: true,
    };

    myMSALObj
      .loginPopup(requestObj)
      .then((loginResponse) => {
        // console.log(loginResponse)
        myMSALObj
          .acquireTokenSilent(requestObj)
          .then((response) => {
            // console.log(response)
            // console.log(requestObj)
            userSetOnedriveCredentials(response);
          })
          .catch((error) => {
            // handle error by invoking an interactive login method
            // msalInstance.loginPopup(ssoRequest);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleSignOut = (drive) => {
    switch (drive) {
      case 'GOOGLEDRIVE':
        this.googleSignOut();
        break;

      case 'ONEDRIVE':
        this.onedriveSignOut();
        break;
      case 'DROPBOX':
        this.dropboxSignOut();
        break;

      default:
        console.log('Something vent wrong...');
    }
  };

  googleSignOut = () => {
    const { userUnsetGoogleCredentials } = this.props;
    const auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut()
      .then(() => {
        userUnsetGoogleCredentials();
      });
  };

  dropboxSignOut = () => {
    const { userUnsetDropboxCredentials } = this.props;
    userUnsetDropboxCredentials();
  };

  dropboxSignIn = () => {
    const _url = `https://www.dropbox.com/oauth2/authorize?client_id=${AppConsts.DROPBOX_CLIENT_ID}&response_type=code&token_access_type=offline&redirect_uri=${AppConsts.DROPBOX_REDIRECT_URI}`;
    window.location.assign(_url);
  };

  onedriveSignOut = () => {
    const { userUnsetOnedriveCredentials } = this.props;
    userUnsetOnedriveCredentials();
  };

  // eslint-disable-next-line consistent-return
  isGoogleCredentialsExist = () => {
    const { user } = this.props;
    if (!user || !user.externalStorages || user.externalStorages.length === 0) return false;
    return user.externalStorages.find(cred => cred.type === 'GOOGLEDRIVE');
  };

  isDropboxCredentialsExist = () => {
    const { user } = this.props;
    if (!user || !user.externalStorages || user.externalStorages.length === 0) return false;
    return user.externalStorages.find(cred => cred.type === 'DROPBOX');
  };

  isOnedriveCredentialsExist = () => {
    const { user } = this.props;
    if (!user || !user.externalStorages || user.externalStorages.length === 0) return false;
    return user.externalStorages.find(cred => cred.type === 'ONEDRIVE');
  };

  render() {
    // const dropboxOauthUrl = `https://www.dropbox.com/oauth2/authorize?client_id=${process.env.REACT_APP_DROPBOX_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_DROPBOX_REDIRECT_URI}`;
    const googleDriveUserData = this.isGoogleCredentialsExist();
    const onedriveUserData = this.isOnedriveCredentialsExist();
    const dropboxUserData = this.isDropboxCredentialsExist();
    const { classes } = this.props;
    const { purgeModalData } = this.state;

    return (
      <div className={classes.tabWrapper}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid rgb(237, 237, 237)',
          padding: 20,
        }}
        >
          <BackupIcon style={{
            marginRight: 8,
            color: '#2c5ce2',
          }}
          />
          <span style={{
            fontWeight: 500,
            fontSize: 16,
          }}
          >
            {' '}
            External Storages
          </span>
        </div>
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <div className={classes.storageSection}>
            <div className={classes.storageInfo}>
              <div className={classes.storageIconWrapper}>
                <GoogleDriveIcon />
              </div>
              <div className={classes.storageNameWrapper}>
                <Typography variant="subtitle2">Google Drive:</Typography>
                {googleDriveUserData && (
                  <Typography variant="subtitle2">
                    {googleDriveUserData.userData.email}
                  </Typography>
                )}
              </div>
            </div>
            <div>
              {googleDriveUserData ? (
                <>
                  <Button
                    onClick={() => this.handlePurgeModal({
                      open: true,
                      drive: 'GOOGLEDRIVE',
                      type: 'purge',
                    })
                    }
                    className={classes.storageAuthButton}
                    style={{
                      backgroundColor: '#EF4056',
                      color: '#fff',
                      marginRight: 10,
                    }}
                  >
                    PURGE
                  </Button>
                  <Button
                    onClick={() => this.handlePurgeModal({
                      open: true,
                      drive: 'GOOGLEDRIVE',
                      type: 'signOut',
                    })
                    }
                    className={classes.storageAuthButton}
                  >
                    DISCONNECT
                  </Button>
                </>
              ) : (
                <Button
                  onClick={this.googleSignIn}
                  className={classes.storageAuthButton}
                >
                  CONNECT
                </Button>
              )}
            </div>
          </div>
          <div className={classes.storageSection}>
            <div className={classes.storageInfo}>
              <div className={classes.storageIconWrapper}>
                <DropBoxIcon />
              </div>
              <div className={classes.storageNameWrapper}>
                <Typography variant="subtitle2">DropBox:</Typography>
                {dropboxUserData && (
                  <Typography variant="subtitle2">
                    {dropboxUserData.userData.email}
                  </Typography>
                )}
              </div>
            </div>
            <div>
              {!dropboxUserData ? (
                <Link
                  // style={{ textTransform: 'none' }}
                  color="inherit"
                  className={classes.storageAuthButton}
                  // href={dropboxOauthUrl}
                >
                  <Button
                    onClick={this.dropboxSignIn}
                    className={classes.storageAuthButton}
                  >
                    CONNECT
                  </Button>
                </Link>
              ) : (
                <>
                  <Button
                    onClick={() => this.handlePurgeModal({
                      open: true,
                      drive: 'DROPBOX',
                      type: 'purge',
                    })
                    }
                    className={classes.storageAuthButton}
                    style={{
                      backgroundColor: '#EF4056',
                      color: '#fff',
                      marginRight: 10,
                    }}
                  >
                    PURGE
                  </Button>
                  <Button
                    onClick={() => this.handlePurgeModal({
                      open: true,
                      drive: 'DROPBOX',
                      type: 'signOut',
                    })
                    }
                    className={classes.storageAuthButton}
                    style={{
                      backgroundColor: '#2158eb',
                      color: '#fff',
                    }}
                  >
                    DISCONNECT
                  </Button>
                </>
              )}
            </div>
          </div>
          {/* <div className={classes.storageSection}> */}
          {/*  <div className={classes.storageInfo}> */}
          {/*    <div className={classes.storageIconWrapper}> */}
          {/*      <OneDriveIcon /> */}
          {/*    </div> */}
          {/*    <div className={classes.storageNameWrapper}> */}
          {/*      <Typography variant="subtitle2">OneDrive:</Typography> */}
          {/*      {onedriveUserData && ( */}
          {/*        <Typography variant="subtitle2"> */}
          {/*          {onedriveUserData.data.account.userName} */}
          {/*        </Typography> */}
          {/*      )} */}
          {/*    </div> */}
          {/*  </div> */}
          {/*  /!*<div className={classes.storageAuthButtonWrapper}>*!/ */}
          {/*  /!*  <Button*!/ */}
          {/*  /!*    onClick={*!/ */}
          {/*  /!*      onedriveUserData ? this.onedriveSignOut : this.onedriveSignIn*!/ */}
          {/*  /!*    }*!/ */}
          {/*  /!*    className={classes.storageAuthButton}*!/ */}
          {/*  /!*    style={{ backgroundColor: '#2158eb', color: '#fff' }}*!/ */}
          {/*  /!*  >*!/ */}
          {/*  /!*    {onedriveUserData ? 'DISCONNECT' : 'CONNECT'}*!/ */}
          {/*  /!*  </Button>*!/ */}
          {/*  /!*  /!* UNDER DEVELOPMENT *!/*!/ */}
          {/*  /!*</div>*!/ */}
          {/* </div> */}
        </div>
        <PurgeExternalDriveModal
          purgeModalData={purgeModalData}
          handleClose={() => this.handlePurgeModal({
            open: false,
            drive: null,
          })
          }
          handleSignOut={this.handleSignOut}
        />
      </div>
    );
  }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
  getUserDataSuccess: () => {
    dispatch(getUserDataSuccess());
  },
  userSetGoogleCredentials: (payload) => {
    dispatch(userSetGoogleCredentials(payload));
  },
  userUnsetGoogleCredentials: () => {
    dispatch(userUnsetGoogleCredentials());
  },
  userSetDropboxCredentials: (payload) => {
    dispatch(userSetDropboxCredentials(payload));
  },
  userUnsetDropboxCredentials: (payload) => {
    dispatch(userUnsetDropboxCredentials(payload));
  },
  userSetOnedriveCredentials: (payload) => {
    dispatch(userSetOnedriveCredentials(payload));
  },
  userUnsetOnedriveCredentials: (payload) => {
    dispatch(userUnsetOnedriveCredentials(payload));
  },
});

export default compose(
  withStyles(styles),
  withWidth(),
  connect(mapStateToProps, mapDispatchToProps),
)(ExternalStoragesTab);
