import React, { useCallback, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';

import DropZoneFolderIcon from '../../atoms/DropZoneFolderIcon';
import PlugIcon from '../../atoms/PlugIcon';
import {
  downloadByLinkLinkSubmit,
  filesUploadSubmit,
} from '../../../redux/files/filesActions';
import FilesList from './List';
import FilesGrid from './Grid';
import { setDashboardTab } from '../../../redux/viewsEffects/viewsEffectsActions';

const useStyles = makeStyles(theme => ({
  dropZoneContentText: {
    fontSize: 34,
    color: 'rgba(0,0,0,0.6)',
    fontWeight: 600,
    marginTop: 20,
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
      marginTop: 15,
      textAlign: 'center',
    },
  },
  invalidCredentialsText: {
    fontSize: 24,
    color: 'rgba(0,0,0,0.6)',
    fontWeight: 600,
    marginTop: 20,
    userSelect: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      marginTop: 15,
      textAlign: 'center',
    },
  },
  dropZoneContentTextSpan: {
    color: '#0086FA',
  },
  dropZoneWarningText: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 12,
  },
}));

const baseStyle = {
  flex: 1,
  display: 'flex',
  marginTop: 10,
  // height: '70vh',
};

const activeStyle = {
  borderColor: '#2196f3',
  backgroundColor: '#eff3f6',
};

const configError = {
  border: 'none',
};
const borderedZone = {
  minHeight: 340,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderWidth: 4,
  borderRadius: 2,
  borderColor: '#CCCCCC',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
  padding: '60px 20px',
};

const container = {
  height: '100%',
  paddingLeft: 20,
  paddingRight: 20,
};
const acceptStyle = {
  borderColor: '#00e676',
  backgroundColor: '#eff3f6',
};

const rejectStyle = {
  borderColor: '#EF4056',
  backgroundColor: '#f6f1f2',
};

export default function StyledDropzone(props) {
  const classes = useStyles();
  const {
    displayType, folders, files, isDownloadsBlocked,
  } = props;
  const dispatch = useDispatch();
  const setTab = (e) => {
    e.stopPropagation();
    window.history.replaceState(
      { path: '/dashboard/settings/usenet-providers' },
      '',
      '/dashboard/settings/usenet-providers',
    );

    dispatch(setDashboardTab(2));
  };
  const { location } = window;
  useEffect(() => {
    if (location.search) {
      const params = new URL(location.href).searchParams;
      const isUrlParam = params.get('url');
      if (isUrlParam) {
        const url = location.search.substring(5);

        if (url) {
          dispatch(downloadByLinkLinkSubmit(url));
          window.history.replaceState({}, '', '/dashboard/downloads');
        }
      }
    }
  }, [location]);
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length) {
        dispatch(filesUploadSubmit(acceptedFiles));
      }
    },
    [dispatch],
  );
  const onDropRejected = useCallback(() => {
    toast.error('You selected an .NZB file, right?', {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    rejectedFiles,
  } = useDropzone({
    accept: '.nzb,.gz,.zip,.gzip,.tar,.tar.gz,.tgz',
    disabled: isDownloadsBlocked,
    onDrop,
    onDropRejected,
    noKeyboard: true,
    noClick: Boolean(folders.length || files.length),
  });

  const style = useMemo(
    () => ({
      ...borderedZone,
      ...(rejectedFiles.length ? rejectStyle : {}),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDownloadsBlocked ? {} : configError),
    }),
    [isDragAccept, isDragActive, rejectedFiles.length],
  );

  return (
    <div style={container}>
      <div {...getRootProps({ style: baseStyle })} className="step6">
        <input {...getInputProps()} />
        {isDragActive || (!folders.length && !files.length) ? (
          <div style={style}>
            {/* <div>{validationStatus ? <DropZoneFolderIcon /> : <PlugIcon />}</div> */}
            <div>
              {' '}
              <DropZoneFolderIcon />
            </div>
            <>
              <div className={classes.dropZoneContentText}>
                {/* {validationStatus ? ( */}
                <div>
                  Drop NZB file here or
                  {' '}
                  <span className={classes.dropZoneContentTextSpan}>
                    browse
                  </span>
                  {' '}
                  to upload
                </div>
                {/* // ) : ( */}
                {/* //   <div>Set up the Usenet Connection</div> */}
                {/* // )} */}
              </div>
              {!isDownloadsBlocked ? (
                <div
                  className={classes.dropZoneWarningText}
                  style={{
                    color:
                      rejectedFiles.length && !isDragActive
                        ? '#EF4056'
                        : 'rgba(0,0,0,0.6)',
                  }}
                >
                  Upload
                  {' '}
                  <span style={{ fontWeight: 600 }}>
                    only .nzb, .gz, .zip, .gzip, .tar, .tar.gz, .tgz
                  </span>
                  {' '}
                  files.
                </div>
              ) : (
                <div
                  className={classes.dropZoneWarningText}
                  style={{
                    color: 'rgba(0,0,0,0.6)',
                    maxWidth: 600,
                  }}
                >
                  To start downloading the NZB files, you need to add the correct
                  credentials of your Usenet provider in the
                  {' '}
                  <span
                    className="step7"
                    onClick={e => setTab(e)}
                    style={{ color: '#0086FA' }}
                  >
                    Settings
                  </span>
                </div>
              )}
            </>
          </div>
        ) : (
          <>
            {displayType === 'list' ? (
              <FilesList folders={folders} files={files} />
            ) : (
              <FilesGrid folders={folders} files={files} />
            )}
          </>
        )}
      </div>
    </div>
  );
}
