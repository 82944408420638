import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ServicePageHeaders from '../ServicePageHeaders';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8,
      paddingRight: 8,
      paddingBottom: 50,
    },
  },
}));
const PageName = 'FAQ';
const BoldHeader = 'Got questions?';
const Subtitle = '';
export default function Faq() {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container className={classes.container} component="main" maxWidth="md">
        <ServicePageHeaders
          pageName={PageName}
          boldHeader={BoldHeader}
          subtitle={Subtitle}
        />

        <div>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Is it possible to download several files to NZBCloud at once?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, just select files and move them to the download area.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                What if my internet connection is down? Would my download
                continue?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes your download will continue, as NZBCloud is cloud-based we
                keep downloading the files while you're offline.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography className={classes.heading}>
                Can I upload subtitles while streaming videos?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Unfortunately, now. Fortunately, NZBCloud supports Usenet files
                which contain subtitles. In this case, subtitles are added
                automatically to the video playing. Of course, you can turn them
                off if you’d like.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography className={classes.heading}>
                Do you support Android or Smart TV?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, NZBCloud is optimized for all modern devices which have
                browsers.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <Typography className={classes.heading}>
                Can I watch the video online and download it to my device at the
                same time?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, you can stream the video through NZBCloud and download it
                to your device simultaneously. Performance won’t be affected.
              </Typography>
            </AccordionDetails>
          </Accordion>

        </div>
      </Container>
    </>
  );
}
