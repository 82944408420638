import * as React from 'react';
import Button from '@material-ui/core/Button';
import useStyles from './styles';
import SneilIcon from '../../../atoms/SneilIcon';

const EncodeBadge = ({
  transcodeStatus, handleEncode,
}) => {
  const classes = useStyles();

  const handleEncodeClick = () => {
    handleEncode();
  };

  return (
    <div className={classes.encodeMessage}>
      <div className={classes.iconWrapper}>
        <SneilIcon />
      </div>
      <div className={classes.textBlock}>
        <div style={{ color: '#000000', fontWeight: 500 }}>
          Can`t play this video?
        </div>
        {!transcodeStatus && (
          <div style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
            Try live encoding!
          </div>
        )}
        {transcodeStatus === 'queued' && (
          <div style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
            Encoding in progress
          </div>
        )}
        {transcodeStatus === 'executing' && (
          <div style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
            Encoding in progress
          </div>
        )}
        {transcodeStatus === 'failed' && (
          <div style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
            Unfortunately Encoder cannot proceed with this file.
          </div>
        )}
      </div>
      <div className={classes.encodeButtonWrapper}>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          disabled={transcodeStatus === 'executing' || transcodeStatus === 'queued' || transcodeStatus === 'failed'}
          className={classes.encodeButton}
          onClick={() => handleEncodeClick()}
        >
          ENCODE
        </Button>
      </div>
    </div>
  );
};

export default EncodeBadge;
