export const GET_NOTIFICATIONS_SUBMIT = 'GET_NOTIFICATIONS_SUBMIT';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const MARK_ALL_READ_SUBMIT = 'MARK_ALL_READ_SUBMIT';
export const MARK_ALL_READ_SUCCESS = 'MARK_ALL_READ_SUCCESS';
export const MARK_ALL_READ_FAILURE = 'MARK_ALL_READ_FAILURE';

export const HIDE_ALL_NOTIFICATIONS_SUBMIT = 'HIDE_ALL_NOTIFICATIONS_SUBMIT';
export const HIDE_ALL_NOTIFICATIONS_SUCCESS = 'HIDE_ALL_NOTIFICATIONS_SUCCESS';
export const HIDE_ALL_NOTIFICATIONS_FAILURE = 'HIDE_ALL_NOTIFICATIONS_FAILURE';

export const hideAllNotificationsSubmit = payload => ({
  type: HIDE_ALL_NOTIFICATIONS_SUBMIT,
  payload,
});

export const hideAllNotificationsSuccess = payload => ({
  type: HIDE_ALL_NOTIFICATIONS_SUCCESS,
  payload,
});
export const hideAllNotificationsFailure = payload => ({
  type: HIDE_ALL_NOTIFICATIONS_FAILURE,
  payload,
});

export const getNotificationsSubmit = payload => ({
  type: GET_NOTIFICATIONS_SUBMIT,
  payload,
});

export const getNotificationsSuccess = payload => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload,
});
export const getNotificationsFailure = payload => ({
  type: GET_NOTIFICATIONS_FAILURE,
  payload,
});

export const markAllNotificationsReadSubmit = payload => ({
  type: MARK_ALL_READ_SUBMIT,
  payload,
});

export const markAllNotificationsReadSuccess = payload => ({
  type: MARK_ALL_READ_SUCCESS,
  payload,
});
export const markAllNotificationsReadFailure = payload => ({
  type: MARK_ALL_READ_FAILURE,
  payload,
});
