import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import CryptoJS from 'crypto-js';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  setWelcomeModalOpen,
} from '../../../redux/viewsEffects/viewsEffectsActions';

import WelcomeStepper from './WelcomeStepper/index';

const useStyles = makeStyles(theme => ({
  someClass: {
    display: 'flex',
  },
}));

export default function WelcomeModal(props) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleWelcomeModalOpen = (value) => {
    dispatch(setWelcomeModalOpen(value));
  };


  const { wizardFinished } = useSelector(state => state.user);


  const { welcomeModalOpen, welcomeModalStep } = useSelector(state => state.viewsEffects);


  useEffect(() => {
    if (!wizardFinished) {
      handleWelcomeModalOpen(true);
    } else {
      handleWelcomeModalOpen(false);
    }
  }, [wizardFinished]);
  return (
    <Dialog
      open={welcomeModalOpen}
      disableBackdropClick
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          Few simple steps to start using NZBCLOUD
          {' '}
        </div>
      </DialogTitle>
      <WelcomeStepper welcomeModalStep={welcomeModalStep} />
    </Dialog>

  );
}
