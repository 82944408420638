import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ServicePageHeaders from '../ServicePageHeaders';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
}));
const PageName = 'Terms of Service';
const BoldHeader = 'Read it carefully';
const Subtitle = '';
export default function TermsOfService() {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container className={classes.container} component="main" maxWidth="md">
        <ServicePageHeaders
          pageName={PageName}
          boldHeader={BoldHeader}
          subtitle={Subtitle}
        />

        <div style={{ padding: '5px 32px 32px 32px' }}>
          <Typography variant="caption">
            <p>
              Nzbcloud.com lets you download files from public sources using its
              servers – the servers act as a proxy for you. This means that no
              Usenet peers or files are controlled by us and you must have the
              legal right to download the content you are accessing through our
              servers; this service should not be used for any other purpose. If
              you want to check the legitimacy of the data, please contact the
              relevant tracker owners.
            </p>
            <p>
              This service was created to allow you to download public and
              freely available information like Linux distributions or content
              from independent artists or other non-commercial software. As
              such, no copyrighted data is stored on our servers and made
              publicly available. Only those who have initiated the transfer
              have access to valid download links.
            </p>
            <p>
              Nzbcloud.com reserves the right at any time to modify or
              discontinue, temporarily or permanently, any of the services
              (‘Services’) it provides you with or without notice. You agree
              that nzbcloud.com shall not be liable to you or to any third party
              for any modification, suspension or discontinuance of the services
              (or any part thereof) it provides.
            </p>
            <p>
              Acceptance of Terms Nzbcloud.com provides the services (defined
              below) according to the following Terms of Service, which may be
              updated by us any time without notice to you. You can review the
              ToS anytime by visiting this page. By accessing and using
              nzbcloud.com services, you accept and agree to be bound by the
              terms and provisions of the ToS. In addition, when using
              particular nzbcloud.com owned or operated services, you and
              nzbcloud.com shall be subject to any posted guidelines or rules
              applicable to such services, which may be posted and modified any
              time. All such guidelines or rules are hereby incorporated by
              reference into the ToS.
            </p>
            <p>
              Your registration obligations and representations to nzbcloud.com
              In regards of your use of nzbcloud.com services, you accept that
              you are of legal age to form a binding contract and are not a
              person barred from receiving nzbcloud.com services under the laws
              of any applicable jurisdiction. You further agree, warrant and
              covenant that: (a) you will not use nzbcloud.com services in a way
              that would: (i) restrict or inhibit any other user from using and
              enjoying nzbcloud.com Services, (ii) be illegal, (iii) constitute
              or encourage conduct that would constitute a criminal offense,
              give rise to civil liability or otherwise violate applicable laws
              or regulations, (iv) violate, plagiarize or infringe the rights of
              third parties including, without limitation, copyright, trademark,
              patent, rights of privacy or publicity or any other proprietary
              right, (v) introduce or propagate a virus or other harmful
              component, (vi) contain any information, software or other
              material of a commercial nature, (vii) contain advertising of any
              kind or any deceptive online marketing, (viii) constitute or
              contain false or misleading indications of origin or statements of
              fact; or (ix) violate the Acceptable Use Policies of any service
              or technology accessed through nzbcloud.com services: (b) that you
              are at least eighteen (18) years old; (c) you are not barred from
              receiving any portion of the nzbcloud.com Services by any
              applicable law or order; and (d) that all information submitted in
              connection with your registration is and will remain accurate and
              complete. Nzbcloud.com is concerned about the safety and privacy
              of all its users. You agree to notify nzbcloud.com immediately of
              any impending or actual breach of security or other event known to
              you that may negatively affect nzbcloud.com services.
            </p>
            <p>
              Member account, password, and security You will receive a username
              and password designation upon completing nzbcloud.com services’
              registration process. You are responsible for maintaining the
              confidentiality of the username and password and are fully
              responsible for all activities that occur under your password or
              account. You agree to (a) immediately notify nzbcloud.com of any
              unauthorized use of your password or account or any other breach
              of security, and (b) ensure that you exit from your account at the
              end of each session. Nzbcloud.com cannot and will not be liable
              for any loss or damage arising from your failure to comply with
              this information.
            </p>
            <p>
              General practices regarding use and storage You acknowledge that
              nzbcloud.com may establish general practices and limits concerning
              use of nzbcloud.com services, including without limitation the
              maximum number of days that files or other uploaded content
              including but not limited to NZB files that will be stored by
              nzbcloud.com services, the maximum number of uploads or downloads
              that may be processed by an account on nzbcloud.com services, the
              maximum size of any file that may downloaded by an account on the
              nzbcloud.com Services, the maximum disk space that will be
              allocated on NZBCloud’s contracted servers on your behalf, and the
              maximum number of times (and the maximum duration for which) you
              may access the nzbcloud.com Services in a given period of time.
              You agree that nzbcloud.com has no responsibility or liability for
              the deletion or failure to store any files and other
              communications or other content maintained or transmitted by
              nzbcloud.com services. You acknowledge that nzbcloud.com reserves
              the right to log off accounts that are inactive for an extended
              period of time. You further acknowledge that nzbcloud.com reserves
              the right to modify these general practices and limits from time
              to time.
            </p>
            <p>
              NZBCloud’s proprietary rights and trademark information You
              acknowledge and agree that nzbcloud.com services and any necessary
              software used in connection with nzbcloud.com services
              ("Software") contain proprietary and confidential information that
              is protected by applicable intellectual property and other laws.
            </p>
            <p>
              Nzbcloud.com claims no ownership or control over any content
              uploaded, downloaded or displayed by you on or through the
              Service. You retain all patent, trademark and copyright to any
              content you upload, download or display on or through the service
              and you are responsible for protecting those rights, as
              appropriate. You hereby waive all rights to any claim against
              nzbcloud.com for any alleged or actual infringements of any
              proprietary rights, rights of privacy or publicity, moral rights,
              and rights of attribution in connection with such content.
            </p>
            <p>
              You agree that all of NZBCloud’s trademarks, trade names, service
              marks, logos, brand features, and product and service names are
              trademarks and the property of nzbcloud.com
            </p>
            <p>
              Acceptable use policy You understand that all information, data,
              text, software, music, sound, photographs, graphics, video,
              messages, tags, or other materials including but not limited to
              uploaded files and torrents (‘Content’), whether publicly posted
              or privately transmitted, are the sole responsibility of the
              person from whom such Content originated. This means that you, and
              not nzbcloud.com, are entirely responsible for all Content that
              you upload, download, email, transmit or otherwise make available
              via nzbcloud.com services.
            </p>
            <p>
              Nzbcloud.com does not control the Content available via
              nzbcloud.com services, as such, does not guarantee the accuracy,
              integrity or quality of such content. You understand that by using
              nzbcloud.com services, you may be exposed to content that is
              offensive, indecent or objectionable. Under no circumstances will
              nzbcloud.com be liable in any way for any content, including, but
              not limited to, any errors or omissions in any Content, or any
              loss or damage of any kind incurred as a result of the use of any
              Content downloaded, transmitted or otherwise made available via
              nzbcloud.com services.
              {' '}
            </p>
            <p>
              While nzbcloud.com requires appropriate member conduct and Content
              in connection with use of the nzbcloud.com Services, you
              understand and agree that you nonetheless may be exposed to
              inappropriate conduct and/or Content and that you use the
              nzbcloud.com Services and the global data networks at your own
              risk.
            </p>
            <p>
              While nzbcloud.com requires appropriate member conduct and Content
              in connection with use of the nzbcloud.com Services, you
              understand and agree that you nonetheless may be exposed to
              inappropriate conduct and/or Content and that you use the
              nzbcloud.com Services and the global data networks at your own
              risk.
            </p>
            <p>
              You agree to not use nzbcloud.com services to: (A) upload,
              download, transmit or otherwise make available any Content that is
              unlawful, harmful, threatening, abusive, harassing, tortious,
              defamatory, vulgar, obscene, libelous, invasive of another's
              privacy, hateful, or racially, ethnically or otherwise
              objectionable; (B) harm minors in any way; store or retrieve any
              kind of child exploitation content; (C) impersonate any person or
              entity, including, but not limited to, nzbcloud.com itself, or
              falsely state or otherwise misrepresent your affiliation with a
              person or entity; (E) upload, download, transmit or otherwise make
              available any Content that you do not have a right to make
              available under any law or under contractual or fiduciary
              relationships (such as inside information, proprietary and
              confidential information learned or disclosed as part of
              employment relationships or under nondisclosure agreements); (F)
              upload, download, transmit or otherwise make available any Content
              that infringes any patent, trademark, trade secret, copyright or
              other proprietary rights of any party including the right of
              privacy ("Rights"); (H) upload, download, transmit or otherwise
              make available any material that contains software viruses, worms,
              Trojan horses, or any other computer code, files or programs
              designed to interrupt, destroy or limit the functionality of any
              computer software or hardware or telecommunications equipment; (I)
              interfere with or disrupt nzbcloud.com services or servers or
              networks connected to or utilized by nzbcloud.com services, or
              disobey any requirements, procedures, policies or regulations of
              networks connected to nzbcloud.com services, including using any
              device, software or routine to bypass robot exclusion headers; (J)
              intentionally or unintentionally violate any applicable local,
              state, national or international law, including, but not limited
              to, regulations promulgated by the U.S. Securities and Exchange
              Commission, any rules of any national or other securities
              exchange, and any regulations having the force of law; (K) provide
              material support or resources (or to conceal or disguise the
              nature, location, source, or ownership of material support or
              resources) to any organization(s) designated by the United States
              government as a foreign terrorist organization pursuant to section
              219 of the Immigration and Nationality Act; (M) use any robot,
              spider, site search/retrieval application, or other device to
              retrieve or index any portion of the Service or collect
              information about users for any unauthorized purpose; (N) create
              user accounts by automated means or under false or fraudulent
              pretenses; (O) interfere with or disrupt nzbcloud.com services or
              servers or networks connected to the Service, or disobey any
              requirements, procedures, policies or regulations of networks
              connected to the Service; (P) use nzbcloud.com services for
              anything other than personal non-commercial use; (Q) reproduce,
              duplicate, copy, sell, trade, resell or exploit for any commercial
              purposes, any portion or use of, or access to, the nzbcloud.com
              Services (including Content, advertisements, Software and your
              nzbcloud.com ID); (R) violate this Policy or run, install, store,
              transmit or otherwise make available any software, program,
              product or service that violates this Policy; (S) violate any
              specific instructions given by nzbcloud.com for reasons of health,
              safety or quality of the Services; (T) sabotage any computers,
              machines or networks, or use or control bots, flooding or cloning
              or data extraction or gathering tools; (U) attempt to interfere
              with or deny service to any user or host (e.g. denial of service
              attacks and/or DNS spoofing attacks); (V) scan the networks of
              others for vulnerabilities without authorization; (W) execute any
              form of network monitoring (e.g., using a packet sniffer) or
              otherwise engage in any monitoring or interception of data not
              intended for you without authorization; (X) attempt to circumvent
              authentication or security of any host, network, or account
              ("cracking") without authorization; (Y) use any program / script/
              command, or send messages of any kind, designed to interfere with
              a third party customer terminal session, via any means, locally or
              via the Internet; (Z) "pharming," that is, using malware, DNS
              cache poisoning or other means to redirect a user to a website or
              other service that simulates a service offered by a legitimate
              entity in order to collect identity information, authentication
              credentials, or other information from the legitimate users of
              that entity's service; (AA) use nzbcloud.com services in excess of
              any specified bandwidth limitation for your user account or
              generate excessive server load on the servers providing
              nzbcloud.com services; (BB) attempt to circumvent or alter the
              process or procedures to measure time, bandwidth utilization, or
              other methods to document "use" of nzbcloud.com services; (CC)
              make any service, resource or part of nzbcloud.com services
              available to others without authorization; (DD) hijack IP space;
              (FF) maintain an open mail relay and/or an open proxy; (GG) use
              the Services in connection with or in support of the running of a
              mail server without a license to run such a server in any
              jurisdiction where such license is required; (HH) be listed on an
              industry-recognized spam abuse list; (II) use meta tags or any
              other "hidden text" utilizing nzbcloud.com name, domain name or
              trademarks on nzbcloud.com services; (JJ) collect or store
              personal data about other users in connection with the prohibited
              conduct and activities set forth in the subparagraphs above;
              and/or (KK) do anything which hereinafter will be widely regarded
              as network abuse. You acknowledge that nzbcloud.com or its
              affiliates or designees may or may not pre-screen Content, but
              that nzbcloud.com and its designees shall have the right (but not
              the obligation) in their sole discretion to pre-screen, refuse, or
              remove any Content that is hosted on the servers to which you are
              given access via nzbcloud.com services. Without limiting the
              foregoing, nzbcloud.com and its designees shall have the right to
              remove any Content that violates the ToS or is otherwise
              objectionable. You agree that you must evaluate, and bear all
              risks associated with, the use of any Content, including any
              reliance on the accuracy, completeness, or usefulness of such
              Content. In this regard, you acknowledge that you may not rely on
              any Content created by nzbcloud.com, including without limitation
              information in all other parts of nzbcloud.com services, or
              information found in Usenet newsgroups You acknowledge, consent
              and agree that nzbcloud.com may access, preserve and disclose your
              account information and Content if required to do so by law or in
              a good faith belief that such access preservation or disclosure is
              reasonably necessary to: You acknowledge, consent and agree that
              nzbcloud.com will not tolerate any kind of child exploitation
              content on its servers and offenders will be reported to the
              appropriate authorities in their countries. (i) comply with legal
              process; (ii) enforce the ToS; (iii) respond to claims that any
              Content violates the rights of third parties; (iv) respond to your
              requests for customer service; or (v) protect the rights, property
              or personal safety of nzbcloud.com, its users and the public.
              Please be aware that some files may contain adult or mature
              content. nzbcloud.com has no control over files content and unable
              to provide any content rating. You must be at least 18 years of
              age to subscribe to nzbcloud.com Services. You acknowledge that
              nzbcloud.com does not pre-screen, control, edit or endorse Content
              made available through the nzbcloud.com Services and has no
              obligation to monitor the Content uploaded via the use of
              nzbcloud.com services. Through our services, you will have access
              to and you may at some time or another become exposed to materials
              that you find offensive. Such materials include sexually explicit
              text and (encoded) images, pro-religious and anti-religious
              debate, questionable political views, and hateful speech.
              Nzbcloud.com expressly disclaims liability for any harm resulting
              from encountering such material. Moreover, we cannot be and are
              not responsible for the contents of any of your communications
              made via the use of nzbcloud.com services. If nzbcloud.com
              discovers Content (as hereinafter defined) that does not appear to
              conform to the Terms of Service, nzbcloud.com may investigate and
              determine in good faith and in its sole discretion whether to have
              the Content removed. Nzbcloud.com will have no liability or
              responsibility for performance or non-performance of such
              activities. You acknowledge that nzbcloud.com has absolutely no
              control over the Content in global data networks. You understand
              that nzbcloud.com services and software embodied within
              nzbcloud.com services may include security components that permit
              digital materials to be protected, and that use of these materials
              is subject to usage rules set by nzbcloud.com and/or content
              providers who provide content to nzbcloud.com services. You may
              not attempt to override or circumvent any of the usage rules
              embedded into the nzbcloud.com Services. Any unauthorized
              reproduction, publication, further distribution or public
              exhibition of the materials provided on the nzbcloud.com Services,
              in whole or in part, is strictly prohibited.
            </p>
            <p>
              Contributions to nzbcloud.com By submitting ideas, suggestions,
              documents, and/or proposals ("Contributions") to nzbcloud.com
              through its suggestion or feedback webpages or email addresses,
              you acknowledge and agree that: (a) your Contributions do not
              contain confidential or proprietary information; (b) nzbcloud.com
              is not under any obligation of confidentiality, express or
              implied, with respect to the Contributions; (c) nzbcloud.com shall
              be entitled to use or disclose (or choose not to use or disclose)
              such Contributions for any purpose, in any way, in any media
              worldwide; (d) nzbcloud.com may have something similar to the
              Contributions already under consideration or in development; (e)
              your Contributions automatically become the property of
              nzbcloud.com without any obligation of nzbcloud.com to you; and
              (f) you are not entitled to any compensation or reimbursement of
              any kind from nzbcloud.com under any circumstances.
            </p>
            <p>
              Termination You may terminate your NZBCloud account and access to
              the NZBCloud’s Services by submitting termination request to
              nzbcloud.com support. Account termination will be completed after
              you will get notice about successful termination from
              nzbcloud.com. You agree that nzbcloud.com may, without prior
              notice, immediately terminate, limit your access to or suspend
              your nzbcloud.com account and access to nzbcloud.com services.
              Cause for such termination, limitation of access or suspension
              shall include, but not be limited to, (a) a breach or a violation
              of the ToS or other incorporated agreements or guidelines, (b)
              requests by law enforcement or other government agencies, (c)
              discontinuance or material modification to nzbcloud.com services
              (or any part thereof), (d) unexpected technical or security issues
              or problems, (e) extended periods of inactivity, (f) engagement by
              you in fraudulent or illegal activities
            </p>
            <p>
              Further, you agree that all terminations, limitations of access
              and suspensions for cause shall be made in NZBCloud’s sole
              discretion and that nzbcloud.com shall not be liable to you or any
              third party for any termination of your account, any associated
              email address, or access to nzbcloud.com services. Termination of
              your nzbcloud.com account includes any or all of the following:
              (a) removal of access to all or part of the offerings within
              nzbcloud.com services, (b) deletion of your password and all
              related information, files and content associated with or inside
              your account (or any part thereof), (c) deletion of any Content or
              any part thereof posted by you, and (d) barring of further use of
              all or part of nzbcloud.com services.
            </p>
            <p>
              Indemnity You agree to indemnify and hold nzbcloud.com and its
              subsidiaries, affiliates, officers, agents, employees, partners,
              licensors and licensees harmless from any claim or demand,
              including reasonable attorneys' fees, made by any third party due
              to or arising out of Content you submit, post, transmit, modify or
              otherwise make available through nzbcloud.com services, your use
              of the nzbcloud.com Services, your connection to nzbcloud.com
              services, your connection to the global network, your violation of
              the ToS, or your violation of any rights of another.
            </p>
            <p>
              Disclaimer of warranties and limitation of liability By submitting
              ideas, suggestions, documents, and/or proposals (‘Contributions’)
              to nzbcloud.com through its suggestion or feedback webpages or
              email addresses, you acknowledge and agree that: (a) your
              Contributions do not contain confidential or proprietary
              information; (b) nzbcloud.com is not under any obligation of
              confidentiality, express or implied, with respect to the
              Contributions; (c) nzbcloud.com shall be entitled to use or
              disclose (or choose not to use or disclose) such Contributions for
              any purpose, in any way, in any media worldwide; (d) nzbcloud.com
              may have something similar to the Contributions already under
              consideration or in development; (e) your Contributions
              automatically become the property of nzbcloud.com without any
              obligation of nzbcloud.com to you; and (f) you are not entitled to
              any compensation or reimbursement of any kind from nzbcloud.com
              under any circumstances.
            </p>
            <p>
              You expressly understand and agree that: (a) your use of
              nzbcloud.com services and software are at your sole risk.
              NZBCloud’s services and software are provided on an "as is" and
              "as available" basis. Nzbcloud.com and its subsidiaries,
              affiliates, officers, employees, agents, partners, licensors and
              licensees expressly disclaim all warranties of any kind, whether
              express or implied, including, but not limited to the implied
              warranties of title, merchantability, fitness for a particular
              purpose and non-infringement. (b) nzbcloud.com and its
              subsidiaries, affiliates, officers, employees, agents, partners,
              licensors and licensees make no warranty that (i) nzbcloud.com
              services or software will meet your requirements; (ii)
              nzbcloud.com services or software will be uninterrupted, timely,
              secure or error-free; (iii) the results that may be obtained from
              the use of nzbcloud.com services or software will be accurate or
              reliable; (iv) the quality of any products, services, information
              or other material purchased or obtained by you through the
              nzbcloud.com services or software will meet your expectations; (v)
              that you find the interface developed by the software to be user
              friendly and (v) any errors in the software will be corrected. (c)
              any material downloaded or otherwise obtained through the use of
              nzbcloud.com services or software is accessed at your own
              discretion and risk, and you will be solely responsible for and
              hereby waive any and all claims and causes of action with respect
              to any damage to your computer system, internet access, download
              or display device, or loss of data that results from the download
              of any such material. (d) no advice or information, whether oral
              or written, obtained by you from nzbcloud.com or through or from
              nzbcloud.com services or software shall create any warranty not
              expressly stated in the ToS. You expressly understand and agree
              that nzbcloud.com and its subsidiaries, affiliates, officers,
              employees, agents, partners, licensors and licensees shall not be
              liable to you for any punitive, indirect, incidental, special,
              consequential or exemplary damages, including, but not limited to,
              damages for loss of profits, goodwill, use, data or other
              intangible losses (even if nzbcloud.com has been advised of the
              possibility of such damages), resulting from: (a) the use or the
              inability to use nzbcloud.com services; (b) the cost of
              procurement of substitute goods and services; (c) unauthorized
              access to or alteration of your transmissions or data; (d)
              statements or conduct of any third party on nzbcloud.com services;
              or (e) any other matter relating to nzbcloud.com services. Unless
              explicitly stated otherwise, any new features augmenting or
              enhancing the current nzbcloud.com Services, including the release
              of new nzbcloud.com properties, shall be subject to these ToS. You
              understand and agree that nzbcloud.com services are provided
              "AS-IS" and that nzbcloud.com assumes no responsibility for the
              timeliness, deletion, mis-delivery or failure to post or store any
              user communications or personalization settings. You further
              understand that nzbcloud.com Services may be interrupted and
              nzbcloud.com and the entities providing hosted servers shall not
              be liable for interruptions. You are responsible for obtaining
              access to the nzbcloud.com website and the nzbcloud.com Services,
              and that access may involve third-party fees (such as Internet
              service provider or airtime charges). You are responsible for
              those fees, including those fees associated with your subscription
              to nzbcloud.com services as hereinafter described. In addition,
              you must provide and are responsible for all equipment necessary
              to access nzbcloud.com services. You understand that the technical
              processing and transmission of nzbcloud.com services, including
              your postings or content, may involve (a) transmissions over
              various networks; and (b) changes to conform and adapt to
              technical requirements of connecting networks or devices.
            </p>
            <p>
              No third-party beneficiaries You agree that, except as otherwise
              expressly provided in this ToS, there shall be no third-party
              beneficiaries to this agreement.
            </p>
            <p>
              Notice nzbcloud.com may provide you with notices, including those
              regarding changes to the ToS, including by but not limited to
              email, regular mail, SMS, MMS, text message, postings on
              nzbcloud.com services, or other reasonable means now known or
              hereafter developed. Such notices may not be received if you
              violate this ToS by accessing the nzbcloud.com Services in an
              unauthorized manner. Your agreement to this ToS constitutes your
              agreement that you are deemed to have received any and all notices
              that would have been delivered had you accessed nzbcloud.com
              services in an authorized manner.
            </p>
            <p>
              General information The ToS constitutes the entire agreement
              between you and nzbcloud.com and governs your use of nzbcloud.com
              services, superseding any prior version of this ToS between you
              and nzbcloud.com with respect to nzbcloud.com services. You also
              may be subject to additional terms and conditions that may apply
              when you use or purchase certain other nzbcloud.com Services,
              affiliate services, third-party content or third-party software.
            </p>
          </Typography>
        </div>
      </Container>
    </>
  );
}
