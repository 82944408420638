import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import QueueIcon from '@material-ui/icons/Queue';
import PublicIcon from '@material-ui/icons/Public';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import {
  setAddIndexerModalClose,
  setAddIndexerModalOpen,
} from '../../../redux/viewsEffects/viewsEffectsActions';
import RecommendedIndexersList from './RecommendedIndexersList';

import {
  hdrSearchAddIndexerSubmit,
  hdrSearchDeleteIndexerSubmit,
  hdrSearchGetIndexersSubmit,
} from '../../../redux/hydraSearch/hydraSearchActions';

import AddIndexerModal from './addIndexerModal';


const useStyles = makeStyles(theme => ({
  accountTabContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  emailWarningWrapper: {
    backgroundColor: '#FCEED6',
    borderRadius: 2,
    padding: 24,
    marginLeft: 32,
    marginRight: 32,
    // width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '16px 24px 16px 24px',
    },
  },
  emailWarningButtonWrapper: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      width: '100%',
    },
  },
  sectionWrapper: {
    padding: '24px 32px 24px 32px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 0px 24px 0px',
    },
  },
  sectionIconWrapper: {
    height: 48,
    width: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
    borderRadius: 2,
  },
  sectionContentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionDataWrapper: {
    marginLeft: 24,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      width: 150,
    },
  },
  sectionTitle: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.87)',
    fontWeight: 500,
  },
  sectionSubTitle: {
    fontSize: 14,
    color: 'rgba(0,0,0,0.38)',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  emailWarningTitle: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 2,
    },
  },
  emailWarningText: {
    color: 'rgba(0,0,0,0.6)',
    fontWeight: 400,
  },

  divider: {
    position: 'relative',
    '&:before': {
      content: "''",
      height: 1,
      background: '#F7F7F7',
      width: '100%',
      position: 'absolute',
      top: 0,
    },
  },
  generalInfoContainer: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 16,
    flex: 1,
    borderRadius: 2,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 16,
      marginBottom: 100,
    },
  },
  profileContainer: {
    backgroundColor: theme.palette.background.paper,
    width: 330,
    borderRadius: 2,
    height: 470,
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mobileProfileContainer: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  profileEmail: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      maxWidth: 156,
    },
  },
  profileAvatarWrapper: {
    width: 140,
    height: 140,
    borderRadius: '50%',
    backgroundColor: 'black',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 24,
    marginTop: 24,
  },
  profileActivePlan: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontWeight: 400,
    fontSize: 14,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  expContent: {
    flexDirection: 'column',
  },
  expRoot: {
    padding: '0 32px 0 32px',
  },
  details: {
    borderTop: '1px solid rgb(247, 247, 247)',
    padding: 0,
  },
  avatarList: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  sectionSubTitlePriority: {
    fontSize: 14,
    color: 'rgba(0,0,0,0.38)',
    fontWeight: 400,
    marginLeft: 15,
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginLeft: 0,
    },
  },
  sectionSubTitleWrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

export default function SearchSettingsTab() {
  const dispatch = useDispatch();

  const classes = useStyles();

  useEffect(() => {
    dispatch(hdrSearchGetIndexersSubmit());
  }, [dispatch]);
  const handleAddIndexerModalOpen = () => {
    dispatch(setAddIndexerModalOpen(true));
  };
  const handleAddIndexerModalClose = () => {
    dispatch(setAddIndexerModalClose(false));
  };
  const handleAddIndexer = (data) => {
    dispatch(hdrSearchAddIndexerSubmit(data));
  };
  const handleDeleteIndexer = (name) => {
    dispatch(hdrSearchDeleteIndexerSubmit(name));
  };
  const {
    isDisabled,
    addIndexerModalIsLoading,
    hdrError,
    indexers,
  } = useSelector(state => state.hydraSearchSettings);
  const { addIndexerModalOpen } = useSelector(state => state.viewsEffects);

  return (
    <div>
      <div
        className={classes.accountTabContainer}
        style={{
          display: 'flex',
        }}
      >
        <div className={classes.profileContainer}>
          <div
            style={{
              paddingTop: 32,
              paddingBottom: 20,
              paddingRight: 32,
              paddingLeft: 32,
              borderBottom: '1px solid rgb(247, 247, 247)',
            }}
          >
            <Typography variant="h6">Recommended Indexers</Typography>
          </div>

          <RecommendedIndexersList />
        </div>
        <div className={classes.mobileProfileContainer}>
          <Accordion defaultExpanded={false} elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
              classes={{ content: classes.expContent, root: classes.expRoot }}
            >
              <div style={{ paddingTop: 18 }}>
                <Typography variant="h6">Recommended Indexers</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <RecommendedIndexersList />
            </AccordionDetails>
          </Accordion>
        </div>
        <div className={classes.generalInfoContainer}>
          {isDisabled ? (
            <div
              style={{
                // position: 'absolute',
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                top: 0,
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {' '}
              <div
                style={{
                  color: '#0086FA',
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    marginRight: 15,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <SettingsEthernetIcon />
                </div>
                Connecting to NZBCloud Search Engine
              </div>
              <div
                style={{
                  color: '#2E5373',
                  fontSize: 12,
                }}
              >
                If connecting takes more than 60 seconds, please reload the
                page.
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  backgroundColor: '#FFF',
                  borderRadius: 2,
                  padding: 32,
                }}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography variant="h6">Indexers</Typography>

                  <Button
                    startIcon={(
                      <div style={{ width: 25, height: 25 }}>
                        <QueueIcon />
                      </div>
                    )}
                    style={{ backgroundColor: '#2158eb', color: '#fff' }}
                    onClick={handleAddIndexerModalOpen}
                  >
                    ADD
                  </Button>
                </div>
                <div>
                  {Array.isArray(indexers) && indexers.map(ind => (
                    <div className={classes.sectionWrapper} key={ind.name}>
                      <div className={classes.sectionContentWrapper}>
                        <div className={classes.sectionIconWrapper}>
                          <PublicIcon style={{ color: 'rgba(0,0,0,0.6)' }} />
                        </div>
                        <div className={classes.sectionDataWrapper}>
                          <div className={classes.sectionTitle}>{ind.name}</div>
                          <div className={classes.sectionSubTitleWrapper}>
                            <div className={classes.sectionSubTitle}>
                              {ind.host}
                            </div>
                            <div className={classes.sectionSubTitlePriority}>
                              Priority:
                              <span
                                style={{
                                  marginLeft: 4,
                                  color: 'rgba(0,0,0,0.6)',
                                }}
                              >
                                {ind.score ? 'HIGH' : 'LOW'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Button
                          color="primary"
                          onClick={() => handleDeleteIndexer(ind.name)}
                          disabled={
                            ind.name === 'Binsearch' || ind.name === 'NZBIndex'
                          }
                        >
                          DELETE
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <AddIndexerModal
        handleClose={handleAddIndexerModalClose}
        open={addIndexerModalOpen}
        handleAddIndexer={handleAddIndexer}
        modalIsLoading={addIndexerModalIsLoading}
        hdrError={hdrError}
      />
    </div>
  );
}
