import {
  call, put, all, takeLatest, take, select,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import socketIOClient from 'socket.io-client';
import { toast } from 'react-toastify';

import { delay } from '@redux-saga/core/effects';
import AppConsts from '../../config/appConsts';
import fileTypeFilter from '../../helpers/fileTypeFilter';
import { nzbFileDownloadingComplete } from '../files/filesActions';
import {
  ioCompressJobsUpdate,
  ioDownloadsUpdate,
  ioExternalDrivePurgeComplete, ioForceNodeUpdate,
  ioForceNotificationsUpdate, ioForceUserUpdate,
  ioHdrSearchIsReady,
  ioTranscodeJobsUpdate,
  mediaFileTranscodeUpdate,
  nzbFileCompressComplete,
  SOCKETS_CONNECTED,
  SOCKETS_DISCONNECTED,
} from './socketsActions';
import { USER_LOGOUT_SUBMIT } from '../login/loginActions';
import { GET_USER_SUCCESS } from '../user/userActions';
import downloadsStatusFilter from '../../helpers/downloadsStatusFilter';
import { usenetInvalidCredentials } from '../usenet/usenetProvidersActions';

const { IO_URL } = AppConsts;

export let socket;


const emitAuth = () => {
  const token = localStorage.getItem('@accessToken');
  socket.emit('AUTH', { token });
};
export function socketConnect() {
  socket = socketIOClient(IO_URL, {
    transports: ['websocket'],
    forceNew: true,
  });
}
export function* startSockets() {
  yield delay(500);
  yield call(socketConnect);
  socket.on('connect', () => { console.log('Sockets Connected'); emitAuth(); });
  socket.on('disconnect', () => { console.log('Sockets disconnected'); });
  socket.on('connect_error', () => { console.log('Sockets Connection Error'); });
  yield put({ type: SOCKETS_CONNECTED });
}
export function userLimitsUpdateChannel() {
  const subscribe = (emitter) => {
    socket.on('DOWNLOAD_LIMIT_EXCEEDED', emitter);

    return () => socket.removeListener('DOWNLOAD_LIMIT_EXCEEDED', emitter);
  };

  return eventChannel(subscribe);
}
export function usenetCredentialsUpdateChannel() {
  const subscribe = (emitter) => {
    socket.on('SC_NZBGET_CREDENTIALS_UPDATE', emitter);

    return () => socket.removeListener('SC_NZBGET_CREDENTIALS_UPDATE', emitter);
  };

  return eventChannel(subscribe);
}

export function externalStoragesPurgeCompleteChanel() {
  const subscribe = (emitter) => {
    socket.on('EXTERNAL_STORAGE_CLEANED', emitter);

    return () => socket.removeListener('EXTERNAL_STORAGE_CLEANED', emitter);
  };

  return eventChannel(subscribe);
}

export function createDownloadsUpdatesChannel() {
  const subscribe = (emitter) => {
    socket.on('DOWNLOADS_UPDATES', emitter);

    return () => socket.removeListener('DOWNLOADS_UPDATES', emitter);
  };

  return eventChannel(subscribe);
}

export function createDownloadsNotificationChannel() {
  const subscribe = (emitter) => {
    socket.on('DOWNLOAD_FINISHED_NOTIFICATION', emitter);

    return () => socket.removeListener('DOWNLOAD_FINISHED_NOTIFICATION', emitter);
  };

  return eventChannel(subscribe);
}
export function createTranscodeNotificationChannel() {
  const subscribe = (emitter) => {
    socket.on('TRANSCODE_FINISHED_NOTIFICATION', emitter);

    return () => socket.removeListener('TRANSCODE_FINISHED_NOTIFICATION', emitter);
  };

  return eventChannel(subscribe);
}
export function createHdrSearchReadyChanel() {
  const subscribe = (emitter) => {
    socket.on('SEARCHER_READY_NOTIFICATION', emitter);

    return () => socket.removeListener('SEARCHER_READY_NOTIFICATION', emitter);
  };

  return eventChannel(subscribe);
}
export function createCompressNotificationChannel() {
  const subscribe = (emitter) => {
    socket.on('COMPRESS_FINISHED_NOTIFICATION', emitter);

    return () => socket.removeListener('COMPRESS_FINISHED_NOTIFICATION', emitter);
  };

  return eventChannel(subscribe);
}

export function createTranscodeJobsUpdatesChannel() {
  const subscribe = (emitter) => {
    socket.on('TRANSCODE_UPDATES', emitter);

    return () => socket.removeListener('TRANSCODE_UPDATES', emitter);
  };

  return eventChannel(subscribe);
}

export function createForceNotificationsUpdatesChannel() {
  const subscribe = (emitter) => {
    socket.on('FORCE_NOTIFICATIONS_UPDATE', emitter);

    return () => socket.removeListener('FORCE_NOTIFICATIONS_UPDATE', emitter);
  };

  return eventChannel(subscribe);
}
export function createForceNodeUpdatesChannel() {
  const subscribe = (emitter) => {
    socket.on('FORCE_NODE_UPDATE', emitter);

    return () => socket.removeListener('FORCE_NODE_UPDATE', emitter);
  };

  return eventChannel(subscribe);
}

export function createForceUserUpdatesChannel() {
  const subscribe = (emitter) => {
    socket.on('FORCE_ME_UPDATE', emitter);

    return () => socket.removeListener('FORCE_ME_UPDATE', emitter);
  };

  return eventChannel(subscribe);
}

export function* ioForceUserUpdateSaga() {
  const forceUserUpdateChanel = yield call(
    createForceUserUpdatesChannel,
  );

  while (true) {
    const forceUserUpdate = yield take(forceUserUpdateChanel);

    yield put(ioForceUserUpdate(forceUserUpdate));
  }
}


export function createCompressJobsUpdatesChannel() {
  const subscribe = (emitter) => {
    socket.on('COMPRESS_UPDATES', emitter);

    return () => socket.removeListener('COMPRESS_UPDATES', emitter);
  };

  return eventChannel(subscribe);
}
export function* ioTranscodeNotificationsSaga() {
  const transcodeNotificationsChanel = yield call(
    createTranscodeNotificationChannel,
  );

  while (true) {
    const transcodeNotifications = yield take(transcodeNotificationsChanel);
    const {
      transcodeNodeFolderContent,
      transcodeStatus,
      name,
    } = transcodeNotifications;
    if (transcodeStatus && transcodeStatus === 'finished') {
      toast.success(`${name} Transcoding complete`);
    } else {
      toast.warn(`${name} Transcode fail`);
    }
    const state = yield select();
    const { path } = state.userStorage;
    if (
      path.length !== 0
        && path[path.length - 1]._id === transcodeNodeFolderContent._id
    ) {
      const { children } = transcodeNodeFolderContent;
      const files = fileTypeFilter(children);
      yield put(mediaFileTranscodeUpdate({ ...files, breadCrumb: path }));
    }
  }
}

export function* ioTranscodeJobsSaga() {
  const transcodeJobsChanel = yield call(createTranscodeJobsUpdatesChannel);

  while (true) {
    const transcodeJobs = yield take(transcodeJobsChanel);

    yield put(ioTranscodeJobsUpdate(transcodeJobs));
  }
}

export function* ioForceNotificationsUpdateSaga() {
  const forceNotificationsUpdateChanel = yield call(
    createForceNotificationsUpdatesChannel,
  );

  while (true) {
    const forceNotificationsUpdate = yield take(forceNotificationsUpdateChanel);

    yield put(ioForceNotificationsUpdate(forceNotificationsUpdate));
  }
}
export function* ioForceNodeUpdateSaga() {
  const forceNotificationsUpdateChanel = yield call(
    createForceNodeUpdatesChannel,
  );

  while (true) {
    const forceNodeUpdate = yield take(forceNotificationsUpdateChanel);

    yield put(ioForceNodeUpdate(forceNodeUpdate));
  }
}
export function* ioExternalStoragePurgeSaga() {
  const purgeCompleteChanel = yield call(externalStoragesPurgeCompleteChanel);
  while (true) {
    const { storageType } = yield take(purgeCompleteChanel);
    toast.success(`${storageType} Cleaning complete`);
    yield put(ioExternalDrivePurgeComplete());
  }
}

export function* ioHdrSearchNotificationSaga() {
  const hdrSearchReadyChanel = yield call(createHdrSearchReadyChanel);

  while (true) {
    const hdrSearchReadyNotification = yield take(hdrSearchReadyChanel);
    yield put(ioHdrSearchIsReady(true));
  }
}

export function* ioCompressJobsSaga() {
  const compressJobsChanel = yield call(createCompressJobsUpdatesChannel);

  while (true) {
    const compressJobs = yield take(compressJobsChanel);

    yield put(ioCompressJobsUpdate(compressJobs));
  }
}
export function* ioDownloadsNotificationsSaga() {
  const downloadsNotificationsChanel = yield call(
    createDownloadsNotificationChannel,
  );

  while (true) {
    const downloadsNotifications = yield take(downloadsNotificationsChanel);
    yield put(nzbFileDownloadingComplete(downloadsNotifications));
  }
}

export function* ioCompressNotificationsSaga() {
  const compressNotificationsChanel = yield call(
    createCompressNotificationChannel,
  );

  while (true) {
    const compressNotifications = yield take(compressNotificationsChanel);
    const state = yield select();
    const { path } = state.userStorage;
    toast.success('Awesome! Your ZIP-file is ready. ');
    if (!path.length) {
      const files = fileTypeFilter(compressNotifications);
      yield put(nzbFileCompressComplete(files));
    }
  }
}
export function* ioUserLimitsUpdateSaga() {
  const usenetCredentialsNotificationChanel = yield call(
    userLimitsUpdateChannel,
  );

  while (true) {
    yield take(usenetCredentialsNotificationChanel);

    toast.warn('Free plan daily limits exceeded.', {
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });


    // yield put(usenetInvalidCredentials(credentials));
  }
}
export function* ioUsenetCredentialsErrorSaga() {
  const usenetCredentialsNotificationChanel = yield call(
    usenetCredentialsUpdateChannel,
  );

  while (true) {
    const { credentials, status } = yield take(usenetCredentialsNotificationChanel);
    if (status === 'ERROR') {
      toast.warn('Usenet Credentials are invalid', {
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }

    yield put(usenetInvalidCredentials(credentials));
  }
}

export function* ioDownloadsUpdatesSaga() {
  const channel = yield call(createDownloadsUpdatesChannel);

  while (true) {
    const updates = yield take(channel);

    const result = downloadsStatusFilter(updates);
    yield put(ioDownloadsUpdate(result));
  }
}
function* socketDisconnect() {
  console.log('disc');
  try {
    if (socket) {
      socket.disconnect();
    } else {
      console.log('no sockets');
    }
  } catch (error) {
    console.log(error);
    yield put({ type: SOCKETS_DISCONNECTED });
  }
}

export default function* socketsSaga() {
  yield all([takeLatest([GET_USER_SUCCESS], startSockets)]);
  yield all([takeLatest([USER_LOGOUT_SUBMIT], socketDisconnect)]);
  yield all([takeLatest([SOCKETS_CONNECTED], ioDownloadsUpdatesSaga)]);
  yield all([takeLatest([SOCKETS_CONNECTED], ioDownloadsNotificationsSaga)]);
  yield all([takeLatest([SOCKETS_CONNECTED], ioTranscodeNotificationsSaga)]);
  yield all([takeLatest([SOCKETS_CONNECTED], ioCompressNotificationsSaga)]);
  yield all([takeLatest([SOCKETS_CONNECTED], ioTranscodeJobsSaga)]);
  yield all([takeLatest([SOCKETS_CONNECTED], ioCompressJobsSaga)]);
  yield all([takeLatest([SOCKETS_CONNECTED], ioHdrSearchNotificationSaga)]);
  yield all([takeLatest([SOCKETS_CONNECTED], ioUsenetCredentialsErrorSaga)]);
  yield all([takeLatest([SOCKETS_CONNECTED], ioExternalStoragePurgeSaga)]);
  yield all([takeLatest([SOCKETS_CONNECTED], ioForceNotificationsUpdateSaga)]);
  yield all([takeLatest([SOCKETS_CONNECTED], ioForceNodeUpdateSaga)]);
  yield all([takeLatest([SOCKETS_CONNECTED], ioUserLimitsUpdateSaga)]);
  yield all([takeLatest([SOCKETS_CONNECTED], ioForceUserUpdateSaga)])
}
