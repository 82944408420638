import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { passwordApplySubmit } from '../../redux/files/filesActions';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 2,
  },
  formInput: {
    padding: '0px 24px',
  },
  submit: {
    marginLeft: 20,
  },
}));
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .label('Password')
    .required('Enter your password.'),
});
export default function DownloadsPasswordModal(props) {
  const classes = useStyles();
  const {
    open, handleOpen, handleClose, name, id,
  } = props;
  const dispatch = useDispatch();

  const handleFormSubmit = (valuesFromForm) => {
    const { password } = valuesFromForm;

    dispatch(passwordApplySubmit({ id, password: password.toString() }));
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Password</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: 'rgba(0,0,0,0.87)' }}
          >
            Please input password for
            {' '}
            <span style={{ fontWeight: 500 }}>{name}</span>
          </DialogContentText>
        </DialogContent>
        <div>
          <Formik
            initialValues={{ password: '' }}
            validationSchema={validationSchema}
            onSubmit={values => handleFormSubmit(values)}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              touched,
              errors,
            }) => (
              <>
                <form>
                  <div className={classes.formInput}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      error={!!(touched.password && errors.password)}
                      fullWidth
                      id="filePassword"
                      label="Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange('password')}
                    />
                    <div style={{ color: 'red', height: 20 }}>
                      {touched.password && errors.password}
                    </div>
                  </div>
                  <div
                    style={{
                      padding: '18px 32px',
                      backgroundColor: '#F7F7F7',
                      borderTop: '1px solid #E0E0E0',
                      justifyContent: 'flex-end',
                      display: 'flex',
                    }}
                  >
                    <Button onClick={handleClose} color="default">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={handleSubmit}
                    >
                      Apply
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
}
